import React, {Component} from 'react';
import { connect } from 'react-redux';

//import * as actionCreators from '../../../../store/actions/index';
import NewsSinglePageComponent from '../../../Components/InnerPages/NewsSinglePage/NewsSinglePage';

class WetterNewsSingle extends Component {
    render(){
        return (<NewsSinglePageComponent token={this.props.toke} {...this.props}/>)
    }
}

const mapStateToProps = state => {
    return {
        token: state.kTokenSubReducer,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        //https://apps.qmet.de/v2/news/id/${props.match.params.id}
        //handleGetKWetternews: (token, page) => dispatch(actionCreators.getKWetternews(token, page)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WetterNewsSingle);
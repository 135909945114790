/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Typography, Breadcrumbs, Divider } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import axios from 'axios';

import cssModule from './LocationSearchResult.module.css';

const useStyles = makeStyles({
    pageHead: {
        fontSize: '2.2rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    pageSubHead: {
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
});

const LocationSearchResult = React.memo((props) => {

    const classes = useStyles();
    let componentOrganizer = null;
    const history = useHistory();

    const [hookResults, setResults] = useState([]);
    const [hookResultText, setResultText] = useState("");

    useEffect(() => {
        try {
            const fetchDateData = async () => {

                const axiosInstance = axios.create({
                    baseURL: 'http://165.227.162.31:8000/v4/',
                    headers: { 'Authorization': `Bearer ${props.userToken.kToken}` }
                });

                await axiosInstance.get(`location/${props.match.params.name}`)
                    .then(response => {
                        if (response && response.data && response.data.data) {
                            if (response.data.data.length > 0) {
                                setResults(response.data.data);
                                setResultText(`Es wurden ${response.data.data.length} Auswahlmöglichkeiten (Suchbegriff: ${ props.match.params.name ? props.match.params.name.toString() : "-" }) gefunden:`)
                            } else {
                                setResults([]);
                                setResultText(`Es wurden keine Optionen gefunden (Suchbegriff: ${props.match.params.name ? props.match.params.name.toString() : "-" }).`);
                            }
                        }
                        else {
                            setResults([]);
                            setResultText(`Es wurden keine Optionen gefunden (Suchbegriff: ${props.match.params.name ? props.match.params.name.toString() : "-"}).`);
                        }
                    })
                    .catch(error => {
                        setResults([]);
                        setResultText(`Es wurden keine Optionen gefunden (Suchbegriff: ${props.match.params.name ? props.match.params.name.toString() : "-"}).`);
                    })
            }
            if (props.userToken.kToken && props.userToken.kToken.length > 0 && props.match && props.match.params && props.match.params.name){
                fetchDateData();
            }
        } catch (error) {
            console.log('202103101531', error);
        }
    }, [props.match.params.name, props.userToken]);

    const setLocation = (argLocation) => {
        try{
            if(argLocation){
                props.setLocation(argLocation);
                history.push("/");
            }
        }catch(error){
            console.log('202103101532',error);
        }
    }

    try{
    if (hookResults.length > 0){
        componentOrganizer = hookResults.map((ort, index) => {
            return(
                <React.Fragment key={index}>
                    { index === 0 ? null : <Divider/>}
                    <div className={cssModule.LocationListItem} onClick={() => setLocation(ort)}>{ort.name.toString()}</div>
                </React.Fragment>
            )
        })
    }
    } catch (error) {console.log('202103101533', error);}

return(
    <React.Fragment>
        <div className={cssModule.LocationSearchResultParent}>
            <div className={cssModule.HeadSection}>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    <Link className={cssModule.BreadcrubsLink} to="/" color="inherit">Home</Link>
                    <Link className={cssModule.BreadcrubsNonLink} color="inherit">Wetter</Link>
                    <Link className={cssModule.BreadcrubsNonLink} color="inherit">Suche</Link>
                </Breadcrumbs>
            </div>

            <div className={cssModule.DataSection}>
                <Typography className={classes.pageHead} variant="h2" component="h2" >Ihre Suchergebnisse</Typography>
                <Typography className={classes.pageSubHead} variant="h6">{hookResultText}</Typography>
            </div>

            <div className={cssModule.ListSection}>
                {componentOrganizer}
            </div>
        </div>
    </React.Fragment>
);

});

export default LocationSearchResult;
import React, {memo} from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import cssModule from './BiowetterDetailsPage.module.css';

import icon_bio1_red from '../../../../../Assets/Desktop/PollenIcons/icon_bio1_red.svg';
import icon_bio2_red from '../../../../../Assets/Desktop/PollenIcons/icon_bio2_red.svg';
import icon_bio3_red from '../../../../../Assets/Desktop/PollenIcons/icon_bio3_red.svg';
import icon_bio4_red from '../../../../../Assets/Desktop/PollenIcons/icon_bio4_red.svg';
import icon_bio1_orange from '../../../../../Assets/Desktop/PollenIcons/icon_bio1_orange.svg';
import icon_bio2_orange from '../../../../../Assets/Desktop/PollenIcons/icon_bio2_orange.svg';
import icon_bio3_orange from '../../../../../Assets/Desktop/PollenIcons/icon_bio3_orange.svg';
import icon_bio4_orange from '../../../../../Assets/Desktop/PollenIcons/icon_bio4_orange.svg';
import icon_bio1_yellow from '../../../../../Assets/Desktop/PollenIcons/icon_bio1_yellow.svg';
import icon_bio2_yellow from '../../../../../Assets/Desktop/PollenIcons/icon_bio2_yellow.svg';
import icon_bio3_yellow from '../../../../../Assets/Desktop/PollenIcons/icon_bio3_yellow.svg';
import icon_bio4_yellow from '../../../../../Assets/Desktop/PollenIcons/icon_bio4_yellow.svg';
import icon_bio1_green from '../../../../../Assets/Desktop/PollenIcons/icon_bio1_green.svg';
import icon_bio2_green from '../../../../../Assets/Desktop/PollenIcons/icon_bio2_green.svg';
import icon_bio3_green from '../../../../../Assets/Desktop/PollenIcons/icon_bio3_green.svg';
import icon_bio4_green from '../../../../../Assets/Desktop/PollenIcons/icon_bio4_green.svg';

import ImgBiowetter from '../../../../../Assets/Desktop/Common/AdobeStock_3559268_Elvira Schäfer.jpg';
import { display } from '@material-ui/system';

const useStyles = makeStyles((theme) => ({
    lagentParent: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        marginTop:'2rem',
        marginBottom:'2rem'
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '15px'
    },
    lagentHeading: {
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginRight: '15px',
        lineHeight:'1.5'
    },
    lagentItem:{
        display: 'flex',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginLeft:'.7rem',
        marginRight:'.7rem',
        lineHeight:'1.5'
    },
    lagentColor:{
        height: '27px',
        width: '27px',
        borderRadius:'50%',
        marginRight:'.5rem'
    },
    colorKeine:{
        background:'#6AE200'
    },
    colorNiedrig:{
        background:'#E2CC03'
    },
    colorMasig:{
        background:'#FF9000'
    },
    colorStark:{
        background:'#E6143C'
    },
    lagentData:{
        display:'flex',
    },
    lagentDataImage:{
        
    },
    lagentDataText:{
        padding:'0px 10px',
        display:'flex',
        alignItems:'center'
    },
    bioImage:{
        maxWidth: '100%',
        display: 'block',
        height: 'auto',
        borderRadius:'5px'
    },
    bioImageParent:{
        margin:'0px',
        width:'300px'
    },
    bioImageCaption:{
        fontSize:'.8rem',
        marginTop:'5px',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '400'
    }
}));

let circulationArray, airwayArray, powerArray, painArray, splitArray = [];
let circulationComponent, airwayComponent, powerComponent, painComponent = null;

const getBiowetterIntensity = (argValue, argLevel) => {
    try {
        /*if (parseInt(argLevel) === 9010) {
            if (parseInt(argValue) === 1) {
                return ("Niedrig");
            }
            else {
                return ("Keine");
            }
        } else if (parseInt(argLevel) === 9020) {
            if (parseInt(argValue) === 1) {
                return ("Stark");
            }
            else {
                return ("Keine");
            }
        } else {
            return ("Keine");
        }*/

        if(parseInt(argLevel) === 9000){
            return ("Keine");
        }else if(parseInt(argLevel) === 9010){
            if (parseInt(argValue) === 1) {
                return ("Niedrig");
            }
            else {
                return ("Keine");
            }
        }else if (parseInt(argLevel) === 9020) {
            if (parseInt(argValue) === 1) {
                return ("mäßig");
            }
            else {
                return ("Keine");
            }
        }
        else if (parseInt(argLevel) === 9030) {
            if (parseInt(argValue) === 1) {
                return ("Stark");
            }
            else {
                return ("Keine");
            }
        }else {
            return('-');
        }
    } catch (error) { console.log('202103101511', error) }
}

const getGermanName = (argBioName) => {
    try {
        switch (argBioName) {
            case "High Blood Pressure": return ("Hoher Blutdruck");
            case "Low Blood Pressure": return ("Niedriger Blutdruck");
            case "Angina Pectoris": return ("Angina Pectoris");
            case "Heart": return ("Herz");
            case "Circuit": return ("Kreislauf");
            case "Indisposition": return ("Unwohlsein");
            case "Airways": return ("Atemwege");
            case "Common Cold": return ("Erkältung");
            case "Asthma": return ("Asthma");
            case "Concentration Capacity": return ("Leistungsfähigkeit");
            case "Capacity": return ("Konzentration");
            case "Response Time": return ("Reaktion");
            case "Insomania": return ("Schlafprobleme");
            case "Headache": return ("Kopfschmerzen");
            case "Migrane": return ("Migräne");
            case "Phantom Pain": return ("Phantomschmerzen");
            case "Rheumatism": return ("Rheuma");
            case "Gout": return ("Gicht");
            case "Colics": return ("Koliken");
            case "Cramps": return ("Krämpfe");
            case "crapms": return ("Krämpfe");
            default: return argBioName;
        }
    } catch (error) { console.log('202103101512', error) }
}

const getBiowetterIntensColor = (argValue, argLevel, argType) => {

    try {

        if (argType === "circulation"){
            if (parseInt(argLevel) === 9010) {
                if (parseInt(argValue) === 1) {
                    return icon_bio2_yellow;
                }
                else {
                    return icon_bio2_green;
                }
            } else if (parseInt(argLevel) === 9020) {
                if (parseInt(argValue) === 1) {
                    return icon_bio2_orange;
                }
                else {
                    return icon_bio2_green;
                }
            }else if (parseInt(argLevel) === 9030) {
                if (parseInt(argValue) === 1) {
                    return icon_bio2_red;
                }
                else {
                    return icon_bio2_green;
                }
            } else {
                return icon_bio2_green;
            }
        } else if (argType === "airway"){
            if (parseInt(argLevel) === 9010) {
                if (parseInt(argValue) === 1) {
                    return icon_bio1_yellow;
                }
                else {
                    return icon_bio1_green;
                }
            } else if (parseInt(argLevel) === 9020) {
                if (parseInt(argValue) === 1) {
                    return icon_bio1_orange;
                }
                else {
                    return icon_bio1_green;
                }
            } else if (parseInt(argLevel) === 9030) {
                if (parseInt(argValue) === 1) {
                    return icon_bio1_red;
                }
                else {
                    return icon_bio1_green;
                }
            } else {
                return icon_bio1_green;
            }
        } else if (argType ==="power"){
            if (parseInt(argLevel) === 9010) {
                if (parseInt(argValue) === 1) {
                    return icon_bio4_yellow;
                }
                else {
                    return icon_bio4_green;
                }
            } else if (parseInt(argLevel) === 9020) {
                if (parseInt(argValue) === 1) {
                    return icon_bio4_orange;
                }
                else {
                    return icon_bio4_green;
                }
            }
            else if (parseInt(argLevel) === 9030) {
                if (parseInt(argValue) === 1) {
                    return icon_bio4_red;
                }
                else {
                    return icon_bio4_green;
                }
            } else {
                return icon_bio4_green;
            }
        } else if (argType === "pain"){
            if (parseInt(argLevel) === 9010) {
                if (parseInt(argValue) === 1) {
                    return icon_bio3_yellow;
                }
                else {
                    return icon_bio3_green;
                }
            } else if (parseInt(argLevel) === 9020) {
                if (parseInt(argValue) === 1) {
                    return icon_bio3_orange;
                }
                else {
                    return icon_bio3_green;
                }
            }else if (parseInt(argLevel) === 9030) {
                if (parseInt(argValue) === 1) {
                    return icon_bio3_red;
                }
                else {
                    return icon_bio3_green;
                }
            } else {
                return icon_bio3_green;
            }
        }

    } catch (error) { console.log('202103101514', error) }
}

function chunkArray(arr, chunkCount) {
    try{
    const chunks = [];
    while(arr.length) {
      const chunkSize = Math.ceil(arr.length / chunkCount--);
      const chunk = arr.slice(0, chunkSize);
      chunks.push(chunk);
      arr = arr.slice(chunkSize);
    }
    return chunks;
    }catch(error){}
  }

const BiowetterDetailsComponent = memo((props) => {

    const classes = useStyles();

    try{

    if (props.componentData && props.componentData.level && props.componentData.biowetter_data && props.componentData.biowetter_data.length > 0){
        /*circulationArray = props.componentData.biowetter_data.filter(item => item.type.toString() === "circulation");
        airwayArray = props.componentData.biowetter_data.filter(item => item.type.toString() === "airway");
        powerArray = props.componentData.biowetter_data.filter(item => item.type.toString() === "power");
        painArray = props.componentData.biowetter_data.filter(item => item.type.toString() === "pain");*/

        splitArray = chunkArray(props.componentData.biowetter_data, 4);

        if(splitArray && splitArray[0] && splitArray[0].length > 0){
            circulationComponent = splitArray[0].map((item, index) => {
                //if(index <=2){
                    return(<div className={cssModule.BiowetterItemsColumn} key={index}>
                        <div className={cssModule.BiowetterIcon}>
                            <div style={{ backgroundImage: `url(${getBiowetterIntensColor(parseInt(item.val), parseInt(props.componentData.level), item.type.toString())})` }}></div>
                        </div>
                        <div className={cssModule.BiowetterValues}>
                            <div className={cssModule.BiowetterName}>{getGermanName(item.name)}</div>
                            <div className={cssModule.BiowetterStrength}>{getBiowetterIntensity(parseInt(item.val), parseInt(props.componentData.level))}</div>
                        </div>
                    </div>)
                //}else{return null;}
            });
        }

        if (splitArray && splitArray[1] && splitArray[1].length > 0){
            airwayComponent = splitArray[1].map((item, index) => {
                //if (index <= 2) {
                    return (<div className={cssModule.BiowetterItemsColumn} key={index}>
                        <div className={cssModule.BiowetterIcon}>
                            <div style={{ backgroundImage: `url(${getBiowetterIntensColor(parseInt(item.val), parseInt(props.componentData.level), item.type.toString())})` }}></div>
                        </div>
                        <div className={cssModule.BiowetterValues}>
                            <div className={cssModule.BiowetterName}>{getGermanName(item.name)}</div>
                            <div className={cssModule.BiowetterStrength}>{getBiowetterIntensity(parseInt(item.val), parseInt(props.componentData.level))}</div>
                        </div>
                    </div>)
                //} else {return null;}
            });
        }

        if (splitArray && splitArray[2] && splitArray[2].length > 0){
            powerComponent = splitArray[2].map((item, index) => {
                //if (index <= 2) { to display all power
                    return (<div className={cssModule.BiowetterItemsColumn} key={index}>
                        <div className={cssModule.BiowetterIcon}>
                            <div style={{ backgroundImage: `url(${getBiowetterIntensColor(parseInt(item.val), parseInt(props.componentData.level), item.type.toString())})` }}></div>
                        </div>
                        <div className={cssModule.BiowetterValues}>
                            <div className={cssModule.BiowetterName}>{getGermanName(item.name)}</div>
                            <div className={cssModule.BiowetterStrength}>{getBiowetterIntensity(parseInt(item.val), parseInt(props.componentData.level))}</div>
                        </div>
                    </div>)
                //} else {return null;}
            });
        }

        if (splitArray && splitArray[3] && splitArray[3].length > 0) {
            painComponent = splitArray[3].map((item, index) => {
                //if (index <= 2) { to display all pain
                    return (<div className={cssModule.BiowetterItemsColumn} key={index}>
                        <div className={cssModule.BiowetterIcon}>
                            <div style={{ backgroundImage: `url(${getBiowetterIntensColor(parseInt(item.val), parseInt(props.componentData.level), item.type.toString())})` }}></div>
                        </div>
                        <div className={cssModule.BiowetterValues}>
                            <div className={cssModule.BiowetterName}>{getGermanName(item.name)}</div>
                            <div className={cssModule.BiowetterStrength}>{getBiowetterIntensity(parseInt(item.val), parseInt(props.componentData.level))}</div>
                        </div>
                    </div>)
                //} else {return null;}
            });
        }

    }

    } catch (error) { console.log('202103101515', error) }

    return(
        <React.Fragment>
            <div className={cssModule.BiowetterItems}>
                <div className={cssModule.BiowetterItemsRow}>
                    {circulationComponent}
                </div>
                <div className={cssModule.BiowetterItemsRow}>
                    {airwayComponent}
                </div>
                <div className={cssModule.BiowetterItemsRow}>
                    {powerComponent}
                </div>
                <div className={cssModule.BiowetterItemsRow}>
                    {painComponent}
                </div>
            </div>
            <div className={classes.lagentParent}>
                <div className={classes.lagentHeading}>Biowetter:</div>
                <div className={classes.lagentItem}>
                    <span className={[classes.lagentColor, classes.colorKeine].join(" ")}></span>
                    <span className={classes.lagentName}>Keine</span>
                </div>
                <div className={classes.lagentItem}>
                    <span className={[classes.lagentColor, classes.colorNiedrig].join(" ")}></span>
                    <span className={classes.lagentName}>Niedrig</span>
                </div>
                <div className={classes.lagentItem}>
                    <span className={[classes.lagentColor, classes.colorMasig].join(" ")}></span>
                    <span className={classes.lagentName}>mäßig</span>
                </div>
                <div className={classes.lagentItem}>
                    <span className={[classes.lagentColor, classes.colorStark].join(" ")}></span>
                    <span className={classes.lagentName}>Stark</span>
                </div>
            </div>
            <div className={classes.lagentData}>
                <div className={classes.lagentDataImage}>
                    <figure className={classes.bioImageParent}>
                        <img src={ImgBiowetter} alt="Biowetter" className={classes.bioImage}/>
                    <figcaption className={classes.bioImageCaption}>Elvira Schäfer / AdobeStock</figcaption>
                    </figure>
                </div>
                <div className={classes.lagentDataText}>
                    <Typography className={classes.data} variant="body1" component="p">
                        In Deutschland sind Millionen Menschen wetterfühlig, d.h. sie reagieren mehr oder weniger stark auf Wetteränderungen. 
                        Meist liegen bei diesen Menschen bereits Vorerkrankungen wie Herz-Kreislaufprobleme oder rheumatische Beschwerden vor. 
                        Diese können durch bestimmte Wetterlagen beeinflusst werden. Beim Biowetter von wetter.net bekommen Sie interessante 
                        Anhaltspunkte über den aktuellen Einfluss der Wetterlage auf Ihr Wohlbefinden vermittelt.
                    </Typography>
                </div>
            </div>
        </React.Fragment>
    )

});

export default BiowetterDetailsComponent;
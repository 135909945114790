/* eslint-disable no-unreachable */
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import './index.css';
import 'leaflet/dist/leaflet.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import throttle from 'lodash.throttle';

import { loadState, saveState} from './localStorage';

//import reducer from './store/reducer';
import counterReducer from './store/reducers/counter';
import resultReducer from './store/reducers/result';
import locationReducer from './store/reducers/location';
import settingsReducer from './store/reducers/settings';
import tokenReducer from './store/reducers/token';
import homeReducer from './store/reducers/home';
import newsReducer from './store/reducers/news';
import videoReducer from './store/reducers/video';
import regenReducer from './store/reducers/regen';
import biowetterReducer from './store/reducers/biowetter';
import pollenReducer from './store/reducers/pollen';

import kTokenReducer from './store/reducers/kToken';
import kLocationReducer from './store/reducers/kLocation';
import kAktuelleReducer from './store/reducers/kAktuelle';
import kBiowetterReducer from './store/reducers/kBiowetter';
import kdTagesverlaufReducer from './store/reducers/kdTagesverlauf';
import kdVierzehntagesprognoseReducer from './store/reducers/kdVierzehntagesprognose';
import kPollenflugReducer from './store/reducers/kPollenflug';
import ksTagesverlaufReducer from './store/reducers/ksTagesverlauf';
import ksVierzehntagesprognoseReducer from './store/reducers/ksVierzehntagesprognose';
import kWetternewsReducer from './store/reducers/kWetternews';
import kWettertvReducer from './store/reducers/kWettertv';
import kGraphDataReducer from './store/reducers/KGraphData';
import kGrosswetterlagenkarte from './store/reducers/kGrosswetterlagenkarte';
import kGartenWetterReducer from './store/reducers/kGartenWetter';
import kGartenTippReducer from './store/reducers/kGartenTipp';
import kWetterLexikonReducer from './store/reducers/kWetterLexikon';
import kPollenKalender from './store/reducers/kPollenKalender';


const rootReducer = combineReducers({
  //counterSubReducer: counterReducer,
  //resultSubReducer: resultReducer,
  locationSubReducer: locationReducer,
  locationSubReducer1: locationReducer,
  settingsSubReducer: settingsReducer,
  tokenSubReducer: tokenReducer,
  homeSubReducer: homeReducer,
  newsSubReducer: newsReducer,
  videoSubReducer: videoReducer,
  regenSubReducer: regenReducer,
  biowetterSubReducer: biowetterReducer,
  pollenSubReducer: pollenReducer,

  kTokenSubReducer: kTokenReducer,
  kLocationSubReducer: kLocationReducer,
  kAktuelleSubReducer: kAktuelleReducer,
  kBiowetterSubReducer: kBiowetterReducer,
  kdTagesverlaufSubReducer: kdTagesverlaufReducer,
  kdVierzehntagesprognoseSubReducer: kdVierzehntagesprognoseReducer,
  kPollenflugSubReducer: kPollenflugReducer,
  ksTagesverlaufSubReducer: ksTagesverlaufReducer,
  ksVierzehntagesprognoseSubReducer: ksVierzehntagesprognoseReducer,
  kWetternewsSubReducer: kWetternewsReducer,
  kWettertvSubReducer: kWettertvReducer,
  kGraphDataSubReducer: kGraphDataReducer,
  kGrosswetterlagenkarteSubReducer: kGrosswetterlagenkarte,
  kGartenWetterSubReducer: kGartenWetterReducer,
  kGartenTippSubReducer: kGartenTippReducer,
  kWetterLexikonSubReducer: kWetterLexikonReducer,
  kPollenKalenderSubReducer: kPollenKalender,
});

const logger = (store) => {
  try{
  return (next) => {
    return (action) => {
      //console.log('[middlewarer] dispatching' ,action);
      const result = next(action);
      //console.log('[Middleware] next state' , store.getState())
      return result;
    }
  }
  } catch (error) { console.log('202102191600', error) }
}

const persistedState = loadState();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(logger, thunk)));

store.subscribe(throttle(() => {
  try{
  saveState({
    location: store.getState().locationSubReducer,
    settings: store.getState().settingsSubReducer,
    token: store.getState().tokenSubReducer,
    homeData: store.getState().homeSubReducer,
    video: store.getState().videoSubReducer,
    news: store.getState().newsSubReducer,
    regen: store.getState().regenSubReducer,
    biowetter: store.getState().biowetterSubReducer,
    pollen: store.getState().pollenSubReducer,
    kToken: store.getState().kTokenSubReducer,
    kLocation: store.getState().kLocationSubReducer,
    kWettertv: store.getState().kWettertvSubReducer,
    kWetternews: store.getState().kWetternewsSubReducer,
    kGraphData: store.getState().kGraphDataSubReducer,
    kAktuelle: store.getState().kAktuelleSubReducer,
    ksTagesvedrlauf: store.getState().ksTagesverlaufSubReducer,
    kPollenflug: store.getState().kPollenflugSubReducer,
    kBiowetter: store.getState().kBiowetterSubReducer,
    kGrosswetterlagenkarte: store.getState().kGrosswetterlagenkarteSubReducer,
    kGartenWetter: store.getState().kGartenWetterSubResucer,
    kGartenTipp: store.getState().kGartenTippSubReducer,
    kWetterLexikon: store.getState().kWetterLexikonSubReducer,
    kPollenKalender: store.getState().kPollenKalenderSubReducer,
  });
  } catch (error) { console.log('202102191601', error) }
}));

console.log('store', store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

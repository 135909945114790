import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import cssModule from './PollenflugDetailsPage.module.css';
import ImgPollen from '../../../../../Assets/Desktop/Common/pollen.jpg';

import icon_pollen1_red from '../../../../../Assets/Desktop/PollenIcons/icon_pollen1_red.svg';
//import icon_pollen2_red from '../../../../Assets/Desktop/PollenIcons/icon_pollen2_red.svg';
import icon_pollen3_red from '../../../../../Assets/Desktop/PollenIcons/icon_pollen3_red.svg';
//import icon_pollen4_red from '../../../../Assets/Desktop/PollenIcons/icon_pollen4_red.svg';
import icon_pollen1_orange from '../../../../../Assets/Desktop/PollenIcons/icon_pollen1_orange.svg';
//import icon_pollen2_orange from '../../../../Assets/Desktop/PollenIcons/icon_pollen2_orange.svg';
import icon_pollen3_orange from '../../../../../Assets/Desktop/PollenIcons/icon_pollen3_orange.svg';
//import icon_pollen4_orange from '../../../../Assets/Desktop/PollenIcons/icon_pollen4_orange.svg';
import icon_pollen1_yellow from '../../../../../Assets/Desktop/PollenIcons/icon_pollen1_yellow.svg';
//import icon_pollen2_yellow from '../../../../Assets/Desktop/PollenIcons/icon_pollen2_yellow.svg';
import icon_pollen3_yellow from '../../../../../Assets/Desktop/PollenIcons/icon_pollen3_yellow.svg';
//import icon_pollen4_yellow from '../../../../Assets/Desktop/PollenIcons/icon_pollen4_yellow.svg';
import icon_pollen1_green from '../../../../../Assets/Desktop/PollenIcons/icon_pollen1_green.svg';
//import icon_pollen2_green from '../../../../Assets/Desktop/PollenIcons/icon_pollen2_green.svg';
import icon_pollen3_green from '../../../../../Assets/Desktop/PollenIcons/icon_pollen3_green.svg';
//import icon_pollen4_green from '../../../../Assets/Desktop/PollenIcons/icon_pollen4_green.svg';

const useStyles = makeStyles((theme) => ({
    lagentParent: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        marginTop:'2rem',
        marginBottom:'2rem'
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '15px'
    },
    lagentHeading: {
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginRight: '10px',
        lineHeight:'1.5'
    },
    lagentItem:{
        display: 'flex',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginLeft:'.5rem',
        marginRight:'.5rem',
        lineHeight:'1.5'
    },
    lagentColor:{
        height: '27px',
        width: '27px',
        borderRadius:'50%',
        marginRight:'.5rem'
    },
    colorKeine:{
        background:'#6AE200'
    },
    colorNiedrig:{
        background:'#E2CC03'
    },
    colorMasig:{
        background:'#FF9000'
    },
    colorStark:{
        background:'#E6143C'
    },
    lagentData:{
        display:'flex',
    },
    lagentDataImage:{
        
    },
    lagentDataText:{
        padding:'0px 10px',
        display:'flex',
        alignItems:'center'
    },
    bioImage:{
        maxWidth: '100%',
        display: 'block',
        height: 'auto',
        borderRadius:'5px'
    },
    bioImageParent:{
        margin:'0px',
        width:'300px'
    },
    bioImageCaption:{
        fontSize:'.8rem',
        marginTop:'5px',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '400'
    }
}));

let grassComponent1, grassComponent2, treeComponent1, treeComponent2 = null;

const getPollenIntensity = (argValue) => {
    try {
        /*switch (argValue) {
            case 0: return "keine";
            case 1: return "gering";
            case 2: return "mäßig";
            case 3: return "stark";
            case 4: return "sehr stark";
            default: return "niedrig";
        }*/
        switch (argValue) {
            case 0:
                    return "keine";
            case 1:
                    return "kein bis schwach";
            case 2:
                    return "schwach";
            case 3:
                    return "schwach bis mäßig";
            case 4:
                    return "mäßig";
            case 5:
                    return "mäßig bis stark";
            case 6:
                    return "stark";
            default:
                    return "-";
        }
    } catch (error) { console.log('202103041605', error) }
}

const getPollenIcon = (argValue, argType) => {
    try{
        if(argType === "tree"){
            if (parseInt(argValue) === 0) {
                return icon_pollen3_green;
            } else if (parseInt(argValue) === 1 || parseInt(argValue) === 2) {
                return icon_pollen3_yellow;
            }else if (parseInt(argValue) === 3 || parseInt(argValue) === 4) {
                return icon_pollen3_orange;
            }else if (parseInt(argValue) === 5 || parseInt(argValue) === 6) {
                return icon_pollen3_red;
            } else {
                return icon_pollen3_green
            }
        }else if(argType === "grass"){
            if (parseInt(argValue) === 0) {
                return icon_pollen1_green;
            } else if (parseInt(argValue) === 1 || parseInt(argValue) === 2) {
                return icon_pollen1_yellow;
            }else if (parseInt(argValue) === 3 || parseInt(argValue) === 4) {
                return icon_pollen1_orange;
            }else if (parseInt(argValue) === 5 || parseInt(argValue) === 6) {
                return icon_pollen1_red;
            } else {
                return icon_pollen1_green
            }
        }

    }catch(error) {console.log('202103041614', error)}
}

function chunkArray(arr, chunkCount) {
    try{
    const chunks = [];
    while(arr.length) {
      const chunkSize = Math.ceil(arr.length / chunkCount--);
      const chunk = arr.slice(0, chunkSize);
      chunks.push(chunk);
      arr = arr.slice(chunkSize);
    }
    return chunks;
    }catch(error){}
  }

const PollenflugDetailsComponent = React.memo((props)=>{

    const classes = useStyles();

    let pollenArray = [];
    let splitArray = [];

    try{

        if (props.componentData && props.componentData.grass && props.componentData.grass.length > 0){
           let pollenArrayGrass = props.componentData.grass.map((pollen, index) => {
                return({Datum: pollen.Datum, Pollenart: pollen.Pollenart, value: pollen.value, type: "grass"});
            });
            pollenArray = pollenArray.concat(pollenArrayGrass);
        }

        if (props.componentData && props.componentData.tree && props.componentData.tree.length > 0) {
            let pollenArrayTree = props.componentData.tree.map((pollen, index) => {
                return({Datum: pollen.Datum, Pollenart: pollen.Pollenart, value: pollen.value, type: "tree"});
            });
            pollenArray = pollenArray.concat(pollenArrayTree);
        }

        if(pollenArray.length && pollenArray.length > 0){
            splitArray= chunkArray(pollenArray, 4);
        }

        if(splitArray.length > 0){
            if(splitArray && splitArray[0] && splitArray[0].length > 0){
                grassComponent1 = splitArray[0].map((pollen, index) => {
                        return (<div className={cssModule.PollenflugItemsColumn} key={index}>
                                    <div className={cssModule.PollenflugIcon}>
                                <div style={{ backgroundImage: `url(${getPollenIcon(parseInt(pollen.value), pollen.type.toString())})` }}></div>
                                    </div>
                                    <div className={cssModule.PollenflugValues}>
                                <div className={cssModule.PollenflugName}>{pollen.Pollenart}</div>
                                <div className={cssModule.PollenflugStrength}>{getPollenIntensity(parseInt(pollen.value))}</div>
                                    </div>
                                </div>);
                });
            }

            if(splitArray && splitArray[1] && splitArray[1].length > 0){
                grassComponent2 = splitArray[1].map((pollen, index) => {
                        return (<div className={cssModule.PollenflugItemsColumn} key={index}>
                            <div className={cssModule.PollenflugIcon}>
                                <div style={{ backgroundImage: `url(${getPollenIcon(parseInt(pollen.value), pollen.type.toString())})` }}></div>
                            </div>
                            <div className={cssModule.PollenflugValues}>
                                <div className={cssModule.PollenflugName}>{pollen.Pollenart}</div>
                                <div className={cssModule.PollenflugStrength}>{getPollenIntensity(parseInt(pollen.value))}</div>
                            </div>
                        </div>);
                });
            }

            if(splitArray && splitArray[2] && splitArray[2].length > 0){
                treeComponent1 = splitArray[2].map((pollen, index) => {
                        return (<div className={cssModule.PollenflugItemsColumn} key={index}>
                            <div className={cssModule.PollenflugIcon}>
                                <div style={{ backgroundImage: `url(${getPollenIcon(parseInt(pollen.value), pollen.type.toString())})` }}></div>
                            </div>
                            <div className={cssModule.PollenflugValues}>
                                <div className={cssModule.PollenflugName}>{pollen.Pollenart}</div>
                                <div className={cssModule.PollenflugStrength}>{getPollenIntensity(parseInt(pollen.value))}</div>
                            </div>
                        </div>);
                });
            }

            if(splitArray && splitArray[3] && splitArray[3].length > 0){
                treeComponent2 = splitArray[3].map((pollen, index) => {
                        return (<div className={cssModule.PollenflugItemsColumn} key={index}>
                            <div className={cssModule.PollenflugIcon}>
                                <div style={{ backgroundImage: `url(${getPollenIcon(parseInt(pollen.value), pollen.type.toString())})` }}></div>
                            </div>
                            <div className={cssModule.PollenflugValues}>
                                <div className={cssModule.PollenflugName}>{pollen.Pollenart}</div>
                                <div className={cssModule.PollenflugStrength}>{getPollenIntensity(parseInt(pollen.value))}</div>
                            </div>
                        </div>);
                });
            }

        }

    } catch (error) { console.log('202103101529', error) }

    return(
        <React.Fragment>
        <div className={cssModule.PollenflugItems}>
            <div className={cssModule.PollenflugItemsRow}>
                {grassComponent1}
            </div>
            <div className={cssModule.PollenflugItemsRow}>
                {grassComponent2}
            </div>
            <div className={cssModule.PollenflugItemsRow}>
                {treeComponent1}
            </div>
            <div className={cssModule.PollenflugItemsRow}>
                {treeComponent2}
            </div>
        </div>
        <div className={classes.lagentParent}>
                <div className={classes.lagentHeading}>Pollenflug:</div>
                <div className={classes.lagentItem}>
                    <span className={[classes.lagentColor, classes.colorKeine].join(" ")}></span>
                    <span className={classes.lagentName}>Keine</span>
                </div>
                <div className={classes.lagentItem}>
                    <span className={[classes.lagentColor, classes.colorNiedrig].join(" ")}></span>
                    <span className={classes.lagentName}>kein bis schwach / schwach</span>
                </div>
                <div className={classes.lagentItem}>
                    <span className={[classes.lagentColor, classes.colorMasig].join(" ")}></span>
                    <span className={classes.lagentName}>schwach bis mäßig / mäßig</span>
                </div>
                <div className={classes.lagentItem}>
                    <span className={[classes.lagentColor, classes.colorStark].join(" ")}></span>
                    <span className={classes.lagentName}>mäßig bis stark / stark</span>
                </div>
            </div>
            <div className={classes.lagentData}>
                <div className={classes.lagentDataImage}>
                    <figure className={classes.bioImageParent}>
                        <img src={ImgPollen} alt="Biowetter" className={classes.bioImage}/>
                    <figcaption className={classes.bioImageCaption}>Ingo Bartussek / AdobeStock</figcaption>
                    </figure>
                </div>
                <div className={classes.lagentDataText}>
                    <Typography className={classes.data} variant="body1" component="p">
                        In Deutschland leiden schätzungsweise 20 bis 30 Millionen Menschen an Allergien. Das sind beispielsweise Nahrungsmittel-, 
                        Arzneimittel- oder Pollenallergien. Rund jeder fünfte Deutsche leidet an Heuschnupfen und plagt sich Jahr für Jahr aufs 
                        Neue mit den Pollen herum. Laut Medizinern ist der allergische Schnupfen die häufigste Allergie im Land. Um 
                        Pollenallergikern das Leben etwas leichter zu machen, bietet Ihnen wetter.net eine ausführliche Pollenvorhersage an.
                        Es werden die allergensten Pollen aufgeführt.
                    </Typography>
                </div>
            </div>
        </React.Fragment>
    )

});

export default PollenflugDetailsComponent;
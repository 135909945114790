import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getKGraphData = (argToken, argLat, argLon, argDate) => {
    try {
        return dispatch => {
            dispatch(getKGraphDataStarted());
            const axiosInstance = axios.create({
                baseURL: 'http://165.227.162.31:8000/v4/', /*https://apps.qmet.de/v2/*/
                headers: { 'Authorization': `Bearer ${argToken}` }
            });

            axiosInstance.post(`graph_data`, { "lat": argLat, "lon": argLon, "date": argDate })
                .then(response => {
                    if (response && response.data && response.data.data) {
                        dispatch(getKGraphDataSuccess(response.data.data));
                    }
                    else {
                        dispatch(getKGraphDataFailure('Something went wrong'));
                    }
                })
                .catch(error => {
                    dispatch(getKGraphDataFailure(error.message));
                })
        };
        // eslint-disable-next-line no-unreachable
    } catch (error) { console.log('202103021052', error) }
};

const getKGraphDataSuccess = data => ({
    type: actionTypes.K_GET_GRAPH_DATA_SUCCESS,
    payload: data
});

const getKGraphDataStarted = () => ({
    type: actionTypes.K_GET_GRAPH_DATA_STARTED,
});

const getKGraphDataFailure = error => ({
    type: actionTypes.K_GET_GRAPH_DATA_FAILURE,
    payload: {
        error
    }
});

export const getCacheGraphData = () => ({
    type: actionTypes.K_GET_GRAPH_DATA_CACHED,
});
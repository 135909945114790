import React, {PureComponent, Fragment, lazy, Profiler} from 'react';
import {Grid, Box} from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import { GATiming, GAEvent, GAPageView } from '../../../Assets/GoogleAnalytics/googleAnalytics';
import * as actionCreators from '../../../store/actions/index';

const Einheiten = lazy(() => import('../../Components/Settings/Einheiten/Einheiten'));

class settings extends PureComponent{

    state = {
        hasError: null,
        errorInfo: null,
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        this.setState({ hasError: error, errorInfo: errorInfo })
    }

    setSettings = (argSettings) => {
        try{
            this.props.onSettingsAdd(argSettings);
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Settings - setSettings", ("202107151453 - Error: " + error.message), null, true);
         }
    }

    setPerformance = (argProfilerId, argMode, argActualTime, argBaseTime, argStartTime, argCommitTime, argInteracions) =>{
        try{
            if(argMode && argMode.toString() === "mount"){
                GATiming(argProfilerId, argMode, argActualTime, ( argProfilerId + " " + argMode ));
            }
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Settings - setPerformance", ("202107151454 - Error: " + error.message), null, true);
        }
    }

    render(){

        let componentOrganizer = null;

        try{
            componentOrganizer = (<Einheiten
            druck={this.props.settings.druck}
            temperature={this.props.settings.temperature}
            windgeschwindigkeit={this.props.settings.windgeschwindigkeit}
            colorMode={this.props.settings.colorMode}
            changeSettings = {this.setSettings} />);
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Settings - render", ("202107151455 - Error: " + error.message), null, true);
        }
        return(
            <Fragment>
                <Profiler id="Einheiten" onRender={this.setPerformance}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Box p='10px'>
                                {componentOrganizer ? componentOrganizer : <div className="NoPage">{this.props.t('common.messages.unable_to_load_data')}</div>}
                        </Box>
                    </Grid>
                </Grid>
                {this.state.errorInfo ?
                    (<div>
                        <h2>Something went wrong.</h2>
                        <details style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo.componentStack}
                        </details>
                    </div>)
                    : null}
                </Profiler>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        settings: state.settingsSubReducer,
        /*druck: state.settingsSubReducer.druck,
        temperature: state.settingsSubReducer.temperature,
        windgeschwindigkeit: state.settingsSubReducer.windgeschwindigkeit,*/
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onSettingsAdd: (objSettings) => dispatch(actionCreators.addSettings(objSettings))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(settings));
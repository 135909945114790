import * as actionTypes from './actionTypes';
import axios from 'axios';
import { GAEvent } from '../../Assets/GoogleAnalytics/googleAnalytics';

export const getBiowetter = (argToken, argLocID) => {
    try{
    return dispatch => {
        dispatch(getBiowetterDataStarted());
        const axiosInstance = axios.create({
            baseURL: 'https://apps.qmet.de/v2/',
            headers: { 'Authorization': `Bearer ${argToken}` }
        });

        axiosInstance.get(`biowetter/${argLocID}`)
            .then(response => {
                if (response && response.data && response.data.data) {
                    dispatch(getBiowetterDataSuccess(response.data.data));
                }
                else {
                    dispatch(getBiowetterDataFailure('Something went wrong'));
                    GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Biowetter - https://apps.qmet.de/v2/biowetter/", "202107141721 - Location id: " + argLocID + " Error: API respond without data / wrong data structure", null, true);
                }
            })
            .catch(error => {
                dispatch(getBiowetterDataFailure(error.message));
                GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Biowetter - https://apps.qmet.de/v2/biowetter/", "202107141722 - Location id: " + argLocID + " Error: " + error.message, null, true);
            })
    };
    // eslint-disable-next-line no-unreachable
    } catch (error) {
        GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Biowetter - https://apps.qmet.de/v2/biowetter/", "202107141723 - Location id: " + argLocID + " Error: " + error.message, null, true);
     }
};

const getBiowetterDataSuccess = data => ({
    type: actionTypes.GET_BIOWETTER_SUCCESS,
    payload: data
});

const getBiowetterDataStarted = () => ({
    type: actionTypes.GET_BIOWETTER_STARTED
});

const getBiowetterDataFailure = error => ({
    type: actionTypes.GET_BIOWETTER_FAILURE,
    payload: {
        error
    }
});

export const resetBiowetterError = () => ({
    type: actionTypes.RESET_BIOWETTER_ERROR,
});
import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getKWettertv = (argToken, argPageVal) => {

    try {
        return dispatch => {
            dispatch(getKWettertvStarted());
            const axiosInstance = axios.create({
                baseURL: 'http://165.227.162.31:8000/v4/',
                headers: { 'Authorization': `Bearer ${argToken}` }
            });
            axiosInstance.get(`news/youtubevideos/${parseInt(argPageVal)}?locale=en`)
                .then(response => {
                    if (response && response.data && response.data.data && response.data.data.length > 0) {
                        if (parseInt(argPageVal) === 0){
                            dispatch(getKWettertvSuccess(response.data.data));
                        }else{
                            dispatch(addKWettertvSuccess(response.data.data));
                        }
                    }
                    else {
                        dispatch(getKWettertvFailure('Something went wrong'));
                    }
                })
                .catch(error => {
                    dispatch(getKWettertvFailure(error.message));
                })
        };
        // eslint-disable-next-line no-unreachable
    } catch (error) { console.log('202102231130', error) }
};

const getKWettertvSuccess = data => ({
    type: actionTypes.K_GET_WETTERTV_SUCCESS,
    payload: data
});

const addKWettertvSuccess = data => ({
    type: actionTypes.K_ADD_WETTERTV_SUCCESS,
    payload: data
});

const getKWettertvStarted = () => ({
    type: actionTypes.K_GET_WETTERTV_STARTED,
});

const getKWettertvFailure = error => ({
    type: actionTypes.K_GET_WETTERTV_FAILURE,
    payload: {
        error
    }
});

export const getCacheKWettertvData = () => ({
    type: actionTypes.K_GET_WETTERTV_CACHED,
})
import React, { Component, Fragment, lazy } from 'react';
import { connect } from 'react-redux';

import * as actionCreators from '../../../store/actions/index';
//import cssModule from './Home.module.css';

const AktuelleKachel = lazy(() => import('../AktuelleKachel/AktuelleKachel'));
const BiowetterKachel = lazy(() => import('../BioWetterKachel/BioWetterKachel'));
const PollenflugKachel = lazy(() => import('../PollenflugKachel/PollenflugKachel'));
const WetterNewsKachel = lazy(() => import('../WetterNewsKachel/WetterNewsKachel'));
const HeuteTagesverlaufKachel = lazy(() => import('../HeuteTagesverlaufKachel/HeuteTagesverlaufKachel'));
const VierzehnTageAussichtenKachel = lazy(() => import('../VierzehnTageAussichtenKachel/VierzehnTageAussichtenKachel'));
const WetterTVKachel = lazy(() => import('../WetterTVKachel/WetterTVKachel'));
const WetterRadarKachel = lazy(() => import('../WetterRadarKachel/WetterRadarKachel'));
const VierzehnTagesprognodeKachel = lazy(() => import('../VierzehnTagesprognodeKachel/VierzehnTagesprognodeKachel'));
const TagesverlaufKachel = lazy(() => import('../TagesverlaufKachel/TagesverlaufKachel'));
const AnziegeKachel = lazy(() => import('../AnziegeKachel/AnziegeKachel'));

/*import AktuelleKachel from '../AktuelleKachel/AktuelleKachel';
import BiowetterKachel from '../BioWetterKachel/BioWetterKachel';
import PollenflugKachel from '../PollenflugKachel/PollenflugKachel';
import WetterNewsKachel from '../WetterNewsKachel/WetterNewsKachel';
import VierzehnTageAussichtenKachel from '../VierzehnTageAussichtenKachel/VierzehnTageAussichtenKachel';
import WetterTVKachel from '../WetterTVKachel/WetterTVKachel';
import WetterRadarKachel from '../WetterRadarKachel/WetterRadarKachel';
import VierzehnTagesprognodeKachel from '../VierzehnTagesprognodeKachel/VierzehnTagesprognodeKachel';
import TagesverlaufKachel from '../TagesverlaufKachel/TagesverlaufKachel';
import HeuteTagesverlaufKachel from '../HeuteTagesverlaufKachel/HeuteTagesverlaufKachel';
import AnziegeKachel from '../AnziegeKachel/AnziegeKachel';*/

const getFormatedDate = (argDate) => {
    try {
        let today = new Date(argDate);
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        return yyyy + '-' + mm + '-' + dd;
    } catch (error) { console.log('202103101243', error) }
}

const isTokenValid = (argKToken) => {
try{

    let timeNow = new Date();
    if (argKToken.createTime !== null){
        console.log('argToken', argKToken.createTime, timeNow);
        var hours = Math.abs(timeNow - new Date(argKToken.createTime)) / 36e5;
        console.log('------------------', hours);
        if(hours > 5){
            return false;
        }
        else{
            return true;
        }
    }else{
        return false;
    }

}catch(error){}
}

class Home extends Component {

    componentDidMount = () => {
        try{
            if (this.props.kToken.kToken && this.props.kLocation.kLocation) {
                if (isTokenValid(this.props.kToken)) {
                    this.props.onGetAktuelleData(this.props.kToken.kToken, this.props.kLocation.kLocation.lon, this.props.kLocation.kLocation.lat, this.props.kLocation.kLocation.name);
                    this.props.onGetGraphData(this.props.kToken.kToken, this.props.kLocation.kLocation.lon, this.props.kLocation.kLocation.lat, getFormatedDate(new Date()));
                    this.props.onGetKSTagesferlauf(this.props.kToken.kToken, this.props.kLocation.kLocation.lon, this.props.kLocation.kLocation.lat, getFormatedDate(new Date()));
                    this.props.onGetBiowetterData(this.props.kToken.kToken, this.props.kLocation.kLocation.ID_Stadt);
                    this.props.onGetPollenflugData(this.props.kToken.kToken, this.props.kLocation.kLocation.ID_Stadt);
                    this.props.onGetKWetternewsData(this.props.kToken.kToken, 0);
                    this.props.onGetKWettertvData(this.props.kToken.kToken, 0);
                }else{
                    console.log('token expired1');
                    this.props.handleGetkToken();
                }
            } else {
                this.props.onGetCacheAktuellData();
                this.props.onGetCacheGraphData();
                this.props.onGetCacheKSTagesferlauf();
                this.props.onGetCacheKBiowetterData();
                this.props.onGetCacheKPollenflugData();
                this.props.onGetCacheKWetternewsData();
                this.props.onGetCacheKWettertvData();
            }
        }catch(error){console.log('202103101244',error)}
    }

    componentDidUpdate = (prevProp) => {
        try{
        /*LOCATION SEARCH */
        if (this.props.kLocation && this.props.kLocation.kLocation && this.props.kLocation.kLocation.name && prevProp.kLocation && prevProp.kLocation.kLocation && prevProp.kLocation.kLocation.name
            && this.props.kLocation.kLocation.name !== prevProp.kLocation.kLocation.name) {
            if (isTokenValid(this.props.kToken)) {
                this.props.onGetAktuelleData(this.props.kToken.kToken, this.props.kLocation.kLocation.lon, this.props.kLocation.kLocation.lat, this.props.kLocation.kLocation.name);
                this.props.onGetGraphData(this.props.kToken.kToken, this.props.kLocation.kLocation.lon, this.props.kLocation.kLocation.lat, getFormatedDate(new Date()));
                this.props.onGetKSTagesferlauf(this.props.kToken.kToken, this.props.kLocation.kLocation.lon, this.props.kLocation.kLocation.lat, getFormatedDate(new Date()));
                this.props.onGetBiowetterData(this.props.kToken.kToken, this.props.kLocation.kLocation.ID_Stadt);
                this.props.onGetPollenflugData(this.props.kToken.kToken, this.props.kLocation.kLocation.ID_Stadt);
                this.props.onGetKWetternewsData(this.props.kToken.kToken, 0);
                this.props.onGetKWettertvData(this.props.kToken.kToken, 0);
            }else{
                console.log('token expired2');
                this.props.handleGetkToken();
            }
        }else{
        /*LOADING DATA AFTER FETCHING TOKEN AND LOCATION - WITH CACHE AVAILABLE*/
        if (this.props.kToken.loading !== true && this.props.kToken.kToken !== null && this.props.kToken.error === null && this.props.kLocation.kLocation
            && this.props.kLocation.kLocation.name && prevProp.kLocation && prevProp.kLocation.kLocation && prevProp.kLocation.kLocation.name
            && this.props.kLocation.kLocation.name === prevProp.kLocation.kLocation.name) {
            if (isTokenValid(this.props.kToken)) {
            this.props.onGetAktuelleData(this.props.kToken.kToken, this.props.kLocation.kLocation.lon, this.props.kLocation.kLocation.lat, this.props.kLocation.kLocation.name);
            this.props.onGetGraphData(this.props.kToken.kToken, this.props.kLocation.kLocation.lon, this.props.kLocation.kLocation.lat, getFormatedDate(new Date()));
            this.props.onGetKSTagesferlauf(this.props.kToken.kToken, this.props.kLocation.kLocation.lon, this.props.kLocation.kLocation.lat, getFormatedDate(new Date()));
            this.props.onGetBiowetterData(this.props.kToken.kToken, this.props.kLocation.kLocation.ID_Stadt);
            this.props.onGetPollenflugData(this.props.kToken.kToken, this.props.kLocation.kLocation.ID_Stadt);
            this.props.onGetKWetternewsData(this.props.kToken.kToken, 0);
            this.props.onGetKWettertvData(this.props.kToken.kToken, 0);
        }else{
                console.log('token expired3');
                this.props.handleGetkToken();
        }
        }else{
            /* FIRST TIME LOADING FIRST TIME WITHOUT LOCAL CACHE*/
            if (this.props.kToken.loading !== true && this.props.kToken.kToken !== null && this.props.kToken.error === null && this.props.kLocation.kLocation && this.props.kLocation.kLocation.name){
                if (isTokenValid(this.props.kToken)) {
                    this.props.onGetAktuelleData(this.props.kToken.kToken, this.props.kLocation.kLocation.lon, this.props.kLocation.kLocation.lat, this.props.kLocation.kLocation.name);
                    this.props.onGetGraphData(this.props.kToken.kToken, this.props.kLocation.kLocation.lon, this.props.kLocation.kLocation.lat, getFormatedDate(new Date()));
                    this.props.onGetKSTagesferlauf(this.props.kToken.kToken, this.props.kLocation.kLocation.lon, this.props.kLocation.kLocation.lat, getFormatedDate(new Date()));
                    this.props.onGetBiowetterData(this.props.kToken.kToken, this.props.kLocation.kLocation.ID_Stadt);
                    this.props.onGetPollenflugData(this.props.kToken.kToken, this.props.kLocation.kLocation.ID_Stadt);
                    this.props.onGetKWetternewsData(this.props.kToken.kToken, 0);
                    this.props.onGetKWettertvData(this.props.kToken.kToken, 0);
                } else {
                    console.log('token expired4');
                    this.props.handleGetkToken();
                }
                }
        }
        }
        }catch(error){console.log('202103101250',error)}
    }

    render() {
        let comAktuelleKachel, comBiowetterKachel, comPollenflugKachel, comWetterNewsKachel,
            comHeuteTagesverlaufKachel, comVierzehnTageAussichtenKachel, comWetterTVKachel,
            comWetterRadarKachel, comVierzehnTagesprognodeKachel, comTagesverlaufKachel, comAnziegeKachel = null;

        /*if (this.props.kToken.kToken && this.props.kLocation.kLocation) {*/
        comAktuelleKachel = (<AktuelleKachel deliverError={this.props.deliverError} />);
        comBiowetterKachel = (<BiowetterKachel deliverError={this.props.deliverError} />);
        comPollenflugKachel = (<PollenflugKachel deliverError={this.props.deliverError} />);
        comWetterNewsKachel = (<WetterNewsKachel deliverError={this.props.deliverError} />);
        comHeuteTagesverlaufKachel = (<HeuteTagesverlaufKachel deliverError={this.props.deliverError} />);
        comVierzehnTageAussichtenKachel = (<VierzehnTageAussichtenKachel deliverError={this.props.deliverError} />);
        comWetterTVKachel = (<WetterTVKachel deliverError={this.props.deliverError} />);
        comWetterRadarKachel = (<WetterRadarKachel deliverError={this.props.deliverError} />);
        comVierzehnTagesprognodeKachel = (<VierzehnTagesprognodeKachel deliverError={this.props.deliverError} />);
        comTagesverlaufKachel = (<TagesverlaufKachel deliverError={this.props.deliverError} />);
        comAnziegeKachel = (<AnziegeKachel deliverError={this.props.deliverError} werbungType="H" />);
        /*}*/
        return (
            <Fragment>
                {comAktuelleKachel}
                {comAnziegeKachel}
                {comTagesverlaufKachel}
                {comHeuteTagesverlaufKachel}
                {comVierzehnTagesprognodeKachel}
                {comVierzehnTageAussichtenKachel}
                {comBiowetterKachel}
                {comPollenflugKachel}
                {comWetterNewsKachel}
                {comWetterTVKachel}
                {comWetterRadarKachel}
            </Fragment>
        );
    }
};

const mapStateToProps = state => {
    return {
        kLocation: state.kLocationSubReducer,
        kToken: state.kTokenSubReducer,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetkToken: () => { dispatch(actionCreators.getkToken()) },
        onGetAktuelleData: (argToken, argLat, argLon, argCity) => { dispatch(actionCreators.getKAktuelle(argToken, argLat, argLon, argCity)) },
        onGetGraphData: (argToken, argLat, argLon, argDate) => { dispatch(actionCreators.getKGraphData(argToken, argLat, argLon, argDate)) },
        onGetKSTagesferlauf: (argToken, argLat, argLon, argDate) => { dispatch(actionCreators.getKSTagesverlauf(argToken, argLat, argLon, argDate)) },
        onGetBiowetterData: (argToken, argLocationID) => { dispatch(actionCreators.getKBiowetter(argToken, argLocationID)) },
        onGetPollenflugData: (argToken, argLocationID) => { dispatch(actionCreators.getKPollenflug(argToken, argLocationID)) },
        onGetKWetternewsData: (argToken, argPageNumber) => { dispatch(actionCreators.getKWetternews(argToken, argPageNumber)) },
        onGetKWettertvData: (argToken, argPageNumber) => { dispatch(actionCreators.getKWettertv(argToken, argPageNumber)) },
        onGetCacheAktuellData: () => { dispatch(actionCreators.getCacheAktuellData()) },
        onGetCacheGraphData: () => { dispatch(actionCreators.getCacheGraphData()) },
        onGetCacheKSTagesferlauf: () => { dispatch(actionCreators.getCacheKSTagesferlauf()) },
        onGetCacheKBiowetterData: () => { dispatch(actionCreators.getCacheKBiowetterData()) },
        onGetCacheKPollenflugData: () => { dispatch(actionCreators.getCacheKPollenflugData()) },
        onGetCacheKWetternewsData: () => { dispatch(actionCreators.getCacheKWetternewsData()) },
        onGetCacheKWettertvData: () => { dispatch(actionCreators.getCacheKWettertvData()) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
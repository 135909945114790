import React, { memo } from 'react';
import {
    Typography,
    Breadcrumbs,
    Grid,
} from '@material-ui/core';
import { Link as SiteLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import cssModule from './Wolkenfilm.module.css';

const useStyles = makeStyles((theme) => ({

    head: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '3rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '15px'
    },
    weatherVideo:{
        borderRadius: '5px',
        marginBottom: '1rem'
    }
}));

const Wolkenfilm = memo((props) => {

    const classes = useStyles();

    function handleClick(event) {
        try {
            event.preventDefault();
        } catch (error) { console.log('202103101712', error) }
    }

    let componentOrganizer = null;

    try {

        componentOrganizer = (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography className={classes.head} variant="h1" component="h1" gutterBottom>Der Wolkenfilm für Europa</Typography>
                        <video width="100%" height="68%" controls className={classes.weatherVideo}>
                            <source src='http://167.172.173.171/images/videos/Wolken_EU_Wetternet.mp4' type="video/mp4"/>
                        </video>
                        <Typography className={classes.data} variant="body1" component="p">
                            Der Wolkenfilm Europa zeigt die Bewölkung der kommenden 72 Stunden. Dabei handelt es sich um eine Vorhersage der 
                            Wolkenverteilung. Auf einem Blick ist zu sehen, wo der Himmel aktuell wolkenlos oder bedeckt ist. Je dünner die 
                            Wolkendecke, desto freundlicher gestaltet sich in der Regel das Wetter darunter. Aber aufgepasst: Regionale 
                            Hochnebel- und Dunstfelder gibt der Wolkenfilm nicht bzw. nur unzureichend wieder. Der Wolkenfilm wird einmal 
                            täglich am frühen Morgen aktualisiert.
                        </Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        );

    } catch (error) {
        console.log('202107281519', error);
    }

    return (
        <React.Fragment>
            <div className={cssModule.StromungsfilmParent}>
                <div className={cssModule.HeadSection}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <SiteLink className={cssModule.BreadcrubsLink} color="inherit" to="/" onClick={handleClick}>Home</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Aktuell</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Wolkenfilm</SiteLink>
                    </Breadcrumbs>
                </div>
                <div className={cssModule.DataSection}>
                    {componentOrganizer}
                </div>
            </div>
        </React.Fragment>
    );
});

export default Wolkenfilm;
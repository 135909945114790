import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getkToken = () => {
    try {
        
        return dispatch => {
            dispatch(getkTokenStarted());
            const axiosInstance = axios.create({
                /*baseURL: 'http://165.227.162.31:8000/v4/',*/
                baseURL: 'https://apps.qmet.de/v2/',
            });

            axiosInstance.get(`token`)
                .then(response => {
                    if (response && response.data && response.data.data && response.data.data.token) {
                        dispatch(getkTokenSuccess(response.data.data.token));
                        console.log('ktoken', response.data.data.token);
                    }
                    else {
                        dispatch(getkTokenFailure('Something went wrong'));
                    }
                })
                .catch(error => {
                    dispatch(getkTokenFailure(error.message));
                })
        };
        // eslint-disable-next-line no-unreachable
    } catch (error) { console.log('202102231105', error) }
};

const getkTokenSuccess = data => ({
    type: actionTypes.K_GET_TOKEN_SUCCESS,
    payload: data
});

const getkTokenStarted = () => ({
    type: actionTypes.K_GET_TOKEN_STARTED,
});

const getkTokenFailure = error => ({
    type: actionTypes.K_GET_TOKEN_FAILURE,
    payload: {
        error
    }
});
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actionCreators from '../../../../store/actions/index';
import BiowetterInnerPageComponent from '../../../Components/InnerPages/BiowetterInnerPage/BiowetterInnerPage';

class BiowetterInnerPage extends Component {

    componentDidMount = () => {
        try {
            if (this.props.kToken && this.props.kToken.kToken && this.props.kToken.kToken.length > 0) {
                //this.props.handleGetKWettertv(this.props.token.token, 0);
                console.log('*******************', this.props.kToken.kToken, this.props.kLocation.kLocation.ID_Stadt);
                this.props.onGetBiowetterData(this.props.kToken.kToken, this.props.kLocation.kLocation.ID_Stadt);
            }
        } catch (error) { console.log('202103101405', error) }
    }

    /*componentDidUpdate = (prevProps) => {
        try{
            if(prevProps.kToken && prevProps.kToken.kToken && prevProps.kToken.kToken && prevProps.kToken.kToken.length > 0 && this.props.kToken.kToken === prevProps.kToken.kToken){
                if(this.props.kLocation && this.props.kLocation.kLocation && this.props.kLocation.kLocation.ID_Stadt){
                    if(this.props.kBiowetter !== prevProps.kBiowetter)
                    {
                        this.props.onGetBiowetterData(this.props.kToken.kToken, this.props.kLocation.kLocation.ID_Stadt);
                    }
                }
            }
        }catch(error){
            console.log('202108091701', error)
        }
    }*/

    render() {

        return (
            <BiowetterInnerPageComponent componentData={this.props.kBiowetter} locationData={this.props.kLocation}/>
        );
    }

}

const mapStateToProps = state => {
    return {
        kLocation: state.kLocationSubReducer,
        kToken: state.kTokenSubReducer,
        kBiowetter: state.kBiowetterSubReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onGetBiowetterData: (argToken, argLocationID) => { dispatch(actionCreators.getKBiowetter(argToken, argLocationID)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BiowetterInnerPage);
import React, { memo } from 'react';
import {
    Typography,
    Breadcrumbs,
    Grid,
} from '@material-ui/core';
import { Link as SiteLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import cssModule from './WetterLexikon.module.css';

const useStyles = makeStyles((theme) => ({

    head: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '3rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '15px'
    },
    weatherVideo: {
        borderRadius: '5px',
        marginBottom: '1rem'
    },
    AlphabetParent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'stretch',

    },
    AlphabetItem: {
        textAlign: 'center',
        padding: '2px',
        borderRight: '1px solid rgba(0, 108, 189, 0.5)',
        fontWeight: '400',
        color: 'rgb(0, 108, 189)',
        fontSize: '1.1rem',
        cursor: 'pointer',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        flexGrow: '1',
        '&:last-of-type': {
            borderRight: '0px solid rgb(0, 108, 189)'
        }
    },
    AlphabetItemSelected: {
        textAlign: 'center',
        padding: '2px',
        borderRight: '1px solid rgba(0, 108, 189, 0.5)',
        backgroundColor: 'rgb(0, 108, 189)',
        color: '#fff',
        fontSize: '1.1rem',
        fontWeight: '400',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        flexGrow: '1',
        '&:last-of-type': {
            borderRight: '0px solid rgb(0, 108, 189)'
        }
    },
    LexikonParent: {

    },
    LexikonItemParent: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'stretch',
    },
    LexikonItem: {
        width: '45%',
        display: 'inline-block',
        padding: '5px 20px',
        fontSize: '1.1rem',
        fontWeight: '300',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        /*'&:nth-child(odd)': {
            borderRight: '1px solid rgba(0, 108, 189, 0.1)'
        },
        '&:nth-child(even)': {
            borderLeft: '1px solid rgba(0, 108, 189, 0.1)'
        },*/
        '&:hover': {
            color: 'rgb(0, 108, 189)',
            cursor: 'pointer',
        }
    },
    lexikonTable:{
        width:'100%',
        border: '0px solid black',
        borderCollapse: 'collapse',
        '& tr':{
            border: '0px solid black',
        },
        '& td':{
            padding:'7px 15px',
            fontSize:'1.1rem',
            fontWeight:'300',
            width:'49%'
        },
        '& td:nth-child(odd)':{
            borderRight: '1px solid rgba(0, 108, 189, 0.8)'
        },
        '& td:nth-child(even)': {
            padding: '7px 20px',
        },
        '& span:hover':{
            color:'rgba(0, 108, 189, 1)',
            cursor: 'pointer',
        }
    }
}));

const WetterLexikon = memo((props) => {

    const classes = useStyles();
    const history = useHistory();

    const [hookLetter, setLetter] = React.useState("");

    React.useEffect(() => {
        try {
            let searchLetter = "";
            if (history.location && history.location.search && history.location.search.toString() && history.location.search.toString().length > 0){
                searchLetter = history.location.search.toString().replace('?search=','');
                props.getWetterLexikon(getUmlaut(searchLetter));
                setLetter(getUmlaut(searchLetter));
                history.push({pathname:'/wetterlexikon', search: `?search=${searchLetter}`});
            }else{
                setLetter("a");
                props.getWetterLexikon("a");
                history.push({ pathname: '/wetterlexikon', search: `?search=a` });

            }
        } catch (error) { console.log('202103101414', error) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function compare(a, b) {

        try{
        if (a.Begriff < b.Begriff) {
            return -1;
        }
        if (a.Begriff > b.Begriff) {
            return 1;
        }
        return 0;
        }catch(error){
            console.log('202109071451',error);
        }
    }

    function handleClick(event) {
        try {
            event.preventDefault();
        } catch (error) { console.log('202103101712', error) }
    }

    let componentOrganizer, lexikonOrganizer = null;

    const slugify = (str) => {
        try {
            str = str.replace(/^\s+|\s+$/g, ''); // trim
            str = str.toLowerCase();

            str = str.replace(/ü+/g, '~ue').replace(/ä+/g, '~ae').replace(/ö+/g, '~oe').replace(/ß+/g,'~ss');

            // remove accents, swap ñ for n, etc
            //var from = "ãàáäâáº½èéëêìíïîõòóöôùúüûñç·/_,:;";
            //var to = "aaaaaeeeeeiiiiooooouuuunc------";
            /*var from = "·/_,:;";
            var to = "------";
            for (var i = 0, l = from.length; i < l; i++) {
                str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
            }*/

            str = str.replace(/[^a-z0-9 -~]/g, '') // remove invalid chars
                .replace(/\s+/g, '_') // collapse whitespace and replace by -
                .replace(/-+/g, '-'); // collapse dashes

            return str;
        } catch (error) { console.log('202103101707', error) }
    };

    const goToPage = (argLexikon) => {
        try {
            if (argLexikon && argLexikon.length > 0) {
                //history.push(`/wetterlexikon/eintrag/${slugify(argLexikon)}`);
                history.push({ pathname: '/wetterlexikon/eintrag', search: `?lexikon=${slugify(argLexikon)}`})
            }
        } catch (error) { console.log('202109071402', error) }
    }

    const handleLetterChange = (argLetter) =>{
        try{
            setLetter(getUmlaut(argLetter));
            props.getWetterLexikon(getUmlaut(argLetter));
            //history.push(`/wetterlexikon/${argLetter}`);
            history.push({ pathname: '/wetterlexikon', search: `?search=${argLetter}` });
        }catch(error){
            console.log('202109061711',error);
        }
    }

    const getUmlaut = (argLetter) => {
        try {
            if (argLetter === "ae") {
                return "ä";
            } else if (argLetter === "ue") {
                return "ü";
            } else if (argLetter === "oe") {
                return "ö";
            } else {
                return argLetter;
            }
        } catch (error) {
            console.log('202109071546', error);
        }
    }

    try {
            /*<span className={classes.LexikonItem} onClick={() => goToPage(item.Begriff)}>{item.Begriff + " " + item.Begriff}</span>*/
            if (props.componentData && props.componentData.kWetterLexikon && props.componentData.kWetterLexikon.length > 0){
                let dataArray = props.componentData.kWetterLexikon;
                dataArray = dataArray.sort(compare)
                lexikonOrganizer = dataArray.map((item,index) => {
                    let tableRow = null;
                    if(index % 2 === 0){
                        tableRow = (<tr>
                            <td>{dataArray[index] ? dataArray[index].Begriff ? <span onClick={() => goToPage(dataArray[index].Begriff)}>{dataArray[index].Begriff}</span> : null : null}</td>
                            <td>{dataArray[index + 1] ? dataArray[index + 1].Begriff ? <span onClick={() => goToPage(dataArray[index + 1].Begriff)}>{dataArray[index + 1].Begriff}</span> : null : null}</td>
                        </tr>);
                    }
                        return (
                            <React.Fragment>
                                {tableRow}
                            </React.Fragment>
                        );
                    });
            }else{
                lexikonOrganizer = (<div>Keine Daten gefunden!</div>)
            }

        componentOrganizer = (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography className={classes.head} variant="h1" component="h1" gutterBottom>Wetter-Lexikon</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.AlphabetParent}>
                            <span onClick={() => {handleLetterChange("a")}} className={hookLetter === "a" ? classes.AlphabetItemSelected : classes.AlphabetItem}>A</span>
                            <span onClick={() => {handleLetterChange("b")}} className={hookLetter === "b" ? classes.AlphabetItemSelected : classes.AlphabetItem}>B</span>
                            <span onClick={() => {handleLetterChange("c")}} className={hookLetter === "c" ? classes.AlphabetItemSelected : classes.AlphabetItem}>C</span>
                            <span onClick={() => {handleLetterChange("d")}} className={hookLetter === "d" ? classes.AlphabetItemSelected : classes.AlphabetItem}>D</span>
                            <span onClick={() => {handleLetterChange("e")}} className={hookLetter === "e" ? classes.AlphabetItemSelected : classes.AlphabetItem}>E</span>
                            <span onClick={() => {handleLetterChange("f")}} className={hookLetter === "f" ? classes.AlphabetItemSelected : classes.AlphabetItem}>F</span>
                            <span onClick={() => {handleLetterChange("g")}} className={hookLetter === "g" ? classes.AlphabetItemSelected : classes.AlphabetItem}>G</span>
                            <span onClick={() => {handleLetterChange("h")}} className={hookLetter === "h" ? classes.AlphabetItemSelected : classes.AlphabetItem}>H</span>
                            <span onClick={() => {handleLetterChange("i")}} className={hookLetter === "i" ? classes.AlphabetItemSelected : classes.AlphabetItem}>I</span>
                            <span onClick={() => {handleLetterChange("j")}} className={hookLetter === "j" ? classes.AlphabetItemSelected : classes.AlphabetItem}>J</span>
                            <span onClick={() => {handleLetterChange("k")}} className={hookLetter === "k" ? classes.AlphabetItemSelected : classes.AlphabetItem}>K</span>
                            <span onClick={() => {handleLetterChange("l")}} className={hookLetter === "l" ? classes.AlphabetItemSelected : classes.AlphabetItem}>L</span>
                            <span onClick={() => {handleLetterChange("m")}} className={hookLetter === "m" ? classes.AlphabetItemSelected : classes.AlphabetItem}>M</span>
                            <span onClick={() => {handleLetterChange("n")}} className={hookLetter === "n" ? classes.AlphabetItemSelected : classes.AlphabetItem}>N</span>
                            <span onClick={() => {handleLetterChange("o")}} className={hookLetter === "o" ? classes.AlphabetItemSelected : classes.AlphabetItem}>O</span>
                            <span onClick={() => {handleLetterChange("p")}} className={hookLetter === "p" ? classes.AlphabetItemSelected : classes.AlphabetItem}>P</span>
                            <span onClick={() => {handleLetterChange("q")}} className={hookLetter === "q" ? classes.AlphabetItemSelected : classes.AlphabetItem}>Q</span>
                            <span onClick={() => {handleLetterChange("r")}} className={hookLetter === "r" ? classes.AlphabetItemSelected : classes.AlphabetItem}>R</span>
                            <span onClick={() => {handleLetterChange("s")}} className={hookLetter === "s" ? classes.AlphabetItemSelected : classes.AlphabetItem}>S</span>
                            <span onClick={() => {handleLetterChange("t")}} className={hookLetter === "t" ? classes.AlphabetItemSelected : classes.AlphabetItem}>T</span>
                            <span onClick={() => {handleLetterChange("u")}} className={hookLetter === "u" ? classes.AlphabetItemSelected : classes.AlphabetItem}>U</span>
                            <span onClick={() => {handleLetterChange("v")}} className={hookLetter === "v" ? classes.AlphabetItemSelected : classes.AlphabetItem}>V</span>
                            <span onClick={() => {handleLetterChange("w")}} className={hookLetter === "w" ? classes.AlphabetItemSelected : classes.AlphabetItem}>W</span>
                            {/*<span onClick={() => {handleLetterChange("x")}} className={hookLetter === "x" ? classes.AlphabetItemSelected : classes.AlphabetItem}>X</span>
                            <span onClick={() => {handleLetterChange("y")}} className={hookLetter === "y" ? classes.AlphabetItemSelected : classes.AlphabetItem}>Y</span>*/}
                            <span onClick={() => {handleLetterChange("z")}} className={hookLetter === "z" ? classes.AlphabetItemSelected : classes.AlphabetItem}>Z</span>
                            <span onClick={() => { handleLetterChange("ae") }} className={hookLetter === "ae" ? classes.AlphabetItemSelected : classes.AlphabetItem}>Ä</span>
                            <span onClick={() => { handleLetterChange("ue") }} className={hookLetter === "ue" ? classes.AlphabetItemSelected : classes.AlphabetItem}>Ü</span>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.LexikonParent}>
                            <table className={classes.lexikonTable}>
                                <tbody>
                                    {lexikonOrganizer}
                                </tbody>
                            </table>
                        </div>
                    </Grid>
                </Grid>
            </React.Fragment>
        );

    } catch (error) {
        console.log('202107281519', error);
    }

    return (
        <React.Fragment>
            <div className={cssModule.WetterLexikonDetailsParent}>
                <div className={cssModule.HeadSection}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <SiteLink className={cssModule.BreadcrubsLink} color="inherit" to="/" onClick={handleClick}>Home</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Allgemein</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Wetter-Lexikon</SiteLink>
                    </Breadcrumbs>
                </div>
                <div className={cssModule.DataSection}>
                    {componentOrganizer}
                </div>
            </div>
        </React.Fragment>
    );
});

export default WetterLexikon;
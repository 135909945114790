import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import cssModule from './PollenKalenderDetails.module.css';

const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    color: 'rgb(0, 108, 189)',
  },
  tooltip: {
    backgroundColor: 'rgb(0, 108, 189)',
    color: 'white',
    maxWidth: 320,
    border: '1px solid rgb(0, 108, 189)',
    fontSize: '1.1rem',
    fontFamily: 'Source Sans Pro, sans-serif !important',
    fontWeight: '300',
  },
}))(Tooltip);

const chartPolenkalender = React.memo((props) => {

  const getPollenShap = (argPollenValue, argDate) => {

    try{

    const datum = new Date(argDate).toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' });
    const monate = new Date(argDate).getMonth();
      if (props.filterMonate.toString() === "ALLM"){

    if (parseInt(argPollenValue) === 0) {
      return (
        <div style={{ width: '12px' }}></div>
      );
    } else if (parseInt(argPollenValue) === 1) {
      return (
        <HtmlTooltip key={argDate} arrow title={
          <React.Fragment>
            <Typography variant="subtitle2" color="inherit">Datum: {datum}</Typography>
            <Typography variant="subtitle2" color="inherit">Stand: sporadische Belastung</Typography>
          </React.Fragment>
        }>
          <svg height="25" width="12">
            <circle cx="6" cy="14" r="4" fill="#4CAF50" />
          </svg>
        </HtmlTooltip>
      );
    }
    else if (parseInt(argPollenValue) === 2) {
      return (
        <HtmlTooltip key={argDate} arrow title={
          <React.Fragment>
            <Typography variant="subtitle2" color="inherit">Datum: {datum}</Typography>
            <Typography variant="subtitle2" color="inherit">Stand: mäßige Belastung</Typography>
          </React.Fragment>
        }>
          <svg height="25" width="12">
            <circle cx="6" cy="14" r="5" fill="#FF9800" />
          </svg>
        </HtmlTooltip>);
    }
    else if (parseInt(argPollenValue) === 3) {
      return (
        <HtmlTooltip key={argDate} arrow title={
          <React.Fragment>
            <Typography variant="subtitle2" color="inherit">Datum: {datum}</Typography>
            <Typography variant="subtitle2" color="inherit">Stand: starke Belastung</Typography>
          </React.Fragment>
        }>
          <svg height="25" width="12">
            <circle cx="6" cy="14" r="6" fill="#f44336" />
          </svg>
        </HtmlTooltip>);
    }else{
      return (
        <div style={{ width: '12px' }}></div>
      );
    }
      } else if (props.filterMonate.toString() === monate.toString())
      {
        if (parseInt(argPollenValue) === 0) {
          return (
            <div style={{ width: '12px' }}></div>
          );
        } else if (parseInt(argPollenValue) === 1) {
          return (
            <HtmlTooltip key={argDate} arrow title={
              <React.Fragment>
                <Typography variant="subtitle2" color="inherit">Datum: {datum}</Typography>
                <Typography variant="subtitle2" color="inherit">Stand: sporadische Belastung</Typography>
              </React.Fragment>
            }>
              <svg height="25" width="12">
                <circle cx="6" cy="14" r="4" fill="#4CAF50" />
              </svg>
            </HtmlTooltip>
          );
        }
        else if (parseInt(argPollenValue) === 2) {
          return (
            <HtmlTooltip key={argDate} arrow title={
              <React.Fragment>
                <Typography variant="subtitle2" color="inherit">Datum: {datum}</Typography>
                <Typography variant="subtitle2" color="inherit">Stand: mäßige Belastung</Typography>
              </React.Fragment>
            }>
              <svg height="25" width="12">
                <circle cx="6" cy="14" r="5" fill="#FF9800" />
              </svg>
            </HtmlTooltip>);
        }
        else if (parseInt(argPollenValue) === 3) {
          return (
            <HtmlTooltip key={argDate} arrow title={
              <React.Fragment>
                <Typography variant="subtitle2" color="inherit">Datum: {datum}</Typography>
                <Typography variant="subtitle2" color="inherit">Stand: starke Belastung</Typography>
              </React.Fragment>
            }>
              <svg height="25" width="12">
                <circle cx="6" cy="14" r="6" fill="#f44336" />
              </svg>
            </HtmlTooltip>);
        } else {
          return (
            <div style={{ width: '12px' }}></div>
          );
        }
      }else{
        return (
          <div style={{ width: '12px', height:"25px" }}></div>
        );
      }
  }catch(error) {
    console.log('202108201403',error);
  }
  }

  let tableData = [];
  let tableHeadData = [];
  let weekCount = { 'Jan': 0, 'Feb': 0, 'Mar': 0, 'Apr': 0, 'Mai': 0, 'Jun': 0, 'Jul': 0, 'Aug': 0, 'Sep': 0, 'Okt': 0, 'Nov': 0, 'Dez': 0 };

  try{
  if (parseInt(props.itemIndex) === 0) {
    for (const index in props.pollenData) {
      if (index.toString() !== 'ID_Pollengebiet') {
        if (index.toString() !== 'ID_Polle') {
          switch (new Date(index).toLocaleDateString('de-DE', { month: 'short' }).toString()) {
            case 'Jan':
              weekCount.Jan += 1;
              break;
            case 'Feb':
              weekCount.Feb += 1;
              break;
            case 'Mär':
              weekCount.Mar += 1;
              break;
            case 'Apr':
              weekCount.Apr += 1;
              break;
            case 'Mai':
              weekCount.Mai += 1;
              break;
            case 'Jun':
              weekCount.Jun += 1;
              break;
            case 'Jul':
              weekCount.Jul += 1;
              break;
            case 'Aug':
              weekCount.Aug += 1;
              break;
            case 'Sep':
              weekCount.Sep += 1;
              break;
            case 'Okt':
              weekCount.Okt += 1;
              break;
            case 'Nov':
              weekCount.Nov += 1;
              break;
            case 'Dez':
              weekCount.Dez += 1;
              break;
            default:
          }
        }
      }
    }

    let thisHeadMonth = parseInt(new Date().getMonth());

    tableHeadData.push(
      <React.Fragment key='0'>
        <td key='pollenName' className={cssModule.PollenName}></td>
        <td key='Jan' className={thisHeadMonth === 0 ? cssModule.TableHeadBlue : cssModule.TableHead} colSpan={weekCount.Jan - 1}> Jan </td>
        <td key='Feb' className={thisHeadMonth === 1 ? cssModule.TableHeadBlue : cssModule.TableHead} colSpan={weekCount.Feb}> Feb </td>
        <td key='Mar' className={thisHeadMonth === 2 ? cssModule.TableHeadBlue : cssModule.TableHead} colSpan={weekCount.Mar}> Mär </td>
        <td key='Apr' className={thisHeadMonth === 3 ? cssModule.TableHeadBlue : cssModule.TableHead} colSpan={weekCount.Apr}> Apr </td>
        <td key='Mai' className={thisHeadMonth === 4 ? cssModule.TableHeadBlue : cssModule.TableHead} colSpan={weekCount.Mai}> Mai </td>
        <td key='Jun' className={thisHeadMonth === 5 ? cssModule.TableHeadBlue : cssModule.TableHead} colSpan={weekCount.Jun}> Jun </td>
        <td key='Jul' className={thisHeadMonth === 6 ? cssModule.TableHeadBlue : cssModule.TableHead} colSpan={weekCount.Jul}> Jul </td>
        <td key='Aug' className={thisHeadMonth === 7 ? cssModule.TableHeadBlue : cssModule.TableHead} colSpan={weekCount.Aug}> Aug </td>
        <td key='Sep' className={thisHeadMonth === 8 ? cssModule.TableHeadBlue : cssModule.TableHead} colSpan={weekCount.Sep}> Sep </td>
        <td key='Okt' className={thisHeadMonth === 9 ? cssModule.TableHeadBlue : cssModule.TableHead} colSpan={weekCount.Okt}> Okt </td>
        <td key='Nov' className={thisHeadMonth === 10 ? cssModule.TableHeadBlue : cssModule.TableHead} colSpan={weekCount.Nov}> Nov </td>
        <td key='Dez' className={thisHeadMonth === 11 ? cssModule.TableHeadBlue : cssModule.TableHead} colSpan={weekCount.Dez}> Dez </td>
      </React.Fragment>);
  }

  for (const index in props.pollenData) {
    if (index.toString() !== 'ID_Pollengebiet') {
      if (index.toString() === 'ID_Polle') {
        tableData.push(<td style={{ padding:'5px 10px !important' }} className={[cssModule.PollenName, cssModule.ItemOdd].join(' ')} key={props.pollenData[index]}>{props.pollenData[index]}</td>)
      }
    }
  }

  for (const index in props.pollenData) {
    if (index.toString() !== 'ID_Pollengebiet') {
      if (index.toString() !== 'ID_Polle') {
        let month = parseInt(new Date(index).getMonth());
        let thisMonth = parseInt(new Date().getMonth());
        let year = Number(new Date(index).getFullYear());
        let thisYear = parseInt(new Date().getFullYear());
        if(year === thisYear){
          if (month === thisMonth) {
            tableData.push(<td className={[cssModule.Item, cssModule.ItemBlue].join(' ')} key={index}>{getPollenShap(props.pollenData[index], index)}</td>)
          } else if (month === 1 || month === 3 || month === 5 || month === 7 || month === 9 || month === 11) {
            tableData.push(<td className={[cssModule.Item, cssModule.ItemOdd].join(' ')} key={index}>{getPollenShap(props.pollenData[index], index)}</td>)
          } else {
            tableData.push(<td className={cssModule.Item} key={index}>{getPollenShap(props.pollenData[index], index)}</td>)
          }
        }
      }
    }
  }

}catch(error){
  console.log('202108201407',error);
}

  return (<div>
    <table className={cssModule.SingleTable}>
      <thead>
        <tr>
          {tableHeadData}
        </tr>
      </thead>
      <tbody>
        <tr>
          {tableData}
        </tr>
      </tbody>
    </table>
  </div>);
});

export default chartPolenkalender;
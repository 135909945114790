import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actionCreators from '../../../../store/actions/index';
import WetterTVWeiterComponent from '../../../Components/WetterTVKachelComponent/WetterTVWeiterComponent/WetterTVWeiterComponent';

class WetterTVWeiter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            videoPageNumber: 1,
        }
    }

    componentDidMount = () => {
        try{
        if (this.props.token && this.props.token.token && this.props.token.token.length > 0) {
            this.props.handleGetKWettertv(this.props.token.token, 0);
        }
        } catch (error) { console.log('202103101405', error) }
    }

    getVideo = () => {
        try{
        if (this.props.token && this.props.token.token && this.props.token.token.length > 0) {
            this.props.handleGetKWettertv(this.props.token.token, this.state.videoPageNumber)
            this.setState({ videoPageNumber: this.state.videoPageNumber + 1 });
        }
        } catch (error) { console.log('202103101406', error) }
    }

    render() {
        return (
            <WetterTVWeiterComponent componentData ={this.props.kWettertv} getMoreVideo = {this.getVideo}/>
        );
    }

}

const mapStateToProps = state => {
    return {
        kWettertv: state.kWettertvSubReducer,
        token: state.tokenSubReducer,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetKWettertv: (token, page) => dispatch(actionCreators.getKWettertv(token, page)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WetterTVWeiter);
/*this file read and write data to local storage*/

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('wetternet');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        console.log('202102160844', err);
        return undefined;
    }
};

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('wetternet', serializedState);
    } catch(error) {
        // ignore write errors
        console.log('202102160845', error);
    }
};
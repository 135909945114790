import React, { memo } from 'react';

import cssModule from './AnziegeKachelComponent.module.css';

const AnziegeKachelComponent = memo((props) => {
    return (
        <div className={cssModule.AnzigeParent}>
        {props.type === "H" ?
            (<div className={cssModule.HorizontaleWerbung}>
                Hier wird horizontale Werbung angezeigt
            </div>)
            :
            (<div className={cssModule.VertilaleWerbung}>
                Hier wird vertikale Werbung angezeigt
            </div>)
            }

        </div>
    );
});

export default AnziegeKachelComponent;
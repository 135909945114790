import React, { Component, Fragment } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import axios from 'axios';
import { withTranslation } from "react-i18next";
import { GAEvent } from '../../../Assets/GoogleAnalytics/googleAnalytics';

import Navigation from '../Navigation/Navigation';
import * as actionCreators from '../../../store/actions/index';
import cssModule from './Layout.module.css';

class Layout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: "",
            tokenFetchFailed: false,
            locationAccess: null,
            locationCached: false,
            locationFetchFails: false,
            locationWithUrl: false,
        }
    }

    delay = (ms) => new Promise(res => setTimeout(res, ms));

    isTokenValid = async () => {
        try {
            let timeNow = new Date();
            let LSData = window.localStorage.getItem('wetternet');
            let LSDataObject = null;
            if (LSData && LSData !== null && LSData.length > 0) {
                LSDataObject = JSON.parse(LSData);
                if (LSDataObject && LSDataObject.token && LSDataObject.token.createTime) {
                    if (new Date(timeNow) > new Date(LSDataObject.token.createTime)) {
                        this.props.handleGetToken();
                        await this.delay(5000);
                        GAEvent("Token", "Token Expired", "Fetch new token", null, true);
                    }
                }
            }
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Token - isTokenValid", ("202107201633 - Error: " + error.message), null, true);
        }
    }

    componentDidMount = async () => {
        try {
            this.props.handleGetToken();
            /*await this.getToken();*/
            //await this.getUserLocation();
            const timer = setTimeout(() => {
                if (this.props.token && this.props.token.token) {
                    if (this.props.token.token.length <= 0 || this.props.token.error != null) {
                        this.setState({ tokenFetchFailed: true });
                    }
                }
                else {
                    this.setState({ tokenFetchFailed: true });
                }
            }, 10000);
            return () => clearTimeout(timer);
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Layout - componentDidMount", ("202107151250 - Error: " + error.message), null, true);
        }
    }

    componentDidUpdate = async (prevProp) => {

        try{

        if (this.props && this.props.token !== null && this.props.token.loading !== true && this.props.token.createTime !== null && this.props.token !== prevProp.token) {
            /*if (isTokenValid(this.props.token)) {
                this.getLocation();
            }
            else {
                this.props.handleGetToken();
            }*/
            await this.isTokenValid();
            this.getLocation();
        }
    }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Layout - componentDidUpdate", ("202107151251 - Error: " + error.message), null, true);
    }
    }

    /*=====================================================*/
    /*START GET LOCATION*/
    /*=====================================================*/

    getLocation = () => {
        try{
        if (this.props.location && this.props.location.ID_Stadt) {
            this.setState({ locationCached: true });
        }
        else {
            this.setState({ locationCached: false });
            let appURL = new URL(window.location.href);
            let lat = appURL.searchParams.get("lat");
            let lon = appURL.searchParams.get("lon");
            if( lat !== null && lon !== null && lat !== undefined && lon !== undefined & !isNaN(lat) && !isNaN(lon)){
                this.setState({ locationAccess: "allowed", locationWithUrl: true });
                this.props.onGetCurrentLocation(this.props.token.token, lat, lon);
            }else{
                this.getUserLocation();
            }
        }}catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Layout - getLocation", ("202107151252 - Error: " + error.message), null, true);
        }
    }

    getUserLocation = async () => {
        try {
            let options = {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0
            };
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.gotLocation, this.gotError, options);
            } else {
                this.setState({ locationFetchFails: true });
            }
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Layout - getUserLocation", ("202107151253 - Error: " + error.message), null, true);
        }
    }

    gotError = (error) => {
        try {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    this.props.onGetCurrentLocationFromLocal();
                    this.setState({ locationAccess: "denied" });
                    break;
                case error.POSITION_UNAVAILABLE:
                    this.props.onGetCurrentLocationFromLocal();
                    this.setState({ locationAccess: "unavailable" });
                    break;
                case error.TIMEOUT:
                    this.setState({ locationAccess: "timedout" });
                    this.props.onGetCurrentLocationFromLocal();
                    break;
                case error.UNKNOWN_ERROR:
                    this.setState({ locationAccess: "error" });
                    this.props.onGetCurrentLocationFromLocal();
                    break;
                default:
                    this.setState({ locationAccess: "error" });
                    this.props.onGetCurrentLocationFromLocal();
            }
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Layout - gotError", ("202107151254 - Error: " + error.message), null, true);
         }
    }

    gotLocation = (argPosition) => {
        try {
            let coords = argPosition.coords;
            this.setState({ locationAccess: "allowed" });
            /*this.getBrowserLocation(this.props.token.token, coords.latitude, coords.longitude);*/
            this.props.onGetCurrentLocation(this.props.token.token, coords.latitude, coords.longitude);
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Layout - gotLocation", ("202107151255 - Error: " + error.message), null, true);
         }
    }

    /*=====================================================*/
    /*END GET LOCATION*/
    /*=====================================================*/

    getBrowserLocation = async (argToken, argLat, argLon) => {
        try {
            const axiosInstance = axios.create({
                baseURL: 'https://apps.qmet.de/v2/',
                headers: { 'Authorization': `Bearer ${argToken}` }
            });
            await axiosInstance.get(`location/geo/${argLon}/${argLat}`)
                .then(response => {
                    if (response && response.data && response.data.data) {
                        this.props.onStoreLocation(response.data.data);
                        this.props.onAddLocation(response.data.data);
                    }
                    else {
                        this.setState({ locationFetchFails: true });
                        GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Layout - https://apps.qmet.de/v2/location/geo/", ("202107151256 - Error: API responding with no data or wrong data structure"), null, true);
                    }
                })
                .catch(error => {
                    this.setState({ locationFetchFails: true });
                    GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Layout - https://apps.qmet.de/v2/location/geo/", ("202107151257 - Error: " + error.message), null, true);
                })
        }
        catch (error) {
            GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Layout - https://apps.qmet.de/v2/location/geo/", ("202107151258 - Error: " + error.message), null, true);
            this.setState({ locationFetchFails: true });
        }
    }

    tokenFetchErrorReset = () => {
        try {
            this.setState({ tokenFetchFailed: false });
            /*this.getToken();*/
            this.props.onResetTokenError();
            window.location.reload();
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Layout - tokenFetchErrorReset", ("202107151259 - Error: " + error.message), null, true);
         }
    }

    locationFetchErrorReset = () => {
        try {
            this.setState({ locationFetchFails: false, locationAccess: "error" });
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Layout - locationFetchErrorReset", ("202107151400 - Error: " + error.message), null, true);
        }
    }

    getListSelectedLocation = (argLocation) => {
        try {
            window.stop();
            this.props.onStoreLocation(argLocation);
            this.props.onAddLocation(argLocation);
            if (this.props.location && this.props.location.ID_Stadt) {
                if (parseInt(argLocation.ID_Stadt) !== parseInt(this.props.location.ID_Stadt)) {
                    //window.location.reload();
                }
            }
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Layout - getListSelectedLocation", ("202107151401 - Error: " + error.message), null, true);
        }
    }

    render() {

        let componentOrganizer = (
            <div className={cssModule.ErsteLadung}>
                <div className={cssModule.LinearActivity}>
                    <div className={cssModule.Indeterminate}></div>
                </div>
            </div>);
        try{
        if(this.state.locationWithUrl === true){
            if (this.props.token && this.props.token.token && this.props.token.token.length >= 0 && this.props.location && this.props.location.ID_Stadt) {
                componentOrganizer = (<Navigation
                    userToken={this.props.token}
                    location={this.props.location}
                    nowData={this.props.homeData.homeData}
                    settings={this.props.settings}
                    locationHistory={this.props.locationHistory}
                    changeLocation={this.getListSelectedLocation}
                    accessLocation={this.state.locationAccess} />);
            } else {
                if(this.props.loc.error){
                    componentOrganizer = (<Navigation
                        userToken={this.props.token}
                        location={this.props.location}
                        settings={this.props.settings}
                        nowData={this.props.homeData.homeData}
                        locationHistory={this.props.locationHistory}
                        changeLocation={this.getListSelectedLocation}
                        accessLocation={this.state.locationAccess} />);
                }else{
                    componentOrganizer = (<div className={cssModule.ErsteLadung}>
                        <div className={cssModule.LinearActivity}>
                            <div className={cssModule.Indeterminate}></div>
                        </div>
                    </div>);
                }
            }
        }else if (this.state.locationCached === true) {
            if (this.props.token && this.props.token.token && this.props.token.token.length >= 0 && this.props.location && this.props.location.ID_Stadt) {
                componentOrganizer = (<Navigation
                    userToken={this.props.token}
                    location={this.props.location}
                    nowData={this.props.homeData.homeData}
                    settings={this.props.settings}
                    locationHistory={this.props.locationHistory}
                    changeLocation={this.getListSelectedLocation}
                    accessLocation={this.state.locationAccess} />);
            } else {
                componentOrganizer = (<div className={cssModule.ErsteLadung}>
                    <div className={cssModule.LinearActivity}>
                        <div className={cssModule.Indeterminate}></div>
                    </div>
                </div>);
            }
        } else if (this.state.locationCached === false) {
            if (this.state.locationAccess === null) {
                componentOrganizer = (<div className={cssModule.ErsteLadung}>
                    <div className={cssModule.LinearActivity}>
                        <div className={cssModule.Indeterminate}></div>
                    </div>
                </div>);
            } else if (this.state.locationAccess === "allowed") {
                if (this.props.token && this.props.token.token && this.props.token.token.length >= 0) {
                    if (this.props.location && this.props.location.ID_Stadt) {
                        componentOrganizer = (<Navigation
                            userToken={this.props.token}
                            location={this.props.location}
                            settings={this.props.settings}
                            nowData={this.props.homeData.homeData}
                            locationHistory={this.props.locationHistory}
                            changeLocation={this.getListSelectedLocation}
                            accessLocation={this.state.locationAccess} />);
                    } else {
                        componentOrganizer = (<Navigation
                            userToken={this.props.token}
                            location={this.props.location}
                            settings={this.props.settings}
                            nowData={this.props.homeData.homeData}
                            locationHistory={this.props.locationHistory}
                            changeLocation={this.getListSelectedLocation}
                            accessLocation={this.state.locationAccess} />);
                    }
                } else {
                    componentOrganizer = (<div className={cssModule.ErsteLadung}>
                        <div className={cssModule.LinearActivity}>
                            <div className={cssModule.Indeterminate}></div>
                        </div>
                    </div>);
                }
            } else {

                if (this.props.token && this.props.token.token && this.props.token.token.length >= 0) {
                    componentOrganizer = (<Navigation
                        userToken={this.props.token}
                        location={this.props.location}
                        settings={this.props.settings}
                        nowData={this.props.homeData.homeData}
                        locationHistory={this.props.locationHistory}
                        changeLocation={this.getListSelectedLocation}
                        accessLocation={this.state.locationAccess} />);
                } else {
                    componentOrganizer = (<div className={cssModule.ErsteLadung}>
                        <div className={cssModule.LinearActivity}>
                            <div className={cssModule.Indeterminate}></div>
                        </div>
                    </div>);
                }
            }
        }
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Layout - render", ("202107151402 - Error: " + error.message), null, true);
        }

        return (
            <Fragment>
                {this.props.homeData.loading ?
                        <div className={cssModule.ErsteLadung}>
                            <div className={cssModule.LinearActivity}>
                                <div className={cssModule.Indeterminate}></div>
                            </div>
                        </div>
                    : null}
                {componentOrganizer ? componentOrganizer : <div className="NoPage">{this.props.t('common.messages.unable_to_load_data')}</div>}
                {/*
                    this.state.locationCached === true ? (
                        this.props.token ? this.props.token.token ? this.props.token.token.length > 0 ? this.props.location ? this.props.location.ID_Stadt ?
                            <Navigation
                                userToken={this.props.token}
                                location={this.props.location}
                                nowData={this.props.homeData.homeData}
                                settings={this.props.settings}
                                locationHistory={this.props.locationHistory}
                                changeLocation={this.getListSelectedLocation}
                                accessLocation={this.state.locationAccess}/>
                            : <div className={cssModule.ErsteLadung}><div className={cssModule.LocationLoader}></div></div> :
                            <div className={cssModule.ErsteLadung}><div className={cssModule.LocationLoader}></div></div> :
                            <div className={cssModule.ErsteLadung}><div className={cssModule.LocationLoader}></div></div> :
                            <div className={cssModule.ErsteLadung}><div className={cssModule.LocationLoader}></div></div> :
                            <div className={cssModule.ErsteLadung}><div className={cssModule.LocationLoader}></div></div>
                            ) : (
                            this.state.locationAccess === null ? <div className={cssModule.ErsteLadung}><div className={cssModule.LocationLoader}></div></div> :
                                (this.state.locationAccess === "allowed" ?
                                    (this.props.token ? this.props.token.token ? this.props.token.token.length > 0 ? this.props.location ? this.props.location.ID_Stadt ?
                                        <Navigation
                                            userToken={this.props.token}
                                            location={this.props.location}
                                            settings={this.props.settings}
                                            nowData={this.props.homeData.homeData}
                                            locationHistory={this.props.locationHistory}
                                            changeLocation={this.getListSelectedLocation}
                                            accessLocation={this.state.locationAccess}
                                        />
                                        : <Navigation
                                            userToken={this.props.token}
                                            location={this.props.location}
                                            nowData={this.props.homeData.homeData}
                                            settings={this.props.settings}
                                            locationHistory={this.props.locationHistory}
                                            changeLocation={this.getListSelectedLocation}
                                            accessLocation={this.state.locationAccess} /> :
                                        <Navigation
                                            userToken={this.props.token}
                                            location={this.props.location}
                                            nowData={this.props.homeData.homeData}
                                            settings={this.props.settings}
                                            locationHistory={this.props.locationHistory}
                                            changeLocation={this.getListSelectedLocation}
                                            accessLocation={this.state.locationAccess} /> :
                                        <div className={cssModule.ErsteLadung}><div className={cssModule.LocationLoader}></div></div> :
                                        <div className={cssModule.ErsteLadung}><div className={cssModule.LocationLoader}></div></div> :
                                        <div className={cssModule.ErsteLadung}><div className={cssModule.LocationLoader}></div></div>)
                                    :
                                    (this.props.token ? this.props.token.token ? this.props.token.token.length > 0 ?
                                        <Navigation
                                            userToken={this.props.token}
                                            location={this.props.location}
                                            nowData={this.props.homeData.homeData}
                                            settings={this.props.settings}
                                            locationHistory={this.props.locationHistory}
                                            changeLocation={this.getListSelectedLocation}
                                            accessLocation={this.state.locationAccess} />
                                        : <div className={cssModule.ErsteLadung}><div className={cssModule.LocationLoader}></div></div> :
                                        <div className={cssModule.ErsteLadung}><div className={cssModule.LocationLoader}></div></div> :
                                        <div className={cssModule.ErsteLadung}><div className={cssModule.LocationLoader}></div></div>)))
                */}

                <SweetAlert
                    show={this.state.tokenFetchFailed}
                    warning
                    confirmBtnText={this.props.t('common.messages.try_again')}
                    confirmBtnCssClass={this.props.settings.colorMode ? this.props.settings.colorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn" }
                    title={this.props.t('common.messages.attention')}
                    style={this.props.settings.colorMode ? this.props.settings.colorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor : '#303030', color: '#d1d1d1'} : null : null}
                    onConfirm={this.tokenFetchErrorReset}>
                        {
                            this.props.t('common.messages.token_error')
                        }
                </SweetAlert>
                <SweetAlert
                    show={this.state.locationFetchFails}
                    warning
                    confirmBtnText="Okay"
                    confirmBtnCssClass={this.props.settings.colorMode ? this.props.settings.colorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                    style={this.props.settings.colorMode ? this.props.settings.colorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                    title={this.props.t('common.messages.attention')}
                    onConfirm={this.locationFetchErrorReset}>
                    {
                        this.props.t('common.messages.location_fetch_problem')
                    }
                </SweetAlert>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loc: state.locationSubReducer,
        location: state.locationSubReducer.location,
        locationHistory: state.locationSubReducer.perviousLocations,
        token: state.tokenSubReducer,
        homeData: state.homeSubReducer,
        settings: state.settingsSubReducer,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onStoreLocation: (objectLocation) => { dispatch(actionCreators.storeCurrentLoc(objectLocation)) },
        onAddLocation: (objectLocation) => { dispatch(actionCreators.addLocation(objectLocation)) },
        handleGetToken: () => { dispatch(actionCreators.getToken()) },
        onGetCurrentLocation: (token, lat, lon) => { dispatch(actionCreators.getCurrentLocation(token, lat, lon)) },
        onGetCurrentLocationFromLocal: () => { dispatch(actionCreators.getCurrentLocationFromLocal()) },
        onResetTokenError: () => dispatch(actionCreators.resetTokenError()),
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Layout));
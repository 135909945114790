/* eslint-disable no-unreachable */
import * as actionTypes from './actionTypes';
import axios from 'axios';
import { GAEvent } from '../../Assets/GoogleAnalytics/googleAnalytics';

export const getHomeData = (argToken, argLocID) => {
    try{
    return dispatch => {
        dispatch(getHomeDataStarted());
        const axiosInstance = axios.create({
            baseURL: 'https://apps.qmet.de/v2/',
            headers: { 'Authorization': `Bearer ${argToken}` }
        });

        axiosInstance.get(`home_api/${argLocID}`, { timeout: 10000})
            .then(response => {
                if (response && response.data && response.data.data && response.data.data.data) {
                    dispatch(getHomeDataSuccess(response.data.data.data));
                }
                else {
                    dispatch(getHomeDataFailure('Something went wrong'));
                    GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Home - https://apps.qmet.de/v2/home_api/", "202107141724 - Location id: " + argLocID + " Error: API respond without data / wrong data structure", null, true);
                }
            })
            .catch(error => {
                dispatch(getHomeDataFailure(error.message));
                GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Home - https://apps.qmet.de/v2/home_api/", "202107141725 - Location id: " + argLocID + " Error: " + error.message, null, true);
            })
    };
    } catch (error) {
        GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Home - https://apps.qmet.de/v2/home_api/", "202107141726 - Location id: " + argLocID + " Error: " + error.message, null, true);
    }
};

const getHomeDataSuccess = data => ({
    type: actionTypes.GET_HOME_SUCCESS,
    payload: data
});

const getHomeDataStarted = () => ({
    type: actionTypes.GET_HOME_STARTED
});

const getHomeDataFailure = error => ({
    type: actionTypes.GET_HOME_FAILURE,
    payload: {
        error
    }
});

export const resetHomeDataError = () => ({
    type: actionTypes.RESET_HOME_ERROR,
});
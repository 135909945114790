import React, {Component} from 'react';
import { connect } from 'react-redux';

import * as actionCreators from '../../../../store/actions/index';
import WetterTVSingleComponent from '../../../Components/WetterTVKachelComponent/WetterTVSingleComponent/WetterTVSingleComponent';

class WetterTVSingle extends Component{
    render(){
        return(
            <WetterTVSingleComponent componentData={this.props.kWettertv} userToken = {this.props.token}/>
        );
    }
}

const mapStateToProps = state => {
    return {
        kWettertv: state.kWettertvSubReducer,
        token: state.tokenSubReducer,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetKWettertv: (token, page) => dispatch(actionCreators.getKWettertv(token, page)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WetterTVSingle);
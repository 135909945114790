import * as actionTypes from '../actions/actionTypes';
/*import { loadState } from '../../localStorage';*/

let initialState = null;

/*let localCache = loadState();

if(localCache && localCache.token){
    initialState = localCache.token;
}
else{*/
    initialState = {
        token: null,
        loading: false,
        error: null,
        createTime: null,
    }
/*}*/

try{
// eslint-disable-next-line no-extend-native
Date.prototype.addHours = function (h) {
    this.setHours(this.getHours() + h);
    return this;
}
}catch(error){}

const reducer = (state = initialState, action) => {
    try{
    if (action.type === actionTypes.GET_TOKEN_SUCCESS) {
        return {
            ...state,
            loading: false,
            error: null,
            token: action.payload,
            createTime: new Date().addHours(3), //TOKEN EXPIRE TIME
        }
    } else if (action.type === actionTypes.GET_TOKEN_STARTED) {
        return {
            ...state,
            loading: true
        }
    }
    else if (action.type === actionTypes.GET_TOKEN_FAILURE) {
        return {
            ...state,
            loading: false,
            error: action.payload.error,
        }
    }
    else if (action.type === actionTypes.RESET_TOKEN_ERROR) {
        return {
            ...state,
            error: null,
        }
    }
    else {
        return state;
    }
    } catch (error) { console.log('202102191555', error) }
};

export default reducer;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, Fragment, useState, useEffect, useRef, Profiler } from 'react';
import { Grid, IconButton, makeStyles, Typography, Divider } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import {useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { GAEvent, GAPageView, GATiming } from '../../../../Assets/GoogleAnalytics/googleAnalytics';
//import i18n from '../../../../Assets/Languages/i18n';
import cssModule from './DateData.module.css';
import { ReactComponent as SunAufIcon } from '../../../../Assets/Mobile/Common/icon_sunrise.svg';
import { ReactComponent as MondAufIcon } from '../../../../Assets/Mobile/Common/icon_moonrise.svg';
import { ReactComponent as SunAufDarkIcon } from '../../../../Assets/Mobile/Common/icon_sunrise_dark.svg';
import { ReactComponent as MondAufDarkIcon } from '../../../../Assets/Mobile/Common/icon_moonrise_dark.svg';

/*import Wetter_id_1_day from '../../../../Assets/Mobile/WeatherIcons/Day/wetter_id_1_BG.png';
import Wetter_id_2_day from '../../../../Assets/Mobile/WeatherIcons/Day/wetter_id_2_BG.png';
import Wetter_id_3_day from '../../../../Assets/Mobile/WeatherIcons/Day/wetter_id_3_BG.png';
import Wetter_id_4_day from '../../../../Assets/Mobile/WeatherIcons/Day/wetter_id_4_BG.png';
import Wetter_id_5_day from '../../../../Assets/Mobile/WeatherIcons/Day/wetter_id_5_BG.png';
import Wetter_id_6_day from '../../../../Assets/Mobile/WeatherIcons/Day/wetter_id_6_BG.png';
import Wetter_id_7_day from '../../../../Assets/Mobile/WeatherIcons/Day/wetter_id_7_BG.png';
import Wetter_id_8_day from '../../../../Assets/Mobile/WeatherIcons/Day/wetter_id_8_BG.png';
import Wetter_id_9_day from '../../../../Assets/Mobile/WeatherIcons/Day/wetter_id_9_BG.png';
import Wetter_id_10_day from '../../../../Assets/Mobile/WeatherIcons/Day/wetter_id_10_BG.png';
import Wetter_id_11_day from '../../../../Assets/Mobile/WeatherIcons/Day/wetter_id_11_BG.png';
import Wetter_id_12_day from '../../../../Assets/Mobile/WeatherIcons/Day/wetter_id_12_BG.png';
import Wetter_id_13_day from '../../../../Assets/Mobile/WeatherIcons/Day/wetter_id_13_BG.png';
import Wetter_id_14_day from '../../../../Assets/Mobile/WeatherIcons/Day/wetter_id_14_BG.png';

import Wetter_id_1_night from '../../../../Assets/Mobile/WeatherIcons/Night/wetter_id_1_night_BG.png';
import Wetter_id_2_night from '../../../../Assets/Mobile/WeatherIcons/Night/wetter_id_2_night_BG.png';
import Wetter_id_3_night from '../../../../Assets/Mobile/WeatherIcons/Night/wetter_id_3_night_BG.png';
import Wetter_id_4_night from '../../../../Assets/Mobile/WeatherIcons/Night/wetter_id_4_night_BG.png';
import Wetter_id_5_night from '../../../../Assets/Mobile/WeatherIcons/Night/wetter_id_5_night_BG.png';
import Wetter_id_6_night from '../../../../Assets/Mobile/WeatherIcons/Night/wetter_id_6_night_BG.png';
import Wetter_id_7_night from '../../../../Assets/Mobile/WeatherIcons/Night/wetter_id_7_night_BG.png';
import Wetter_id_8_night from '../../../../Assets/Mobile/WeatherIcons/Night/wetter_id_8_night_BG.png';
import Wetter_id_9_night from '../../../../Assets/Mobile/WeatherIcons/Night/wetter_id_9_night_BG.png';
import Wetter_id_10_night from '../../../../Assets/Mobile/WeatherIcons/Night/wetter_id_10_night_BG.png';
import Wetter_id_11_night from '../../../../Assets/Mobile/WeatherIcons/Night/wetter_id_11_night_BG.png';
import Wetter_id_12_night from '../../../../Assets/Mobile/WeatherIcons/Night/wetter_id_12_night_BG.png';
import Wetter_id_13_night from '../../../../Assets/Mobile/WeatherIcons/Night/wetter_id_13_night_BG.png';
import Wetter_id_14_night from '../../../../Assets/Mobile/WeatherIcons/Night/wetter_id_14_night_BG.png';*/

import { ReactComponent as WetterID1Day } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/sonnig_tag_wetter_id_1_BG.svg';
import { ReactComponent as WetterID2Day } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/heiter_tag_wetter_id_2_BG.svg';
import { ReactComponent as WetterID3Day } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/wolkig_tag_wetter_id_3_BG.svg';
import { ReactComponent as WetterID4Day } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/bewoelkt_tag_wetter_id_4_BG.svg';
import { ReactComponent as WetterID5Day } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/bedeckt_tag_wetter_id_5_BG.svg';
import { ReactComponent as WetterID6Day } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/regenschauer_tag_wetter_id_6_BG.svg';
import { ReactComponent as WetterID7Day } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/regen_tag_wetter_id_7_BG.svg';
import { ReactComponent as WetterID8Day } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/gewitter_tag_wetter_id_8_BG.svg';
import { ReactComponent as WetterID9Day } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/schneeschauer_tag_wetter_id_9_BG.svg';
import { ReactComponent as WetterID10Day } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/schnee_tag_wetter_id_10_BG.svg';
import { ReactComponent as WetterID11Day } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/schneeregen_tag_wetter_id_11_BG.svg';
import { ReactComponent as WetterID12Day } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/nebel_tag_wetter_id_12_BG.svg';
import { ReactComponent as WetterID13Day } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/schneeregenschauer_tag_wetter_id_13_BG.svg';
import { ReactComponent as WetterID14Day } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/spruehregen_tag_wetter_id_14_BG.svg';

import { ReactComponent as WetterID1DayD } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/sonnig_tag_wetter_id_1_BG_D.svg';
import { ReactComponent as WetterID2DayD } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/heiter_tag_wetter_id_2_BG_D.svg';
import { ReactComponent as WetterID3DayD } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/wolkig_tag_wetter_id_3_BG_D.svg';
import { ReactComponent as WetterID4DayD } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/bewoelkt_tag_wetter_id_4_BG_D.svg';
import { ReactComponent as WetterID5DayD } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/bedeckt_tag_wetter_id_5_BG_D.svg';
import { ReactComponent as WetterID6DayD } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/regenschauer_tag_wetter_id_6_BG_D.svg';
import { ReactComponent as WetterID7DayD } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/regen_tag_wetter_id_7_BG_D.svg';
import { ReactComponent as WetterID8DayD } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/gewitter_tag_wetter_id_8_BG_D.svg';
import { ReactComponent as WetterID9DayD } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/schneeschauer_tag_wetter_id_9_BG_D.svg';
import { ReactComponent as WetterID10DayD } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/schnee_tag_wetter_id_10_BG_D.svg';
import { ReactComponent as WetterID11DayD } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/schneeregen_tag_wetter_id_11_BG_D.svg';
import { ReactComponent as WetterID12DayD } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/nebel_tag_wetter_id_12_BG_D.svg';
import { ReactComponent as WetterID13DayD } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/schneeregenschauer_tag_wetter_id_13_BG_D.svg';
import { ReactComponent as WetterID14DayD } from '../../../../Assets/Mobile/WeatherIcons/svg_day_BG/spruehregen_tag_wetter_id_14_BG_D.svg';

import { ReactComponent as WetterID1Night } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/klar_nacht_wetter_id_1_BG.svg';
import { ReactComponent as WetterID2Night } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/heiter_nacht_wetter_id_2_BG.svg';
import { ReactComponent as WetterID3Night } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/wolkig_nacht_wetter_id_3_BG.svg';
import { ReactComponent as WetterID4Night } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/bewoelkt_nacht_wetter_id_4_BG.svg';
import { ReactComponent as WetterID5Night } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/bedeckt_nacht_wetter_id_5_BG.svg';
import { ReactComponent as WetterID6Night } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/regenschauer_nacht_wetter_id_6_BG.svg';
import { ReactComponent as WetterID7Night } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/regen_nacht_wetter_id_7_BG.svg';
import { ReactComponent as WetterID8Night } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/gewitter_nacht_wetter_id_8_BG.svg';
import { ReactComponent as WetterID9Night } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/schneeschauer_nacht_wetter_id_9_BG.svg';
import { ReactComponent as WetterID10Night } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/schnee_nacht_wetter_id_10_BG.svg';
import { ReactComponent as WetterID11Night } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/schneeregen_nacht_wetter_id_11_BG.svg';
import { ReactComponent as WetterID12Night } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/nebel_nacht_wetter_id_12_BG.svg';
import { ReactComponent as WetterID13Night } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/schneeregenschauer_nacht_wetter_id_13_BG.svg';
import { ReactComponent as WetterID14Night } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/spruehregen_nacht_wetter_id_14_BG.svg';

import { ReactComponent as WetterID1NightD } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/klar_nacht_wetter_id_1_BG_D.svg';
import { ReactComponent as WetterID2NightD } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/heiter_nacht_wetter_id_2_BG_D.svg';
import { ReactComponent as WetterID3NightD } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/wolkig_nacht_wetter_id_3_BG_D.svg';
import { ReactComponent as WetterID4NightD } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/bewoelkt_nacht_wetter_id_4_BG_D.svg';
import { ReactComponent as WetterID5NightD } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/bedeckt_nacht_wetter_id_5_BG_D.svg';
import { ReactComponent as WetterID6NightD } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/regenschauer_nacht_wetter_id_6_BG_D.svg';
import { ReactComponent as WetterID7NightD } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/regen_nacht_wetter_id_7_BG_D.svg';
import { ReactComponent as WetterID8NightD } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/gewitter_nacht_wetter_id_8_BG_D.svg';
import { ReactComponent as WetterID9NightD } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/schneeschauer_nacht_wetter_id_9_BG_D.svg';
import { ReactComponent as WetterID10NightD } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/schnee_nacht_wetter_id_10_BG_D.svg';
import { ReactComponent as WetterID11NightD } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/schneeregen_nacht_wetter_id_11_BG_D.svg';
import { ReactComponent as WetterID12NightD } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/nebel_nacht_wetter_id_12_BG_D.svg';
import { ReactComponent as WetterID13NightD } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/schneeregenschauer_nacht_wetter_id_13_BG_D.svg';
import { ReactComponent as WetterID14NightD } from '../../../../Assets/Mobile/WeatherIcons/svg_night_BG/spruehregen_nacht_wetter_id_14_BG_D.svg';


const useStyles = makeStyles({
    button: {
        color: '#fafafa',
    },
    buttonDark: {
        color: '#d1d1d1',
    }
});

const useStyles1 = makeStyles((theme) => ({
    divider: {
        background: 'rgba(255,255,255,.3)',
    },
}));

const getWetterIcon = (argWetterID, argStunde, argSunUpTime, argSunDownTime, argColorMode) => {
    try {

        let sunUpStunde, sunDownStunde = null;

        if (!isNaN(argWetterID) && !isNaN(argStunde)) {
            if (argSunUpTime && argSunDownTime && argSunUpTime.length > 0 && argSunDownTime.length > 0) {
                sunUpStunde = parseInt(argSunUpTime.toString().substr(0, 2));
                sunDownStunde = parseInt(argSunDownTime.toString().substr(0, 2));
                if ((parseInt(argStunde) > sunUpStunde) && (parseInt(argStunde) < sunDownStunde)) {
                    /*DAY ICONS */
                    if(argColorMode==="D"){
                        switch (parseInt(argWetterID)) {
                            case 1: return (<WetterID1DayD height="40px" width="40px"/>);
                            case 2: return (<WetterID2DayD height="40px" width="40px"/>);
                            case 3: return (<WetterID3DayD height="40px" width="40px"/>);
                            case 4: return (<WetterID4DayD height="40px" width="40px"/>);
                            case 5: return (<WetterID5DayD height="40px" width="40px"/>);
                            case 6: return (<WetterID6DayD height="40px" width="40px"/>);
                            case 7: return (<WetterID7DayD height="40px" width="40px"/>);
                            case 8: return (<WetterID8DayD height="40px" width="40px"/>);
                            case 9: return (<WetterID9DayD height="40px" width="40px"/>);
                            case 10: return (<WetterID10DayD height="40px" width="40px"/>);
                            case 11: return (<WetterID11DayD height="40px" width="40px"/>);
                            case 12: return (<WetterID12DayD height="40px" width="40px"/>);
                            case 13: return (<WetterID13DayD height="40px" width="40px"/>);
                            case 14: return (<WetterID14DayD height="40px" width="40px"/>);
                            default: return null;
                        }
                    }else{
                        switch (parseInt(argWetterID)) {
                            case 1: return (<WetterID1Day height="40px" width="40px" />);
                            case 2: return (<WetterID2Day height="40px" width="40px" />);
                            case 3: return (<WetterID3Day height="40px" width="40px" />);
                            case 4: return (<WetterID4Day height="40px" width="40px" />);
                            case 5: return (<WetterID5Day height="40px" width="40px" />);
                            case 6: return (<WetterID6Day height="40px" width="40px" />);
                            case 7: return (<WetterID7Day height="40px" width="40px" />);
                            case 8: return (<WetterID8Day height="40px" width="40px" />);
                            case 9: return (<WetterID9Day height="40px" width="40px" />);
                            case 10: return (<WetterID10Day height="40px" width="40px" />);
                            case 11: return (<WetterID11Day height="40px" width="40px" />);
                            case 12: return (<WetterID12Day height="40px" width="40px" />);
                            case 13: return (<WetterID13Day height="40px" width="40px" />);
                            case 14: return (<WetterID14Day height="40px" width="40px" />);
                            default: return null;
                        }
                    }
                }
                else {
                    /*NIGHT ICONS */
                    if (argColorMode === "D") {
                        switch (parseInt(argWetterID)) {
                            case 1: return (<WetterID1NightD height="40px" width="40px" />);
                            case 2: return (<WetterID2NightD height="40px" width="40px" />);
                            case 3: return (<WetterID3NightD height="40px" width="40px" />);
                            case 4: return (<WetterID4NightD height="40px" width="40px" />);
                            case 5: return (<WetterID5NightD height="40px" width="40px" />);
                            case 6: return (<WetterID6NightD height="40px" width="40px" />);
                            case 7: return (<WetterID7NightD height="40px" width="40px" />);
                            case 8: return (<WetterID8NightD height="40px" width="40px" />);
                            case 9: return (<WetterID9NightD height="40px" width="40px" />);
                            case 10: return (<WetterID10NightD height="40px" width="40px" />);
                            case 11: return (<WetterID11NightD height="40px" width="40px" />);
                            case 12: return (<WetterID12NightD height="40px" width="40px" />);
                            case 13: return (<WetterID13NightD height="40px" width="40px" />);
                            case 14: return (<WetterID14NightD height="40px" width="40px" />);
                            default: return null;
                        }
                    }else{
                        switch (parseInt(argWetterID)) {
                            case 1: return (<WetterID1Night height="40px" width="40px" />);
                            case 2: return (<WetterID2Night height="40px" width="40px" />);
                            case 3: return (<WetterID3Night height="40px" width="40px" />);
                            case 4: return (<WetterID4Night height="40px" width="40px" />);
                            case 5: return (<WetterID5Night height="40px" width="40px" />);
                            case 6: return (<WetterID6Night height="40px" width="40px" />);
                            case 7: return (<WetterID7Night height="40px" width="40px" />);
                            case 8: return (<WetterID8Night height="40px" width="40px" />);
                            case 9: return (<WetterID9Night height="40px" width="40px" />);
                            case 10: return (<WetterID10Night height="40px" width="40px" />);
                            case 11: return (<WetterID11Night height="40px" width="40px" />);
                            case 12: return (<WetterID12Night height="40px" width="40px" />);
                            case 13: return (<WetterID13Night height="40px" width="40px" />);
                            case 14: return (<WetterID14Night height="40px" width="40px" />);
                            default: return null;
                        }
                    }
                }
            }
            else {
                if (argColorMode === "D") {
                    switch (parseInt(argWetterID)) {
                        case 1: return (<WetterID1DayD height="40px" width="40px" />);
                        case 2: return (<WetterID2DayD height="40px" width="40px" />);
                        case 3: return (<WetterID3DayD height="40px" width="40px" />);
                        case 4: return (<WetterID4DayD height="40px" width="40px" />);
                        case 5: return (<WetterID5DayD height="40px" width="40px" />);
                        case 6: return (<WetterID6DayD height="40px" width="40px" />);
                        case 7: return (<WetterID7DayD height="40px" width="40px" />);
                        case 8: return (<WetterID8DayD height="40px" width="40px" />);
                        case 9: return (<WetterID9DayD height="40px" width="40px" />);
                        case 10: return (<WetterID10DayD height="40px" width="40px" />);
                        case 11: return (<WetterID11DayD height="40px" width="40px" />);
                        case 12: return (<WetterID12DayD height="40px" width="40px" />);
                        case 13: return (<WetterID13DayD height="40px" width="40px" />);
                        case 14: return (<WetterID14DayD height="40px" width="40px" />);
                        default: return null;
                    }
                } else {
                    switch (parseInt(argWetterID)) {
                        case 1: return (<WetterID1Day height="40px" width="40px" />);
                        case 2: return (<WetterID2Day height="40px" width="40px" />);
                        case 3: return (<WetterID3Day height="40px" width="40px" />);
                        case 4: return (<WetterID4Day height="40px" width="40px" />);
                        case 5: return (<WetterID5Day height="40px" width="40px" />);
                        case 6: return (<WetterID6Day height="40px" width="40px" />);
                        case 7: return (<WetterID7Day height="40px" width="40px" />);
                        case 8: return (<WetterID8Day height="40px" width="40px" />);
                        case 9: return (<WetterID9Day height="40px" width="40px" />);
                        case 10: return (<WetterID10Day height="40px" width="40px" />);
                        case 11: return (<WetterID11Day height="40px" width="40px" />);
                        case 12: return (<WetterID12Day height="40px" width="40px" />);
                        case 13: return (<WetterID13Day height="40px" width="40px" />);
                        case 14: return (<WetterID14Day height="40px" width="40px" />);
                        default: return null;
                    }
                }
            }
        }
    } catch (error) { 
        GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly Data - getWetterIcon", ("202107151456 - Error: " + error.message + " Values: " + argWetterID + ", " + argStunde + ", " + argSunUpTime + ", " + argSunDownTime + ", " + argColorMode), null, true);
     }
}

const dayData = memo((props) => {

    const { t, i18n } = useTranslation();

    const storeSettings = useSelector(state => state.settingsSubReducer);
    const history = useHistory();

    const formatWindDirection = (argWindDir) => {
        try {
            if (argWindDir && argWindDir.length > 0) {
                return argWindDir.toString().replace(/E/g, "O");
            }
            else {
                return argWindDir;
            }
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly Data - formatWindDirection", ("202107151457 - Error: " + error.message + " Values: " + argWindDir), null, true);
         }
    }

    const getDate = (argDate) => {
        try {
            let today;
            today = new Date(argDate);
            let dd = String(today.getDate()).padStart(2, '0');
            let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            let yyyy = today.getFullYear();
            return yyyy + '-' + mm + '-' + dd;
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly Data - getDate", ("202107151458 - Error: " + error.message + " Values: " + argDate), null, true);
         }
    }

    const formatDateDisplay = (argDate) => {
        try {
            let date = new Date(argDate);
            if(i18n.language.toString() === "de"){
                return (date.toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }));
            }else if(i18n.language.toString() === "en"){
                return(date.toLocaleDateString('en-GB', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }));
            }
            //8. JAN. 2021
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly Data - formatDateDisplay", ("202107151459 - Error: " + error.message + " Values: " + argDate), null, true);
         }
    }

    const formatDateEN = (argDate) => {
        try {
            let date = new Date(argDate);
            return (date.toLocaleDateString('en-EN', { year: 'numeric', month: 'short', day: 'numeric' }).toUpperCase());
            //8. JAN. 2021
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly Data - formatDateEN", ("202107151500 - Error: " + error.message + " Values: " + argDate), null, true);
         }
    }

    const validateCurrentWeather = (argTemp, argUnit = storeSettings) => {
        try {
            if (argTemp === 99 || argTemp === -99) {
                return "-";
            }
            else {
                if (argUnit && argUnit.temperature) {
                    if (argUnit.temperature.toString() === "F") {
                        return Math.round((argTemp * 9 / 5) + 32);
                    }
                    else {
                        return argTemp;
                    }
                } else {
                    return argTemp;
                }
            }
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly Data - validateCurrentWeather", ("202107151501 - Error: " + error.message + " Values: " + argTemp + ", " + argUnit), null, true);
         }
    }

    const calculateWind = (argValue, argUnit = storeSettings) => {
        try {

            if (!isNaN(argValue) && argUnit && argUnit.windgeschwindigkeit) {
                if (argUnit.windgeschwindigkeit.toString() === "bft") {
                    if (argValue < 1) { return "0 Bft"; }
                    else if (argValue >= 1 && argValue <= 5) { return "1 Bft"; }
                    else if (argValue >= 6 && argValue <= 11) { return "2 Bft"; }
                    else if (argValue >= 12 && argValue <= 19) { return "3 Bft"; }
                    else if (argValue >= 20 && argValue <= 28) { return "4 Bft"; }
                    else if (argValue >= 29 && argValue <= 38) { return "5 Bft"; }
                    else if (argValue >= 39 && argValue <= 49) { return "6 Bft"; }
                    else if (argValue >= 50 && argValue <= 61) { return "7 Bft"; }
                    else if (argValue >= 62 && argValue <= 74) { return "8 Bft"; }
                    else if (argValue >= 75 && argValue <= 88) { return "9 Bft"; }
                    else if (argValue >= 89 && argValue <= 102) { return "10 Bft"; }
                    else if (argValue >= 102 && argValue <= 118) { return "11 Bft"; }
                    else if (argValue > 118) { return "12 Bft"; }
                } else if (argUnit.windgeschwindigkeit.toString() === "ms") {
                    return ((argValue * 0.277778).toLocaleString('en-GB',{ maximumFractionDigits: 1}) + " m/s");
                } else {
                    return (argValue + " km/h");
                }
            } else {
                return (argValue + " km/h");
            }
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly Data - calculateWind", ("202107151502 - Error: " + error.message + " Values: " + argValue + ", " + argUnit), null, true);
         }
    }

    const classes = useStyles();
    const classes1 = useStyles1();

    const [height, setHeight] = useState(0);
    const [hookDateData, setDateData] = useState(null);
    const [hookDateDataError, setDateDataError] = useState(false);
    const [hookDateNoData, setDateNoData] = useState(false);
    const [hookDate, setDate] = useState(formatDateEN(props.match.params.id));
    const [hookDateFormated, setDateFormated] = useState(formatDateDisplay(props.match.params.id));
    const [hookDateAddCount, setDateAddCount] = useState(1);
    const [hookDateRedCount, setDateRedCount] = useState(1);

    const [locationKeys, setLocationKeys] = useState([]);


    const ref = useRef(null);

    const handlePrevDate = (argDate) => {
        try {
            if (hookDate != null) {
                let date = new Date(new Date(hookDate).getTime() - 86400000);
                setDate(date);
                setDateRedCount(hookDateRedCount + 1);
                setDateFormated(formatDateDisplay(date));
                getDateData(getDate(date));
                history.push(`/tagesdaten/${getDate(date)}/mul/${props.userLocation.ID_Stadt}`);
                GAEvent("Hourly Data", "Button pressed", ("Prev date button pressed. Date selected " + date), null, false);
            }
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly Data - handlePrevDate", ("202107151503 - Error: " + error.message + " Values: " + hookDate), null, true);
         }
    }
    const handleNextDate = (argDate) => {
        try {
            if (hookDate != null) {
                let date = new Date(new Date(hookDate).getTime() + 86400000);
                setDate(date);
                setDateAddCount(hookDateAddCount + 1);
                setDateFormated(formatDateDisplay(date));
                getDateData(getDate(date));
                history.push(`/tagesdaten/${getDate(date)}/mul/${props.userLocation.ID_Stadt}`);
                GAEvent("Hourly Data", "Button pressed", ("Next date button pressed. Date selected " + date), null, false);
            }
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly Data - handleNextDate", ("202107151504 - Error: " + error.message + " Values: " + hookDate), null, true);
        }
    }

    const getDateDiffer = (argDate) => {
        try {
            const _MS_PER_DAY = 1000 * 60 * 60 * 24;
            const today = Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
            const date = Date.UTC(new Date(hookDate).getFullYear(), new Date(hookDate).getMonth(), new Date(hookDate).getDate());
            return Math.floor((date - today) / _MS_PER_DAY);
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly Data - getDateDiffer", ("202107151505 - Error: " + error.message + " Values: " + hookDate), null, true);
        }
    }

    useEffect(() => {
        try{
            if (props.match.params.location !== 'undefined' && props.match.params.location !== undefined) {
                GAPageView(window.location.pathname, [], "Hourly data page");
            }
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly Data - useEffect", ("202107151506 - Error: " + error.message), null, true);
        }
    }, [props.match.params.location]);

    let componentOrganizer = null;

    useEffect(() => {
        try{
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([location.key])
            }

            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys)
                } else {
                    setLocationKeys((keys) => [location.key, ...keys])
                    history.push('/');
                }
            }
        })
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly Data - useEffect", ("202107151506 - Error: " + error.message), null, true);
        }
    }, [locationKeys,])

    useEffect(() => {
        setHeight(ref.current.clientHeight + 20);
        try {
            const fetchDateData = async () => {

                const axiosInstance = axios.create({
                    baseURL: 'https://apps.qmet.de/v2/',
                    headers: { 'Authorization': `Bearer ${props.userToken}`}
                });

                await axiosInstance.post(`hourly_data`, { id: props.match.params.location, date: props.match.params.id ? props.match.params.id.toString() : getDate(new Date()) })
                    .then(response => {
                        if (response && response.data && response.data.data && response.data.data) {
                            if (response.data.data.data.length > 0) {
                                setDateData(response.data.data);
                                setDate(new Date(props.match.params.id).toLocaleDateString('en-EN', { year: 'numeric', month: 'short', day: 'numeric' }).toUpperCase());
                            } else {
                                setDateNoData(true);
                                GAEvent("No data - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly data - No data available", ("202105191003 - No data in database for specified parameters - Location ID: " + props.match.params.location + " date: " + props.match.params.id), null, true);
                            }
                        }
                        else {
                            setDateDataError(true);
                            GAEvent("Error - " + new Date().toLocaleDateString('de-DE', {year: 'numeric', month: 'long', day: 'numeric' }), "Hourly data - https://apps.qmet.de/v2/hourly_data", ("202107141700 - Location ID: " + props.match.params.location + " date: " + props.match.params.id + "Error: API respond without data / wrong data structure"), null, true);
                        }
                    })
                    .catch(error => {
                        setDateDataError(true);
                        GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly data - https://apps.qmet.de/v2/hourly_data", ("202107141701 - Location ID: " + props.match.params.location + " date: " + props.match.params.id + "Error: " + error.message), null, true);
                    })
            }

            if (props.match.params.location !== undefined && Number(props.match.params.location) > 0){
                fetchDateData();
            }
        } catch (error) {
            setDateDataError(true);
            GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly data - https://apps.qmet.de/v2/hourly_data", ("202107141702 - Lat: " + props.match.params.lon + " lon: " + props.match.params.lat + " date: " + props.match.params.id + "Error: " + error.message), null, true);
        }
    }, [props.match.params.location]);

    useEffect(() => {
        setHeight(ref.current.clientHeight + 20);
        try {
            const fetchDateData = async () => {

                const axiosInstance = axios.create({
                    baseURL: 'https://apps.qmet.de/v2/',
                    headers: { 'Authorization': `Bearer ${props.userToken}` }
                });

                await axiosInstance.post(`hourly_data`, { id: props.userLocation.ID_Stadt, date: props.match.params.id ? props.match.params.id.toString() : getDate(new Date()) })
                    .then(response => {
                        if (response && response.data && response.data.data && response.data.data) {
                            if (response.data.data.data.length > 0) {
                                setDateData(response.data.data);
                                setDate(new Date(props.match.params.id).toLocaleDateString('en-EN', { year: 'numeric', month: 'short', day: 'numeric' }).toUpperCase());
                                history.push(`/tagesdaten/${getDate(props.match.params.id.toString())}/mul/${props.userLocation.ID_Stadt}`);
                            } else {
                                setDateNoData(true);
                                GAEvent("No data - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly data - No data available", ("202105191004 - No data in database for specified parameters Location ID: " + props.match.params.location + " date: " + props.match.params.id), null, true);
                            }
                        }
                        else {
                            setDateDataError(true);
                            GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly data - https://apps.qmet.de/v2/hourly_data", ("202107141703 - Location ID: " + props.match.params.location + " date: " + props.match.params.id + "Error: API respond without data / wrong data structure"), null, true);
                        }
                    })
                    .catch(error => {
                        setDateDataError(true);
                        GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly data - https://apps.qmet.de/v2/hourly_data", ("202107141704 - Location ID: " + props.match.params.location + " date: " + props.match.params.id + "Error: " + error.message), null, true);
                    })
            }
            fetchDateData();
        } catch (error) {
            setDateDataError(true);
            GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly data - https://apps.qmet.de/v2/hourly_data", ("202107141705 - Location ID: " + props.match.params.location + " date: " + props.match.params.id + "Error: " + error.message), null, true);
        }
    }, [props.userLocation]);

    const getDateData = async (argDate) => {
        try {
            const axiosInstance = axios.create({
                baseURL: 'https://apps.qmet.de/v2/',
                headers: { 'Authorization': `Bearer ${props.userToken}` }
            });

            await axiosInstance.post(`hourly_data`, { id: props.match.params.location, date: argDate ? argDate.toString() : getDate(new Date()) })
                .then(response => {
                    if (response && response.data && response.data.data && response.data.data) {
                        if (response.data.data.data.length > 0) {
                            setDateData(response.data.data);
                        } else {
                            setDateNoData(true);
                            GAEvent("No data - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly data - No data available", ("202105191005 - No data in database for specified parameters - lat: " + props.match.params.lon + " lon: " + props.match.params.lat + " date: " + argDate ), null, true);
                        }
                    }
                    else {
                        setDateDataError(true);
                        GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly data - https://apps.qmet.de/v2/hourly_data", ("202107141706 - Location ID: " + props.match.params.location + " date: " + props.match.params.id + "Error: API respond without data / wrong data structure"), null, true);
                    }
                })
                .catch(error => {
                    setDateDataError(true);
                    GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly data - https://apps.qmet.de/v2/hourly_data", ("202107141707 - Location ID: " + props.match.params.location + " date: " + props.match.params.id + "Error: " + error.message), null, true);
                })
        } catch (error) {
            setDateDataError(true);
            GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly data - https://apps.qmet.de/v2/hourly_data", ("202107141708 - Location ID: " + props.match.params.location + " date: " + props.match.params.id + "Error: " + error.message), null, true);
        }
    }

    const handleAlertClose = () => {
        try {
            props.history.push('/');
            setDateDataError(false);
            setDateNoData(false);
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly Data - handleAlertClose", ("202107151507 - Error: " + error.message), null, true);
        }
    }

    const setPerformance = (argProfilerId, argMode, argActualTime, argBaseTime, argStartTime, argCommitTime, argInteracions) =>{
        try{
            if(argMode && argMode.toString() === "mount"){
                GATiming(argProfilerId, argMode, argActualTime, ( argProfilerId + " " + argMode ));
            }
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly Data - setPerformance", ("202107151508 - Error: " + error.message), null, true);
        }
    }

    let parentOrganizer = null;

    try {
        if (hookDateData && hookDateData.data && hookDateData.data.length > 0) {
            const varHour = parseInt(new Date().getHours());
            const varDate = parseInt(new Date().getDate());
            const varMonth = parseInt(new Date().getMonth()) + 1;
            componentOrganizer = hookDateData.data.map((stunde, index) => {
                let stundeHour = stunde.date ? parseInt(new Date(stunde.date.toString().replace("Z","")).getHours()) : null;//parseInt(stunde.hour);
                let stundeDate = stunde.date ? parseInt(new Date(stunde.date.toString().replace("Z","")).getDate()) : null;
                let stundeMonth = stunde.date ? parseInt(new Date(stunde.date.toString().replace("Z","")).getMonth()) + 1 : null;
                if (stundeMonth !== null && stundeDate !== null && stundeHour !== null && stundeDate === varDate && stundeMonth === varMonth && stundeHour <= varHour){
                    return null;
                }else{
                return (
                    <tr key={index}>
                        <td align="center">
                            <Typography variant="body2">{isNaN(stunde.hour) ? '-' : stunde.hour + ":00"}</Typography>{/*format 00:00*/}
                        </td>
                        <td align="center">
                            {/*<img className={cssModule.WeatherIcon} src={isNaN(stunde.ID_Wetter) ? "-" : getWetterIcon(stunde.ID_Wetter, stunde.hour, hookDateData.sunrise, hookDateData.sunset, props.activeColorMode.toString())} alt="weatherIcon"></img>*/}
                            {getWetterIcon(stunde.ID_Wetter, stunde.hour, hookDateData.sunrise, hookDateData.sunset, props.activeColorMode.toString())}
                        </td>
                        <td align="center">
                            <Typography variant="body2">{isNaN(stunde.Temp) ? '-' : validateCurrentWeather(stunde.Temp)}°</Typography>
                        </td>
                        <td align="center">
                            <Typography variant="body2">{isNaN(stunde.Precipitation) ? '0.0' : stunde.Precipitation === 0 ? '0.0' : stunde.Precipitation } mm</Typography>
                        </td>
                        <td align="center">
                            <Typography variant="body2">{stunde.WindDirectionVal ? formatWindDirection(stunde.WindDirectionVal) : '-'}</Typography>
                            <Typography variant="body2">{isNaN(stunde.WindDirection) ? '-' : calculateWind(stunde.WindDirection)}</Typography>
                        </td>
                    </tr>
                );
                }
            });
        }
    } catch (error) {
        GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly Data - render", ("202107151509 - Error: " + error.message), null, true);
    }

    try{
        parentOrganizer =(
            <div className={props.activeColorMode ? props.activeColorMode.toString() === "D" ? cssModule.TopSectionDark : cssModule.TopSection : cssModule.TopSection} ref={ref}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <div className={cssModule.DateSelector}>
                            <span className={props.activeColorMode ? props.activeColorMode.toString() === "D" ? cssModule.DateContainerDark : cssModule.DateContainer : cssModule.DateContainer}>
                                {
                                    props.match.params.type.toString() === 'mul' ?
                                        getDateDiffer() < 1 ? null : (
                                            (<IconButton onClick={() => handlePrevDate(props.match.params.id ? props.match.params.id : null)} aria-label="reduce" size="small" className={props.activeColorMode ? props.activeColorMode.toString() === "D" ? classes.buttonDark : classes.button : classes.button}>
                                                <ArrowLeftIcon fontSize="large" />
                                            </IconButton>))
                                        : null
                                }
                                <span className={props.activeColorMode ? props.activeColorMode.toString() === "D" ? cssModule.DateValueDark : cssModule.DateValue : cssModule.DateValue}>{props.match.params.id ? hookDateFormated : null}</span>
                                {
                                    props.match.params.type.toString() === 'mul' ?
                                        getDateDiffer() > 8 ? null : (
                                            <IconButton onClick={() => handleNextDate(props.match.params.id ? props.match.params.id : null)} aria-label="add" size="small" className={props.activeColorMode ? props.activeColorMode.toString() === "D" ? classes.buttonDark : classes.button : classes.button}>
                                                <ArrowRightIcon fontSize="large" />
                                            </IconButton>)
                                        : null
                                }
                            </span>
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={0}>
                    <Grid item xs={6}>
                        <div className={cssModule.SunTimeSection}>
                            {
                                props.activeColorMode ? props.activeColorMode.toString() === "D" ? <SunAufDarkIcon width={55} /> : <SunAufIcon width={55} /> : <SunAufIcon width={55} />
                            }
                            {
                                hookDateData ?
                                    (<Typography variant="body1" className={props.activeColorMode ? props.activeColorMode.toString() === "D" ? cssModule.TextDark : cssModule.TextLight : cssModule.TextLight}>{hookDateData.sunrise ? hookDateData.sunrise : '-'}</Typography>)
                                    : null
                            }
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={cssModule.SunTimeSection}>
                            {
                                props.activeColorMode ? props.activeColorMode.toString() === "D" ? <MondAufDarkIcon width={55} /> : <MondAufIcon width={55} /> : <MondAufIcon width={55} />}
                            {
                                hookDateData ?
                                    (<Typography variant="body1" className={props.activeColorMode ? props.activeColorMode.toString() === "D" ? cssModule.TextDark : cssModule.TextLight : cssModule.TextLight}>{hookDateData.sunset ? hookDateData.sunset : '-'}</Typography>)
                                    : null
                            }
                        </div>
                    </Grid>
                </Grid>
                <Divider variant="middle" classes={{ root: classes1.divider }} />

                <table className={props.activeColorMode ? props.activeColorMode.toString() === "D" ? cssModule.TableWeatherDark : cssModule.TableWeather : cssModule.TableWeather} aria-label="datas" cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            <th align="center">
                                {
                                    /*Uhrzeit*/
                                    t('app.pages.hourly_data.labels.time')
                                }
                            </th>
                            <th align="center">
                                {
                                    /*Wetter*/
                                    t('app.pages.hourly_data.labels.weather')
                                }
                            </th>
                            <th align="center">
                                {
                                    /*Temp*/
                                    t('app.pages.hourly_data.labels.temp')
                                }
                            </th>
                            <th align="center">
                                {
                                    /*Niederschlag*/
                                    t('app.pages.hourly_data.labels.precipitation')
                                }
                            </th>
                            <th align="center">
                                {
                                    /*Wind*/
                                    t('app.pages.hourly_data.labels.wind')
                                }
                            </th>
                        </tr>
                    </thead>
                </table>
            </div>
        );
    }catch(error){
        GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Hourly Data - render", ("202107151510 - Error: " + error.message), null, true);
    }

    return (
        <Fragment>
            <Profiler id="Hourly data" onRender={setPerformance}>
                {parentOrganizer}
            <div className={cssModule.TableSection} style={{ marginTop: `${height}px` }}>
                <table className={props.activeColorMode ? props.activeColorMode.toString() === "D" ? cssModule.TableWeatherDark : cssModule.TableWeather : cssModule.TableWeather} aria-label="day data" cellSpacing="0" cellPadding="0">
                    <tbody>
                        {componentOrganizer}
                    </tbody>
                </table>
            </div>

            <SweetAlert
                show={hookDateDataError}
                warning
                confirmBtnText="Okay"
                confirmBtnCssClass={props.activeColorMode ? props.activeColorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                style={props.activeColorMode ? props.activeColorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                title={t('common.messages.attention')}
                onConfirm={handleAlertClose}>
                {
                    t('common.messages.unable_to_load_data')
                }
            </SweetAlert>
            <SweetAlert
                show={hookDateNoData}
                warning
                confirmBtnText="Okay"
                confirmBtnCssClass={props.activeColorMode ? props.activeColorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                style={props.activeColorMode ? props.activeColorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                title={t('common.messages.attention')}
                onConfirm={handleAlertClose}>
                {
                    t('common.messages.no_data_now')
                }
            </SweetAlert>
            </Profiler>
        </Fragment>
    );

});

export default dayData;
import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getKGartenWetter = (argToken, argMonth) => {

    try {
        return dispatch => {
            dispatch(getKGartenWetterStarted());
            const axiosInstance = axios.create({
                baseURL: 'http://165.227.162.31:8000/v4/',
                headers: { 'Authorization': `Bearer ${argToken}` }
            });

            axiosInstance.get(`home/garden_weather?month=${argMonth}`)
                .then(response => {
                    if (response && response.data && response.data.data) {
                        dispatch(getKGartenWetterSuccess(response.data.data));
                    }
                    else {
                        dispatch(getKGartenWetterFailure('Something went wrong'));
                    }
                })
                .catch(error => {
                    dispatch(getKGartenWetterFailure(error.message));
                })
        };
        // eslint-disable-next-line no-unreachable
    } catch (error) { console.log('202108251533', error) }
};

const getKGartenWetterSuccess = data => ({
    type: actionTypes.K_GET_GARTENWETTER_SUCCESS,
    payload: data
});

const getKGartenWetterStarted = () => ({
    type: actionTypes.K_GET_GARTENWETTER_STARTED,
});

const getKGartenWetterFailure = error => ({
    type: actionTypes.K_GET_GARTENWETTER_FAILURE,
    payload: {
        error
    }
});

export const getCacheKGartenWetterData = () => ({
    type: actionTypes.K_GET_GARTENWETTER_CACHED,
});
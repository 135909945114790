import React, { Fragment, useState, memo, useEffect } from 'react';
import { Container, TextField, Link as ExterLink } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import { useHistory, Link } from 'react-router-dom';

import cssModule from './Header.module.css';
import Logo from '../../../../Assets/Desktop/Common/wetter.net-logo-qmet.png';
import MenuImageWetter from '../../../../Assets/Desktop/Common/imgWetterFreizeit.jpg';
import MenuImageAktuell from '../../../../Assets/Desktop/Common/imgAktuell.jpg';
import MenuImageGesundheit from '../../../../Assets/Desktop/Common/imgGesundheit.jpg';
import MenuImageAllgemein from '../../../../Assets/Desktop/Common/imgAllgemein.jpg';

let newsObject = {};
let newsOrganizer = null;

const Header = memo((props) => {

    const history = useHistory();

    const [hookMenuOpenClass, setMenuOpenClass] = useState(cssModule.MainMenuNavigationFirst);
    const [hookMegaMenuOpen, setMegaMenuOpen] = useState('0');
    const [hookLocations, setLocations] = useState([]);
    const [hookInsertedText, setInsertedText] = useState("");

    useEffect(() => {
        try{
        newsObject = JSON.parse(window.localStorage.getItem('wetternet'));
        newsObject = newsObject ? newsObject.kWetternews ? newsObject.kWetternews : {} : {};
        if (newsObject.kWetternews  && newsObject.kWetternews.length > 0){
            newsOrganizer = newsObject.kWetternews.slice(0, 5).map((news) => {
                    return (<tr>
                        <td className={cssModule.MenuAktuellNachriten} aria-details={news.Headline ? news.Headline : "-"}>{news.Headline ? news.Headline : "-"}</td>
                    </tr>);
            });
        }else{
            newsOrganizer = (<tr>
                <td className={cssModule.MenuAktuellNachriten} aria-details="Im Moment keine Nachrichten gefunden">Im Moment keine Nachrichten gefunden</td>
            </tr>);
        }
    }catch(error){
            console.log('202107261638', error)
    }
    }, []);

    const ToggleMenuClass = () => {
        try{
        if (hookMenuOpenClass === cssModule.MainMenuNavigationFirst) {
            setMenuOpenClass(cssModule.MainMenuNavigationOpen);
        }
        else if (hookMenuOpenClass === cssModule.MainMenuNavigation) {
            setMenuOpenClass(cssModule.MainMenuNavigationOpen);
        }
        else {
            setMenuOpenClass(cssModule.MainMenuNavigation);
        }
        } catch (error) { console.log('202103101525',error)}
    }

    const toggleMegaMenuVisibility = (argClass) => {
        try{
        switch (argClass) {
            case 'Wetter':
                setMegaMenuOpen('Wetter');
                break;
            case 'Aktuell':
                setMegaMenuOpen('Aktuell');
                break;
            case 'Gesundheit':
                setMegaMenuOpen('Gesundheit');
                break;
            case 'Freizeit':
                setMegaMenuOpen('Freizeit');
                break;
            case 'Allgemein':
                setMegaMenuOpen('Allgemein');
                break;
            default:
                setMegaMenuOpen('');
        }
        } catch (error) { console.log('202103101526',error)}
    }

    const getLocations = (event, value, reason) => {
        try {
            setInsertedText(value);
            if (value && value.length > 2) {
                const axiosInstance = axios.create({
                    baseURL: 'http://165.227.162.31:8000/v4/',
                    headers: { 'Authorization': `Bearer ${props.userToken}` }
                });
                const timer = setTimeout(() => {
                    axiosInstance.get(`location/${value}`)
                        .then(response => {
                            if (response && response.data && response.data.data) {
                                if (response.data.data.length > 0) {
                                    setLocations(response.data.data);
                                } else {
                                    //this.setState({ newsNoData: true })
                                }
                            }
                        })
                        .catch(error => {
                        })
                }, 500);
                return () => {
                    clearTimeout(timer);
                };
            }
        } catch (error) {
            console.log('202103101527', error);
            /*this.setState({ newsDataError: true });*/
        }
    }

    const handleMenuMouseLeave = () => {
        try {
        setMegaMenuOpen('');
        } catch (error) { console.log('202103101528', error) }
    }

    const getSelectedLocation = (event, value, reason) => {
        try {
            props.setParentLocation(value);
        } catch (error) { console.log('202103101529', error) }
    }

    const loadResultPage = (argLocation) =>{
        try{
        if(argLocation.length > 2){
            history.push(`/wetter/suchergebnisse/${argLocation}`);
        }
        } catch (error) { console.log('202103101530', error) }
    }

    const loadLexikonDetailsPage = (argLexikon) => {
        try{
            history.push({ pathname: `/wetterlexikon/eintrag`, search: `?lexikon=${argLexikon}`});
        }catch(error){
            console.log('202109071420', error);
        }
    }

    return (
        <Fragment>
            <header className={cssModule.Header}>
                <div className={cssModule.HeaderParent}>
                    <div className={cssModule.NavTop}>
                        <Container maxWidth="lg">
                            <div className={cssModule.SearchParent}>
                                <div className={cssModule.SearchContainer}>
                                    <Autocomplete
                                        id="search-location"
                                        freeSolo
                                        options={hookLocations}
                                        getOptionLabel={(location) => location.name}
                                        getOptionSelected={(option, value) => option.name === value.name}
                                        onInputChange={getLocations}
                                        onChange={getSelectedLocation}
                                        style={{ width: '100%' }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                <div style={{ width: '100%' }}>
                                                <div style={{display:"block", fontSize: "1rem", fontWeight:"500"}}>{option.name}</div>
                                                    {option.postal_code ? <div style={{ display: "block", fontSize: ".7rem", fontWeight: "300" }}>PLZ: {option.postal_code}</div> : null }
                                            </div>
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => <TextField {...params} label="" placeholder="Wetter in..." size="small" InputProps={{ ...params.InputProps, disableUnderline: true }} />} />
                                    <div className={cssModule.SearchIcon} onClick={() => (loadResultPage(hookInsertedText))}>
                                        <SearchOutlinedIcon />
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                    <div className={cssModule.LogoContainer}>
                        <div className={cssModule.LogoParent}>
                            <img className={cssModule.Logo} src={Logo} alt="logo" />
                        </div>
                        <div className={cssModule.Parallelogram}></div>
                    </div>
                    <div className={cssModule.NavigationMenu}>
                        <div className={cssModule.MenuIcon} onClick={ToggleMenuClass}>
                            <MenuIcon />
                        </div>
                    </div>
                    <div className={hookMenuOpenClass}>
                        <Container maxWidth="lg" className={cssModule.MainMenuNavContainer}>
                            <nav className={cssModule.NavContainer}>
                                <div
                                    className={cssModule.NavContainerItem}
                                    onMouseEnter={() => { toggleMegaMenuVisibility('Wetter') }}
                                    onMouseLeave={handleMenuMouseLeave}
                                    aria-details="Wetter Menü">
                                    <span>Wetter</span><span className={cssModule.NavContainerItemIcon}>&nbsp;&nbsp;<ExpandMoreIcon fontSize="small" /></span>
                                </div>
                                <div
                                    className={cssModule.NavContainerItem}
                                    onMouseEnter={() => { toggleMegaMenuVisibility('Aktuell') }}
                                    onMouseLeave={handleMenuMouseLeave}
                                    aria-details="Aktuell Menü">
                                    <span>Aktuell</span><span className={cssModule.NavContainerItemIcon}>&nbsp;&nbsp;<ExpandMoreIcon fontSize="small"/></span>
                                </div>
                                <div
                                    className={cssModule.NavContainerItem}
                                    onMouseEnter={() => { toggleMegaMenuVisibility('Gesundheit') }}
                                    onMouseLeave={handleMenuMouseLeave}
                                    aria-details="Gesundheit Menü">
                                    <span>Gesundheit</span><span className={cssModule.NavContainerItemIcon}>&nbsp;&nbsp;<ExpandMoreIcon fontSize="small"/></span>
                                </div>
                                <div
                                    className={cssModule.NavContainerItem}
                                    onMouseEnter={() => { toggleMegaMenuVisibility('Freizeit') }}
                                    onMouseLeave={handleMenuMouseLeave}
                                    aria-details="Freizeit Menü">
                                    <span>Freizeit</span><span className={cssModule.NavContainerItemIcon}>&nbsp;&nbsp;<ExpandMoreIcon fontSize="small"/></span>
                                </div>
                                <div
                                    className={cssModule.NavContainerItem}
                                    onMouseEnter={() => { toggleMegaMenuVisibility('Allgemein') }}
                                    onMouseLeave={handleMenuMouseLeave}
                                    aria-details="Allgemein Menü">
                                    <span>Allgemein</span><span className={cssModule.NavContainerItemIcon}>&nbsp;&nbsp;<ExpandMoreIcon fontSize="small"/></span>
                                </div>
                            </nav>
                        </Container>
                        <div className={cssModule.MegaMenu}>
                            <Container maxWidth="lg">
                                <div
                                    className={hookMegaMenuOpen === 'Wetter' ? cssModule.Visible : cssModule.Hide}
                                    onMouseEnter={() => { toggleMegaMenuVisibility('Wetter') }}
                                    onMouseLeave={handleMenuMouseLeave}>
                                    <div className={cssModule.MenuParent}>
                                        <div className={cssModule.MenuSectionLeft}>
                                            {/*<div className={cssModule.MenuSectionLeftItem} aria-details="Das Wetter für Deutschland">
                                                Deutschland
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Das Wetter für Österreich">
                                                Österreich
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Das Wetter für die Schweiz">
                                                Schweiz
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Das Wetter für Europa">
                                                Europa
                                            </div>*/}
                                        </div>
                                        <div className={cssModule.MenuSectionRight}>
                                            <div className={cssModule.MenuSectionRightLinks}>
                                                <h6>Das Wetter in...</h6>
                                                <hr className={cssModule.HrLine} />
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td aria-details="Das Wetter für Berlin">Berlin</td>
                                                            <td aria-details="Das Wetter fürDas Wetter für Hamburg">Hamburg</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Das Wetter für München">München</td>
                                                            <td aria-details="Das Wetter für Köln">Köln</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Das Wetter für Frankfurt am Main">Frankfurt am Main</td>
                                                            <td aria-details="Das Wetter für Stuttgart">Stuttgart</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={cssModule.Divide} aria-details="Das Wetter für Wien">Wien</td>
                                                            <td className={cssModule.Divide} aria-details="Das Wetter für Graz">Graz</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Das Wetter für Linz">Linz</td>
                                                            <td aria-details="Das Wetter für Salzburg">Salzburg</td>
                                                        </tr>
                                                        <tr>
                                                            <td className={cssModule.Divide} aria-details="Das Wetter für Zürich">Zürich</td>
                                                            <td className={cssModule.Divide} aria-details="Das Wetter für Genf">Genf</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Das Wetter für Basel">Basel</td>
                                                            <td aria-details="Das Wetter für Bern">Bern</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className={cssModule.MenuSectionRightImages} style={{ backgroundImage: "url(" + MenuImageWetter + ")"}}>
                                                {/*<img className={cssModule.MenuImage} src={MenuImageWetter} alt="wetter"></img>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={hookMegaMenuOpen === 'Aktuell' ? cssModule.Visible : cssModule.Hide}
                                    onMouseEnter={() => { toggleMegaMenuVisibility('Aktuell') }}
                                    onMouseLeave={handleMenuMouseLeave}>
                                    <div className={cssModule.MenuParent}>
                                        <div className={cssModule.MenuSectionLeft}>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Wetter-News">
                                                <Link to="/wetter-news">
                                                    Wetter-News
                                                </Link>
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Wetter-TV">
                                                <Link to="/wetter-tv">
                                                    Wetter-TV
                                                </Link>
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Das Niederschlagsradar für Deutschland">
                                                <Link to="/niederschlagsradar">
                                                    Niederschlagsradar
                                                </Link>
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Der Wolkenfilm für Europa">
                                                <Link to="/wolkenfilm">
                                                    Wolkenfilm
                                                </Link>
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Der Strömungsfilm für Europa">
                                                <Link to="/stromungsfilm">
                                                    Strömungsfilm
                                                </Link>
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Die Großwetterlagenkarte für Europa">
                                                <Link to="/grosswetterlagenkarte">
                                                    Großwetterlagenkarte
                                                </Link>
                                            </div>
                                            {/*<div className={cssModule.MenuSectionLeftItem} aria-details="Die Großwetterlagenkarte für Europa">
                                                Großwetterlagenkarte
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Der Strömungsfilm für Europa">
                                                Strömungsfilm
                                            </div>*/}
                                        </div>
                                        <div className={cssModule.MenuSectionRight}>
                                            <div className={cssModule.MenuSectionRightLinks}>
                                                <h6>Aktuelle Nachrichten rund ums Wetter</h6>
                                                <hr className={cssModule.HrLine} />
                                                <table>
                                                    <tbody>
                                                        {newsOrganizer}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className={cssModule.MenuSectionRightImages} style={{ backgroundImage: "url(" + MenuImageAktuell + ")" }}>
                                                {/*<img className={cssModule.MenuImage} src={MenuImageAktuell} alt="aktuell"></img>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={hookMegaMenuOpen === 'Gesundheit' ? cssModule.Visible : cssModule.Hide}
                                    onMouseEnter={() => { toggleMegaMenuVisibility('Gesundheit') }}
                                    onMouseLeave={handleMenuMouseLeave}>
                                    <div className={cssModule.MenuParent}>
                                        <div className={cssModule.MenuSectionLeft}>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Pollenkalender">
                                                <Link to="/pollenkalender">
                                                    Pollenkalender
                                                </Link>
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Der Pollenflug in Deutschland">
                                                <Link to="/pollen">
                                                    Pollenflug
                                                </Link>
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Das Biowetter für Deutschland">
                                                <Link to="/biowetter">
                                                    Biowetter
                                                </Link>
                                            </div>
                                        </div>
                                        <div className={cssModule.MenuSectionRight}>
                                            {/*<div className={cssModule.MenuSectionRightLinks}>
                                                <h6>Pollenflugbelastung nach Arten</h6>
                                                <hr className={cssModule.HrLine} />
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td aria-details="Der Pollenflug in Deutschland - Ambrosia">Ambrosia</td>
                                                            <td aria-details="Der Pollenflug in Deutschland - Hasel">Hasel</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Der Pollenflug in Deutschland - Beifuß">Beifuß</td>
                                                            <td aria-details="Der Pollenflug in Deutschland - Kiefer">Kiefer</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Der Pollenflug in Deutschland - Birke">Birke</td>
                                                            <td aria-details="Der Pollenflug in Deutschland - Linde">Linde</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Der Pollenflug in Deutschland - Brennnessel">Brennnessel</td>
                                                            <td aria-details="Der Pollenflug in Deutschland - Pappel">Pappel</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Der Pollenflug in Deutschland - Buche">Buche</td>
                                                            <td aria-details="Der Pollenflug in Deutschland - Roggen">Roggen</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Der Pollenflug in Deutschland - Eiche">Eiche</td>
                                                            <td aria-details="Der Pollenflug in Deutschland - Sauerampfer">Sauerampfer</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Der Pollenflug in Deutschland - Erle">Erle</td>
                                                            <td aria-details="Der Pollenflug in Deutschland - Ulme">Ulme</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Der Pollenflug in Deutschland - Esche">Esche</td>
                                                            <td aria-details="Der Pollenflug in Deutschland - Weide">Weide</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Der Pollenflug in Deutschland - Gänsefuß">Gänsefuß</td>
                                                            <td aria-details="Der Pollenflug in Deutschland - Spitzwegerich">Spitzwegerich</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className={cssModule.MenuSectionRightImages} style={{ backgroundImage: "url(" + MenuImageGesundheit + ")" }}>
                                        </div>*/}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={hookMegaMenuOpen === 'Freizeit' ? cssModule.Visible : cssModule.Hide}
                                    onMouseEnter={() => { toggleMegaMenuVisibility('Freizeit') }}
                                    onMouseLeave={handleMenuMouseLeave}>
                                    <div className={cssModule.MenuParent}>
                                        <div className={cssModule.MenuSectionLeft}>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Das Gartenwetter für Deutschland">
                                                <Link to="/gartenwetter">
                                                    Gartenwetter
                                                </Link>
                                            </div>
                                            {/*<div className={cssModule.MenuSectionLeftItem} aria-details="Das Grillwetter für Deutschland">
                                                Grillwetter
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Das Urlaubswetter und Reisewetter">
                                                Urlaubswetter
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Wintersport in Deutschland, Europa und der Welt">
                                                Wintersport
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Das Cabriowetter für Deutschland">
                                                Cabriowetter
                                            </div>*/}
                                        </div>
                                        <div className={cssModule.MenuSectionRight}>
                                            {/*<div className={cssModule.MenuSectionRightLinks}>
                                                <h6>Die beliebtesten Reiseziele</h6>
                                                <hr className={cssModule.HrLine} />
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td aria-details="Die beliebtesten Reiseziele - Paris">Paris</td>
                                                            <td aria-details="Die beliebtesten Reiseziele - Las Palmas">Las Palmas</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Die beliebtesten Reiseziele - Rom">Rom</td>
                                                            <td aria-details="Die beliebtesten Reiseziele - Dubai">Dubai</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Die beliebtesten Reiseziele - Palma de Mallorca">Palma de Mallorca</td>
                                                            <td aria-details="Die beliebtesten Reiseziele - Luxor">Luxor</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Die beliebtesten Reiseziele - Athen">Athen</td>
                                                            <td aria-details="Die beliebtesten Reiseziele - Phuket">Phuket</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Die beliebtesten Reiseziele - Madrid">Madrid</td>
                                                            <td aria-details="Die beliebtesten Reiseziele - Bordeaux">Bordeaux</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Die beliebtesten Reiseziele - Salzburg">Salzburg</td>
                                                            <td aria-details="Die beliebtesten Reiseziele - Nizza">Nizza</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Die beliebtesten Reiseziele - Hurghada">Hurghada</td>
                                                            <td aria-details="Die beliebtesten Reiseziele - Valletta">Valletta</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Die beliebtesten Reiseziele - New York">New York</td>
                                                            <td aria-details="Die beliebtesten Reiseziele - Venedig">Venedig</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Die beliebtesten Reiseziele - Wien">Wien</td>
                                                            <td aria-details="Die beliebtesten Reiseziele - Dubrovnik">Dubrovnik</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className={cssModule.MenuSectionRightImages} style={{ backgroundImage: "url(" + MenuImageWetter + ")" }}>
                                            </div>*/}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={hookMegaMenuOpen === 'Allgemein' ? cssModule.Visible : cssModule.Hide}
                                    onMouseEnter={() => { toggleMegaMenuVisibility('Allgemein') }}
                                    onMouseLeave={handleMenuMouseLeave}>
                                    <div className={cssModule.MenuParent}>
                                        <div className={cssModule.MenuSectionLeft}>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Wetter-Lexikon">
                                                <Link to="/wetterlexikon?search=a">
                                                    Wetter-Lexikon
                                                </Link>
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Wetter.net mobil">
                                                <Link to="/mobile">
                                                    Wetter.net Mobil
                                                </Link>
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Modellwetter">
                                                <ExterLink href="http://www.wetterdata.de/" target="_blank" rel="noreferrer">
                                                    Profikarten
                                                </ExterLink>
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Modellwetter">
                                                Schadenservice
                                            </div>
                                            <div className={cssModule.MenuSectionLeftItem} aria-details="Wetterlegende">
                                                <Link to="/allgemein/wetterlegende">
                                                    Wetterlegende
                                                </Link>
                                            </div>
                                        </div>
                                        <div className={cssModule.MenuSectionRight}>
                                            <div className={cssModule.MenuSectionRightLinks}>
                                                <h6>Spannende Wetterphänomene</h6>
                                                <hr className={cssModule.HrLine} />
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td aria-details="Gewitter" onClick={() => {loadLexikonDetailsPage("gewitter")}}>Gewitter</td>
                                                            <td aria-details="Hochdruckkeil" onClick={() => {loadLexikonDetailsPage("hochdruckkeil")}}>Hochdruckkeil</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Tornado" onClick={() => { loadLexikonDetailsPage("tornado") }}>Tornado</td>
                                                            <td aria-details="Orkan" onClick={() => { loadLexikonDetailsPage("orkan")}}>Orkan</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Genua-Zyklone" onClick={() => { loadLexikonDetailsPage("genua-zyklone")}}>Genua-Zyklone</td>
                                                            <td aria-details="Schirokko" onClick={() => { loadLexikonDetailsPage("schirokko")}}>Schirokko</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Trombe" onClick={() => { loadLexikonDetailsPage("trombe")}}>Trombe</td>
                                                            <td aria-details="Okklusion" onClick={() => { loadLexikonDetailsPage("okklusion")}}>Okklusion</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Medicane" onClick={() => { loadLexikonDetailsPage("medicane")}}>Medicane</td>
                                                            <td aria-details="Kaltfront" onClick={() => { loadLexikonDetailsPage("kaltfront")}}>Kaltfront</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Mittagshitze" onClick={() => { loadLexikonDetailsPage("mittagshitze")}}>Mittagshitze</td>
                                                            <td aria-details="Kondensstreifen" onClick={() => { loadLexikonDetailsPage("kondensstreifen")}}>Kondensstreifen</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Hangwind" onClick={() => { loadLexikonDetailsPage("hangwind")}}>Hangwind</td>
                                                            <td aria-details="Hebungswolken" onClick={() => { loadLexikonDetailsPage("hebungswolken")}}>Hebungswolken</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Hurrikan" onClick={() => { loadLexikonDetailsPage("hurrikan")}}>Hurrikan</td>
                                                            <td aria-details="Neufundlandtief" onClick={() => { loadLexikonDetailsPage("neufundlandtief")}}>Neufundlandtief</td>
                                                        </tr>
                                                        <tr>
                                                            <td aria-details="Hitzetief" onClick={() => { loadLexikonDetailsPage("hitzetief")}}>Hitzetief</td>
                                                            <td aria-details="Mammatuswolke" onClick={() => { loadLexikonDetailsPage("mammatuswolke")}}>Mammatuswolke</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/*<div className={cssModule.MenuSectionRightImages} style={{ backgroundImage: "url(" + MenuImageAllgemein + ")" }}>
                                            </div>*/}
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </div>
                </div>
            </header>

        </Fragment>
    );
});

export default Header;
import * as actionTypes from '../actions/actionTypes';
import { loadState } from '../../localStorage';

let localCache = loadState();
let initialState = null;

if (localCache && localCache.kPollenflug) {
    initialState = localCache.kPollenflug;
}
else {
    initialState = {
        kPollenflug: null,
        loading: false,
        error: null
    }
}

const reducer = (state = initialState, action) => {
    try {
        if (action.type === actionTypes.K_GET_POLLENFLUG_SUCCESS) {
            return {
                ...state,
                loading: false,
                error: null,
                kPollenflug: action.payload
            }
        } else if (action.type === actionTypes.K_GET_POLLENFLUG_STARTED) {
            return {
                ...state,
                loading: true
            }
        }
        else if (action.type === actionTypes.K_GET_POLLENFLUG_FAILURE) {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        }
        else if (action.type === actionTypes.K_GET_POLLENFLUG_CACHED){
            return {
                ...state,
            }
        }
        else {
            return state;
        }
    } catch (error) { console.log('202102231150', error) }
};

export default reducer;
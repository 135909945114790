import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actionCreators from '../../../../store/actions/index';
import WinterLexikonComponent from '../../../Components/InnerPages/WetterLexikon/WetterLexikon';

class WinterLexikon extends Component {

    componentDidMount = () => {
        try {
            if (this.props.kToken && this.props.kToken.kToken && this.props.kToken.kToken.length > 0) {
                this.props.handleGetKWetterLexikon("a",this.props.kToken.kToken);
            }
        } catch (error) { console.log('2021082501141', error) }
    }

    getWetterLexikon = (argLetter) => {
        try{
            this.props.handleGetKWetterLexikon(argLetter, this.props.kToken.kToken);
        } catch (error) {
            console.log('202109061717', error)
        }
    }

    render() {
        return (
            <WinterLexikonComponent deliverError={this.props.deliverError} componentData={this.props.kWetterLexikon} getWetterLexikon={this.getWetterLexikon}/>
        );
    }
};

const mapStateToProps = state => {
    return {
        kWetterLexikon: state.kWetterLexikonSubReducer,
        kToken: state.kTokenSubReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetKWetterLexikon: (letter, token) => dispatch(actionCreators.getKWetterLexikon(letter, token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WinterLexikon);
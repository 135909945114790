import React, { memo } from 'react';
import {
    Typography,
    Breadcrumbs,
    Grid,
} from '@material-ui/core';
import { Link as SiteLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import cssModule from './WetterLexikonDetails.module.css';

const useStyles = makeStyles((theme) => ({

    head: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '3rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '15px'
    },
    weatherVideo: {
        borderRadius: '5px',
        marginBottom: '1rem'
    },
    lagentData: {
        display: 'flex',
    },
    lagentDataText: {
        paddingRight: '35px',
    },
    bioImage: {
        maxWidth: '100%',
        display: 'block',
        height: 'auto',
        borderRadius: '5px'
    },
    bioImageParent: {
        margin: '0px',
        width: '300px',
        marginTop: '7px'
    },
    bioImageCaption: {
        fontSize: '.8rem',
        marginTop: '5px',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '400'
    },
    AlphabetParent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'stretch',

    },
    AlphabetItem: {
        textAlign: 'center',
        padding: '2px',
        borderRight: '1px solid rgba(0, 108, 189, 0.5)',
        color: 'rgb(0, 108, 189)',
        cursor: 'pointer',
        flexGrow: '1',
        '&:last-of-type': {
            borderRight: '0px solid rgb(0, 108, 189)'
        }
    },
    AlphabetItemSelected: {
        textAlign: 'center',
        padding: '2px',
        borderRight: '1px solid rgba(0, 108, 189, 0.5)',
        backgroundColor: 'rgb(0, 108, 189)',
        color: '#fff',
        flexGrow: '1',
        '&:last-of-type': {
            borderRight: '0px solid rgb(0, 108, 189)'
        }
    },
}));

const WetterLexikonDetails = memo((props) => {

    const classes = useStyles();
    const history = useHistory();

    let componentOrganizer = null;

    const [hookLetter, setLetter] = React.useState("");
    const [hookLexikon, setLexikon] = React.useState({});

    React.useEffect(() => {
        try {

            if (props.userToken && props.userToken.kToken && props.userToken.kToken.length > 0) {
                const axiosInstance = axios.create({
                    baseURL: 'http://165.227.162.31:8000/v2/',
                    headers: { 'Authorization': `Bearer ${props.userToken.kToken}` }
                });

                let searchLexikon = "";

                if (history.location && history.location.search && history.location.search.toString() && history.location.search.toString().length > 0) {
                    searchLexikon = history.location.search.toString().replace('?lexikon=', '');
                    axiosInstance.get(`home/wetterlexikon?name=${unslugify(searchLexikon)}`)
                        .then(response => {
                            if (response && response.data && response.data.data && response.data.data.length > 0) {
                                setLexikon(response.data.data[0]);
                            }
                            else {
                                // eslint-disable-next-line react-hooks/exhaustive-deps
                                componentOrganizer = (<div>Keine Daten gefunden!</div>);
                            }
                        })
                        .catch(error => {
                            componentOrganizer = (<div>Keine Daten gefunden!</div>);
                            console.log('202109071243', error);
                        })
                }
            } else {
                componentOrganizer = (<div>Keine Daten gefunden!</div>);
            }

        } catch (error) { console.log('202109071044', error) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.search]);

    const unslugify = (argLexikon) => {
        try {
            let str = argLexikon;
            str = str.replace(/^\s+|\s+$/g, ''); // trim
            str = str.toLowerCase();
            str = str.replace(/~ue+/g, 'ü').replace(/~ae+/g, 'ä').replace(/~oe+/g, 'ö').replace(/~ss+/g, 'ß').replace(/_+/g, ' ');
            return str;
        } catch (error) {
            console.log('202109071138', error);
        }
    }

    function handleClick(event) {
        try {
            event.preventDefault();
        } catch (error) { console.log('202103101712', error) }
    }

    const handleLetterChange = (argLetter) => {
        try {
            setLetter(argLetter);
            history.push({ pathname: `/wetterlexikon`, search: `?search=${argLetter}` });
        } catch (error) {
            console.log('202109061711', error);
        }
    }

    try {

        componentOrganizer = (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className={classes.AlphabetParent}>
                            <span onClick={() => { handleLetterChange("a") }} className={hookLetter === "a" ? classes.AlphabetItemSelected : classes.AlphabetItem}>A</span>
                            <span onClick={() => { handleLetterChange("b") }} className={hookLetter === "b" ? classes.AlphabetItemSelected : classes.AlphabetItem}>B</span>
                            <span onClick={() => { handleLetterChange("c") }} className={hookLetter === "c" ? classes.AlphabetItemSelected : classes.AlphabetItem}>C</span>
                            <span onClick={() => { handleLetterChange("d") }} className={hookLetter === "d" ? classes.AlphabetItemSelected : classes.AlphabetItem}>D</span>
                            <span onClick={() => { handleLetterChange("e") }} className={hookLetter === "e" ? classes.AlphabetItemSelected : classes.AlphabetItem}>E</span>
                            <span onClick={() => { handleLetterChange("f") }} className={hookLetter === "f" ? classes.AlphabetItemSelected : classes.AlphabetItem}>F</span>
                            <span onClick={() => { handleLetterChange("g") }} className={hookLetter === "g" ? classes.AlphabetItemSelected : classes.AlphabetItem}>G</span>
                            <span onClick={() => { handleLetterChange("h") }} className={hookLetter === "h" ? classes.AlphabetItemSelected : classes.AlphabetItem}>H</span>
                            <span onClick={() => { handleLetterChange("i") }} className={hookLetter === "i" ? classes.AlphabetItemSelected : classes.AlphabetItem}>I</span>
                            <span onClick={() => { handleLetterChange("j") }} className={hookLetter === "j" ? classes.AlphabetItemSelected : classes.AlphabetItem}>J</span>
                            <span onClick={() => { handleLetterChange("k") }} className={hookLetter === "k" ? classes.AlphabetItemSelected : classes.AlphabetItem}>K</span>
                            <span onClick={() => { handleLetterChange("l") }} className={hookLetter === "l" ? classes.AlphabetItemSelected : classes.AlphabetItem}>L</span>
                            <span onClick={() => { handleLetterChange("m") }} className={hookLetter === "m" ? classes.AlphabetItemSelected : classes.AlphabetItem}>M</span>
                            <span onClick={() => { handleLetterChange("n") }} className={hookLetter === "n" ? classes.AlphabetItemSelected : classes.AlphabetItem}>N</span>
                            <span onClick={() => { handleLetterChange("o") }} className={hookLetter === "o" ? classes.AlphabetItemSelected : classes.AlphabetItem}>O</span>
                            <span onClick={() => { handleLetterChange("p") }} className={hookLetter === "p" ? classes.AlphabetItemSelected : classes.AlphabetItem}>P</span>
                            <span onClick={() => { handleLetterChange("q") }} className={hookLetter === "q" ? classes.AlphabetItemSelected : classes.AlphabetItem}>Q</span>
                            <span onClick={() => { handleLetterChange("r") }} className={hookLetter === "r" ? classes.AlphabetItemSelected : classes.AlphabetItem}>R</span>
                            <span onClick={() => { handleLetterChange("s") }} className={hookLetter === "s" ? classes.AlphabetItemSelected : classes.AlphabetItem}>S</span>
                            <span onClick={() => { handleLetterChange("t") }} className={hookLetter === "t" ? classes.AlphabetItemSelected : classes.AlphabetItem}>T</span>
                            <span onClick={() => { handleLetterChange("u") }} className={hookLetter === "u" ? classes.AlphabetItemSelected : classes.AlphabetItem}>U</span>
                            <span onClick={() => { handleLetterChange("v") }} className={hookLetter === "v" ? classes.AlphabetItemSelected : classes.AlphabetItem}>V</span>
                            <span onClick={() => { handleLetterChange("w") }} className={hookLetter === "w" ? classes.AlphabetItemSelected : classes.AlphabetItem}>W</span>
                            {/*<span onClick={() => {handleLetterChange("x")}} className={hookLetter === "x" ? classes.AlphabetItemSelected : classes.AlphabetItem}>X</span>
                            <span onClick={() => { handleLetterChange("y") }} className={hookLetter === "y" ? classes.AlphabetItemSelected : classes.AlphabetItem}>Y</span>*/}
                            <span onClick={() => { handleLetterChange("z") }} className={hookLetter === "z" ? classes.AlphabetItemSelected : classes.AlphabetItem}>Z</span>
                            <span onClick={() => { handleLetterChange("ae") }} className={hookLetter === "ae" ? classes.AlphabetItemSelected : classes.AlphabetItem}>Ä</span>
                            <span onClick={() => { handleLetterChange("ue") }} className={hookLetter === "ue" ? classes.AlphabetItemSelected : classes.AlphabetItem}>Ü</span>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.head} variant="h1" component="h1" gutterBottom>{hookLexikon.Begriff ? hookLexikon.Begriff.toString() : '-'}</Typography>
                        <div className={classes.lagentData}>
                            <div className={classes.lagentDataText}>
                                <Typography className={classes.data} variant="body1" component="p">
                                    {hookLexikon.Beschreibung ? hookLexikon.Beschreibung.toString() : '-'}
                                </Typography>
                            </div>
                            <div className={classes.lagentDataImage}>
                                <figure className={classes.bioImageParent}>
                                    <img src={`http://167.172.173.171/images/wetterlexikon/${hookLexikon.Bildname ? hookLexikon.Bildname.toString() : ""}`} alt={hookLexikon.Begriff ? hookLexikon.Begriff.toString() : '-'} className={classes.bioImage} />
                                    <figcaption className={classes.bioImageCaption}>{hookLexikon.Quelle ? hookLexikon.Quelle.toString() : '-'}</figcaption>
                                </figure>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </React.Fragment>
        );

    } catch (error) {
        console.log('202107281519', error);
    }

    return (
        <React.Fragment>
            <div className={cssModule.WetterLexikonDetailsParent}>
                <div className={cssModule.HeadSection}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <SiteLink className={cssModule.BreadcrubsLink} color="inherit" to="/" onClick={handleClick}>Home</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Allgemein</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Wetter-Lexikon</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>{hookLexikon.Begriff ? hookLexikon.Begriff.toString() : '-'}</SiteLink>
                    </Breadcrumbs>
                </div>
                <div className={cssModule.DataSection}>
                    {componentOrganizer}
                </div>
            </div>
        </React.Fragment>
    );
});

export default WetterLexikonDetails;
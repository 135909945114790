import React, { memo, useState } from 'react';
import {
    Typography,
    Breadcrumbs,
    Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as SiteLink } from 'react-router-dom';
import BiowetterDetailsComponent from './BiowetterDetailsPage/BiowetterDetailsPage';

import cssModule from './BiowetterInnerPage.module.css';

const useStyles = makeStyles((theme) => ({

    head: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '3rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    head1: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '2.5rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    head2: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '1.9rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    head3: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '1.5rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    subHeadDark: {
        textTransform: "none",
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '500',
        fontSize: '1.2rem'
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '15px'
    },
    dataLast: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '0px'
    },
    dataBlue: {
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    dataLink: {
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '400',
    }
}));

const sortArrayByDate = (a, b) => {
    try {
        if (new Date(a.date) === new Date(b.date)) {
            return 0;
        }
        else {
            return (new Date(a.date) < new Date(b.date)) ? -1 : 1;
        }
    } catch (error) { console.log('202103101516', error) }
}

let tabOrganizer = null;
let componentOrganizer = null;
let sortArray, biowetterArray = [];

const BiowetterInnerPage = memo((props) => {

    //console.log('-------------------', props);

    const classes = useStyles();

    const [hookBiowetterData, setBiowetterData] = useState(null);
    const [hookTabIndex1, setTabIndex1] = useState(1);
    const [hookTabIndex2, setTabIndex2] = useState(0);
    const [hookTabIndex3, setTabIndex3] = useState(0);

    const setParam = (argIndex, argData) => {
        try {
            if (argIndex === 0) {
                setBiowetterData(argData[0]);
                setTabIndex1(1);
                setTabIndex2(0);
                setTabIndex3(0);
            } else if (argIndex === 1) {
                setBiowetterData(argData[1]);
                setTabIndex1(0);
                setTabIndex2(1);
                setTabIndex3(0);
            }
            else if (argIndex === 2) {
                setBiowetterData(argData[2]);
                setTabIndex1(0);
                setTabIndex2(0);
                setTabIndex3(1);
            }
        } catch (error) { console.log('202103101517', error) }
    }

    function handleClick(event) {
        try {
            event.preventDefault();
        } catch (error) { console.log('202103101712', error) }
    }

    let componentOrganizer = null;

    try {
        if (props.componentData && props.componentData.kBiowetter && props.componentData.kBiowetter.length > 0) {

            biowetterArray = props.componentData.kBiowetter.filter(item => item.biowetter_data !== undefined);

            sortArray = biowetterArray;
            sortArray = sortArray.sort(sortArrayByDate);

            if (sortArray.length === 0) {
                tabOrganizer = null;
            } else if (sortArray.length === 1) {
                componentOrganizer = (<BiowetterDetailsComponent componentData={sortArray[0]} />);
                tabOrganizer = (
                    <React.Fragment>
                        <div className={hookTabIndex1 === 1 ? cssModule.ActiveTab : null} onClick={() => { setParam(0, sortArray) }}>Biowetter heute</div>
                        {props.componentData.loading ? <span className={cssModule.Loader}></span> : null}
                    </React.Fragment>
                );
            } else if (sortArray.length === 2) {
                componentOrganizer = (<BiowetterDetailsComponent componentData={sortArray[0]} />);
                tabOrganizer = (
                    <React.Fragment>
                        <div className={hookTabIndex1 === 1 ? cssModule.ActiveTab : null} onClick={() => { setParam(0, sortArray) }}>Biowetter heute</div>
                        <div>|</div>
                        <div className={hookTabIndex2 === 1 ? cssModule.ActiveTab : null} onClick={() => { setParam(1, sortArray) }}>morgen</div>
                        {props.componentData.loading ? <span className={cssModule.Loader}></span> : null}
                    </React.Fragment>
                );
            }
            else if (sortArray.length === 3 || sortArray.length > 3) {
                componentOrganizer = (<BiowetterDetailsComponent componentData={sortArray[0]} />);
                tabOrganizer = (
                    <React.Fragment>
                        <div className={hookTabIndex1 === 1 ? cssModule.ActiveTab : null} onClick={() => { setParam(0, sortArray) }}>Biowetter heute</div>
                        <div>|</div>
                        <div className={hookTabIndex2 === 1 ? cssModule.ActiveTab : null} onClick={() => { setParam(1, sortArray) }}>morgen</div>
                        <div>|</div>
                        <div className={hookTabIndex3 === 1 ? cssModule.ActiveTab : null} onClick={() => { setParam(2, sortArray) }}>übermorgen</div>
                        {props.componentData.loading ? <span className={cssModule.Loader}></span> : null}
                    </React.Fragment>
                );
            }

        }
    } catch (error) { console.log('202103101519', error) }

    console.log('////////////', props.locationData.kLocation.name);

    return (
        <React.Fragment>
            <div className={cssModule.WetterSymbolLegendeParent}>
                <div className={cssModule.HeadSection}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <SiteLink className={cssModule.BreadcrubsLink} color="inherit" to="/" onClick={handleClick}>Home</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Gesundheit</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Biowetter</SiteLink>
                    </Breadcrumbs>
                </div>
                <div className={cssModule.DataSection}>
                    <React.Fragment>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography className={classes.head} variant="h1" component="h1" gutterBottom>Das Biowetter für { props.locationData ? props.locationData.kLocation? props.locationData.kLocation.name ? props.locationData.kLocation.name.toString() : "-" : "-" : "-" }</Typography>
                                <div className={cssModule.BiowetterItemsContainer}>
                                    <div className={cssModule.ButtonTags}>
                                        {tabOrganizer}
                                    </div>
                                    <Typography variant="subtitle1" component="div" className={cssModule.ContainerHeading}>Belastung durch</Typography>
                                    {hookBiowetterData === null ? componentOrganizer : <BiowetterDetailsComponent componentData={hookBiowetterData} />}
                                </div>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                </div>
            </div>
        </React.Fragment>
    );
});

export default BiowetterInnerPage;
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actionCreators from '../../../../store/actions/index';
import WetterNewsInnerPageComponent from '../../../Components/InnerPages/NewsInnerPage/NewsInnerPage';

class WetterNewsInnerPage extends Component {

    constructor(props) {
        super(props);
        this.state = { pageNumber: 1 };
    }

    componentDidMount = () => {
        try {
            if (this.props.kToken && this.props.kToken.kToken && this.props.kToken.kToken.length > 0) {
                this.props.handleGetKWetternews(this.props.kToken.kToken, 0);
            }
        } catch (error) { }
    }

    getNews = () => {
        try{
            if (this.props.kToken && this.props.kToken.kToken && this.props.kToken.kToken.length > 0) {
                this.props.handleGetKWetternews(this.props.kToken.kToken, this.state.pageNumber);
                this.setState({ pageNumber: this.state.pageNumber + 1 });
            }
        }catch(error){
            console.log('202108101729', error);
        }
    }

    render() {
        return (
            <WetterNewsInnerPageComponent componentData = {this.props.kWetternews} getWetterNews={this.getNews}/>
        );
    }
}

const mapStateToProps = state => {
    return {
        kWetternews: state.kWetternewsSubReducer,
        kToken: state.kTokenSubReducer,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetKWetternews: (token, page) => dispatch(actionCreators.getKWetternews(token, page)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WetterNewsInnerPage);
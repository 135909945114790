import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getKGrosswetterlagenkarte = (argToken, argLocID) => {

    try {
        return dispatch => {
            dispatch(getKGrosswetterlagenkarteStarted());
            const axiosInstance = axios.create({
                baseURL: 'http://165.227.162.31:8000/v2/',
                headers: { 'Authorization': `Bearer ${argToken}` }
            });

            axiosInstance.get(`home/synopsis`)
                .then(response => {
                    if (response && response.data && response.data.data) {
                        dispatch(getKGrosswetterlagenkarteSuccess(response.data.data));
                    }
                    else {
                        dispatch(getKGrosswetterlagenkarteFailure('Something went wrong'));
                    }
                })
                .catch(error => {
                    dispatch(getKGrosswetterlagenkarteFailure(error.message));
                })
        };
        // eslint-disable-next-line no-unreachable
    } catch (error) { console.log('202102231105', error) }
};

const getKGrosswetterlagenkarteSuccess = data => ({
    type: actionTypes.K_GET_GROSSWETTERLAGEN_SUCCESS,
    payload: data
});

const getKGrosswetterlagenkarteStarted = () => ({
    type: actionTypes.K_GET_GROSSWETTERLAGEN_STARTED,
});

const getKGrosswetterlagenkarteFailure = error => ({
    type: actionTypes.K_GET_GROSSWETTERLAGEN_FAILURE,
    payload: {
        error
    }
});

export const getCacheKGrosswetterlagenkarteData = () => ({
    type: actionTypes.K_GET_GROSSWETTERLAGEN_CACHED,
});
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import TRANSLATE_EN from './en/en.json';
import TRANSLATE_DE from './de/de.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'de',
        debug: true,
        supportedLngs: ['de', 'en'],
        resources: {
            en: {
                translation: TRANSLATE_EN
            },
            de: {
                translation: TRANSLATE_DE
            }
        }
    });

/*i18n.changeLanguage("en");*/

export default i18n;
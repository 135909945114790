import React, {Fragment} from 'react';
import {Grid, Typography, Button} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { GAEvent } from '../../../Assets/GoogleAnalytics/googleAnalytics';

import i18n from '../../../Assets/Languages/i18n';
import cssModule from './PageNotFound.module.css';
import { ReactComponent as Wetter6 } from '../../../Assets/Mobile/Common/drought.svg';

const useStyles = makeStyles({
    button: {
        textTransform: "none",
        transition: '.2s ease-in',
        fontSize: '1.1rem',
        fontWeight: '300',
        padding: '1px',
        marginTop: '2rem',
    },
    textsHead: {
        fontWeight: '300',
        marginTop: '1rem',
    },
    textsDetails: {
        fontWeight: '300',
        marginTop: '1rem',
        fontSize: '1.3rem',
    }
});

const PageNotFound = (props) => {

    const { t, i18n } = useTranslation();

    const history = useHistory();
    const classes = useStyles();

    const handleButtonClick = () => {
        try{
            history.goBack();
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Page not found - handleButtonClick", ("202107151446 - Error: " + error.message), null, true);
        }
    }

        return(
            <Fragment>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <div className={cssModule.Parent}>
                            <div className={cssModule.ContainerBlock}>
                                <Wetter6 height={200} width={"100%"} />
                                <Typography className={classes.textsHead} variant="h3" component="h3">
                                    {
                                        /*Diese Seite existiert nicht!*/
                                        t('common.messages.page_not_exist')
                                    }
                                </Typography>
                                <Typography className={classes.textsDetails} variant="body1" component="p">
                                    {
                                        /*Die gesuchte Seite wurde leider nicht gefunden!*/
                                        t('common.messages.sorry_page_not_found')
                                    }
                                </Typography>
                                <Button className={classes.button} disableFocusRipple variant="contained" size="small" color="primary" onClick={handleButtonClick}>
                                    {
                                        /*zurück*/
                                        t('common.messages.back')
                                    }
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Fragment>
        )
    }

export default PageNotFound;
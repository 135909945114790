import React, {Component, Fragment, lazy, Profiler} from 'react';
import {Grid, Box} from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import { GATiming, GAEvent } from '../../../../Assets/GoogleAnalytics/googleAnalytics';

const WetterfotoSenden = lazy(()=> import('../../../Components/Menu/WetterfotoSenden/WetterfotoSenden'));

class wetterfotoSenden extends Component{

    setPerformance = (argProfilerId, argMode, argActualTime, argBaseTime, argStartTime, argCommitTime, argInteracions) =>{
        try{
            if(argMode && argMode.toString() === "mount"){
                GATiming(argProfilerId, argMode, argActualTime, ( argProfilerId + " " + argMode ));
            }
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Wetterfoto Senden - setPerformance", ("202107151422 - Error: " + error.message), null, true);
        }
    }

    render(){

        let componentOrganizer = null;

        try{
            componentOrganizer = (<WetterfotoSenden userToken={this.props.userToken} activeColorMode={this.props.colorMode} />);
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Wetterfoto Senden - render", ("202107151423 - Error: " + error.message), null, true);
        }

        return(
            <Fragment>
                <Profiler id="Wetterfoto senden" onRender={this.setPerformance}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Box p='10px'>
                                {componentOrganizer ? componentOrganizer : <div className="NoPage">{this.props.t('common.messages.unable_to_load_data')}</div>}
                            </Box>
                        </Grid>
                    </Grid>
                </Profiler>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        colorMode: state.settingsSubReducer.colorMode,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        /*handleGetPollenData: (token, locID) => dispatch(actionCreators.getPollen(token, locID)),
        onResetPollenError: () => dispatch(actionCreators.resetPollenError()),*/
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(wetterfotoSenden));
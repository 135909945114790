import React, {Component, Fragment, lazy, Profiler} from 'react';
import {Grid, Box} from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import * as actionCreators from '../../../store/actions/index';
import { GATiming, GAEvent } from '../../../Assets/GoogleAnalytics/googleAnalytics';

const MeineStadte = lazy(()=> import('../../Components/MeineStadte/MeineStadte'));

class meineStadte extends Component{

    state = {
        hasError: null,
        errorInfo: null,
        selectedLocation : {}
    }

    delay = (ms) => new Promise(res => setTimeout(res, ms));

    isTokenValid = async () => {

        try {

            let timeNow = new Date();
            let LSData = window.localStorage.getItem('wetternet');
            let LSDataObject = null;
            if (LSData && LSData !== null && LSData.length > 0) {
                LSDataObject = JSON.parse(LSData);
                if (LSDataObject && LSDataObject.token && LSDataObject.token.createTime) {
                    if (new Date(timeNow) > new Date(LSDataObject.token.createTime)) {
                        this.props.handleGetToken();
                        await this.delay(5000);
                        GAEvent("Token", "Token Expired", "Fetch new token", null, true);
                    }
                }
            }
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Token - isTokenValid", ("202107201642 - Error: " + error.message), null, true);
        }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        this.setState({ hasError: error, errorInfo: errorInfo })
    }

    componentDidUpdate = async (prevProp) => {
        try{
            await this.isTokenValid();
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Meine Stadte - componentDidUpdate", ("202107201640 - Error: " + error.message), null, true);
        }
    }

    componentDidMount = async (prevProp) => {
        try {
            await this.isTokenValid();
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Meine Stadte - componentDidMount", ("202107201640 - Error: " + error.message), null, true);
        }
    }

    selSelectedLocation = (argLocation, argType) => {
        try{
        this.setState({selectedLocation: argLocation});
        this.props.onStoreLocation(argLocation);
        this.props.onAddLocation(argLocation);
        /*if(argType==="list"){
        }*/
        this.props.history.push("/");
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Meine Stadte - selSelectedLocation", ("202107151403 - Error: " + error.message), null, true);
         }
    }

    removePrevLocation = (argLocation) => {
        try{
        this.props.onRemoveLocation(argLocation);
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Meine Stadte - removePrevLocation", ("202107151404 - Error: " + error.message), null, true);
         }
    }

    setPerformance = (argProfilerId, argMode, argActualTime, argBaseTime, argStartTime, argCommitTime, argInteracions) =>{
        try{
            if(argMode && argMode.toString() === "mount"){
                GATiming(argProfilerId, argMode, argActualTime, ( argProfilerId + " " + argMode ));
            }
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Meine Stadte - setPerformance", ("202107151405 - Error: " + error.message), null, true);
        }
    }

    render(){

        let componentOrganizer = null;
        try{
        componentOrganizer = (<MeineStadte
        setLocation = { this.selSelectedLocation }
        removeLocation = { this.removePrevLocation }
        locHistory = { this.props.perviousLocations }
        userToken = { this.props.userToken }
        activeColorMode = { this.props.colorMode }
        />);
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Meine Stadte - render", ("202107151406 - Error: " + error.message), null, true);
        }

        return(
            <Fragment>
                <Profiler id="Meine stadte" onRender={this.setPerformance}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Box p='10px'>
                                {componentOrganizer ? componentOrganizer : <div className="NoPage">{this.props.t('common.messages.unable_to_load_data')}</div>}
                        </Box>
                    </Grid>
                </Grid>

                {this.state.errorInfo ?
                    (<div>
                        <h2>Something went wrong.</h2>
                        <details style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo.componentStack}
                        </details>
                    </div>)
                    : null}
                    </Profiler>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        //ctr: state.counterSubReducer.counter,
        //array: state.resultSubReducer.results,
        location: state.locationSubReducer.location,
        perviousLocations: state.locationSubReducer.perviousLocations,
        colorMode: state.settingsSubReducer.colorMode,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onIncrementCounter: (obj) => dispatch(actionCreators.increment(obj.count)),
        onAddCounter: (obj) => dispatch(actionCreators.add(obj.count)),
        onStoreLocation: (objectLocation) => { dispatch(actionCreators.storeCurrentLoc(objectLocation)) },
        onAddLocation: (objectLocation) => { dispatch(actionCreators.addLocation(objectLocation))},
        onRemoveLocation: (objectLocation) => { dispatch(actionCreators.removeLocation(objectLocation))},
        handleGetToken: () => { dispatch(actionCreators.getToken()) }
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(meineStadte));
import React, { Component } from 'react';
import { connect } from 'react-redux';

//import * as actionCreators from '../../../store/actions/index';
import WetterNetMobilComponent from '../../../Components/InnerPages/WetterNetMobil/WetterNetMobil';

class WetterNetMobil extends Component {

    render() {
        return (
            <WetterNetMobilComponent deliverError={this.props.deliverError} />
        );
    }
};

const mapStateToProps = state => {
    return {
        //kPollenflug: state.kPollenflugSubReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        //handleGetKPollenflug: (token, page) => dispatch(actionCreators.getKPollenflug(token, page)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WetterNetMobil);
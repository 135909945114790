import React, { Component, Fragment, lazy, Profiler } from 'react';
import { Grid, Box } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { GAEvent } from '../../../../Assets/GoogleAnalytics/googleAnalytics';

import * as actionCreators from '../../../../store/actions/index';
import { GATiming } from '../../../../Assets/GoogleAnalytics/googleAnalytics';

const Biowetter = lazy(() => import('../../../Components/Menu/Biowetter/Biowetter'));

class biowetter extends Component {

    state = {
        bioWetterDataError: false,
        bioWetterNoData: false,
        bioWetterData: undefined,
    }

    delay = (ms) => new Promise(res => setTimeout(res, ms));

    isTokenValid = async () => {

        try {

            let timeNow = new Date();
            let LSData = window.localStorage.getItem('wetternet');
            let LSDataObject = null;
            if (LSData && LSData !== null && LSData.length > 0) {
                LSDataObject = JSON.parse(LSData);
                if (LSDataObject && LSDataObject.token && LSDataObject.token.createTime) {
                    if (new Date(timeNow) > new Date(LSDataObject.token.createTime)) {
                        this.props.handleGetToken();
                        await this.delay(5000);
                        GAEvent("Token", "Token Expired", "Fetch new token", null, true);
                    }
                }
            }
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Token - isTokenValid", ("202107201704 - Error: " + error.message), null, true);
        }
    }

    componentDidMount = async () => {

        try {
            if (this.props.location && this.props.location !== null && this.props.location.ID_Stadt) {
                await this.isTokenValid();
                this.props.handleGetBiowetterData(this.props.userToken, this.props.location.ID_Stadt);
            } else {
                this.props.history.push('/meine-stadte');
            }

            const timer = setTimeout(() => {
                if (this.props.biowetter.error !== null) {
                    this.setState({ bioWetterDataError: true });
                }
                else {
                    if (this.props.biowetter.biowetter) {
                        if (this.props.biowetter.biowetter === null || this.props.biowetter.biowetter === undefined || this.props.biowetter.biowetter.length <= 0) {
                            this.setState({ bioWetterNoData: true });
                        }
                    }
                    else {
                        this.setState({ bioWetterDataError: true });
                    }
                }
            }, 3000);
            return () => clearTimeout(timer);
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Biowetter - componentDidMount", ("202107151410 - Error: " + error.message), null, true);
         }
    }

    componentDidUpdate = async (prevProps) => {
        try{
        if (prevProps.location && prevProps.location.ID_Stadt && this.props.location && this.props.location.ID_Stadt) {
            if (Number(prevProps.location.ID_Stadt) !== Number(this.props.location.ID_Stadt)) {
                await this.isTokenValid();
                this.props.handleGetBiowetterData(this.props.userToken, this.props.location.ID_Stadt);
            }
        }
    }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Biowetter - componentDidUpdate", ("202107151411 - Error: " + error.message), null, true);
    }
    }

    handleAlertClose = () => {
        try {
            if (this.props.biowetter.biowetter) {
                if (this.props.biowetter.biowetter === null || this.props.biowetter.biowetter === undefined || this.props.biowetter.biowetter.length <= 0) {
                    this.props.history.push('/');
                }
            }
            this.props.onResetBiowetterError();
            this.setState({ bioWetterDataError: false, bioWetterNoData: false });
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Biowetter - handleAlertClose", ("202107151412 - Error: " + error.message), null, true);
         }
    }

    setPerformance = (argProfilerId, argMode, argActualTime, argBaseTime, argStartTime, argCommitTime, argInteracions) =>{
        try{
            if(argMode && argMode.toString() === "mount"){
                GATiming(argProfilerId, argMode, argActualTime, ( argProfilerId + " " + argMode ));
            }
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Biowetter - setPerformance", ("202107151414 - Error: " + error.message), null, true);
        }
    }

    render() {

        let componentOrganizer = null;

        try{

            if (this.props && this.props.biowetter && this.props.biowetter.biowetter){
                componentOrganizer = (<Biowetter componentData={this.props.biowetter.biowetter ? this.props.biowetter.biowetter : null} activeColorMode={this.props.colorMode} />);
            }

        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Biowetter - render", ("202107151415 - Error: " + error.message), null, true);
        }

        return (
            <Fragment>
                <Profiler id="Biowetter" onRender={this.setPerformance}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Box p='0px'>
                            {
                                componentOrganizer ? componentOrganizer : <div className="NoPage">{this.props.t('common.messages.unable_to_load_data')}</div>
                            }
                        </Box>
                    </Grid>
                </Grid>

                <SweetAlert
                    show={this.state.bioWetterDataError}
                    warning
                    confirmBtnText="Okay"
                    confirmBtnCssClass={this.props.colorMode ? this.props.colorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                    style={this.props.colorMode ? this.props.colorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                    title={this.props.t('common.messages.attention')}
                    onConfirm={this.handleAlertClose}>
                    {
                        this.props.t('common.messages.unable_to_load_data')
                    }
            </SweetAlert>
                <SweetAlert
                    show={this.state.bioWetterNoData}
                    warning
                    confirmBtnText={this.props.t('common.messages.back')}
                    confirmBtnCssClass={this.props.colorMode ? this.props.colorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                    style={this.props.colorMode ? this.props.colorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                    title={this.props.t('common.messages.attention')}
                    onConfirm={this.handleAlertClose}>
                        {
                            this.props.t('common.messages.no_data_now')
                        }
            </SweetAlert>
            </Profiler>
            </Fragment>);
    }
}

//export default biowetter;

const mapStateToProps = state => {
    return {
        location: state.locationSubReducer.location,
        biowetter: state.biowetterSubReducer,
        colorMode: state.settingsSubReducer.colorMode,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetBiowetterData: (token, locID) => dispatch(actionCreators.getBiowetter(token, locID)),
        onResetBiowetterError: () => dispatch(actionCreators.resetBiowetterError()),
        handleGetToken: () => { dispatch(actionCreators.getToken()) }
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(biowetter));
/* eslint-disable no-unreachable */
import * as actionTypes from './actionTypes';
import axios from 'axios';
import { GAEvent } from '../../Assets/GoogleAnalytics/googleAnalytics';

export const getVideo = (argToken, argPageVal) => {
try{
    return dispatch => {
        dispatch(getVideoStarted());
        const axiosInstance = axios.create({
            baseURL: 'https://apps.qmet.de/v2/',
            headers: { 'Authorization': `Bearer ${argToken}` }
        });

        axiosInstance.get(`news/youtubevideos/${parseInt(argPageVal)}?locale=en`)
            .then(response => {
                if (response && response.data && response.data.data && response.data.data.length > 0) {
                    dispatch(getVideoSuccess(response.data.data));
                }
                else {
                    dispatch(getVideoFailure('Something went wrong'));
                    GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Video - https://apps.qmet.de/v2/news/youtubevideos/", "202107141745 - page: " + argPageVal + " Error: API respond without data / wrong data structure", null, true);
                }
            })
            .catch(error => {
                dispatch(getVideoFailure(error.message));
                GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Video - https://apps.qmet.de/v2/news/youtubevideos/", "202107141746 - page: " + argPageVal + " Error: " + error.message, null, true);
            })
    };
} catch (error) {
    GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Video - https://apps.qmet.de/v2/news/youtubevideos/", "202107141747 - page: " + argPageVal + " Error: " + error.message, null, true);
 }
};

export const getMoreVideo = (argToken, argPageVal) => {
try{
    return dispatch => {
        dispatch(getVideoStarted());
        const axiosInstance = axios.create({
            baseURL: 'https://apps.qmet.de/v2/',
            headers: { 'Authorization': `Bearer ${argToken}` }
        });

        axiosInstance.get(`news/youtubevideos/${parseInt(argPageVal)}?locale=en`)
            .then(response => {
                if (response && response.data && response.data.data && response.data.data.length > 0) {
                    dispatch(getMoreVideoSuccess(response.data.data));
                }
                else {
                    dispatch(getVideoFailure('Something went wrong'));
                    GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Video - https://apps.qmet.de/v2/news/youtubevideos/", "202107141748 - page: " + argPageVal + " Error: API respond without data / wrong data structure", null, true);
                }
            })
            .catch(error => {
                dispatch(getVideoFailure(error.message));
                GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Video - https://apps.qmet.de/v2/news/youtubevideos/", "202107141749 - page: " + argPageVal + " Error: " + error.message, null, true);
            })
    };
} catch (error) {
    GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Video - https://apps.qmet.de/v2/news/youtubevideos/", "202107141750 - page: " + argPageVal + " Error: " + error.message, null, true);
 }
};

const getVideoSuccess = data => ({
    type: actionTypes.GET_VIDEO_SUCCESS,
    payload: data
});

const getMoreVideoSuccess = data => ({
    type: actionTypes.GET_VIDEO_ADD,
    payload: data
});

const getVideoStarted = () => ({
    type: actionTypes.GET_VIDEO_STARTED
});

const getVideoFailure = error => ({
    type: actionTypes.GET_VIDEO_FAILURE,
    payload: {
        error
    }
});


export const resetVideoError = () => ({
    type: actionTypes.RESET_VIDEO_ERROR,
});
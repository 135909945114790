/* eslint-disable no-unreachable */
import * as actionTypes from './actionTypes';
import axios from 'axios';
import { GAEvent } from '../../Assets/GoogleAnalytics/googleAnalytics';

export const storeCurrentLoc = (argVal) => {
    return {
        type: actionTypes.LOCATION_STORE,
        val: argVal
    }
}

export const addLocation = (argVal) => {
    return{
        type: actionTypes.LOCATION_ADD,
        val: argVal
    }
}

export const removeLocation = (argVal) => {
    return{
        type: actionTypes.LOCATION_REMOVE,
        val: argVal
    }
}

export const getCurrentLocation = (argToken ,argLat, argLon) => {
    /*return {
        type: actionTypes.LOCATION_CURRENT_GET,
        val: argVal
    }*/
    try{

    return dispatch => {
        dispatch(getCurrentLocationStarted());
        const axiosInstance = axios.create({
            baseURL: 'https://apps.qmet.de/v2/',
            headers: { 'Authorization': `Bearer ${argToken}` }
        });

        axiosInstance.get(`location/geo/${argLon}/${argLat}`)
            .then(response => {
                if (response && response.data && response.data.data) {
                    dispatch(getCurrentLocationSuccess(response.data.data));
                    dispatch(getCurrentLocationAdd(response.data.data));
                }
                else {
                    dispatch(getCurrentLocationFailure('Something went wrong'));
                    GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Location - https://apps.qmet.de/v2/location/geo/", "202107141727 - Lat: " + argLon + " Lon: " + argLat + " Error: API respond without data / wrong data structure", null, true);
                }
            })
            .catch(error => {
                dispatch(getCurrentLocationFailure(error.message));
                GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Location - https://apps.qmet.de/v2/location/geo/", "202107141728 - Lat: " + argLon + " Lon: " + argLat + " Error: " + error.message, null, true);
            })
    };
    } catch (error) {
        GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Location - https://apps.qmet.de/v2/location/geo/", "202107141729 - Lat: " + argLon + " Lon: " + argLat + " Error: " + error.message, null, true);
     }
}

export const getCurrentLocationFromLocal = () => ({
    type: actionTypes.LOCATION_FROM_LOCAL,
});

const getCurrentLocationSuccess = data => ({
    type: actionTypes.LOCATION_CURRENT_GET_SUCCESS,
    payload: data
});

const getCurrentLocationAdd = data => ({
    type: actionTypes.LOCATION_ADD,
    val: data
});

const getCurrentLocationStarted = () => ({
    type: actionTypes.LOCATION_CURRENT_GET_STARTED
});

const getCurrentLocationFailure = error => ({
    type: actionTypes.LOCATION_CURRENT_GET_FAILURE,
    payload: {
        error
    }
});
import React, { Component, Fragment, lazy, Profiler } from 'react';
import { Grid, Box } from '@material-ui/core';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withTranslation } from "react-i18next";

//import i18n from '../../../../Assets/Languages/i18n';
import * as actionCreators from '../../../store/actions/index';
import { GAEvent, GATiming } from '../../../Assets/GoogleAnalytics/googleAnalytics';
//import cssModule from './Home.module.css';

const Wetterwarnung = lazy(() => import('../../Components/Home/Wetterwarnung/Wetterwarnung'));
const ExternalLinks = lazy(() => import('../../Components/Home/ExterneLinks/ExterneLinks'));
const HeuteDiagramm = lazy(() => import('../../Components/Home/HeuteDiagramm/HeuteDiagramm'));
const JetztReadings = lazy(() => import('../../Components/Home/JetztReadings/JetztReadings'));
const MorgenDiagramm = lazy(() => import('../../Components/Home/MorgenDiagramm/MorgenDiagramm'));
const SonneMondDaten = lazy(() => import('../../Components/Home/SonneMondDaten/SonneMondDaten'));
const UbermorgenDiagramm = lazy(() => import('../../Components/Home/UbermorgenDiagramm/UbermorgenDiagramm'));
const VierUndZeanzigStundenSymbol = lazy(() => import('../../Components/Home/VierUndZwanzigStundenSymbol/VierUndZwanzigStundenSymbol'));
const VierzehnTeagesprognoseDiagramm = lazy(() => import('../../Components/Home/VierzehnTeagesprognoseDiagramm/VierzehnTeagesprognoseDiagramm'));
const VierzehnTeagesprognoseSymbol = lazy(() => import('../../Components/Home/VierzehnTeagesprognoseSymbol/VierzehnTeagesprognoseSymbol'));

class Home extends Component {

    state = {
        hasError: null,
        errorInfo: null,
        location: null,
        homeDataError: false,
        homeNoData: false,
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        this.setState({ hasError: error, errorInfo: errorInfo })
    }

    delay = (ms) => new Promise(res => setTimeout(res, ms));

    isTokenValid = async () => {
        try {
            let timeNow = new Date();
            let LSData = window.localStorage.getItem('wetternet');
            let LSDataObject = null;
            if(LSData && LSData !== null && LSData.length > 0){
                LSDataObject = JSON.parse(LSData);
                if (LSDataObject && LSDataObject.token && LSDataObject.token.createTime){
                    if (new Date(timeNow) > new Date(LSDataObject.token.createTime)){
                        this.props.handleGetToken();
                        await this.delay(5000);
                        GAEvent("Token", "Token Expired", "Fetch new token", null, true);
                    }
                }
            }
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Token - isTokenValid", ("202107201627 - Error: " + error.message), null, true);
        }
    }

    componentDidMount = async () => {
        try {
            document.addEventListener('contextmenu', event => event.preventDefault());
            let language = "";
            language = localStorage.getItem('i18nextLng');
            if(language.length > 0)
            {
                this.props.i18n.changeLanguage(language);
            }

            /*if (this.props.homeData.error !== null && this.props.homeData.loading !== true && this.props.homeData.error !== "timeout of 5000ms exceeded") {
                this.setState({ homeDataError: true });
            }
            else {
                if (this.props.homeData.homeData) {
                    if ((this.props.homeData.loading !== true) && (this.props.homeData.homeData === null || this.props.homeData.homeData === undefined || Object.keys(this.props.homeData.homeData).length === 0)) {
                        this.setState({ homeNoData: true });
                    }
                }
            }*/
            if (this.props.location && this.props.location.location && this.props.location.location !== null && this.props.location.location.ID_Stadt) {
                this.setState({ location: this.props.location });
                await this.isTokenValid();
                this.props.handleGetHomeData(this.props.userToken, this.props.location.location.ID_Stadt);
            } else {
                this.props.history.push('/meine-stadte');
            }
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Home page - componentDidMount", ("202107151248 - Error: " + error.message), null, true);
        }
    }

    componentDidUpdate = async (prevProps) => {

        try{

        if (this.state.location === null) {
            if (prevProps !== this.props && prevProps.location !== this.props.location && this.props.location.loading !== true && this.props.location.error === null) {
                if (this.props.location.location && this.props.location.perviousLocations && this.props.location.perviousLocations.length > 0) {
                    this.setState({ location: this.props.location });
                    await this.isTokenValid();
                    this.props.handleGetHomeData(this.props.userToken, this.props.location.location.ID_Stadt);
                }
            }
        }
        if (prevProps.location && prevProps.location.location && prevProps.location.location.ID_Stadt && this.props.location.location && this.props.location.location.ID_Stadt) {
            if (Number(prevProps.location.location.ID_Stadt) !== Number(this.props.location.location.ID_Stadt)) {
                await this.isTokenValid();
                this.props.handleGetHomeData(this.props.userToken, this.props.location.location.ID_Stadt);
            }
        }
        if (prevProps.homeData.error !== this.props.homeData.error && this.props.homeData.error !== null) {
            this.setState({ homeDataError: true });
        }

        if (prevProps.location.error !== this.props.location.error && this.props.location.error !== null) {
            this.setState({ homeDataError: true });
        }

        /*if (this.props && this.props.token && this.props.token.token && this.props.token.token !== null && this.props.token.loading !== true && this.props.token.createTime !== null) {
            if (isTokenValid(this.props.token)) {
            }
            else {
                this.props.handleGetToken();
            }
        }*/

        /*if (this.props.homeData) {
            if ((this.props.homeData.loading !== true) && (this.props.homeData.homeData === undefined || this.props.homeData.homeData === null || Object.keys(this.props.homeData.homeData).length === 0)) {
                console.log('test4');
                this.setState({ homeNoData: true });
            }
        }*/
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Home page - componentDidUpdate", ("202107151247 - Error: " + error.message), null, true);
        }
    }

    resetHomeDataError = () => {
        try {
            this.setState({ homeDataError: false, homeNoData: false });
            //window.location.reload();
            this.props.onResetHomeDataError();
            this.props.history.push('/meine-stadte');
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Home page - resetHomeDataError", ("202107151246 - Error: " + error.message), null, true);
        }
    }

    setPerformance = (argProfilerId, argMode, argActualTime, argBaseTime, argStartTime, argCommitTime, argInteracions) =>{
        try{
            if(argMode && argMode.toString() === "mount"){
                GATiming(argProfilerId, argMode, argActualTime, ( argProfilerId + " " + argMode ));
            }
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Home page - setPerformance", ("202107151245 - Error: " + error.message), null, true);
        }
    }

    render() {

        let componentOrganizer = null;

        try{


        let comWetterwarnung, comJetztReadings, comVierUndZwanzigStundenSymbol, comHeuteDiagramm, comSonneMondDaten, comMorgenDiagramm, comUbermorgenDiagram, comVierzehnTagesprognoseSymbol, comVierzehnTagesprognoseDiagramm, comExternalLinks = null;
        if (this.props.location && this.props.location !== null && this.props.homeData && this.props.homeData.homeData && this.props.homeData.homeData !== null) {
            comWetterwarnung =
                this.props.location.location.Landkreis_DWD ? (<Wetterwarnung
                    activeColorMode={this.props.colorMode}
                    Landkreis_DWD = {this.props.location.location.Landkreis_DWD}
                    userToken={this.props.userToken}
                    location={this.props.location.location}
                    />) : null;
            comJetztReadings = (<JetztReadings componentData={this.props.homeData.homeData} unitDruck={this.props.druck} unitTemp={this.props.temperature} unitWind={this.props.windgeschwindigkeit} activeColorMode={this.props.colorMode} locationDetails = {this.props.location.location}/>);
            comVierUndZwanzigStundenSymbol = (<VierUndZeanzigStundenSymbol activeColorMode={this.props.colorMode} unitTemp={this.props.temperature} nowData={this.props.homeData.homeData.sunRiseDetails} locationID={this.props.location.ID_Stadt} componentData={this.props.homeData.homeData ? this.props.homeData.homeData.hourlyTemp ? this.props.homeData.homeData.hourlyTemp : null : null} />);
            comHeuteDiagramm = (<HeuteDiagramm isScrolling={this.props.pageScrolling} activeColorMode={this.props.colorMode} locationID={this.props.location.ID_Stadt} unitTemp={this.props.temperature} componentData={this.props.homeData.homeData ? this.props.homeData.homeData.today ? this.props.homeData.homeData.today : null : null} />);
            comSonneMondDaten = (<SonneMondDaten activeColorMode={this.props.colorMode} componentData={this.props.homeData.homeData ? this.props.homeData.homeData.sunRiseDetails ? this.props.homeData.homeData.sunRiseDetails : null : null} />);
            comMorgenDiagramm = (<MorgenDiagramm isScrolling={this.props.pageScrolling} activeColorMode={this.props.colorMode} locationID={this.props.location.ID_Stadt} unitTemp={this.props.temperature} componentData={this.props.homeData.homeData ? this.props.homeData.homeData.tomorrow ? this.props.homeData.homeData.tomorrow : null : null} />);
            comUbermorgenDiagram = (<UbermorgenDiagramm isScrolling={this.props.pageScrolling} activeColorMode={this.props.colorMode} locationID={this.props.location.ID_Stadt} unitTemp={this.props.temperature} componentData={this.props.homeData.homeData ? this.props.homeData.homeData.day_after_tomorrow ? this.props.homeData.homeData.day_after_tomorrow : null : null} />);
            comVierzehnTagesprognoseSymbol = (<VierzehnTeagesprognoseSymbol activeColorMode={this.props.colorMode} locationID={this.props.location.ID_Stadt} unitTemp={this.props.temperature} componentData={this.props.homeData.homeData ? this.props.homeData.homeData.graph_data ? this.props.homeData.homeData.graph_data : null : null} />);
            comVierzehnTagesprognoseDiagramm = (<VierzehnTeagesprognoseDiagramm activeColorMode={this.props.colorMode} unitTemp={this.props.temperature} componentData={this.props.homeData ? this.props.homeData.homeData.graph_data ? this.props.homeData.homeData.graph_data : null : null} />);
            comExternalLinks = (<ExternalLinks activeColorMode={this.props.colorMode} userToken={this.props.userToken} />);
        }

        componentOrganizer = (
            !this.props.homeData.error ?
                (<Grid container spacing={0}>
                    <Grid item xs={12} className={"warningContainer"}>
                        <Box p='10px' pb='5px'>
                            {comWetterwarnung}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p='10px' pb='5px'>
                            {comJetztReadings}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p='10px' pt='5px' pb='5px'>
                            {comVierUndZwanzigStundenSymbol}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p='10px' pt='5px' pb='5px'>
                            {comHeuteDiagramm}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p='10px' pt='5px' pb='5px'>
                            {comSonneMondDaten}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p='10px' pt='5px' pb='5px'>
                            {comMorgenDiagramm}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p='10px' pt='5px' pb='5px'>
                            {comUbermorgenDiagram}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p='10px' pt='5px' pb='5px'>
                            {comVierzehnTagesprognoseSymbol}
                        </Box>
                    </Grid>
                    <Grid item xs={12} pt='5px' pb='5px'>
                        <Box p='10px' pt='5px' pb='5px'>
                            {comVierzehnTagesprognoseDiagramm}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p='10px' pt='5px' pb='5px'>
                            {comExternalLinks}
                        </Box>
                    </Grid>
                </Grid>)
                : null
                );
                }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Home page - Render", ("202107151244 - Error: " + error.message), null, true);
                }

        return (

            <Fragment>
                <Profiler id="Home" onRender={this.setPerformance}>
                    {componentOrganizer ? componentOrganizer : <div className="NoPage">{this.props.t('common.messages.unable_to_load_data')}</div>}

                    <SweetAlert
                        show={this.state.homeDataError}
                        warning
                        confirmBtnText="okay"
                        confirmBtnCssClass={this.props.colorMode ? this.props.colorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                        style={this.props.colorMode ? this.props.colorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                        title={this.props.t('common.messages.attention')}
                        onConfirm={this.resetHomeDataError}>
                        {
                            this.props.t('common.messages.unable_to_load_data')
                        }
                    </SweetAlert>

                    <SweetAlert
                        show={this.state.homeNoData}
                        warning
                        confirmBtnText="okay"
                        confirmBtnCssClass={this.props.colorMode ? this.props.colorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                        style={this.props.colorMode ? this.props.colorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                        title={this.props.t('common.messages.attention')}
                        onConfirm={this.resetHomeDataError}>
                        {
                            this.props.t('common.messages.no_data_now')
                        }
                    </SweetAlert>

                {this.state.errorInfo ?
                    (<div>
                        <h2>Something went wrong!.</h2>
                        <details style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo.componentStack}
                        </details>
                    </div>)
                    : null
                }
                </Profiler>
            </Fragment >);
    }
}

const mapStateToProps = state => {
    return {
        //ctr: state.counterSubReducer.counter,
        //array: state.resultSubReducer.results,
        location: state.locationSubReducer,
        druck: state.settingsSubReducer.druck,
        temperature: state.settingsSubReducer.temperature,
        windgeschwindigkeit: state.settingsSubReducer.windgeschwindigkeit,
        colorMode: state.settingsSubReducer.colorMode,
        homeData: state.homeSubReducer,
        token: state.tokenSubReducer,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onIncrementCounter: (obj) => dispatch(actionCreators.increment(obj.count)),
        onAddCounter: (obj) => dispatch(actionCreators.add(obj.count)),
        handleGetHomeData: (token, locID) => dispatch(actionCreators.getHomeData(token, locID)),
        onResetHomeDataError: () => dispatch(actionCreators.resetHomeDataError()),
        handleGetToken: () => { dispatch(actionCreators.getToken()) },
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Home));
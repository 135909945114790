import * as actionTypes from '../actions/actionTypes';
import { loadState } from '../../localStorage';

let localCache = loadState();
let initialState = null;

if (localCache && localCache.news) {
    initialState = localCache.news;
}
else {
    initialState = {
        news: null,
        loading: false,
        error: null
    }
}

const reducer = (state = initialState, action) => {
try{
    if (action.type === actionTypes.GET_NEWS_SUCCESS) {
        return {
            ...state,
            loading: false,
            error: null,
            news: action.payload
        }
    } else if (action.type === actionTypes.GET_NEWS_STARTED) {
        return {
            ...state,
            loading: true
        }
    }
    else if (action.type === actionTypes.GET_NEWS_FAILURE) {
        return {
            ...state,
            loading: false,
            error: action.payload.error,
        }
    }
    else if(action.type === actionTypes.GET_NEWS_ADD){
        return{
            ...state,
            loading: false,
            error: null,
            news: state.news.concat(action.payload)
        }
    } else if (action.type === actionTypes.RESET_NEWS_ERROR) {
        return {
            ...state,
            error: null,
        }
    }
    else {
        return state;
    }
} catch (error) { console.log('202102191550', error) }
};

export default reducer;
import * as actionTypes from '../actions/actionTypes';
import { loadState } from '../../localStorage';

let localCache = loadState();
let initialState = null;

if (localCache && localCache.biowetter) {
    initialState = localCache.biowetter;
}
else {
    initialState = {
        biowetter: null,
        loading: false,
        error: null
    }
}

const reducer = (state = initialState, action) => {
    try{
    if (action.type === actionTypes.GET_BIOWETTER_SUCCESS) {
        return {
            ...state,
            loading: false,
            error: null,
            biowetter: action.payload
        }
    } else if (action.type === actionTypes.GET_BIOWETTER_STARTED) {
        return {
            ...state,
            loading: true
        }
    }
    else if (action.type === actionTypes.GET_BIOWETTER_FAILURE) {
        return {
            ...state,
            loading: false,
            error: action.payload.error,
        }
    } else if (action.type === actionTypes.RESET_BIOWETTER_ERROR) {
        return {
            ...state,
            error: null,
        }
    }
    else {
        return state;
    }
    } catch (error) { console.log('202102191546', error) }
};

export default reducer;
import React, { Component, Fragment, lazy, Profiler } from 'react';
import { Grid, Box } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import axios from 'axios';

//import * as actionCreators from '../../../../store/actions/index';
import { GATiming } from '../../../../Assets/GoogleAnalytics/googleAnalytics';

const FaqPageComponent = lazy(() => import('../../../Components/Menu/FaqPage/FaqPage'));

class faqPage extends Component {

    state = {
        faqDataError: false,
        faqNoData: false,
        faqData: [],
    }

    componentDidMount = async () => {
        try {

            const axiosInstance = axios.create({
                //baseURL: 'http://165.227.162.31:8000/v2/',
                baseURL: 'https://apps.qmet.de/v2/',
            });

            axiosInstance.get(`user/faq`)
                .then(response => {
                    if (response && response.data && response.data.data && response.data.data.length > 0) {
                            let sampArray = [];
                            for (let i = 0; i < response.data.data.length; i++) {
                                if (response.data.data[i].language.toString() === this.props.i18n.language.toString()) {
                                    sampArray.push(response.data.data[i]);
                                }
                            }
                            this.setState({ faqData: sampArray });
                    }
                    else {
                        this.setState({ faqNoData: true});
                    }
                })
                .catch(error => {
                    this.setState({ faqDataError: true });
                });

        } catch (error) { console.log('202102191410', error) }
    }

    handleAlertClose = () => {
        try {
            this.props.history.push('/');
            this.setState({ faqDataError: false, faqNoData: false });
        } catch (error) { console.log('202102191411', error) }
    }

    setPerformance = (argProfilerId, argMode, argActualTime, argBaseTime, argStartTime, argCommitTime, argInteracions) => {
        try {
            if (argMode && argMode.toString() === "mount") {
                GATiming(argProfilerId, argMode, argActualTime, (argProfilerId + " " + argMode));
            }
        } catch (error) { }
    }

    render() {

        const comFaqPage = (<FaqPageComponent componentData={this.state.faqData} activeColorMode={this.props.colorMode} />);

        return (
            <Fragment>
                <Profiler id="faq" onRender={this.setPerformance}>
                    <Grid container spacing={0} style={{ height: '100%' }}>
                        <Grid item xs={12}>
                            <Box p='10px' style={{ height: '90%' }}>
                                {comFaqPage}
                            </Box>
                        </Grid>
                    </Grid>
                    <SweetAlert
                        show={this.state.faqDataError}
                        warning
                        confirmBtnText="Okay"
                        confirmBtnCssClass={this.props.colorMode ? this.props.colorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                        style={this.props.colorMode ? this.props.colorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                        title={this.props.t('common.messages.attention')}/*"Etwas ist schief gelaufen!"*/
                        onConfirm={this.handleAlertClose}>
                        {
                            /*Daten können nicht geladen werden. Bitte versuchen Sie es nach einiger Zeit!*/
                            this.props.t('common.messages.unable_to_load_data')
                        }
                    </SweetAlert>
                    <SweetAlert
                        show={this.state.faqNoData}
                        warning
                        confirmBtnText={this.props.t('common.messages.back')}/*"zurück"*/
                        confirmBtnCssClass={this.props.colorMode ? this.props.colorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                        style={this.props.colorMode ? this.props.colorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                        title={this.props.t('common.messages.attention')}/*"Keine Daten!"*/
                        onConfirm={this.handleAlertClose}>
                        {
                            /*Derzeit keine Daten, bitte versuchen Sie es nach einiger Zeit!*/
                            this.props.t('common.messages.no_data_now')
                        }
                    </SweetAlert>
                </Profiler>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        colorMode: state.settingsSubReducer.colorMode,
    };
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(faqPage));
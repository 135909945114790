import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getKPollenflug = (argToken, argLocID) => {

    try {
        return dispatch => {
            dispatch(getKPollenflugStarted());
            const axiosInstance = axios.create({
                baseURL: 'http://165.227.162.31:8000/v4/',
                headers: { 'Authorization': `Bearer ${argToken}` }
            });

            axiosInstance.get(`pollen/${argLocID}`)
                .then(response => {
                    if (response && response.data && response.data.data) {
                        dispatch(getKPollenflugSuccess(response.data.data));
                    }
                    else {
                        dispatch(getKPollenflugFailure('Something went wrong'));
                    }
                })
                .catch(error => {
                    dispatch(getKPollenflugFailure(error.message));
                })
        };
        // eslint-disable-next-line no-unreachable
    } catch (error) { console.log('202102231105', error) }
};

const getKPollenflugSuccess = data => ({
    type: actionTypes.K_GET_POLLENFLUG_SUCCESS,
    payload: data
});

const getKPollenflugStarted = () => ({
    type: actionTypes.K_GET_POLLENFLUG_STARTED,
});

const getKPollenflugFailure = error => ({
    type: actionTypes.K_GET_POLLENFLUG_FAILURE,
    payload: {
        error
    }
});

export const getCacheKPollenflugData = () => ({
    type: actionTypes.K_GET_POLLENFLUG_CACHED,
});
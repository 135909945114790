import React, { memo } from 'react';
import {
    Typography,
    Breadcrumbs,
    Grid,
    FormControl,
    Select,
    MenuItem
} from '@material-ui/core';
import { Link as SiteLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';

import cssModule from './PollenKalender.module.css';
import ChartDetails from './PollenKalenderDetails/PollenKalenderDetails';

const useStyles = makeStyles((theme) => ({

    head: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '3rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    head2: {
    textTransform: "none",
    color: 'rgba(0, 62, 133, .8)',
    fontSize: '1.9rem',
    fontFamily: 'Source Sans Pro, sans-serif !important',
    fontWeight: '300',
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '15px'
    },
    weatherVideo: {
        borderRadius: '5px',
        marginBottom: '1rem'
    },
    iFrameParent: {
        marginBottom: '1rem',
        borderRadius: '5px'
    },
    legnede:{
        display:'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor:'rgba(252, 239, 196, .6)',
        margin:'15px 0px',
        borderRadius:'5px',
        padding:'10px',
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    legnedeItem:{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center'
    },
    filterContainer:{
        display:'flex',
        alignItems:'center',
        justifyContent:'flex-end',
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.2rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        '& p':{
            marginTop:'.9rem',
            marginBottom: '1.1rem'
        }
    },
    formControl:{
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    selectEmpty:{
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        '& .MuiSelect-select:focus':{
            backgroundColor:'rgba(0,0,0,0)'
        },
        minWidth:'50px'
    },
    filterLabel:{
        fontWeight:'400',
    }
}));

/*const pollenData = [
    { "ID_Polle": "Erle", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 1, "2021-02-01": 1, "2021-02-08": 2, "2021-02-15": 2, "2021-02-22": 2, "2021-03-01": 3, "2021-03-08": 3, "2021-03-15": 3, "2021-03-22": 3, "2021-03-29": 3, "2021-04-05": 2, "2021-04-12": 2, "2021-04-19": 2, "2021-04-26": 2, "2021-05-03": 1, "2021-05-10": 1, "2021-05-17": 1, "2021-05-24": 0, "2021-05-31": 0, "2021-06-07": 0, "2021-06-14": 0, "2021-06-21": 0, "2021-06-28": 0, "2021-07-05": 0, "2021-07-12": 0, "2021-07-19": 0, "2021-07-26": 0, "2021-08-02": 0, "2021-08-09": 0, "2021-08-16": 0, "2021-08-23": 0, "2021-08-30": 0, "2021-09-06": 0, "2021-09-13": 0, "2021-09-20": 0, "2021-09-27": 0, "2021-10-04": 0, "2021-10-11": 0, "2021-10-18": 0, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Weide", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 1, "2021-02-15": 1, "2021-02-22": 1, "2021-03-01": 1, "2021-03-08": 1, "2021-03-15": 2, "2021-03-22": 3, "2021-03-29": 3, "2021-04-05": 3, "2021-04-12": 3, "2021-04-19": 3, "2021-04-26": 3, "2021-05-03": 3, "2021-05-10": 2, "2021-05-17": 1, "2021-05-24": 1, "2021-05-31": 1, "2021-06-07": 1, "2021-06-14": 1, "2021-06-21": 1, "2021-06-28": 0, "2021-07-05": 0, "2021-07-12": 0, "2021-07-19": 0, "2021-07-26": 0, "2021-08-02": 0, "2021-08-09": 0, "2021-08-16": 0, "2021-08-23": 0, "2021-08-30": 0, "2021-09-06": 0, "2021-09-13": 0, "2021-09-20": 0, "2021-09-27": 0, "2021-10-04": 0, "2021-10-11": 0, "2021-10-18": 0, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Hasel", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 1, "2021-02-01": 2, "2021-02-08": 2, "2021-02-15": 3, "2021-02-22": 3, "2021-03-01": 3, "2021-03-08": 3, "2021-03-15": 3, "2021-03-22": 3, "2021-03-29": 2, "2021-04-05": 2, "2021-04-12": 1, "2021-04-19": 1, "2021-04-26": 1, "2021-05-03": 1, "2021-05-10": 1, "2021-05-17": 0, "2021-05-24": 0, "2021-05-31": 0, "2021-06-07": 0, "2021-06-14": 0, "2021-06-21": 0, "2021-06-28": 0, "2021-07-05": 0, "2021-07-12": 0, "2021-07-19": 0, "2021-07-26": 0, "2021-08-02": 0, "2021-08-09": 0, "2021-08-16": 0, "2021-08-23": 0, "2021-08-30": 0, "2021-09-06": 0, "2021-09-13": 0, "2021-09-20": 0, "2021-09-27": 0, "2021-10-04": 0, "2021-10-11": 0, "2021-10-18": 0, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Pappel", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 1, "2021-02-15": 1, "2021-02-22": 1, "2021-03-01": 1, "2021-03-08": 1, "2021-03-15": 2, "2021-03-22": 3, "2021-03-29": 3, "2021-04-05": 3, "2021-04-12": 3, "2021-04-19": 3, "2021-04-26": 2, "2021-05-03": 1, "2021-05-10": 1, "2021-05-17": 1, "2021-05-24": 1, "2021-05-31": 0, "2021-06-07": 0, "2021-06-14": 0, "2021-06-21": 0, "2021-06-28": 0, "2021-07-05": 0, "2021-07-12": 0, "2021-07-19": 0, "2021-07-26": 0, "2021-08-02": 0, "2021-08-09": 0, "2021-08-16": 0, "2021-08-23": 0, "2021-08-30": 0, "2021-09-06": 0, "2021-09-13": 0, "2021-09-20": 0, "2021-09-27": 0, "2021-10-04": 0, "2021-10-11": 0, "2021-10-18": 0, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Birke", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 0, "2021-02-15": 0, "2021-02-22": 1, "2021-03-01": 1, "2021-03-08": 1, "2021-03-15": 1, "2021-03-22": 1, "2021-03-29": 2, "2021-04-05": 2, "2021-04-12": 3, "2021-04-19": 3, "2021-04-26": 3, "2021-05-03": 3, "2021-05-10": 2, "2021-05-17": 2, "2021-05-24": 2, "2021-05-31": 2, "2021-06-07": 1, "2021-06-14": 1, "2021-06-21": 1, "2021-06-28": 0, "2021-07-05": 0, "2021-07-12": 0, "2021-07-19": 0, "2021-07-26": 0, "2021-08-02": 0, "2021-08-09": 0, "2021-08-16": 0, "2021-08-23": 0, "2021-08-30": 0, "2021-09-06": 0, "2021-09-13": 0, "2021-09-20": 0, "2021-09-27": 0, "2021-10-04": 0, "2021-10-11": 0, "2021-10-18": 0, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Roggen", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 0, "2021-02-15": 0, "2021-02-22": 0, "2021-03-01": 0, "2021-03-08": 0, "2021-03-15": 0, "2021-03-22": 0, "2021-03-29": 0, "2021-04-05": 0, "2021-04-12": 0, "2021-04-19": 0, "2021-04-26": 0, "2021-05-03": 0, "2021-05-10": 1, "2021-05-17": 1, "2021-05-24": 1, "2021-05-31": 2, "2021-06-07": 3, "2021-06-14": 3, "2021-06-21": 3, "2021-06-28": 3, "2021-07-05": 2, "2021-07-12": 1, "2021-07-19": 1, "2021-07-26": 1, "2021-08-02": 0, "2021-08-09": 0, "2021-08-16": 0, "2021-08-23": 0, "2021-08-30": 0, "2021-09-06": 0, "2021-09-13": 0, "2021-09-20": 0, "2021-09-27": 0, "2021-10-04": 0, "2021-10-11": 0, "2021-10-18": 0, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Linde", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 0, "2021-02-15": 0, "2021-02-22": 0, "2021-03-01": 0, "2021-03-08": 0, "2021-03-15": 0, "2021-03-22": 0, "2021-03-29": 0, "2021-04-05": 0, "2021-04-12": 0, "2021-04-19": 0, "2021-04-26": 0, "2021-05-03": 0, "2021-05-10": 1, "2021-05-17": 1, "2021-05-24": 1, "2021-05-31": 2, "2021-06-07": 2, "2021-06-14": 3, "2021-06-21": 3, "2021-06-28": 3, "2021-07-05": 3, "2021-07-12": 3, "2021-07-19": 3, "2021-07-26": 3, "2021-08-02": 3, "2021-08-09": 2, "2021-08-16": 2, "2021-08-23": 2, "2021-08-30": 1, "2021-09-06": 1, "2021-09-13": 0, "2021-09-20": 0, "2021-09-27": 0, "2021-10-04": 0, "2021-10-11": 0, "2021-10-18": 0, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Ulme", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 0, "2021-02-15": 0, "2021-02-22": 1, "2021-03-01": 1, "2021-03-08": 1, "2021-03-15": 1, "2021-03-22": 1, "2021-03-29": 2, "2021-04-05": 2, "2021-04-12": 3, "2021-04-19": 3, "2021-04-26": 3, "2021-05-03": 3, "2021-05-10": 2, "2021-05-17": 2, "2021-05-24": 1, "2021-05-31": 1, "2021-06-07": 1, "2021-06-14": 0, "2021-06-21": 0, "2021-06-28": 0, "2021-07-05": 0, "2021-07-12": 0, "2021-07-19": 0, "2021-07-26": 0, "2021-08-02": 0, "2021-08-09": 0, "2021-08-16": 0, "2021-08-23": 0, "2021-08-30": 0, "2021-09-06": 0, "2021-09-13": 0, "2021-09-20": 0, "2021-09-27": 0, "2021-10-04": 0, "2021-10-11": 0, "2021-10-18": 0, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Esche", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 0, "2021-02-15": 1, "2021-02-22": 1, "2021-03-01": 1, "2021-03-08": 1, "2021-03-15": 1, "2021-03-22": 1, "2021-03-29": 2, "2021-04-05": 3, "2021-04-12": 3, "2021-04-19": 3, "2021-04-26": 3, "2021-05-03": 2, "2021-05-10": 2, "2021-05-17": 1, "2021-05-24": 1, "2021-05-31": 1, "2021-06-07": 1, "2021-06-14": 1, "2021-06-21": 1, "2021-06-28": 0, "2021-07-05": 0, "2021-07-12": 0, "2021-07-19": 0, "2021-07-26": 0, "2021-08-02": 0, "2021-08-09": 0, "2021-08-16": 0, "2021-08-23": 0, "2021-08-30": 0, "2021-09-06": 0, "2021-09-13": 0, "2021-09-20": 0, "2021-09-27": 0, "2021-10-04": 0, "2021-10-11": 0, "2021-10-18": 0, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Hainbuche", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 0, "2021-02-15": 0, "2021-02-22": 0, "2021-03-01": 0, "2021-03-08": 1, "2021-03-15": 1, "2021-03-22": 1, "2021-03-29": 1, "2021-04-05": 2, "2021-04-12": 3, "2021-04-19": 3, "2021-04-26": 3, "2021-05-03": 3, "2021-05-10": 2, "2021-05-17": 1, "2021-05-24": 1, "2021-05-31": 1, "2021-06-07": 1, "2021-06-14": 1, "2021-06-21": 0, "2021-06-28": 0, "2021-07-05": 0, "2021-07-12": 0, "2021-07-19": 0, "2021-07-26": 0, "2021-08-02": 0, "2021-08-09": 0, "2021-08-16": 0, "2021-08-23": 0, "2021-08-30": 0, "2021-09-06": 0, "2021-09-13": 0, "2021-09-20": 0, "2021-09-27": 0, "2021-10-04": 0, "2021-10-11": 0, "2021-10-18": 0, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Kiefer", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 0, "2021-02-15": 0, "2021-02-22": 0, "2021-03-01": 0, "2021-03-08": 0, "2021-03-15": 0, "2021-03-22": 0, "2021-03-29": 0, "2021-04-05": 1, "2021-04-12": 1, "2021-04-19": 1, "2021-04-26": 1, "2021-05-03": 2, "2021-05-10": 3, "2021-05-17": 3, "2021-05-24": 3, "2021-05-31": 3, "2021-06-07": 2, "2021-06-14": 2, "2021-06-21": 2, "2021-06-28": 2, "2021-07-05": 2, "2021-07-12": 1, "2021-07-19": 1, "2021-07-26": 1, "2021-08-02": 1, "2021-08-09": 1, "2021-08-16": 0, "2021-08-23": 0, "2021-08-30": 0, "2021-09-06": 0, "2021-09-13": 0, "2021-09-20": 0, "2021-09-27": 0, "2021-10-04": 0, "2021-10-11": 0, "2021-10-18": 0, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Eiche", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 0, "2021-02-15": 0, "2021-02-22": 0, "2021-03-01": 0, "2021-03-08": 1, "2021-03-15": 1, "2021-03-22": 1, "2021-03-29": 1, "2021-04-05": 1, "2021-04-12": 1, "2021-04-19": 2, "2021-04-26": 2, "2021-05-03": 3, "2021-05-10": 3, "2021-05-17": 3, "2021-05-24": 2, "2021-05-31": 2, "2021-06-07": 1, "2021-06-14": 1, "2021-06-21": 1, "2021-06-28": 1, "2021-07-05": 1, "2021-07-12": 0, "2021-07-19": 0, "2021-07-26": 0, "2021-08-02": 0, "2021-08-09": 0, "2021-08-16": 0, "2021-08-23": 0, "2021-08-30": 0, "2021-09-06": 0, "2021-09-13": 0, "2021-09-20": 0, "2021-09-27": 0, "2021-10-04": 0, "2021-10-11": 0, "2021-10-18": 0, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Buche", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 0, "2021-02-15": 0, "2021-02-22": 0, "2021-03-01": 0, "2021-03-08": 0, "2021-03-15": 1, "2021-03-22": 1, "2021-03-29": 1, "2021-04-05": 1, "2021-04-12": 1, "2021-04-19": 2, "2021-04-26": 3, "2021-05-03": 3, "2021-05-10": 3, "2021-05-17": 2, "2021-05-24": 1, "2021-05-31": 1, "2021-06-07": 1, "2021-06-14": 1, "2021-06-21": 1, "2021-06-28": 0, "2021-07-05": 0, "2021-07-12": 0, "2021-07-19": 0, "2021-07-26": 0, "2021-08-02": 0, "2021-08-09": 0, "2021-08-16": 0, "2021-08-23": 0, "2021-08-30": 0, "2021-09-06": 0, "2021-09-13": 0, "2021-09-20": 0, "2021-09-27": 0, "2021-10-04": 0, "2021-10-11": 0, "2021-10-18": 0, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Sauerampfer", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 0, "2021-02-15": 0, "2021-02-22": 0, "2021-03-01": 0, "2021-03-08": 0, "2021-03-15": 0, "2021-03-22": 0, "2021-03-29": 0, "2021-04-05": 0, "2021-04-12": 0, "2021-04-19": 0, "2021-04-26": 1, "2021-05-03": 1, "2021-05-10": 1, "2021-05-17": 2, "2021-05-24": 3, "2021-05-31": 3, "2021-06-07": 3, "2021-06-14": 3, "2021-06-21": 3, "2021-06-28": 3, "2021-07-05": 3, "2021-07-12": 3, "2021-07-19": 2, "2021-07-26": 2, "2021-08-02": 2, "2021-08-09": 2, "2021-08-16": 1, "2021-08-23": 1, "2021-08-30": 1, "2021-09-06": 0, "2021-09-13": 0, "2021-09-20": 0, "2021-09-27": 0, "2021-10-04": 0, "2021-10-11": 0, "2021-10-18": 0, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Gänsefuß", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 0, "2021-02-15": 0, "2021-02-22": 0, "2021-03-01": 0, "2021-03-08": 0, "2021-03-15": 0, "2021-03-22": 0, "2021-03-29": 0, "2021-04-05": 0, "2021-04-12": 0, "2021-04-19": 0, "2021-04-26": 0, "2021-05-03": 0, "2021-05-10": 0, "2021-05-17": 0, "2021-05-24": 0, "2021-05-31": 1, "2021-06-07": 1, "2021-06-14": 1, "2021-06-21": 1, "2021-06-28": 2, "2021-07-05": 2, "2021-07-12": 2, "2021-07-19": 3, "2021-07-26": 3, "2021-08-02": 3, "2021-08-09": 3, "2021-08-16": 3, "2021-08-23": 3, "2021-08-30": 3, "2021-09-06": 2, "2021-09-13": 2, "2021-09-20": 2, "2021-09-27": 1, "2021-10-04": 1, "2021-10-11": 1, "2021-10-18": 1, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Brennnessel", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 0, "2021-02-15": 0, "2021-02-22": 0, "2021-03-01": 0, "2021-03-08": 0, "2021-03-15": 0, "2021-03-22": 0, "2021-03-29": 0, "2021-04-05": 0, "2021-04-12": 0, "2021-04-19": 0, "2021-04-26": 0, "2021-05-03": 1, "2021-05-10": 1, "2021-05-17": 1, "2021-05-24": 1, "2021-05-31": 2, "2021-06-07": 2, "2021-06-14": 2, "2021-06-21": 3, "2021-06-28": 3, "2021-07-05": 3, "2021-07-12": 3, "2021-07-19": 3, "2021-07-26": 3, "2021-08-02": 3, "2021-08-09": 3, "2021-08-16": 3, "2021-08-23": 3, "2021-08-30": 2, "2021-09-06": 2, "2021-09-13": 2, "2021-09-20": 1, "2021-09-27": 1, "2021-10-04": 1, "2021-10-11": 1, "2021-10-18": 1, "2021-10-25": 1, "2021-11-01": 1, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Beifuß", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 0, "2021-02-15": 0, "2021-02-22": 0, "2021-03-01": 0, "2021-03-08": 0, "2021-03-15": 0, "2021-03-22": 0, "2021-03-29": 0, "2021-04-05": 0, "2021-04-12": 0, "2021-04-19": 0, "2021-04-26": 0, "2021-05-03": 0, "2021-05-10": 0, "2021-05-17": 0, "2021-05-24": 0, "2021-05-31": 0, "2021-06-07": 1, "2021-06-14": 1, "2021-06-21": 1, "2021-06-28": 1, "2021-07-05": 1, "2021-07-12": 2, "2021-07-19": 2, "2021-07-26": 3, "2021-08-02": 3, "2021-08-09": 3, "2021-08-16": 3, "2021-08-23": 3, "2021-08-30": 2, "2021-09-06": 2, "2021-09-13": 2, "2021-09-20": 1, "2021-09-27": 1, "2021-10-04": 1, "2021-10-11": 1, "2021-10-18": 0, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Spitzwegerich", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 0, "2021-02-15": 0, "2021-02-22": 0, "2021-03-01": 0, "2021-03-08": 0, "2021-03-15": 0, "2021-03-22": 0, "2021-03-29": 0, "2021-04-05": 0, "2021-04-12": 0, "2021-04-19": 1, "2021-04-26": 1, "2021-05-03": 1, "2021-05-10": 1, "2021-05-17": 2, "2021-05-24": 3, "2021-05-31": 3, "2021-06-07": 3, "2021-06-14": 3, "2021-06-21": 3, "2021-06-28": 3, "2021-07-05": 3, "2021-07-12": 3, "2021-07-19": 3, "2021-07-26": 3, "2021-08-02": 3, "2021-08-09": 3, "2021-08-16": 3, "2021-08-23": 3, "2021-08-30": 2, "2021-09-06": 2, "2021-09-13": 1, "2021-09-20": 1, "2021-09-27": 1, "2021-10-04": 1, "2021-10-11": 1, "2021-10-18": 1, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Gräser", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 0, "2021-02-15": 0, "2021-02-22": 0, "2021-03-01": 0, "2021-03-08": 0, "2021-03-15": 0, "2021-03-22": 0, "2021-03-29": 0, "2021-04-05": 1, "2021-04-12": 1, "2021-04-19": 1, "2021-04-26": 1, "2021-05-03": 1, "2021-05-10": 2, "2021-05-17": 3, "2021-05-24": 3, "2021-05-31": 3, "2021-06-07": 3, "2021-06-14": 3, "2021-06-21": 3, "2021-06-28": 3, "2021-07-05": 3, "2021-07-12": 3, "2021-07-19": 3, "2021-07-26": 3, "2021-08-02": 3, "2021-08-09": 2, "2021-08-16": 2, "2021-08-23": 2, "2021-08-30": 2, "2021-09-06": 2, "2021-09-13": 2, "2021-09-20": 1, "2021-09-27": 1, "2021-10-04": 1, "2021-10-11": 1, "2021-10-18": 1, "2021-10-25": 1, "2021-11-01": 1, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 },
    { "ID_Polle": "Ambrosia", "ID_Pollengebiet": 0, "2021-01-04": 0, "2021-01-11": 0, "2021-01-18": 0, "2021-01-25": 0, "2021-02-01": 0, "2021-02-08": 0, "2021-02-15": 0, "2021-02-22": 0, "2021-03-01": 0, "2021-03-08": 0, "2021-03-15": 0, "2021-03-22": 0, "2021-03-29": 0, "2021-04-05": 0, "2021-04-12": 0, "2021-04-19": 0, "2021-04-26": 0, "2021-05-03": 0, "2021-05-10": 0, "2021-05-17": 0, "2021-05-24": 0, "2021-05-31": 0, "2021-06-07": 0, "2021-06-14": 0, "2021-06-21": 0, "2021-06-28": 0, "2021-07-05": 0, "2021-07-12": 1, "2021-07-19": 1, "2021-07-26": 1, "2021-08-02": 1, "2021-08-09": 2, "2021-08-16": 2, "2021-08-23": 2, "2021-08-30": 2, "2021-09-06": 3, "2021-09-13": 2, "2021-09-20": 1, "2021-09-27": 1, "2021-10-04": 1, "2021-10-11": 1, "2021-10-18": 1, "2021-10-25": 0, "2021-11-01": 0, "2021-11-08": 0, "2021-11-15": 0, "2021-11-22": 0, "2021-11-29": 0, "2021-12-06": 0, "2021-12-13": 0, "2021-12-20": 0, "2021-12-27": 0, "2022-01-03": 0 }]*/

const PollenKalender = memo((props) => {

    const classes = useStyles();

    const [hookFilter, setFilter] = React.useState('PO');
    const [hookFilterPollen, setFilterPollen] = React.useState('ALLP');
    const [hookFilterMonate, setFilterMonate] = React.useState('ALLM');

    function handleClick(event) {
        try {
            event.preventDefault();
        } catch (error) { console.log('202103101712', error) }
    }

    let componentOrganizer = null;

    try {

        componentOrganizer = (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography className={classes.head2} variant="h4" component="h3" gutterBottom>Informationen zum Pollenkalender</Typography>
                        <Typography className={classes.data} variant="body1" component="p">
                            Der dargestellte Pollenkalender zeigt die langjährigen Mittelwerte für die Zeiträume in denen die einzelnen 
                            Pollenarten auftraten. Es handelt sich also nicht um aktuelle Werte oder Prognosen, sondern das Kalendarium 
                            dient lediglich als Richtschnur, wann gewöhnlich mit Pollenflug bestimmter Arten zu rechnen ist.
                        </Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        );

    } catch (error) {
        console.log('202107281519', error);
    }

    let tableRows = null;

    try{

    tableRows = props.componentData.kPollenKalender.map((item, index) => {
        return (
            <tr key={item.ID_Polle}>
                <td><ChartDetails pollenData={item} itemIndex={index} filterPollen={hookFilterPollen} filterMonate={hookFilterMonate}/></td>
            </tr>
        )
    })

    }catch(error){
        console.log('202109081155',error);
    }

    const handleFilterChange = (event) => {
        const name = event.target.value;
        setFilter(name);
        props.PollenChange(null);
        setFilterPollen('ALLP');
        setFilterMonate('ALLM');
    };

    const handleFilterPollenChange = (event) => {
        const name = event.target.value;
        setFilterPollen(name);
        props.PollenChange(name);
        setFilterMonate('ALLM');
    };

    const handleFilterMonateChange = (event) => {
        const name = event.target.value;
        setFilterMonate(name);
        setFilterPollen('ALLP');
    };

    return (
        <React.Fragment>
            <div className={cssModule.PollenKalenderParent}>
                <div className={cssModule.HeadSection}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <SiteLink className={cssModule.BreadcrubsLink} color="inherit" to="/" onClick={handleClick}>Home</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}> Gesundheit</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Pollenkalender</SiteLink>
                    </Breadcrumbs>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography className={classes.head} variant="h1" component="h1" gutterBottom>Pollenkalender</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="flex-end">
                    <Grid item xs={12}>
                        <div className={classes.filterContainer}>
                            <p className={classes.filterLabel}>Filter :&nbsp;&nbsp;&nbsp;&nbsp;</p>
                            <FormControl className={classes.formControl}>
                                <Select
                                    className={classes.selectEmpty}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    IconComponent={() => (
                                        <ExpandMoreOutlinedIcon />
                                    )}
                                    disableUnderline
                                    value={hookFilter}
                                    onChange={handleFilterChange}>
                                    <MenuItem value="PO">Pollen</MenuItem>
                                    <MenuItem value="MO">Monat</MenuItem>
                                </Select>
                            </FormControl>
                            {hookFilter === "PO" ? 
                            (
                            <React.Fragment>
                                <p className={classes.filterLabel}>&nbsp;&nbsp;&nbsp;&nbsp;Pollen :&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                <FormControl className={classes.formControl}>
                                    <Select
                                        className={classes.selectEmpty}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        IconComponent={() => (
                                            <ExpandMoreOutlinedIcon />
                                        )}
                                        disableUnderline
                                        value={hookFilterPollen}
                                        onChange={handleFilterPollenChange}>
                                        <MenuItem value="ALLP">Alle Pollen</MenuItem>
                                                <MenuItem value="Erle">Erle</MenuItem>
                                                <MenuItem value="Weide">Weide</MenuItem>
                                                <MenuItem value="Hasel">Hasel</MenuItem>
                                                <MenuItem value="Pappel">Pappel</MenuItem>
                                                <MenuItem value="Birke">Birke</MenuItem>
                                                <MenuItem value="Roggen">Roggen</MenuItem>
                                                <MenuItem value="Linde">Linde</MenuItem>
                                                <MenuItem value="Ulme">Ulme</MenuItem>
                                                <MenuItem value="Esche">Esche</MenuItem>
                                                <MenuItem value="Hainbuche">Hainbuche</MenuItem>
                                                <MenuItem value="Kiefer">Kiefer</MenuItem>
                                                <MenuItem value="Eiche">Eiche</MenuItem>
                                                <MenuItem value="Buche">Buche</MenuItem>
                                                <MenuItem value="Sauerampfer">Sauerampfer</MenuItem>
                                                <MenuItem value="Gänsefuß">Gänsefuß</MenuItem>
                                                <MenuItem value="Brennnessel">Brennnessel</MenuItem>
                                                <MenuItem value="Beifuß">Beifuß</MenuItem>
                                                <MenuItem value="Spitzwegerich">Spitzwegerich</MenuItem>
                                                <MenuItem value="Gräser">Gräser</MenuItem>
                                                <MenuItem value="Ambrosia">Ambrosia</MenuItem>
                                    </Select>
                                </FormControl>
                            </React.Fragment>) :
                            (
                            <React.Fragment>
                            <p className={classes.filterLabel}>&nbsp;&nbsp;&nbsp;&nbsp;Monat :&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                <FormControl className={classes.formControl}>
                                    <Select
                                        className={classes.selectEmpty}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        IconComponent={() => (
                                            <ExpandMoreOutlinedIcon />
                                        )}
                                        disableUnderline
                                        value={hookFilterMonate}
                                        onChange={handleFilterMonateChange}>
                                        <MenuItem value="ALLM">Alle Monate</MenuItem>
                                        <MenuItem value="0">Januar</MenuItem>
                                        <MenuItem value="1">Februar</MenuItem>
                                        <MenuItem value="2">März</MenuItem>
                                        <MenuItem value="3">April</MenuItem>
                                        <MenuItem value="4">Mai</MenuItem>
                                        <MenuItem value="5">Juni</MenuItem>
                                        <MenuItem value="6">Juli</MenuItem>
                                        <MenuItem value="7">August</MenuItem>
                                        <MenuItem value="8">September</MenuItem>
                                        <MenuItem value="9">Oktober</MenuItem>
                                        <MenuItem value="10">November</MenuItem>
                                        <MenuItem value="11">Dezember</MenuItem>
                                    </Select>
                                </FormControl>
                            </React.Fragment>
                            )
                            }
                        </div>
                    </Grid>
                </Grid>
                <div className={cssModule.DataSection}>
                    <table className={cssModule.MainTable} cellspacing="0">
                        <thead>
                        </thead>
                        <tbody>
                            {tableRows}
                        </tbody>
                    </table>
                </div>
                <div className={classes.legnede}>
                    <div>Legende</div>
                    <div className={classes.legnedeItem}>
                        <div>
                            <svg height="20" width="12">
                                <circle cx="6" cy="14" r="4" fill="#4CAF50" />
                            </svg>
                        </div>
                        <div>&nbsp;&nbsp;sporadische Belastung</div>
                    </div>
                    <div className={classes.legnedeItem}>
                        <div>
                            <svg height="20" width="12">
                                <circle cx="6" cy="14" r="5" fill="#FF9800" />
                            </svg>
                        </div>
                        <div>&nbsp;&nbsp;mäßige Belastung</div>
                    </div>
                    <div className={classes.legnedeItem}>
                        <div>
                            <svg height="20" width="12">
                                <circle cx="6" cy="14" r="6" fill="#f44336" />
                            </svg>
                        </div>
                        <div>&nbsp;&nbsp;starke Belastung</div>
                    </div>
                </div>
                {componentOrganizer}
            </div>
        </React.Fragment>
    );
});

export default PollenKalender;

import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getKPollenKalender = (argToken, argPollen) => {

    try {
        return dispatch => {
            dispatch(getKPollenKalenderStarted());
            const axiosInstance = axios.create({
                baseURL: 'http://165.227.162.31:8000/v2/',
                headers: { 'Authorization': `Bearer ${argToken}` }
            });

            axiosInstance.post(`pollen/calender`, argPollen  ? {"pollen_name": argPollen} : null)
                .then(response => {
                    if (response && response.data && response.data.data) {
                        dispatch(getKPollenKalenderSuccess(response.data.data));
                    }
                    else {
                        dispatch(getKPollenKalenderFailure('Something went wrong'));
                    }
                })
                .catch(error => {
                    dispatch(getKPollenKalenderFailure(error.message));
                })
        };
        // eslint-disable-next-line no-unreachable
    } catch (error) { console.log('202102231105', error) }
};

const getKPollenKalenderSuccess = data => ({
    type: actionTypes.K_GET_POLLENKALENDER_SUCCESS,
    payload: data
});

const getKPollenKalenderStarted = () => ({
    type: actionTypes.K_GET_POLLENKALENDER_STARTED,
});

const getKPollenKalenderFailure = error => ({
    type: actionTypes.K_GET_POLLENKALENDER_FAILURE,
    payload: {
        error
    }
});

export const getCacheKPollenKalenderData = () => ({
    type: actionTypes.K_GET_POLLENKALENDER_CACHED,
});
import React, { memo, Fragment } from 'react';
import { Container, Grid, Typography, Link, Tooltip } from '@material-ui/core';
import { Link as SiteLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import WetterNetWhite from '../../../../Assets/Desktop/Common/wetter.net-logo-w.png';
import IconFacebook from '../../../../Assets/Desktop/Common/facebook.png';
import IconInstagram from '../../../../Assets/Desktop/Common/instagram.png';
import IconYoutube from '../../../../Assets/Desktop/Common/youtube.png';
import IconTwitter from '../../../../Assets/Desktop/Common/twitter.png';
import IconContact from '../../../../Assets/Desktop/Common/contact.png';
import cssModule from './Footer.module.css';

const useStyles = makeStyles((theme) => ({

    head1: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '2.5rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    head2: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '1.9rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    head3: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '1.5rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    subHeadDark: {
        textTransform: "none",
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '500',
        fontSize: '1.2rem'
    },
    subHeadDarkLink: {
        textTransform: "none",
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '500',
        fontSize: '1.2rem',
        color: 'rgba(0, 62, 133, .8)'
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '15px',
        lineHeight: '1.2',
    },
    dataLast: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '0px',
        lineHeight: '1.2',
    },
    dataBlue: {
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    dataLink: {
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '400',
    },
    hr: {
        display: 'block',
        height: '1px',
        border: '0',
        borderTop: '1px solid #ccc',
        margin: '2em 0',
        padding: '0px'
    },
    StoreContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    StoreItemContainer: {
        textAlign: 'center',
        paddingLeft:'5px',
        paddingRight:'5px',
        marginTop: '1rem'
    },
    sectRight: {
        textAlign: 'right'
    },
    footerLink: {
        textTransform: "none",
        color: '#fff',
        fontSize: '1.3rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        paddingRight: '.6rem',
        paddingLeft: '.6rem',
        transition: '.2s',
        "&:nth-of-type(1)": {
            borderRight: '1px solid rgba(255, 255, 255, .5)',
            paddingRight: '.6rem',
            paddingLeft: '0px',
        },
        "&:nth-of-type(3)": {
            borderLeft: '1px solid rgba(255, 255, 255, .5)',
            paddingRight: '0rem',
            paddingLeft: '.6rem',
        },
        "&:hover": {
            color: "rgba(255,255,255, .8)"
        }
    },
    qMetLink: {
        color: "rgba(255,255,255, 1)",
        cursor: 'pointer',
        "&:hover": {
            color: "rgba(255,255,255, .8)",
        }
    },
    copyRightText: {
        color: "rgba(255,255,255, 1)",
        fontWeight: '300',
        fontSize: '.9rem'
    },
    socialParent: {
        display: 'inlineBlock !important',
        width: '210px',
        height: '0px',
        boxSizing: 'contentBox',
        borderLeft: 'calc(100px / 2) solid transparent',
        borderBottom: '45px solid #fff',
        position: 'absolute',
        bottom: '61px',
        right: '0px'
    },
    socialIconContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        height: '45px'
    },
    socialIcons: {
        height: '25px',
        paddingLeft: '5px',
        paddingRight: '5px'
    },
    storeImage:{
        height:'40px'
    }
}));


const Footer = memo((props) => {

    const classes = useStyles();

    return (
        <Fragment>

            <Container maxWidth="lg" >

                <Grid container spacing={2}>
                    <Grid item sm={9}>
                        <div className={cssModule.FooterTParent}>
                            <Typography className={classes.head2} variant="h3" component="h3" gutterBottom>
                                wetter.net - das Wetter auf den Punkt gebracht!
                            </Typography>
                            <Typography className={classes.data} variant="body1" component="p">
                                wetter.net bietet Ihnen alles Wissenswerte rund um Wetter und Klima. Auf unseren Seiten finden Sie weltweite
                                Stadt- und Ortsprognosen für die nächsten 14 Tage in 1-stündiger  zeitlicher Auflösung. Wettervorhersagen,
                                Niederschlagsradar, Wetterwarnungen, Deutschlandwetter und Wetter-News informieren über die aktuelle Entwicklung
                                sowie die kommenden Stunden und Tage. Mit Hilfe der Profikarten können Sie tiefere meteorologische Einblicke
                                gewinnen - ideal beispielsweise für alle Hobbymeteorologen. Erklärende Informationen zu vielen Wetterphänomenen
                                finden Sie in unserem Wetter-Lexikon. Für Allergiker und wetterfühlige Personen halten wir den aktuellen
                                Pollenflug und das Biowetter bereit.
                            </Typography>
                            <Typography className={classes.data} variant="body1" component="p">
                                In redaktionellen Beiträgen informieren Sie unsere Meteorologen rund um die Uhr über alles Wichtige und Interessante
                                aus der Wetter- und Klimawelt. Auch Bewegtbild darf natürlich nicht fehlen, daher erhalten Sie im Bereich Wetter-TV
                                immer die aktuellsten Wetterfilme sowie spannende Beiträge aus Europa und der gesamten Welt.
                            </Typography>
                            <Typography className={classes.dataLast} variant="body1" component="p">
                                Unser Auftritt ist nicht nur für die Desktop-Anwendung, sondern auch für die mobile Anwendung optimiert. Wer es gern
                                kompakter und ohne viel Schnickschnack mag, für den gibt´s unsere neu entwickelte Android-App.
                            </Typography>
                            <div className={classes.StoreContainer}>
                                <span className={classes.StoreItemContainer}>
                                    <Link href="https://play.google.com/store/apps/details?id=com.qmet.wetternet" target="_blank" rel="noreferrer">
                                        <img src="https://www.wetter.net/images/googleplay.png" alt="playstore" className={classes.storeImage}/>
                                        <Typography className={classes.dataLast} variant="body1" component="p">Android app</Typography>
                                    </Link>
                                </span>
                                <span className={classes.StoreItemContainer}>
                                    <Link href="https://apps.apple.com/de/app/wetter-net-weather-app/id438287289 " target="_blank" rel="noreferrer">
                                        <img src="https://linkmaker.itunes.apple.com/assets/shared/badges/de-de/appstore-lrg.svg" alt="playstore" className={classes.storeImage}/>
                                        <Typography className={classes.dataLast} variant="body1" component="p">iPhone</Typography>
                                    </Link>
                                </span>
                                <span className={classes.StoreItemContainer}>
                                    <Link href="https://itunes.apple.com/de/app/wetter-net-for-ipad/id463420637?mt=8" target="_blank" rel="noreferrer">
                                        <img src="https://linkmaker.itunes.apple.com/assets/shared/badges/de-de/appstore-lrg.svg" alt="playstore" className={classes.storeImage}/>
                                        <Typography className={classes.dataLast} variant="body1" component="p">iPad</Typography>
                                    </Link>
                                </span>
                            </div>
                        </div>
                    </Grid>

                    <Grid item sm={3}>
                    </Grid>
                </Grid>

            </Container>
            <div className={cssModule.FooterBParent}>
                <div className={classes.socialParent}>
                    <div className={classes.socialIconContainer}>3
                        <Tooltip title="Youtube" arrow>
                            <Link target="_blank" rel="noopener" href="https://www.youtube.com/user/Wetternet">
                                <img className={classes.socialIcons} src={IconYoutube} alt="youtube" />
                            </Link>
                        </Tooltip>
                        <Tooltip title="Facebook" arrow>
                            <Link target="_blank" rel="noopener" href="https://www.facebook.com/wetter.net/">
                                <img className={classes.socialIcons} src={IconFacebook} alt="facebook" />
                            </Link>
                        </Tooltip>
                        <Tooltip title="Instagram" arrow>
                            <Link target="_blank" rel="noopener" href="https://www.instagram.com/wetternet/">
                                <img className={classes.socialIcons} src={IconInstagram} alt="instagram" />
                            </Link>
                        </Tooltip>
                        <Tooltip title="Twitter" arrow>
                            <Link target="_blank" rel="noopener" href="https://twitter.com/wetternet">
                                <img className={classes.socialIcons} src={IconTwitter} alt="twitter" />
                            </Link>
                        </Tooltip>
                        <Tooltip title="Kontakt" arrow>
                            <SiteLink to="/kontakt">
                                <img className={classes.socialIcons} src={IconContact} alt="contact" />
                            </SiteLink>
                        </Tooltip>
                    </div>
                </div>
                <Container maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item sm={6}>
                            <span>
                                <SiteLink to="/agb" className={classes.footerLink}>AGB</SiteLink><SiteLink to="/impressum" className={classes.footerLink}>Impressum</SiteLink><SiteLink to="/datenschutz" className={classes.footerLink}>Datenschutzerklärung</SiteLink>
                            </span>
                        </Grid>
                        <Grid item sm={6} className={classes.sectRight}>
                            <span className={classes.copyRightText}>
                                &#169; Wetter.net | Ein Service der <Link target="_blank" rel="noopener" href="https://qmet.de/" className={classes.qMetLink}>Q.met GmbH</Link> &nbsp; <img style={{ height: '20px' }} src={WetterNetWhite} alt="wetter.net logo" />
                            </span>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </Fragment>
    );
});

export default Footer;
import React, {memo} from 'react';
import { Typography, Breadcrumbs, Link, Grid } from '@material-ui/core';
import Youtube from 'react-youtube';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import cssModule from './WetterTVSingleComponent.module.css';
import PlayButton from '../../../../Assets/Desktop/Common/play.svg';

function handleClick(event) {
    try{
    event.preventDefault();
    } catch (error) { console.log('202103101712', error) }
}

const useStyles = makeStyles((theme) => ({

    date: {
        color: 'rgba(84, 84, 84, .8)',
        fontSize: '1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    head: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '3rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.2rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    interessant: {
        color: 'rgba(34, 34, 34, .8)',
        fontSize: '1.5rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '1rem',
    }
}));

function slugify(str) {
    try{
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ãàáäâáº½èéëêìíïîõòóöôùúüûñç·/_,:;";
    var to = "aaaaaeeeeeiiiiooooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
    } catch (error) { console.log('202103101714', error) }
};

const getVideoID = (argLink) => {
    try {
        if (argLink != null) {
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            var match = argLink.match(regExp);
            return (match && match[7].length === 11) ? match[7] : false;
        }
    } catch (error) { console.log('202102191535', error) }
}

const shortVideoName = (argText, argLength) => {
    try {
        return (argText.substring(0, argLength) + "...");
    } catch (error) { console.log('202102191536', error) }
}

const WetterTVSingleComponent = memo((props)=>{

    const classes = useStyles();
    const history = useHistory();
    let componentOrganizer = null;

    const opts = {
        height: '496',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            controls: 1,
        },
    };

    const videOnReady = (event) => {
        try{
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
        } catch (error) { console.log('202103101715', error) }
    }

    try{
    if (props.componentData.kWettertv && props.componentData.kWettertv.length > 0){
        componentOrganizer = props.componentData.kWettertv.map((video, index) => {
            if(index < 4){
            return(
                <Grid item xs={6} key={video.ID}>
                    <Link className={cssModule.VideoLink} onClick={() => { history.push(`/wetter-tv/video/${video.ID ? video.ID.toString() + "-" : null}${slugify(video.Title.toString())}`) }}>
                        <div className={cssModule.WetterTVItem}>
                            <div className={cssModule.WetterImage} style={{ backgroundImage: `url(https://img.youtube.com/vi/${getVideoID(video.URL ? video.URL : null)}/hqdefault.jpg)` }}>
                                <img className={cssModule.PlayButton} src={PlayButton} alt="play" />
                            </div>
                            <div className={cssModule.WetterData}>
                                <Typography variant="body1" className={cssModule.WetterDataDate}>21. Januar 2021 | Unwetter | Hochwasser</Typography>
                                {video.Title ? (<Typography variant="subtitle1" className={cssModule.WetterDataTitle}>{shortVideoName(video.Title.toString(), 45)}</Typography>)
                                    : (<Typography variant="subtitle1" className={cssModule.WetterDataTitle}>Titel nicht verfügbar</Typography>)}
                                {video.Beschreibung ? (<Typography variant="body1" className={cssModule.WetterDataBody}>{shortVideoName(video.Beschreibung.toString(), 120)}</Typography>)
                                    : (<Typography variant="body1" className={cssModule.WetterDataBody}>Beschreibung nicht verfügbar</Typography>)}
                            </div>
                        </div>
                    </Link>
                </Grid>
            );
            }else{return null}
        });
    }
    } catch (error) { console.log('202103101716', error) }

    return(

        <React.Fragment>
        <div className={cssModule.WetterTVKachelParent}>
            <div className={cssModule.HeadSection}>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    <Link className={cssModule.BreadcrubsLink} color="inherit" href="#" onClick={handleClick}>Home</Link>
                    <Link className={cssModule.BreadcrubsNonLink} color="inherit" href="#" onClick={handleClick}>Aktuell</Link>
                    <Link className={cssModule.BreadcrubsLink} color="inherit" href="#" onClick={handleClick}>Wetter TV</Link>
                    <Link className={cssModule.BreadcrubsNonLink} color="inherit" href="#" onClick={handleClick}>{
                        props.componentData.kWettertv[0].Title.toString()
                    }</Link>
                </Breadcrumbs>
            </div>

            <div className={cssModule.WetterTVContainer}>
                <Youtube videoId="IJ2YSWOLmgA" opts={opts} onReady={videOnReady} allow="autoplay"/>
            </div>

            <div className={cssModule.VideoDetailsSection}>
                <Typography className={classes.date} variant="subtitle2" component="p">Wiesbaden | 19.02.2021 | 11:59 | Franziska Polak</Typography>
                <Typography className={classes.head} variant="h1" component="h1" gutterBottom>{props.componentData.kWettertv[0].Title.toString()}</Typography>
                <Typography className={classes.data} variant="body1" component="p">{props.componentData.kWettertv[0].Beschreibung.toString()}</Typography>
            </div>
                <div className={cssModule.AuchInteressant}>
                    <div className={cssModule.WetterTVContainer}>
                        <Typography className={classes.interessant} variant ="h3" component="h3">Auch interessant</Typography>
                        <div className={cssModule.WetterTVRow}>
                            <Grid container spacing={2}>
                                {componentOrganizer}
                            </Grid>
                        </div>
                    </div>
                </div>

        </div>

        </React.Fragment>
    );

});

export default WetterTVSingleComponent;
import React, { memo } from 'react';
import {
    Typography,
    Breadcrumbs,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@material-ui/core';
import { Link as SiteLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import cssModule from './Wetterlegende.module.css';

import DayWetter1 from '../../../../Assets/Desktop/WeatherIcons/Day/wetter_id_1_BG.png';
import DayWetter2 from '../../../../Assets/Desktop/WeatherIcons/Day/wetter_id_2_BG.png';
import DayWetter3 from '../../../../Assets/Desktop/WeatherIcons/Day/wetter_id_3_BG.png';
import DayWetter4 from '../../../../Assets/Desktop/WeatherIcons/Day/wetter_id_4_BG.png';
import DayWetter5 from '../../../../Assets/Desktop/WeatherIcons/Day/wetter_id_5_BG.png';
import DayWetter6 from '../../../../Assets/Desktop/WeatherIcons/Day/wetter_id_6_BG.png';
import DayWetter7 from '../../../../Assets/Desktop/WeatherIcons/Day/wetter_id_7_BG.png';
import DayWetter8 from '../../../../Assets/Desktop/WeatherIcons/Day/wetter_id_8_BG.png';
import DayWetter9 from '../../../../Assets/Desktop/WeatherIcons/Day/wetter_id_9_BG.png';
import DayWetter10 from '../../../../Assets/Desktop/WeatherIcons/Day/wetter_id_10_BG.png';
import DayWetter11 from '../../../../Assets/Desktop/WeatherIcons/Day/wetter_id_11_BG.png';
import DayWetter12 from '../../../../Assets/Desktop/WeatherIcons/Day/wetter_id_12_BG.png';
import DayWetter13 from '../../../../Assets/Desktop/WeatherIcons/Day/wetter_id_13_BG.png';
import DayWetter14 from '../../../../Assets/Desktop/WeatherIcons/Day/wetter_id_14_BG.png';

import NightWetter1 from '../../../../Assets/Desktop/WeatherIcons/Night/wetter_id_1_night_BG.png';
import NightWetter2 from '../../../../Assets/Desktop/WeatherIcons/Night/wetter_id_2_night_BG.png';
import NightWetter3 from '../../../../Assets/Desktop/WeatherIcons/Night/wetter_id_3_night_BG.png';
import NightWetter4 from '../../../../Assets/Desktop/WeatherIcons/Night/wetter_id_4_night_BG.png';
import NightWetter5 from '../../../../Assets/Desktop/WeatherIcons/Night/wetter_id_5_night_BG.png';
import NightWetter6 from '../../../../Assets/Desktop/WeatherIcons/Night/wetter_id_6_night_BG.png';
import NightWetter7 from '../../../../Assets/Desktop/WeatherIcons/Night/wetter_id_7_night_BG.png';
import NightWetter8 from '../../../../Assets/Desktop/WeatherIcons/Night/wetter_id_8_night_BG.png';
import NightWetter9 from '../../../../Assets/Desktop/WeatherIcons/Night/wetter_id_9_night_BG.png';
import NightWetter10 from '../../../../Assets/Desktop/WeatherIcons/Night/wetter_id_10_night_BG.png';
import NightWetter11 from '../../../../Assets/Desktop/WeatherIcons/Night/wetter_id_11_night_BG.png';
import NightWetter12 from '../../../../Assets/Desktop/WeatherIcons/Night/wetter_id_12_night_BG.png';
import NightWetter13 from '../../../../Assets/Desktop/WeatherIcons/Night/wetter_id_13_night_BG.png';
import NightWetter14 from '../../../../Assets/Desktop/WeatherIcons/Night/wetter_id_14_night_BG.png';

const useStyles = makeStyles((theme) => ({

    head: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '3rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    subHeadDark: {
        textTransform: "none",
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '500',
        fontSize: '1.2rem',
        marginTop: '0px',
        marginBottom: '0px'
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '15px'
    },
    table: {
        minWidth: 650,
        '& tbody tr td:nth-of-type(1)': {
            backgroundColor: '#F5F5F5'
        },
        '& tbody tr td:nth-of-type(3)': {
            backgroundColor: '#F5F5F5'
        },
        '& tbody tr td:nth-of-type(2)': {
            backgroundColor: 'rgba(252, 239, 196, .6)'
        }
        ,
        '& tbody tr td:nth-of-type(4)': {
            backgroundColor: 'rgba(252, 239, 196, .6)'
        },
        '& tbody tr td': {
            borderBottom: '1px solid #EEEEEE',
            padding: '14px'
        },
        '& thead tr th': {
            borderBottom: '0px solid #EEEEEE'
        },
        '& tbody tr:nth-of-type(1) td:nth-of-type(1)': {
            borderTopLeftRadius: '10px'
        },
        '& tbody tr:nth-of-type(1) td:nth-of-type(4)': {
            borderTopRightRadius: '10px'
        },
        '& tbody tr:nth-of-type(14) td:nth-of-type(1)': {
            borderBottomLeftRadius: '10px'
        },
        '& tbody tr:nth-of-type(14) td:nth-of-type(4)': {
            borderBottomRightRadius: '10px'
        },
        '& tbody tr:nth-of-type(14) td': {
            borderBottom: '0px solid #EEEEEE'
        }
    },
    wetterIcons: {
        height: '60px',
        width: '60px'
    }
}));

const WetterLegende = memo((props) => {

    const classes = useStyles();

    function handleClick(event) {
        try {
            event.preventDefault();
        } catch (error) { console.log('202103101712', error) }
    }

    let componentOrganizer = null;

    try {

        componentOrganizer = (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography className={classes.head} variant="h1" component="h1" gutterBottom>Legende der Wettersymbole</Typography>
                        <TableContainer>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">
                                            <p className={classes.subHeadDark}>
                                                Tag
                                            </p>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.subHeadDark}>
                                                Beschreibung
                                            </p>
                                        </TableCell>
                                        <TableCell align="center">
                                            <p className={classes.subHeadDark}>
                                                Nacht
                                            </p>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.subHeadDark}>
                                                Beschreibung
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key="wetter1">
                                        <TableCell align="center">
                                            <Tooltip title="sonnig">
                                                <img src={DayWetter1} alt="sonnig" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                sonnig
                                            </p>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="klar">
                                                <img src={NightWetter1} alt="klar" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                klar
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="wetter2">
                                        <TableCell align="center">
                                            <Tooltip title="leicht bewölkt / heiter">
                                                <img src={DayWetter2} alt="heiter" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                leicht bewölkt / heiter
                                            </p>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="leicht bewölkt">
                                                <img src={NightWetter2} alt="leicht bewölkt" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                leicht bewölkt
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="wetter3">
                                        <TableCell align="center">
                                            <Tooltip title="wolkig">
                                                <img src={DayWetter3} alt="wolkig" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                wolkig
                                            </p>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="wolkig">
                                                <img src={NightWetter3} alt="wolkig" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                wolkig
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="wetter4">
                                        <TableCell align="center">
                                            <Tooltip title="stark bewölkt">
                                                <img src={DayWetter4} alt="stark bewölkt" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                stark bewölkt
                                            </p>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="stark bewölkt">
                                                <img src={NightWetter4} alt="stark bewölkt" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                stark bewölkt
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="wetter5">
                                        <TableCell align="center">
                                            <Tooltip title="bedeckt">
                                                <img src={DayWetter5} alt="bedeckt" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                bedeckt
                                            </p>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="bedeckt">
                                                <img src={NightWetter5} alt="bedeckt" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                bedeckt
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="wetter6">
                                        <TableCell align="center">
                                            <Tooltip title="Regenschauer">
                                                <img src={DayWetter6} alt="Regenschauer" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Regenschauer
                                            </p>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Regenschauer">
                                                <img src={NightWetter6} alt="Regenschauer" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Regenschauer
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="wetter7">
                                        <TableCell align="center">
                                            <Tooltip title="Regen">
                                                <img src={DayWetter7} alt="Regen" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Regen
                                            </p>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Regen">
                                                <img src={NightWetter7} alt="Regen" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Regen
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="wetter8">
                                        <TableCell align="center">
                                            <Tooltip title="Gewitter">
                                                <img src={DayWetter8} alt="Gewitter" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Gewitter
                                            </p>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Gewitter">
                                                <img src={NightWetter8} alt="Gewitter" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Gewitter
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="wetter9">
                                        <TableCell align="center">
                                            <Tooltip title="Schneeschauer">
                                                <img src={DayWetter9} alt="Schneeschauer" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Schneeschauer
                                            </p>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Schneeschauer">
                                                <img src={NightWetter9} alt="Schneeschauer" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Schneeschauer
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="wetter10">
                                        <TableCell align="center">
                                            <Tooltip title="Schneefall">
                                                <img src={DayWetter10} alt="Schneefall" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Schneefall
                                            </p>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Schneefall">
                                                <img src={NightWetter10} alt="Schneefall" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Schneefall
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="wetter11">
                                        <TableCell align="center">
                                            <Tooltip title="Schneeregen">
                                                <img src={DayWetter11} alt="Schneeregen" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Schneeregen
                                            </p>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Schneeregen">
                                                <img src={NightWetter11} alt="Schneeregen" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Schneeregen
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="wetter12">
                                        <TableCell align="center">
                                            <Tooltip title="Nebel">
                                                <img src={DayWetter12} alt="Nebel" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Nebel
                                            </p>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Nebel">
                                                <img src={NightWetter12} alt="Nebel" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Nebel
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="wetter13">
                                        <TableCell align="center">
                                            <Tooltip title="Schneeregenschauer">
                                                <img src={DayWetter13} alt="Schneeregenschauer" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Schneeregenschauer
                                            </p>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Schneeregenschauer">
                                                <img src={NightWetter13} alt="Schneeregenschauer" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Schneeregenschauer
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="wetter14">
                                        <TableCell align="center">
                                            <Tooltip title="Sprühregen">
                                                <img src={DayWetter14} alt="Sprühregen" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Sprühregen
                                            </p>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Tooltip title="Sprühregen">
                                                <img src={NightWetter14} alt="Sprühregen" className={classes.wetterIcons} />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">
                                            <p className={classes.data}>
                                                Sprühregen
                                            </p>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </React.Fragment>
        );

    } catch (error) {
        console.log('202107281519', error);
    }

    return (
        <React.Fragment>
            <div className={cssModule.WetterSymbolLegendeParent}>
                <div className={cssModule.HeadSection}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <SiteLink className={cssModule.BreadcrubsLink} color="inherit" to="/" onClick={handleClick}>Home</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Allgemein</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Wetterlegende</SiteLink>
                    </Breadcrumbs>
                </div>
                <div className={cssModule.DataSection}>
                    {componentOrganizer}
                </div>
            </div>
        </React.Fragment>
    );
});

export default WetterLegende;
import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getKWetterLexikon = (argLetter, argToken) => {

    try {
        return dispatch => {
            dispatch(getKWetterLexikonStarted());
            const axiosInstance = axios.create({
                baseURL: 'http://165.227.162.31:8000/v2/',
                headers: { 'Authorization': `Bearer ${argToken}` }
            });

            axiosInstance.get(`home/wetterlexikon?selectby=${argLetter}`)
                .then(response => {
                    if (response && response.data && response.data.data) {
                        dispatch(getKWetterLexikonSuccess(response.data.data));
                    }
                    else {
                        dispatch(getKWetterLexikonFailure('Something went wrong'));
                    }
                })
                .catch(error => {
                    dispatch(getKWetterLexikonFailure(error.message));
                })
        };
        // eslint-disable-next-line no-unreachable
    } catch (error) { console.log('202108251533', error) }
};

const getKWetterLexikonSuccess = data => ({
    type: actionTypes.K_GET_WETTERLEXIKON_SUCCESS,
    payload: data
});

const getKWetterLexikonStarted = () => ({
    type: actionTypes.K_GET_WETTERLEXIKON_STARTED,
});

const getKWetterLexikonFailure = error => ({
    type: actionTypes.K_GET_WETTERLEXIKON_FAILURE,
    payload: {
        error
    }
});

export const getCacheKWetterLexikonData = () => ({
    type: actionTypes.K_GET_WETTERLEXIKON_CACHED,
});
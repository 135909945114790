import React, { memo } from 'react';
import { Typography, Button, Breadcrumbs, Link, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import cssModule from './WetterTVWeiterComponent.module.css';

import PlayButton from '../../../../Assets/Desktop/Common/play.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    button: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .6)',
        transition: '.2s ease-in',
        fontSize: '1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '400',
        '&:hover': {
            color: 'rgba(0, 62, 133, 1)',
            background: 'transparent',
        },
    },
}));

function handleClick(event) {
    try{
    event.preventDefault();
    } catch (error) { console.log('202103101717', error) }
}

const getVideoID = (argLink) => {
    try {
        if (argLink != null) {
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            var match = argLink.match(regExp);
            return (match && match[7].length === 11) ? match[7] : false;
        }
    } catch (error) { console.log('202102191535', error) }
}

const shortVideoName = (argText, argLength) => {
    try {
        return (argText.substring(0, argLength) + "...");
    } catch (error) { console.log('202102191536', error) }
}

function slugify(str) {
    try{
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ãàáäâáº½èéëêìíïîõòóöôùúüûñç·/_,:;";
    var to = "aaaaaeeeeeiiiiooooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
    } catch (error) { console.log('202103101718', error) }
};

const WetterTVWeiterComponent = memo((props) => {

    const classes = useStyles();
    const history = useHistory();
    let componentOrganizer = null;

    try{
    if (props.componentData.kWettertv && props.componentData.kWettertv.length > 0) {
        try {
            componentOrganizer = props.componentData.kWettertv.map((video) => {
                return (
                    <Grid item xs={6} key={video.ID}>
                        <Link className={cssModule.VideoLink} onClick={() => { history.push(`/wetter-tv/video/${video.ID ? video.ID.toString() + "-" : null}${slugify(video.Title.toString())}`) }}>
                            <div className={cssModule.WetterTVItem}>
                                <div className={cssModule.WetterImage} style={{ backgroundImage: `url(https://img.youtube.com/vi/${getVideoID(video.URL ? video.URL : null)}/hqdefault.jpg)` }}>
                                    <img className={cssModule.PlayButton} src={PlayButton} alt="play" />
                                </div>
                                <div className={cssModule.WetterData}>
                                    <Typography variant="body1" className={cssModule.WetterDataDate}>21. Januar 2021 | Unwetter | Hochwasser</Typography>
                                    {video.Title ? (<Typography variant="subtitle1" className={cssModule.WetterDataTitle}>{shortVideoName(video.Title.toString(), 45)}</Typography>)
                                        : (<Typography variant="subtitle1" className={cssModule.WetterDataTitle}>Titel nicht verfügbar</Typography>)}
                                    {video.Beschreibung ? (<Typography variant="body1" className={cssModule.WetterDataBody}>{shortVideoName(video.Beschreibung.toString(), 120)}</Typography>)
                                        : (<Typography variant="body1" className={cssModule.WetterDataBody}>Beschreibung nicht verfügbar</Typography>)}
                                </div>
                            </div>
                        </Link>
                    </Grid>
                );
            });
        } catch (error) { console.log('202102251120', error) }
    }
    } catch (error) { console.log('202103101719', error) }

    return (

        <div className={cssModule.WetterTVKachelParent}>
            <div className={cssModule.HeadSection}>
                <Typography variant="subtitle1" component="span" className={cssModule.ComponentLabel}>Wetter TV</Typography>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    <Link className={cssModule.BreadcrubsLink} color="inherit" href="#" onClick={handleClick}>Home</Link>
                    <Link className={cssModule.BreadcrubsNonLink} color="inherit" href="#" onClick={handleClick}>Aktuell</Link>
                    <Link className={cssModule.BreadcrubsNonLink} color="inherit" href="#" onClick={handleClick}>Wetter TV</Link>
                </Breadcrumbs>
            </div>

            <div className={cssModule.WetterTVContainer}>
                <div className={cssModule.WetterTVRow}>
                    <Grid container spacing={2}>
                        {componentOrganizer}
                    </Grid>
                </div>
                <div className={cssModule.MehrNews} style={{ textAlign: 'center' }}>
                    <Button variant="outlined" size="small" className={classes.button} onClick={props.getMoreVideo}>Weitere Videos laden</Button>
                </div>
            </div>


        </div>

    );

});

export default WetterTVWeiterComponent;
import React, { Fragment, Suspense, Component } from 'react';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Route, Switch } from "react-router-dom";
import { Container, Grid } from '@material-ui/core';

import * as actionCreators from '../../../store/actions/index';
import SometingWentWrong from '../../Components/SomethingWentWrong/SomethingWentWrong';
import Header from '../../Components/Layout/Header/Header';
import Home from '../Home/Home';
import AnziegeKachel from '../AnziegeKachel/AnziegeKachel';
import PageNotFound from '../../Components/PageNotFound/PageNotFound';
import WetterTVWeiter from '../WetterTVKachel/WetterTVWeiter/WetterTVWeiter';
import WetterTVSingle from '../WetterTVKachel/WetterTVSingle/WetterTVSingle';
/*import WetterNewsWeiter from '../WetterNewsKachel/WetterNewsWeiter/WetterNewsWeiter';
import WetterNewsSingle from '../WetterNewsKachel/WetterNewsSingle/WetterNewsSingle';*/
import LocationSearch from './LocationSearch/LocationSearch';
import LocationSearchResult from '../../Components/Layout/LocationSearchResult/LocationSearchResult';
import WetterLegende from '../../Components/InnerPages/Wetterlegende/Wetterlegende';
import WetterNetMobil from '../InnerPages/wetterNetMobil/wetterNetMobil';
import Niederschlagsradar from '../../Components/InnerPages/Niederschlagsradar/Niederschlagsradar';
import Wolkenfilm from '../../Components/InnerPages/Wolkenfilm/Wolkenfilm';
import Stromungsfilm  from '../../Components/InnerPages/Stromungsfilm/Stromungsfilm';
import Footer from '../../Components/Layout/Footer/Footer';
import Grosswetterlagenkarte from '../InnerPages/Grosswetterlagenkarte/Grosswetterlagenkarte';
import Gartenwetter from '../InnerPages/Gartenwetter/Gartenwetter';
import WetterLexikon from '../InnerPages/WetterLexikon/WetterLexikon';
import WetterLexikonDetails from '../../Components/InnerPages/WetterLexikon/WetterLexikonDetails/WetterLexikonDetails';
import PollenKalender from '../InnerPages/PollenKalender/PollenKalender';
import BiowetterInnerPage from '../InnerPages/BiowetterInnerPage/BiowetterInnerPage';
import PollenflugInnerPage from '../InnerPages/PollenflugInnerPage/PollenflugInnerPage';
import WetterNewsInnerPage from '../InnerPages/NewsInnerPage/NewsInnerPage';
import WetterNewsSinglePage from '../InnerPages/NewsSinglePage/NewsSinglePage';
/*import WetterTVWeiter from '../WetterTVKachel/WetterTVWeiter/WetterTVWeiter';
import WetterTVSingle from '../WetterTVKachel/WetterTVSingle/WetterTVSingle';*/
import WetterNewsWeiter from '../WetterNewsKachel/WetterNewsWeiter/WetterNewsWeiter';
import WetterNewsSingle from '../WetterNewsKachel/WetterNewsSingle/WetterNewsSingle';
import VideoInnerPage from '../../Containers/InnerPages/VideoInnerPage/VideoInnerPage';
import VideoSingleInnerPage from '../../Containers/InnerPages/VideoSingleInnerPage/VideoSingleInnerPage';

import cssModule from './Navigation.module.css';

const isTokenValid = (argToken) => {
    try{
        let timeNow = new Date();
        //console.log('token++++++++', argToken,timeNow);
    }catch(error){console.log('202103181542', error)}
}


class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tokenFetchFailed: false,
            locationAccess: "allowed",
            locationCached: false,
            hasError: false,
            searchLocOpen: false,
        }
    }

    handleClickOpen = () => {
        try {
        this.setState({ searchLocOpen: true });
        } catch (error) { console.log('202103101255', error) }
    };

    handleClose = () => {
        this.setState({ searchLocOpen: false });
    };

    componentDidMount = async () => {
        try {
            this.props.handleGetkToken();
            //this.getLocation();
        } catch (error) { console.log('202103101256', error) }
    }

    componentDidUpdate = (prevProps) => {

        try{
            if (isTokenValid(this.props.kToken)) { }
        if (this.props.kToken.kToken !== null && prevProps.kToken !== this.props.kToken && this.props.kToken.loading === false) {
            this.getLocation();
        }

        if (this.props.kToken !== prevProps.kToken && this.props.kToken.error !== null) {
            this.setError(true);
        } else if (this.props.kToken !== prevProps.kToken && this.props.kToken.error === null){
            this.setError(false);
        }
        } catch (error) { console.log('202103101257', error)}
    }

    getLocation = () => {

        try {

        if (this.props.kLocation.kLocation && this.props.kLocation.kLocation.ID_Stadt) {
        }
        else {
            this.getUserLocation();
        }

        } catch (error) { console.log('202103101258', error) }
    }

    getUserLocation = async () => {
        try {
            let options = {
                enableHighAccuracy: true,
                maximumAge: 0
            };
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.gotLocation, this.gotError, options);
            } else {

            }
        } catch (error) { console.log('202103101259', error) }
    }

    gotError = (error) => {
        try {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    this.setState({ locationAccess: "Standortzugriff verweigert" });
                    this.handleClickOpen();
                    break;
                case error.POSITION_UNAVAILABLE:
                    this.setState({ locationAccess: "Standort nicht verfügbar" });
                    this.handleClickOpen();
                    break;
                case error.TIMEOUT:
                    this.setState({ locationAccess: "Zeitüberschreitung beim Abrufen des Standorts" });
                    this.handleClickOpen();
                    break;
                case error.UNKNOWN_ERROR:
                    this.setState({ locationAccess: "unbekannter Fehler" });
                    this.handleClickOpen();
                    break;
                default:
                    this.setState({ locationAccess: "unbekannter Fehler" });
                    this.handleClickOpen();
            }
        } catch (error) { console.log('202103101400', error) }
    }

    gotLocation = async (argPosition) => {
        try {
            let coords = argPosition.coords;
            this.setState({ locationAccess: "allowed" });
            this.props.onGetkBrowserLocation(this.props.kToken.kToken, coords.latitude, coords.longitude);
        } catch (error) { console.log('202103101401', error) }
    }

    tokenFetchErrorReset = () => {
        try {
            this.setState({ hasError: false });
            /*this.getToken();*/
        } catch (error) { console.log('202103101402', error) }
    }

    setLocation = (argLocation) => {
        try{
        if (argLocation) {
            //this.props.onStoreLocation(argLocation);
            //this.props.onAddLocation(argLocation);
            this.props.onkStoreCurrentLoc(argLocation);
            //this.setState({selectedLocation : argLocation});
            }
        } catch (error) { console.log('202103101403', error) }
    }

    setError = (argHasError) => {
        try{
            this.setState({ hasError: argHasError });
        } catch (error) { console.log('202103101404', error) }
    }

    render() {
        return (
            this.state.hasError ? <SometingWentWrong resetError={this.setError} />
                :
                (<Fragment>
                    <Header setParentLocation={this.setLocation} />
                    <main className={cssModule.Navigation}>
                        <Suspense fallback={
                            <div className={cssModule.LoadingParent}>
                                <div className={cssModule.Loader}></div>
                            </div>}>
                            <Container maxWidth="lg" >
                                <Grid container spacing={2}>
                                    <Grid container item sm={9}>
                                        {
                                            this.props.kToken ? this.props.kToken.kToken ? this.props.kToken.kToken.length > 0 ?
                                        <Switch>
                                            <Route path="/" exact render={(props) => { return (<Home {...props} deliverError={this.setError} />) }} />
                                            {/*<Route path="/wetter-tv" exact render={(props) => { return (<WetterTVWeiter {...props} deliverError={this.setError} />) }} />
                                            <Route path="/wetter-tv/video/:path" exact render={(props) => { return (<WetterTVSingle {...props} deliverError={this.setError} />) }} />
                                            <Route path="/wetter-news" exact render={(props) => { return (<WetterNewsWeiter {...props} deliverError={this.setError} />) }} />
                                            <Route path="/wetter-news/news/:path" exact render={(props) => { return (<WetterNewsSingle {...props} deliverError={this.setError} />) }} />*/}
                                            <Route path="/wetter/suchergebnisse/:name" exact render={(props) => { return (<LocationSearchResult {...props} deliverError={this.setError} userToken={this.props.kToken} setLocation={this.setLocation} />) }} />
                                            <Route path="/allgemein/wetterlegende" exact render={(props) => { return (<WetterLegende {...props} deliverError={this.setError} />) }} />
                                            <Route path="/mobile" exact render={(props) => { return (<WetterNetMobil {...props} deliverError={this.setError} />) }} />
                                            <Route path="/niederschlagsradar" exact render={(props) => { return (<Niederschlagsradar {...props} deliverError={this.setError} />) }} />
                                            <Route path="/wolkenfilm" exact render={(props) => { return (<Wolkenfilm {...props} deliverError={this.setError} />) }} />
                                            <Route path="/stromungsfilm" exact render={(props) => { return (<Stromungsfilm {...props} deliverError={this.setError} />) }} />
                                            <Route path="/grosswetterlagenkarte" exact render={(props) => { return (<Grosswetterlagenkarte {...props} deliverError={this.setError} />) }} />
                                            <Route path="/gartenwetter" exact render={(props) => { return (<Gartenwetter {...props} deliverError={this.setError} />) }} />
                                            <Route path="/wetterlexikon" exact render={(props) => { return (<WetterLexikon {...props} deliverError={this.setError} />) }} />
                                            <Route path="/wetterlexikon/eintrag" exact render={(props) => { return (<WetterLexikonDetails {...props} deliverError={this.setError} userToken={this.props.kToken}/>) }} />
                                            <Route path="/pollenkalender" exact render={(props) => { return (<PollenKalender {...props} deliverError={this.setError} />) }} />
                                            <Route path="/pollen" exact render={(props) => { return (<PollenflugInnerPage {...props} deliverError={this.setError} userToken={this.props.kToken} />) }} />
                                            <Route path="/biowetter" exact render={(props) => { return (<BiowetterInnerPage {...props} deliverError={this.setError} userToken={this.props.kToken} />) }} />
                                            <Route path="/wetter-news" exact render={(props) => { return (<WetterNewsInnerPage {...props} deliverError={this.setError} />) }} />
                                            <Route path="/wetter-news/:id" exact render={(props) => { return (<WetterNewsSinglePage {...props} deliverError={this.setError} />) }} />
                                            <Route path="/wetter-tv" exact render={(props) => { return (<VideoInnerPage {...props} deliverError={this.setError} />) }} />
                                            <Route path="/wetter-tv/video/:path" exact render={(props) => { return (<VideoSingleInnerPage {...props} deliverError={this.setError} />) }} />
                                            <Route component={PageNotFound} />
                                        </Switch>
                                            : null : null : null
                                        }
                                    </Grid>
                                    <Grid item sm={3}>
                                        <aside className={cssModule.Aside}>
                                            <div className={cssModule.AnziegeKachelParent}>
                                                <AnziegeKachel werbungType="V" />
                                                <AnziegeKachel werbungType="V" />
                                                <AnziegeKachel werbungType="V" />
                                            </div>
                                        </aside>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Suspense>
                    </main>
                    <footer>
                        <Footer/>
                    </footer>
                    <SweetAlert
                        show={this.state.tokenFetchFailed}
                        warning
                        confirmBtnText="Versuchen Sie es nochmal"
                        confirmBtnCssClass='dialog-confirm-btn'
                        title="Vorsicht!"
                        onConfirm={this.tokenFetchErrorReset}>
                        Token kann nicht abgerufen werden, bitte versuchen Sie es nach einiger Zeit erneut!
                    </SweetAlert>
                    <LocationSearch
                        isOpen={this.state.searchLocOpen}
                        closeDialog={this.handleClose}
                        setParentLocation={this.setLocation}
                        locationStatus={this.state.locationAccess} />
                </Fragment>)
        );
    }
}

const mapStateToProps = state => {
    return {
        kLocation: state.kLocationSubReducer,
        kToken: state.kTokenSubReducer,
        //kWetternews: state.kWetternewsSubReducer,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetkToken: () => { dispatch(actionCreators.getkToken()) },
        onkStoreCurrentLoc: (argLocationData) => { dispatch(actionCreators.kStoreCurrentLoc(argLocationData)) },
        onGetkBrowserLocation: (argToken, argLat, argLon) => { dispatch(actionCreators.getkBrowserLocation(argToken, argLat, argLon)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
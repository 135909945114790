import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actionCreators from '../../../../store/actions/index';
import GrosswetterlagenkarteComponent from '../../../Components/InnerPages/Grosswetterlagenkarte/Grosswetterlagenkarte';

class Grosswetterlagenkarte extends Component {

    componentDidMount = () => {
        try {
            if (this.props.kToken && this.props.kToken.kToken && this.props.kToken.kToken.length > 0) {
                this.props.handleGetKGrosswetterlagenkarte(this.props.kToken.kToken);
            }
        } catch (error) { console.log('2021082501141', error) }
    }

    render() {
        return (
            this.props.kGrosswetterlagenkarte ?
                this.props.kGrosswetterlagenkarte.kGrosswetterlagenkarte ?
                    this.props.kGrosswetterlagenkarte.kGrosswetterlagenkarte.length > 0 ?
                        <GrosswetterlagenkarteComponent deliverError={this.props.deliverError} componentData={this.props.kGrosswetterlagenkarte.kGrosswetterlagenkarte}/>
            : null : null : null
        );
    }
};

const mapStateToProps = state => {
    return {
        kGrosswetterlagenkarte: state.kGrosswetterlagenkarteSubReducer,
        kToken: state.kTokenSubReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetKGrosswetterlagenkarte: (token, page) => dispatch(actionCreators.getKGrosswetterlagenkarte(token, page)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Grosswetterlagenkarte);
import axios from 'axios';

//https://cors-anywhere.herokuapp.com/

const axiosInstance = axios.create({
    baseURL: 'https://apps.qmet.de/v2/',
    headers: {
        Authorization: '',
    }
});

export default axiosInstance;
import React from 'react';
import ReactGA from 'react-ga';

export const GAEvent = (argCategory="", argAction="", argLabel="", argValue=null, argNonInteraction=false) => {
    console.log('GAEvent',argCategory, argAction, argLabel, argValue, argNonInteraction);
    try{
        //ReactGA.initialize('UA-634165-14');
        if(argValue === null || argValue === undefined){
            ReactGA.event({
                category: argCategory,
                action: argAction,
                label: argLabel,
                nonInteraction: argNonInteraction
            });
        }else{
            ReactGA.event({
                category: argCategory,
                action: argAction,
                label: argLabel,
                nonInteraction: argNonInteraction,
                value: argValue
            });
        }
    }catch(error){}
}

export const GAPageView = (argPath="", argTrackerNames=[], argTitle="") => {
    console.log('GAPageView',argPath, argTrackerNames, argTitle);
    try{
        //ReactGA.initialize('UA-634165-14');
        ReactGA.set({ page: argPath });
        ReactGA.pageview(argPath, argTrackerNames, argTitle );
    }catch(error){}
}

export const GATiming = (argCategory="", argVariable="", argValue=-1, argLabel = "") => {
    console.log('GATiming',argCategory, argVariable, argLabel, argValue);
    try{
        //ReactGA.initialize('UA-634165-14');
        ReactGA.timing({
            category: argCategory,
            variable: argVariable,
            value: Math.round(argValue),
            label: argLabel
        });
    }catch(error){}
}
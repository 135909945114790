import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actionCreators from '../../../../store/actions/index';
import GartenwetterComponent from '../../../Components/InnerPages/Gartenwetter/Gartenwetter';

const thisMonth = new Date().getMonth() + 1;

class Gartenwetter extends Component {


    componentDidMount = () => {
        try {
            if (this.props.kToken && this.props.kToken.kToken && this.props.kToken.kToken.length > 0) {
                this.props.handleGetKGartenTipp(this.props.kToken.kToken, thisMonth);
                this.props.handleGetKGartenWetter(this.props.kToken.kToken, thisMonth);
            }
        } catch (error) { console.log('2021082501141', error) }
    }

    render() {
        return (
            <GartenwetterComponent deliverError={this.props.deliverError} gartenTippData = {this.props.kGartenTipp} gartenWetterData={this.props.kGartenWetter}/>
        );
    }
};

const mapStateToProps = state => {
    return {
        kGartenTipp: state.kGartenTippSubReducer,
        kGartenWetter: state.kGartenWetterSubReducer,
        kToken: state.kTokenSubReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetKGartenTipp: (token, month) => dispatch(actionCreators.getKGartenTipp(token, month)),
        handleGetKGartenWetter: (token, month) => dispatch(actionCreators.getKGartenWetter(token, month)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Gartenwetter);
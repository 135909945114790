import React, { memo, useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import cssModule from './LocationSearch.module.css';

const LocationSearch = memo((props) => {

    const history = useHistory();

    const [hookLocations, setLocations] = useState([]);
    const [hookInsertedText, setInsertedText] = useState("");

    const getLocations = (event, value, reason) => {
        try {
            setInsertedText(value);
            if (value && value.length > 2) {
                const axiosInstance = axios.create({
                    baseURL: 'http://165.227.162.31:8000/v4/',
                    headers: { 'Authorization': `Bearer ${props.userToken}` }
                });
                const timer = setTimeout(() => {
                    axiosInstance.get(`location/${value}`)
                        .then(response => {
                            if (response && response.data && response.data.data) {
                                if (response.data.data.length > 0) {
                                    setLocations(response.data.data);
                                } else {
                                    //this.setState({ newsNoData: true })
                                }
                            }
                        })
                        .catch(error => {
                        })
                }, 500);
                return () => {
                    clearTimeout(timer);
                };
            }
        } catch (error) {
            console.log('202103101251', error);
            /*this.setState({ newsDataError: true });*/
        }
    }

    const getSelectedLocation = (event, value, reason) => {
        try {
            props.setParentLocation(value);
            props.closeDialog();
        } catch (error) { console.log('202103101252', error) }
    }

    const setDefaultLocation = () => {
        try{
            getSelectedLocation(null, { name: "Berlin", lat: 13.5, lon: 52.52, ID_Stadt: 47, postal_code: "" }, null);
            props.closeDialog();
        }catch(error){
            console.log('202103101253', error);
        }
    }

    const loadResultPage = (argLocation) => {
        try{
        if (argLocation.length > 2) {
            history.push(`/wetter/suchergebnisse/${argLocation}`);
            props.closeDialog();
        }
        }catch(error){console.log('202103101254')}
    }

    return (
        <Dialog open={props.isOpen} onClose={setDefaultLocation} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Ort suchen</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Standort kann nicht gefunden werden, da: {props.locationStatus.toString()}. Bitte suchen Sie einen Ort, um fortzufahren
                </DialogContentText>
                <div className={cssModule.SearchParent}>
                    <div className={cssModule.SearchContainer}>
                        <Autocomplete
                            id="search-location"
                            freeSolo
                            noOptionsText={<p>test</p>}
                            options={hookLocations}
                            getOptionLabel={(location) => location.name}
                            getOptionSelected={(option, value) => option.name === value.name}
                            onInputChange={getLocations}
                            onChange={getSelectedLocation}
                            style={{ width: '100%' }}
                            renderOption={(option) => (
                                <React.Fragment>
                                    <div style={{ width: '100%' }}>
                                        <div style={{ display: "block", fontSize: "1rem", fontWeight: "500" }}>{option.name}</div>
                                        {option.postal_code ? <div style={{ display: "block", fontSize: ".7rem", fontWeight: "300" }}>PLZ: {option.postal_code}</div> : null}
                                    </div>
                                </React.Fragment>
                            )}
                            renderInput={(params) => <TextField {...params} label="" placeholder="Wetter in..." size="small" InputProps={{ ...params.InputProps, disableUnderline: true }} />} />
                        <div className={cssModule.SearchIcon} onClick={() => (loadResultPage(hookInsertedText))}>
                            <SearchOutlinedIcon />
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={setDefaultLocation} color="primary">
                    Cancel
                </Button>

            </DialogActions>
        </Dialog>

    );

})

export default LocationSearch;
import React, { Component, Fragment, lazy, Profiler } from 'react';
import { Grid, Box } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import * as actionCreators from '../../../store/actions/index';
import { GATiming, GAEvent } from '../../../Assets/GoogleAnalytics/googleAnalytics';

const RegenStatus = lazy(() => import('../../Components/Regen/RegenStatus/RegenStatus'));
const RegenDiagramm = lazy(() => import('../../Components/Regen/RegenDiagramm/RegenDiagramm'));

class regen extends Component {

    state = {
        hasError: null,
        errorInfo: null,
        regenDataError: false,
        regenData: undefined,
        regenNoData: false
    }

    delay = (ms) => new Promise(res => setTimeout(res, ms));

    isTokenValid = async () => {

        try {

            let timeNow = new Date();
            let LSData = window.localStorage.getItem('wetternet');
            let LSDataObject = null;
            if (LSData && LSData !== null && LSData.length > 0) {
                LSDataObject = JSON.parse(LSData);
                if (LSDataObject && LSDataObject.token && LSDataObject.token.createTime) {
                    if (new Date(timeNow) > new Date(LSDataObject.token.createTime)) {
                        this.props.handleGetToken();
                        await this.delay(5000);
                        GAEvent("Token", "Token Expired", "Fetch new token", null, true);
                    }
                }
            }
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Token - isTokenValid", ("202107201701 - Error: " + error.message), null, true);
        }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        this.setState({ hasError: error, errorInfo: errorInfo })
    }

    componentDidMount = async () => {
        try {
            //await this.getRegenData();
            if (this.props.location && this.props.location !== null && this.props.location.ID_Stadt) {
                await this.isTokenValid();
                this.props.handleGetRegenData(this.props.userToken, this.props.location.ID_Stadt);
            } else {
                this.props.history.push('/meine-stadte');
            }
            const timer = setTimeout(() => {
                if (this.props.regen.error !== null) {
                    this.setState({ regenDataError: true });
                }
                else {
                    if (this.props.regen.regen) {
                        if (this.props.regen.regen === null || this.props.regen.regen === undefined || this.props.regen.regen.length <= 0) {
                            this.setState({ regenNoData: true });
                        }
                    }
                    else {
                        this.setState({ regenDataError: true });
                    }
                }
            }, 3000);
            return () => clearTimeout(timer);
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Regen - componentDidMount", ("202107151448 - Error: " + error.message), null, true);
         }
    }

    componentDidUpdate = async(prevProps) => {
        try{
        if (prevProps.location && prevProps.location.ID_Stadt && this.props.location && this.props.location.ID_Stadt) {
            if (Number(prevProps.location.ID_Stadt) !== Number(this.props.location.ID_Stadt)) {
                await this.isTokenValid();
                this.props.handleGetRegenData(this.props.userToken, this.props.location.ID_Stadt);
            }
        }
    }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Regen - componentDidUpdate", ("202107151449 - Error: " + error.message), null, true);
    }
    }

    /*getRegenData = async () => {
        try {

            const axiosInstance = axios.create({
                baseURL: 'https://apps.qmet.de/v2/',
                timeout: 7500,
                headers: { 'Authorization': 'Bearer ' + this.props.userToken }
            });

            await axiosInstance.get(`rain/details/${this.props.location.ID_Stadt}`)
                .then(response => {
                    if (response && response.data && response.data.data) {
                        if (response.data.data.data.length > 0) {
                            this.setState({ regenData: response.data.data.data });
                        } else {
                            this.setState({ regenNoData: true })
                        }
                    }
                    else {
                        this.setState({ regenDataError: true });
                    }
                })
                .catch(error => {
                    this.setState({ regenDataError: true });
                })

        } catch (error) {
            console.log('202102091515', error);
            this.setState({ regenDataError: true });
        }
    }*/

    handleAlertClose = () => {
        try {
            if (this.props.regen.regen) {
                if (this.props.regen.regen === null || this.props.regen.regen === undefined || this.props.regen.regen.length <= 0) {
                    this.props.history.push('/');
                }
            }
            this.setState({ regenDataError: false, regenNoData: false });
            this.props.onResetRegenError();
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Regen - handleAlertClose", ("202107151450 - Error: " + error.message), null, true);
         }
    }

    setPerformance = (argProfilerId, argMode, argActualTime, argBaseTime, argStartTime, argCommitTime, argInteracions) =>{
        try{
            if(argMode && argMode.toString() === "mount"){
                GATiming(argProfilerId, argMode, argActualTime, ( argProfilerId + " " + argMode ));
            }
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Regen - setPerformance", ("202107151451 - Error: " + error.message), null, true);
        }
    }

    render() {

        let comRegenStatus = null;
        let comRegenDiagramm = null;

        try{

            if (this.props && this.props.regen && this.props.regen.regen){
                comRegenStatus = (<RegenStatus componentData={this.props.regen.regen} activeColorMode={this.props.colorMode} />);
                comRegenDiagramm = (<RegenDiagramm componentData={this.props.regen.regen} activeColorMode={this.props.colorMode} />);
            }

        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Regen - renter", ("202107151452 - Error: " + error.message), null, true);
        }

        return (
            <Fragment>
                <Profiler id="Rain" onRender={this.setPerformance}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Box p='10px' pb='5px'>
                                {comRegenStatus ? comRegenStatus : <div className="NoPage">{this.props.t('common.messages.unable_to_load_data')}</div>}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box p='10px' pt='5px' pb='5px'>
                                {comRegenDiagramm ? comRegenDiagramm : null}
                        </Box>
                    </Grid>
                </Grid>
                {this.state.errorInfo ?
                    (<div>
                        <h2>Something went wrong.</h2>
                        <details style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo.componentStack}
                        </details>
                    </div>)
                    : null}
                <SweetAlert
                    show={this.state.regenDataError}
                    warning
                    confirmBtnText="Okay"
                    confirmBtnCssClass={this.props.colorMode ? this.props.colorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                    style={this.props.colorMode ? this.props.colorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                    title={this.props.t('common.messages.attention')}
                    onConfirm={this.handleAlertClose}>
                        {
                            this.props.t('common.messages.unable_to_load_data')
                        }
                </SweetAlert>
                <SweetAlert
                    show={this.state.regenNoData}
                    warning
                    confirmBtnText={this.props.t('common.messages.back')}
                    confirmBtnCssClass={this.props.colorMode ? this.props.colorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                    style={this.props.colorMode ? this.props.colorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                    title={this.props.t('common.messages.attention')}
                    onConfirm={this.handleAlertClose}>
                    {
                        this.props.t('common.messages.unable_to_load_data')
                    }
            </SweetAlert>
            </Profiler>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        location: state.locationSubReducer.location,
        regen: state.regenSubReducer,
        colorMode: state.settingsSubReducer.colorMode,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetRegenData: (token, locID) => dispatch(actionCreators.getRegen(token, locID)),
        onResetRegenError: () => dispatch(actionCreators.resetRegenError()),
        handleGetToken: () => { dispatch(actionCreators.getToken()) }
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(regen));
import React from 'react';
import './App.css';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { BrowserRouter }from 'react-router-dom';
import ReactGA from 'react-ga';

import MobileLayout from './mobile/Containers/Layout/Layout';
import DesktopLayout from './desktop/Containers/Layout/Layout';

const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    try{
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    } catch (error) { console.log('202102191557', error) }
  };

  React.useEffect(() => {
    try{
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    } catch (error) { console.log('202102191558', error) }
  }, []);

  return (
    <viewportContext.Provider value={{ width, height }}>
      {children}
    </viewportContext.Provider>
  );
};

const useViewport = () => {
  const { width, height } = React.useContext(viewportContext);
  return { width, height };
};

const MobileComponent = () => (<MobileLayout/>);
const DesktopComponent = () => (<DesktopLayout/>);

const MyComponent = () => {
  const { width } = useViewport();
  const breakpoint = 960;

  return width < breakpoint ? <MobileComponent /> : <DesktopComponent />;
};

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: "rgb(0,62,133)"
    },
    secondary:{
      main: "rgb(0,142,223)"
    },
    third:{
      main: "rgb(84,84,84)"
    },
    fourth: {
      main: "rgb(0,0,0)"
    }

  },
  typography: {
    fontFamily: "'Exo 2' !important",
  },

});

function App() {

  ReactGA.initialize('UA-634165-14');

  return (
    <BrowserRouter>
      <ThemeProvider theme={customTheme}>
        <div className="App">
          <div style={{height:'100vh'}}>
            <ViewportProvider>
              <MyComponent />
            </ViewportProvider>
          </div>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;

import React, {Component} from 'react';
import { connect } from 'react-redux';

import * as actionCreators from '../../../../store/actions/index';
import WetterNewsWeiterComponent from '../../../Components/WetterNewsKachelComponent/WetterNewsWeiterComponent/WetterNewsWeiterComponent';

class WetterNewsWeiter extends Component {
    render(){
        return(
            <WetterNewsWeiterComponent/>
        );
    }
}

const mapStateToProps = state => {
    return {
        kWetternews: state.kWetternewsSubReducer,
        token: state.tokenSubReducer,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetKWetternews: (token, page) => dispatch(actionCreators.getKWetternews(token, page)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WetterNewsWeiter);
import React, {memo} from 'react';
import { Typography, Button, Breadcrumbs, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as SiteLink} from 'react-router-dom';

import cssModule from './NewsInnerPage.module.css';
import { getNewsCategory } from '../../../../Assets/Functions/Functions';

const shortNewsName = (argText, argLength) => {
    try {
        return (argText.substring(0, argLength) + "...");
    } catch (error) { console.log('202102191536', error) }
}

function slugify(str) {
    try{
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "ãàáäâáº½èéëêìíïîõòóöôùúüûñç·/_,:;";
    var to = "aaaaaeeeeeiiiiooooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
    } catch (error) { console.log('202103101707', error) }
};

const getFormatedDate = (argDate)=>{
    try{
    let curDate = new Date(argDate);
    return (curDate.toLocaleDateString('de-DE', {year: 'numeric', month: 'long', day: 'numeric' }));
    } catch (error) { console.log('202103101708', error) }
}

const useStyles = makeStyles((theme) => ({

    head: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '3rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    subHeadDark: {
        textTransform: "none",
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '500',
        fontSize: '1.2rem',
        marginTop: '0px',
        marginBottom: '0px'
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '15px'
    },
    button: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .6)',
        transition: '.2s ease-in',
        fontSize: '1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '400',
        '&:hover': {
            color: 'rgba(0, 62, 133, 1)',
            background: 'transparent',
        },
    },
}));

const WetterNewsInnerPageComponent = memo((props)=>{

    const classes = useStyles();

    function handleClick(event) {
        try {
            event.preventDefault();
        } catch (error) { console.log('202103101712', error) }
    }

    let componentOrganizer = null;

    try{
        if (props.componentData && props.componentData.kWetternews && props.componentData.kWetternews.length > 0) {

            componentOrganizer = props.componentData.kWetternews.map((news, index) => {
                    return (
                        <Grid item xs={6} key={index}>
                            <div className={cssModule.WetterNewsItem}>
                                <SiteLink className={cssModule.VideoLink} to={`/wetter-news/${news.ID ? news.ID.toString(): null}`}>
                                    <div className={cssModule.WetterImage} style={{ backgroundImage: `url(${news.image_path})` }}></div>
                                    <div className={cssModule.WetterData}>
                                        <Typography variant="body1" className={cssModule.WetterDataDate}>{getFormatedDate(news.added_on)}{news.category1 ? parseInt(news.category1) !== 1 ? " | " + getNewsCategory(news.category1) : null : null}{news.category2 ? parseInt(news.category2) !== 1 ? " | " + getNewsCategory(news.category2) : null : null}</Typography>
                                        <Typography variant="subtitle1" className={cssModule.WetterDataTitle}>{shortNewsName(news.Headline.toString(), 55)}</Typography>
                                        <Typography variant="body1" className={cssModule.WetterDataBody}>{shortNewsName(news.sub_heading.toString(), 100)}</Typography>
                                    </div>
                                </SiteLink>
                            </div>
                        </Grid>
                    );
            });
        }

} catch (error) { console.log('202103101710', error) }

    return(
        <React.Fragment>
            <div className={cssModule.NewsInnerPageParent}>
                <div className={cssModule.HeadSection}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <SiteLink className={cssModule.BreadcrubsLink} color="inherit" to="/" onClick={handleClick}>Home</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Aktuell</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Wetter-News</SiteLink>
                    </Breadcrumbs>
                </div>
                <div className={cssModule.DataSection}>
                    <div className={cssModule.WetterNewsContainer}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography className={classes.head} variant="h1" component="h1" gutterBottom>Wetter-News</Typography>
                            </Grid>
                            {componentOrganizer}
                            <Grid item xs={12}>
                                <div className={cssModule.MehrNews} style={{ textAlign: 'center' }}>
                                    <Button variant="outlined" size="small" className={classes.button} onClick={props.getWetterNews}>Weitere Nachrichten laden</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
});

export default WetterNewsInnerPageComponent;
import React, { Component, Fragment, lazy, Profiler } from 'react';
import { Grid, Box } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import * as actionCreators from '../../../store/actions/index';
import { GATiming, GAEvent } from '../../../Assets/GoogleAnalytics/googleAnalytics';

const Video = lazy(() => import('../../Components/Video/Video'));
class video extends Component {

    state = {
        hasError: null,
        errorInfo: null,
        videoDataError: false,
        videoData: undefined,
        videoNoData: false,
        initialVal: 0
    }

    delay = (ms) => new Promise(res => setTimeout(res, ms));

    isTokenValid = async () => {

        try {

            let timeNow = new Date();
            let LSData = window.localStorage.getItem('wetternet');
            let LSDataObject = null;
            if (LSData && LSData !== null && LSData.length > 0) {
                LSDataObject = JSON.parse(LSData);
                if (LSDataObject && LSDataObject.token && LSDataObject.token.createTime) {
                    if (new Date(timeNow) > new Date(LSDataObject.token.createTime)) {
                        this.props.handleGetToken();
                        await this.delay(5000);
                        GAEvent("Token", "Token Expired", "Fetch new token", null, true);
                    }
                }
            }
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Token - isTokenValid", ("202107201704 - Error: " + error.message), null, true);
        }
    }

    componentDidUpdate = async (prevProp) => {
        try {
            await this.isTokenValid();
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Video - componentDidUpdate", ("202107201705 - Error: " + error.message), null, true);
        }
    }

    componentDidMount = async () => {
        //await this.getVideoData();
        try {
            await this.isTokenValid();
            this.props.handleGetVideoData(this.props.userToken, this.state.initialVal);

            const timer = setTimeout(() => {
                if (this.props.video.error !== null) {
                    this.setState({ videoDataError: true });
                }
                else {
                    if (this.props.video.video) {
                        if (this.props.video.video === null || this.props.video.video === undefined || this.props.video.video.length <= 0) {
                            this.setState({ videoNoData: true });
                        }
                    }
                    else {
                        this.setState({ videoDataError: true });
                    }
                }
            }, 3000);
            return () => clearTimeout(timer);
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Video - componentDidMount", ("202107201706 - Error: " + error.message), null, true);
        }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        this.setState({ hasError: error, errorInfo: errorInfo })
    }

    /*getVideoData = async (argVal = this.state.initialVal) => {
        try {

            const axiosInstance = axios.create({
                baseURL: 'https://apps.qmet.de/v2/',
                timeout: 7500,
                headers: { 'Authorization': 'Bearer ' + this.props.userToken }
            });

            await axiosInstance.get(`news/youtubevideos/${parseInt(argVal)}?locale=en`)
                .then(response => {
                    if (response && response.data && response.data.data) {
                        if (response.data.data.length > 0) {
                            if (this.state.videoData && this.state.videoData.length > 0) {
                                this.updateVideo(response.data.data);

                            } else {
                                this.setState({ videoData: response.data.data });
                            }
                        } else {
                            this.setState({ videoNoData: true })
                        }
                    }
                    else {
                        this.setState({ videoDataError: true });
                    }
                })
                .catch(error => {
                    this.setState({ videoDataError: true });
                })
        } catch (error) {
            console.log('202102091515', error);
            this.setState({ videoDataError: true });
        }
    }*/

    handleAlertClose = () => {
        if (this.props.video.video) {
            if (this.props.video.video === null || this.props.video.video === undefined || this.props.video.video.length <= 0) {
                this.props.history.push('/');
            }
        }
        this.props.onResetVideoError();
        this.setState({ videoDataError: false, videoNoData: false });
    }

    loadMoreVideo = () => {
        try {
            this.props.handleGetMoreVideoData(this.props.userToken, this.state.initialVal + 1);
            this.setState({ initialVal: this.state.initialVal + 1 });
        } catch (error) { console.log('202102191421', error) }
    }

    /*updateVideo = (argNewVideo) => {
        let currentNews = this.state.videoData;
        this.setState({ videoData: currentNews.concat(argNewVideo) })
    }*/

    setPerformance = (argProfilerId, argMode, argActualTime, argBaseTime, argStartTime, argCommitTime, argInteracions) =>{
        try{
            if(argMode && argMode.toString() === "mount"){
                GATiming(argProfilerId, argMode, argActualTime, ( argProfilerId + " " + argMode ));
            }
        }catch(error){}
    }

    render() {

        const comVideo = (<Video fetchVideoData={this.loadMoreVideo} componentData={this.props.video.video ? this.props.video.video : null} activeColorMode = { this.props.colorMode } />);

        return (
            <Fragment>
                <Profiler id="Video" onRender={this.setPerformance}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Box p='10px'>
                            {comVideo}
                        </Box>
                    </Grid>
                </Grid>
                {this.state.errorInfo ?
                    (<div>
                        <h2>Something went wrong.</h2>
                        <details style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo.componentStack}
                        </details>
                    </div>)
                    : null}

                <SweetAlert
                    show={this.state.videoDataError}
                    warning
                    confirmBtnText="Okay"
                    confirmBtnCssClass={this.props.colorMode ? this.props.colorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                    style={this.props.colorMode ? this.props.colorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                    title={this.props.t('common.messages.attention')}/*"Etwas ist schief gelaufen!"*/
                    onConfirm={this.handleAlertClose}>
                        {
                            /*Daten können nicht geladen werden. Bitte versuchen Sie es nach einiger Zeit!*/
                            this.props.t('common.messages.unable_to_load_data')
                        }
                </SweetAlert>
                <SweetAlert
                    show={this.state.videoNoData}
                    warning
                    confirmBtnText={this.props.t('common.messages.back')}/*"zurück"*/
                    confirmBtnCssClass={this.props.colorMode ? this.props.colorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                    style={this.props.colorMode ? this.props.colorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                    title={this.props.t('common.messages.attention')}/*"Keine Daten!"*/
                    onConfirm={this.handleAlertClose}>
                        {
                            /*Derzeit keine Daten, bitte versuchen Sie es nach einiger Zeit!*/
                            this.props.t('common.messages.no_data_now')
                        }
            </SweetAlert>
            </Profiler>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        video: state.videoSubReducer,
        colorMode: state.settingsSubReducer.colorMode,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetVideoData: (token, page) => dispatch(actionCreators.getVideo(token, page)),
        handleGetMoreVideoData: (token, page) => dispatch(actionCreators.getMoreVideo(token, page)),
        onResetVideoError: () => dispatch(actionCreators.resetVideoError()),
        handleGetToken: () => { dispatch(actionCreators.getToken()) }
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(video));
/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState, Profiler } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from "react-i18next";

import { GAEvent, GAPageView, GATiming } from '../../../../Assets/GoogleAnalytics/googleAnalytics';
import '../../../../Assets/Languages/i18n';
import cssModule from './NewsDetails.module.css';

const useStyles = makeStyles((theme) => ({
    backButton: {
        textTransform: 'capitalize',
        marginTop: '10px',
        color: 'rgb(0,62,133)',
    },
    backButtonLabel: {
        fontSize: '.7rem',
        fontFamily: 'Exo 2',
        color: 'rgb(0,62,133)',
    },
    backButtonDark: {
        textTransform: 'capitalize',
        marginTop: '10px',
        color: '#3eb8ff',
    },
    backButtonLabelDark: {
        fontSize: '.7rem',
        fontFamily: 'Exo 2',
        color: '#3eb8ff',
    },
}));

const newsDetails = memo((props) => {

    const { t, i18n } = useTranslation();

    const classes = useStyles();

    const [hookNewsData, setNewsData] = useState(null);
    const [hookNewsDataError, setNewsDataError] = useState(false);
    const [hookNewsNoData, setNewsNoData] = useState(false);

    useEffect(() => {
        try {
            const fetchNewsData = async () => {

                const axiosInstance = axios.create({
                    baseURL: 'https://apps.qmet.de/v2/',
                    headers: { 'Authorization': `Bearer ${props.userToken}` }
                });

                await axiosInstance.get(`news/id/${props.match.params.id}`)
                    .then(response => {
                        if (response && response.data && response.data.data) {
                            if (response.data.data.description && response.data.data.description.length > 0) {
                                setNewsData(response.data.data);
                                GAPageView(window.location.pathname,[], response.data.data.ID + " - " + response.data.data.Headline);
                            } else {
                                setNewsNoData(true);
                                GAEvent("No Data - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News details - No data to display", ("202105191130 - No News data to display. ID: " + props.match.params.id), null, true);
                            }
                        }
                        else {
                            setNewsDataError(true);
                            GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News details - https://apps.qmet.de/v2/news/id/", "202107141718 - ID: " + props.match.params.id + " Error: API respond without data / wrong data structure", null, true);
                        }
                    })
                    .catch(error => {
                        setNewsDataError(true);
                        GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News details - https://apps.qmet.de/v2/news/id/", "202107141719 - ID: " + props.match.params.id + " Error: " + error.message, null, true);
                    })
            }
            fetchNewsData();
        } catch (error) {
            GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News details - https://apps.qmet.de/v2/news/id/", "202107141720 - ID: " + props.match.params.id + " Error: " + error.message, null, true);
            setNewsDataError(true);
        }
    }, []);

    const handleAlertClose = () => {
        try{
        props.history.push('/news');
        setNewsDataError(false);
        setNewsNoData(false);
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News Details - handleAlertClose", ("202107141040 - Error: " + error.message), null, true);
         }
    }

    const handleBackBtnClick = (e, argNewsHead) =>{
        try{
            handleAlertClose();
            backBtnPress(argNewsHead);
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News Details - handleBackBtnClick", ("202107141041 - Error: " + error.message), null, true);
        }
    }

    const backBtnPress = (argNewsHead) => {
        try{
          GAEvent("News details", "Button clicked", ("Back button pressed: " + argNewsHead), null, false);
        }catch(error){}
    }

    const formateDate = (argDate) => {
        try{
        //21. Januar 2021
        let date = new Date(argDate);
        let newDate="";
        if(i18n.language.toString() === "de"){
            newDate = date.toLocaleDateString('de-DE', { day: 'numeric', month: 'long', year: 'numeric' });
        }else if(i18n.language.toString() === "en"){
            newDate = date.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' });
        }
        return newDate;
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News Details - formateDate", ("202107141042 - Error: " + error.message), null, true);
        }
    }

    const setPerformance = (argProfilerId, argMode, argActualTime, argBaseTime, argStartTime, argCommitTime, argInteracions) =>{
        try{
            if(argMode && argMode.toString() === "mount"){
                GATiming(argProfilerId, argMode, argActualTime, ( argProfilerId + " " + argMode ));
            }
        }catch(error){}
    }

    let componentOrganizer = null;

    try{
        componentOrganizer = hookNewsData ?
        (<Grid item xs={12}>
            <Box mb={2}>
                {hookNewsData.image_path ?
                    (<div className={cssModule.NewsImage} style={{ backgroundImage: `URL(${hookNewsData.image_path})` }}></div>)
                    : null}
                <Box mb={1} mt={2} pl={3} pr={3}>
                    {hookNewsData.Headline ?
                        (<Typography className={ props.activeColorMode ? props.activeColorMode.toString() === "D" ? cssModule.NewsHeadDark : cssModule.NewsHead :  cssModule.NewsHead} variant="subtitle1">{hookNewsData.Headline.toString()}</Typography>)
                        : null}
                    {hookNewsData.added_on ?
                        (<Typography className={cssModule.NewsDate} variant="body2"> {formateDate(hookNewsData.added_on)}</Typography>)
                        : null}
                </Box>
                <Box mb={1} mt={2} pl={3} pr={3}>
                    <Typography gutterBottom variant="body2" className={props.activeColorMode ? props.activeColorMode.toString() === "D" ? cssModule.NewsDetailsContentDark :  cssModule.NewsDetailsContent : cssModule.NewsDetailsContent }>
                        {hookNewsData.description ?
                            hookNewsData.description
                            : null}
                    </Typography>
                </Box>
                <Box mb={1} mt={2} pl={3} pr={3} style={{textAlign:'right'}}>
                    <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    classes={{ label: props.activeColorMode ? props.activeColorMode.toString() === "D" ? classes.backButtonLabelDark : classes.backButtonLabel : classes.backButtonLabel }}
                    className={ props.activeColorMode ? props.activeColorMode.toString() === "D" ? classes.backButtonDark : classes.backButton : classes.backButton}
                    onClick={(e, argHead = hookNewsData.Headline) => {handleBackBtnClick(e, argHead)}}>
                        {/*Zurück*/}
                        {t('app.pages.news.labels.back')}
                    </Button>
                </Box>
            </Box>
        </Grid>) : null
    }catch(error){
        GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News Details - render", ("202107141043 - Error: " + error.message), null, true);
    }

    return (
        <Grid container spacing={0}>
            <Profiler id="News Details" onRender={setPerformance}>
            {componentOrganizer}
            <SweetAlert
                show={hookNewsDataError}
                warning
                confirmBtnText={t('common.messages.back')}/*"zurück"*/
                confirmBtnCssClass={props.activeColorMode ? props.activeColorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                style={props.activeColorMode ? props.activeColorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                title={t('common.messages.attention')}/*"Etwas ist schief gelaufen!"*/
                onConfirm={handleAlertClose}>
                {
                    /*Daten können nicht geladen werden. Bitte versuchen Sie es nach einiger Zeit!*/
                    t('common.messages.unable_to_load_data')
                }
            </SweetAlert>
            <SweetAlert
                show={hookNewsNoData}
                warning
                confirmBtnText={t('common.messages.back')}/*"zurück"*/
                confirmBtnCssClass={props.activeColorMode ? props.activeColorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                style={props.activeColorMode ? props.activeColorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                title={t('common.messages.attention')}/*"Keine Daten!"*/
                onConfirm={handleAlertClose}>
                {
                    /*Derzeit keine Daten, bitte versuchen Sie es nach einiger Zeit!*/
                    t('common.messages.no_data_now')
                }
            </SweetAlert>
            </Profiler>
        </Grid>
    );
});

export default newsDetails;
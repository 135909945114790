import * as actionTypes from './actionTypes';
import axiosInstance from '../../AxiosInstance/axiosInstansMobile';
import { GAEvent } from '../../Assets/GoogleAnalytics/googleAnalytics';

export const getToken = () => {
    try{
    return dispatch => {
        dispatch(getTokenStarted());
        axiosInstance.get('token', { timeout: 20000 })
            .then(response => {
                if (response && response.data && response.data.data && response.data.data.token && response.data.data.token.length > 0) {
                    dispatch(getTokenSuccess(response.data.data.token));
                }
                else {
                    dispatch(getTokenFailure('Something went wrong'));
                    GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Token - https://apps.qmet.de/v2/token", "202107141742 - Error: API respond without data / wrong data structure", null, true);
                }
            })
            .catch(err => {
                dispatch(getTokenFailure(err.message));
                GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Token - https://apps.qmet.de/v2/token", "202107141743 - Error: " + err.message, null, true);
            });
    };
    // eslint-disable-next-line no-unreachable
    } catch (error) {
        GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Token - https://apps.qmet.de/v2/token", "202107141744 - Error: " + error.message, null, true);
     }
};

const getTokenSuccess = token => ({
    type: actionTypes.GET_TOKEN_SUCCESS,
    payload: token
});

const getTokenStarted = () => ({
    type: actionTypes.GET_TOKEN_STARTED
});

const getTokenFailure = error => ({
    type: actionTypes.GET_TOKEN_FAILURE,
    payload: {
        error
    }
});

export const resetTokenError = () => ({
    type: actionTypes.RESET_TOKEN_ERROR
});
import React, { memo, useState, useEffect } from 'react';
import { Typography, Breadcrumbs, Grid } from '@material-ui/core';
import Youtube from 'react-youtube';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Skeleton from '@material-ui/lab/Skeleton';

import cssModule from './VideoSingleInnerPage.module.css';
import PlayButton from '../../../../Assets/Desktop/Common/play.svg';
import { getFormatedDate } from '../../../../Assets/Functions/Functions';

function handleClick(event) {
    try {
        event.preventDefault();
    } catch (error) { console.log('202103101712', error) }
}

const useStyles = makeStyles((theme) => ({

    date: {
        color: 'rgba(84, 84, 84, .8)',
        fontSize: '1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '.5rem'
    },
    head: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '2.5rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.2rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    interessant: {
        color: 'rgba(34, 34, 34, .8)',
        fontSize: '1.8rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '1rem',
    }
}));

function slugify(str) {
    try {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        var from = "ãàáäâáº½èéëêìíïîõòóöôùúüûñç·/_,:;";
        var to = "aaaaaeeeeeiiiiooooouuuunc------";
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    } catch (error) { console.log('202103101714', error) }
};

const getVideoID = (argLink) => {
    try {
        if (argLink != null) {
            if (argLink.indexOf('-') > -1) {
                let varIndex = argLink.indexOf('-');
                return argLink.substring(0, varIndex);
            }
        }
    } catch (error) { console.log('202102191535', error) }
}

const shortVideoName = (argText, argLength) => {
    try {
        return (argText.substring(0, argLength) + "...");
    } catch (error) { console.log('202102191536', error) }
}

const formateDate = (argDate) => {
    try {
        if (argDate) {
            return new Date(argDate).toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
        }
    } catch (error) {
        console.log('202109241118', error);
    }
};

const formateTime = (argDate) => {
    try {
        if (argDate) {
            return new Date(argDate).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
        }
    } catch (error) {
        console.log('202109241118', error);
    }
};

const componentSkeletonMain = (
    <React.Fragment>
        <Skeleton variant="rect" height={500} width={'100%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '1rem', borderRadius: '5px' }} />
        <Skeleton variant="rect" height={35} width={'100%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '.35rem', borderRadius: '5px' }} />
        <Skeleton variant="rect" height={35} width={'70%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '.9rem', borderRadius: '5px' }} />
        <Skeleton variant="rect" height={20} width={'50%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '.9rem', borderRadius: '5px' }} />
        <Skeleton variant="rect" height={20} width={'100%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '.35rem', borderRadius: '5px' }} />
        <Skeleton variant="rect" height={20} width={'100%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '.35rem', borderRadius: '5px' }} />
        <Skeleton variant="rect" height={20} width={'100%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '.35rem', borderRadius: '5px' }} />
        <Skeleton variant="rect" height={20} width={'100%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '.35rem', borderRadius: '5px' }} />
        <Skeleton variant="rect" height={20} width={'100%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '.35rem', borderRadius: '5px' }} />
        <Skeleton variant="rect" height={20} width={'100%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '.35rem', borderRadius: '5px' }} />
        <Skeleton variant="rect" height={20} width={'100%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '.35rem', borderRadius: '5px' }} />
    </React.Fragment>
);

const componentSkeletonInteressant = (
    <React.Fragment>
        <Grid item xs={6} key="V1">
            <Skeleton variant="rect" height={225} width={'100%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '.5rem', borderRadius: '5px' }} />
            <Skeleton variant="rect" height={50} width={'100%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '1rem', borderRadius: '5px' }} />
        </Grid>
        <Grid item xs={6} key="V2">
            <Skeleton variant="rect" height={225} width={'100%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '.5rem', borderRadius: '5px' }} />
            <Skeleton variant="rect" height={50} width={'100%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '1rem', borderRadius: '5px' }} />
        </Grid>
        <Grid item xs={6} key="V3">
            <Skeleton variant="rect" height={225} width={'100%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '.5rem', borderRadius: '5px' }} />
            <Skeleton variant="rect" height={50} width={'100%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '1rem', borderRadius: '5px' }} />
        </Grid>
        <Grid item xs={6} key="V4">
            <Skeleton variant="rect" height={225} width={'100%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '.5rem', borderRadius: '5px' }} />
            <Skeleton variant="rect" height={50} width={'100%'} style={{ backgroundColor: 'rgba(0, 0, 0, 0.03)', marginBottom: '1rem', borderRadius: '5px' }} />
        </Grid>
    </React.Fragment>
);

const WetterTVSingleComponent = memo((props) => {

    const classes = useStyles();
    let componentOrganizer, componentMainOrganizer = null;

    const [hookVideo, setVideo] = useState({});

    React.useEffect(() => {
        window.scrollTo(0, 0)
    }, [props.match.params.path]);

    useEffect(() => {
        try {
            const fetchNewsData = async () => {

                if (props.kToken && props.kToken.kToken && props.match.params && props.match.params.path) {
                    const axiosInstance = axios.create({
                        baseURL: 'http://165.227.162.31:8000/v4/',
                        headers: { 'Authorization': `Bearer ${props.kToken.kToken}` }
                    });

                    await axiosInstance.get(`news/youtubevideos/id/${getVideoID(props.match.params.path)}`)
                        .then(response => {
                            if (response && response.data && response.data.data) {
                                if (response.data.data.video_id && response.data.data.video_id.length > 0) {
                                    setVideo(response.data.data);
                                } else {
                                    props.deliverError(true);
                                }
                            }
                            else {
                                props.deliverError(true);
                            }
                        })
                        .catch(error => {
                            props.deliverError(true);
                        })
                }
            }
            fetchNewsData();
        } catch (error) {
            props.deliverError(true);
            console.log('202109201117', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.kToken, props.match.params]);

    const opts = {
        height: '496',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            controls: 1,
        },
    };

    const videOnReady = (event) => {
        try {
            // access to player in all event handlers via event.target
            event.target.pauseVideo();
        } catch (error) { console.log('202103101715', error) }
    }

    try {
        if (hookVideo) {
            componentMainOrganizer = (
                <React.Fragment>
                    <div className={cssModule.WetterTVContainer}>
                        <Youtube videoId={hookVideo.video_id ? hookVideo.video_id : null} opts={opts} onReady={videOnReady} allow="autoplay" />
                    </div>

                    <div className={cssModule.VideoDetailsSection}>
                        <Typography className={classes.head} variant="h1" component="h1" gutterBottom>{hookVideo.Title ? hookVideo.Title.toString() : null}</Typography>
                        <Typography className={classes.date} variant="subtitle2" component="p">Wiesbaden | {formateDate(hookVideo.Erstelldatum)} | {formateTime(hookVideo.Erstelldatum)}</Typography>
                        <Typography className={classes.data} variant="body1" component="p">{hookVideo.Beschreibung ? hookVideo.Beschreibung.toString() : null}</Typography>
                    </div>
                </React.Fragment>
            );
        } else {
            componentMainOrganizer = componentSkeletonMain;
        }
    } catch (error) { console.log('202109241230', error) }

    try {
        if (hookVideo && props.componentData && props.componentData.kWettertv && props.componentData.kWettertv.length > 0) {
            let varFlagIndex = 0;
            componentOrganizer = props.componentData.kWettertv.map((video, index) => {
                if (Number(hookVideo.ID) !== Number(video.ID)) {
                    varFlagIndex += 1;
                    if (varFlagIndex < 5) {
                        return (
                            <Grid item xs={6} key={video.ID}>
                                {/*<Link className={cssModule.VideoLink} onClick={() => { history.push(`/wetter-tv/video/${video.ID ? video.ID.toString() + "-" : null}${slugify(video.Title.toString())}`) }}>*/}
                                <Link className={cssModule.VideoLink} to={`/wetter-tv/video/${video.ID ? video.ID.toString() + "-" : null}${slugify(video.Title.toString())}`} >
                                    <div className={cssModule.WetterTVItem}>
                                        <div className={cssModule.WetterImage} style={{ backgroundImage: `url(https://img.youtube.com/vi/${video.video_id ? video.video_id : null}/mqdefault.jpg)` }}>
                                            <img className={cssModule.PlayButton} src={PlayButton} alt="play" />
                                        </div>
                                        <div className={cssModule.WetterData}>
                                            <Typography variant="body1" className={cssModule.WetterDataDate}>{getFormatedDate(video.Erstelldatum)}</Typography>
                                            {video.Title ? (<Typography variant="subtitle1" className={cssModule.WetterDataTitle}>{shortVideoName(video.Title.toString(), 40)}</Typography>)
                                                : (<Typography variant="subtitle1" className={cssModule.WetterDataTitle}>Titel nicht verfügbar</Typography>)}
                                            {video.Beschreibung ? (<Typography variant="body1" className={cssModule.WetterDataBody}>{shortVideoName(video.Beschreibung.toString(), 120)}</Typography>)
                                                : (<Typography variant="body1" className={cssModule.WetterDataBody}>Beschreibung nicht verfügbar</Typography>)}
                                        </div>
                                    </div>
                                </Link>
                            </Grid>
                        );
                    } else { return null }
                } else { return null }
            });
        } else {
            componentOrganizer = componentSkeletonInteressant;
        }
    } catch (error) { console.log('202103101716', error) }

    return (

        <React.Fragment>
            <div className={cssModule.WetterTVKachelParent}>
                <div className={cssModule.HeadSection}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <Link className={cssModule.BreadcrubsLink} color="inherit" to="/">Home</Link>
                        <Link className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Aktuell</Link>
                        <Link className={cssModule.BreadcrubsLink} color="inherit" to="/wetter-tv">Wetter TV</Link>
                        <Link className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>{hookVideo.Title ? hookVideo.Title.toString() : null}</Link>
                    </Breadcrumbs>
                </div>
                {componentMainOrganizer}
                <div className={cssModule.AuchInteressant}>
                    <div className={cssModule.WetterTVContainer}>
                        <Typography className={classes.interessant} variant="h3" component="h3">Auch interessant</Typography>
                        <div className={cssModule.WetterTVRow}>
                            <Grid container spacing={2}>
                                {componentOrganizer}
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );

});

export default WetterTVSingleComponent;
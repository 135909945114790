import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getKSTagesverlauf = (argToken, argLat, argLon, argDate) => {
    try {
        return dispatch => {
            dispatch(getKSTagesverlaufStarted());
            const axiosInstance = axios.create({
                baseURL: 'http://165.227.162.31:8000/v4/',
                headers: { 'Authorization': `Bearer ${argToken}` }
            });

            axiosInstance.post(`hourly_data`, {lat: argLat, lon: argLon, date: argDate})
                .then(response => {
                    if (response && response.data && response.data.data) {
                        dispatch(getKSTagesverlaufSuccess(response.data.data));
                    }
                    else {
                        dispatch(getKSTagesverlaufFailure('Something went wrong'));
                    }
                })
                .catch(error => {
                    dispatch(getKSTagesverlaufFailure(error.message));
                })
        };
        // eslint-disable-next-line no-unreachable
    } catch (error) { console.log('202102231111', error) }
};

const getKSTagesverlaufSuccess = data => ({
    type: actionTypes.KS_GET_TAGESVERLAUF_SUCCESS,
    payload: data
});

const getKSTagesverlaufStarted = () => ({
    type: actionTypes.KS_GET_TAGESVERLAUF_STARTED,
});

const getKSTagesverlaufFailure = error => ({
    type: actionTypes.KS_GET_TAGESVERLAUF_FAILURE,
    payload: {
        error
    }
});

export const getCacheKSTagesferlauf = () => ({
    type: actionTypes.KS_GET_TAGESVERLAUF_CACHED,
});
import React, {memo} from 'react';
import { Typography, Button, Breadcrumbs, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, Link } from 'react-router-dom';

const WetterNewsWeiterComponent = memo(()=>{
    return(
        <div>wetter news weiter component</div>
    );
});

export default WetterNewsWeiterComponent;
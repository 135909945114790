export const getNewsCategory = (argCategory = 1) => {
    try {
        switch (parseInt(argCategory)) {
            case 7:
                return "Aktuell";
            case 99:
                return "Deutschlandwetter";
            case 9:
                return "Wettertrend";
            case 11:
                return "Wetterlage";
            case 13:
                return "Warnungen";
            case 15:
                return "Wochenendwetter";
            case 17:
                return "Wetter weltweit";
            case 19:
                return "Agrarwetter";
            case 21:
                return "Unwetter";
            case 23:
                return "Gewitter";
            case 25:
                return "Starkregen";
            case 27:
                return "Hochwasser";
            case 29:
                return "Orkan";
            case 31:
                return "Glätte";
            case 33:
                return "Tornado";
            case 35:
                return "Stürme";
            case 37:
                return "Sturm Deutschland";
            case 39:
                return "Sturm Europa";
            case 41:
                return "Hurrikan";
            case 43:
                return "Taifun";
            case 45:
                return "Zyklon";
            case 47:
                return "Katastrophen";
            case 49:
                return "Erdbeben";
            case 50:
                return "Waldbrand";
            case 51:
                return "Tsunami";
            case 53:
                return "Vulkanausbruch";
            case 55:
                return "Klima";
            case 57:
                return "Klimawandel";
            case 59:
                return "Klimatrend";
            case 61:
                return "Wissen";
            case 63:
                return "Wissenswertes";
            case 65:
                return "Ratgeber";
            case 67:
                return "Wetterwissen";
            case 69:
                return "Biowetter";
            case 71:
                return "Pollenflug";
            case 73:
                return "UV/Ozon";
            case 75:
                return "Gesundheit";
            case 77:
                return "Freizeit";
            case 79:
                return "Wintersport";
            case 81:
                return "Wandern";
            case 83:
                return "Wassersport";
            case 85:
                return "Badewetter";
            case 87:
                return "Veranstaltungen";
            case 89:
                return "Sport allgemein";
            case 91:
                return "Rückblicke";
            case 93:
                return "Monat";
            case 95:
                return "Jahreszeit";
            case 97:
                return "Jahr";
            case 1:
                return "ohne Kategorie";
            default:
                return "ohne Kategorie";
        }
    } catch (error) { console.log('GAEvent ERROR', error) }
}

export const getFormatedDate = (argDate) => {
    //21. Januar 2021
    try {
        let curDate = new Date(argDate);
        return (curDate.toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }));
    } catch (error) { console.log('202103101720', error) }
}
/* eslint-disable no-unreachable */
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { loadState } from '../../localStorage';

let localCache = loadState();
let initialState = null;

if (localCache && localCache.location) {
    initialState = localCache.location;
}
else {
    initialState = {
        location: {},
        perviousLocations: [],
        loading: false,
        error: null,
    }
}

const handleDuplicates = (argID,argName, argHistory) => {
    try{
    if (argHistory && argHistory.length > 0) {
        for (let i = 0; i < argHistory.length; i++) {
            if (Number(argID) === Number(argHistory[i].ID_Stadt) && argName.toString() === argHistory[i].name.toString()) {
                return false;
                break;
            }
        }
    }
    return true;
    } catch (error) { console.log('202102191548', error) }
}

const reducer = (state = initialState, action) => {
    try{
    const storedLocation = loadState();
    if (action.type === actionTypes.LOCATION_STORE) {
        if (action.val && action.val != null) {
            return updateObject(state, { location: action.val, loading: false, error: null });
        }
    }
    else if (action.type === actionTypes.LOCATION_CURRENT_GET_SUCCESS){
        if (action.payload && action.payload != null) {
            return updateObject(state, { location: action.payload, loading: false, error: null });
        }
    }
    else if (action.type === actionTypes.LOCATION_CURRENT_GET_FAILURE) {
        return {
            ...state,
            loading: false,
            error: action.payload.error,
        }
    } else if (action.type === actionTypes.LOCATION_CURRENT_GET_STARTED) {
        return {
            ...state,
            loading: true,
            error: null,
        }
    }
    else if (action.type === actionTypes.LOCATION_FROM_LOCAL){
        if (initialState.location && initialState.location && initialState.location.name && initialState.location.name.length > 0){
            return updateObject(state, { location: initialState.location, loading: false, error: null });
        }else{
            return updateObject(state, { location: null, loading: false, error: null });
        }
    }
    else if (action.type === actionTypes.LOCATION_ADD) {
        if (action.val && action.val != null) {
            if (state && state.perviousLocations) {
                if (handleDuplicates(action.val.ID_Stadt, action.val.name, state.perviousLocations)) {
                    return updateObject(state, { perviousLocations: [...state.perviousLocations, action.val], loading: false, error: null });
                }
            }
            else {
                return updateObject(state, { perviousLocations: [...state.perviousLocations, action.val], loading: false, error: null });
            }
        }
    }
    else if (action.type === actionTypes.LOCATION_REMOVE) {
        if (action.val && action.val != null) {
            if (storedLocation && storedLocation.location && storedLocation.location.perviousLocations) {
                return updateObject(state, { perviousLocations: storedLocation.location.perviousLocations.filter((loc) => { return loc.ID_Stadt !== action.val.ID_Stadt && loc.name !== action.val.name }), loading: false, error: null });
            }
        }
    }
    return storedLocation ? storedLocation.location ? storedLocation.location : state : state;
    } catch (error) { console.log('202102191549', error) }
};

export default reducer;
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import * as actionCreators from '../../../../store/actions/index';
import VideoInnerPageComponent from '../../../Components/InnerPages/VideoSingleInnerPage/VideoSingleInnerPage';

class VideoSingleInnerPage extends PureComponent {

    componentDidMount = () => {
        try {
            if (this.props.kToken && this.props.kToken.kToken && this.props.kToken.kToken.length > 0) {
                this.props.handleGetKWettertv(this.props.kToken.kToken, 0);
            }
        } catch (error) { console.log('202109241142', error) }
    }

    componentDidUpdate = async (prevProps) => {
        try {
            if (prevProps.kToken !== this.props.kToken && this.props.kToken && this.props.kToken.kToken && this.props.kToken.kToken.length > 0) {
                /*if (await this.isTokenValid()) {*/
                this.props.handleGetKWettertv(this.props.kToken.kToken, 0);
                /*}*/
            }
        } catch (error) {
            console.log('202109241143', error);
        }
    }

    render() {
        return (
            <VideoInnerPageComponent kToken={this.props.kToken} {...this.props} componentData={this.props.kWettertv} />
        );
    }
}

const mapStateToProps = state => {
    return {
        kWettertv: state.kWettertvSubReducer,
        kToken: state.kTokenSubReducer,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetKWettertv: (token, page) => dispatch(actionCreators.getKWettertv(token, page)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoSingleInnerPage);
import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getKBiowetter = (argToken, argLocID) => {
    try {
        return dispatch => {
            dispatch(getKBiowetterStarted());
            const axiosInstance = axios.create({
                baseURL: 'http://165.227.162.31:8000/v4/',
                headers: { 'Authorization': `Bearer ${argToken}` }
            });

            axiosInstance.get(`biowetter/${argLocID}`)
                .then(response => {
                    if (response && response.data && response.data.data) {
                        dispatch(getKBiowetterSuccess(response.data.data));
                    }
                    else {
                        dispatch(getKBiowetterFailure('Something went wrong'));
                    }
                })
                .catch(error => {
                    dispatch(getKBiowetterFailure(error.message));
                })
        };
        // eslint-disable-next-line no-unreachable
    } catch (error) { console.log('202102231042', error) }
};

const getKBiowetterSuccess = data => ({
    type: actionTypes.K_GET_BIOWETTER_SUCCESS,
    payload: data
});

const getKBiowetterStarted = () => ({
    type: actionTypes.K_GET_BIOWETTER_STARTED,
});

const getKBiowetterFailure = error => ({
    type: actionTypes.K_GET_BIOWETTER_FAILURE,
    payload: {
        error
    }
});

export const getCacheKBiowetterData = () => ({
    type: actionTypes.K_GET_BIOWETTER_CACHED,
});
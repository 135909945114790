import React, { Component } from 'react';
import AnziegeKachelComponent from '../../Components/AnziegeKachelComponent/AnziegeKachelComponent';

//import cssModule from './AnziegeKachel.module.css';

class AktuelleKachel extends Component{

    render(){
        return (<AnziegeKachelComponent type={this.props.werbungType}/>);
    };
};

export default AktuelleKachel;
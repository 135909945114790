import React, { memo, useEffect, useState } from 'react';
import { Typography, Breadcrumbs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as SiteLink } from 'react-router-dom';
import axios from 'axios';

import cssModule from './NewsSinglePage.module.css';
import { getNewsCategory } from '../../../../Assets/Functions/Functions';

function handleClick(event) {
    try {
        event.preventDefault();
    } catch (error) { console.log('202103101712', error) }
}

const useStyles = makeStyles((theme) => ({

    date: {
        color: 'rgba(84, 84, 84, .8)',
        fontSize: '1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    head: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '3rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    subHead: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '2rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.2rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    mainImage:{
        height: '45vh',
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        borderRadius:'5px',
        marginBottom:'1rem'
    }
}));

const WetterTVSingleComponent = memo((props) => {

    const classes = useStyles();
    let componentOrganizer = null;

    const [hookNews, setNews] = useState(null);

    const getDate = (argDate) => {
        try{
            if(argDate){
                let newsDate = new Date(argDate);
                return(newsDate.toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }));
            }
            else{
                return null;
            }
        }catch(error){}
    }

    const getTime = (argDate) => {
        try{
            if(argDate){
                let newsDate = new Date(argDate);
                return(newsDate.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit'}));
            }
            else{
                return null;
            }
        }catch(error){}
    }

    useEffect(() => {
        try {
            const fetchNewsData = async () => {

                const axiosInstance = axios.create({
                    baseURL: 'https://apps.qmet.de/v2/',
                    headers: { 'Authorization': `Bearer ${props.token.kToken}` }
                });

                await axiosInstance.get(`news/id/${props.match.params.id}`)
                    .then(response => {
                        if (response && response.data && response.data.data) {
                            if (response.data.data.description && response.data.data.description.length > 0) {
                                setNews(response.data.data);
                            } else {
                                //setNewsNoData(true);
                            }
                        }
                        else {
                            //setNewsDataError(true);
                        }
                    })
                    .catch(error => {
                        //setNewsDataError(true);
                        })
            }
            fetchNewsData();
        } catch (error) {
            //setNewsDataError(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.match.params.id, props.match.params.path]);

    try{
        componentOrganizer=(
            <React.Fragment>
                <div style={{backgroundImage:`URL(${hookNews ? hookNews.image_path ? hookNews.image_path : null : null})`}} className={classes.mainImage}></div>
                <Typography className={classes.head} variant="h1" component="h1" gutterBottom>{hookNews ? hookNews.Headline ? hookNews.Headline : null : null}</Typography>
                <Typography className={classes.date} variant="subtitle2" component="p" gutterBottom>{`Wiesbaden | ${hookNews ? hookNews.added_on ? getDate(hookNews.added_on) : null : null} | ${hookNews ? hookNews.added_on ? getTime(hookNews.added_on) : null : null} ${/*| Franziska Polak*/ "" }`}</Typography>
                {/*<Typography className={classes.subHead} variant="body1" component="p">{hookNews ? hookNews.sub_heading ? hookNews.sub_heading : null : null}</Typography>*/}
                <Typography className={classes.data} variant="body1" component="p">{hookNews ? hookNews.description ? hookNews.description : null : null}</Typography>
            </React.Fragment>
        );
    }catch(error){

    }

    return (

        <React.Fragment>
            <div className={cssModule.WetterTVKachelParent}>
                <div className={cssModule.HeadSection}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <SiteLink className={cssModule.BreadcrubsLink} color="inherit" to="/" onClick={handleClick}>Home</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Aktuell</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsLink} color="inherit" to="/wetter-news" onClick={handleClick}>Wetter-News</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>{
                            hookNews ? hookNews.Headline ? hookNews.Headline : null : null
                        }</SiteLink>
                    </Breadcrumbs>
                </div>

                <div className={cssModule.VideoDetailsSection}>
                    {componentOrganizer}
                </div>
            </div>

        </React.Fragment>
    );

});

export default WetterTVSingleComponent;
import * as actionTypes from './actionTypes';
import axios from 'axios';
import { GAEvent } from '../../Assets/GoogleAnalytics/googleAnalytics';

export const getPollen = (argToken, argLocID) => {
    try{
    return dispatch => {
        dispatch(getPollenDataStarted());
        const axiosInstance = axios.create({
            baseURL: 'https://apps.qmet.de/v2/',
            headers: { 'Authorization': `Bearer ${argToken}` }
        });

        axiosInstance.get(`pollen/${argLocID}`)
            .then(response => {
                if (response && response.data && response.data.data) {
                    dispatch(getPollenDataSuccess(response.data.data));
                }
                else {
                    dispatch(getPollenDataFailure('Something went wrong'));
                    GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Pollenflug - https://apps.qmet.de/v2/pollen/", "202107141736 - Location id: " + argLocID + ", Error: API respond without data / wrong data structure", null, true);
                }
            })
            .catch(error => {
                dispatch(getPollenDataFailure(error.message));
                GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Pollenflug - https://apps.qmet.de/v2/pollen/", "202107141737 - Location id: " + argLocID + ", Error: " + error.message, null, true);
            })
    };
    // eslint-disable-next-line no-unreachable
    } catch (error) { 
        GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Pollenflug - https://apps.qmet.de/v2/pollen/", "202107141738 - Location id: " + argLocID + ", Error: " + error.message, null, true);
     }
};

const getPollenDataSuccess = data => ({
    type: actionTypes.GET_POLLEN_SUCCESS,
    payload: data
});

const getPollenDataStarted = () => ({
    type: actionTypes.GET_POLLEN_STARTED
});

const getPollenDataFailure = error => ({
    type: actionTypes.GET_POLLEN_FAILURE,
    payload: {
        error
    }
});

export const resetPollenError = () => ({
    type: actionTypes.RESET_POLLEN_ERROR,
});
import * as actionTypes from './actionTypes';
import axios from 'axios';
import { GAEvent } from '../../Assets/GoogleAnalytics/googleAnalytics';

export const getRegen = (argToken, argLocID) => {
    try{
    return dispatch => {
        dispatch(getRegenDataStarted());
        const axiosInstance = axios.create({
            baseURL: 'https://apps.qmet.de/v2/',
            headers: { 'Authorization': `Bearer ${argToken}` }
        });

        axiosInstance.get(`rain/details/${argLocID}`)
            .then(response => {
                if (response && response.data && response.data.data) {
                    dispatch(getRegenDataSuccess(response.data.data));
                }
                else {
                    dispatch(getRegenDataFailure('Something went wrong'));
                    GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Regen - https://apps.qmet.de/v2/rain/details/", "202107141739 - Location id: " + argLocID + ", Error: API respond without data / wrong data structure", null, true);
                }
            })
            .catch(error => {
                dispatch(getRegenDataFailure(error.message));
                GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Regen - https://apps.qmet.de/v2/rain/details/", "202107141740 - Location id: " + argLocID + ", Error: " + error.message, null, true);
            })
        };
    // eslint-disable-next-line no-unreachable
    } catch (error) {
        GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Regen - https://apps.qmet.de/v2/rain/details/", "202107141741 - Location id: " + argLocID + ", Error: " + error.message, null, true);
     }
};

const getRegenDataSuccess = data => ({
    type: actionTypes.GET_REGEN_SUCCESS,
    payload: data
});

const getRegenDataStarted = () => ({
    type: actionTypes.GET_REGEN_STARTED
});

const getRegenDataFailure = error => ({
    type: actionTypes.GET_REGEN_FAILURE,
    payload: {
        error
    }
});

export const resetRegenError = () => ({
    type: actionTypes.RESET_REGEN_ERROR,
});
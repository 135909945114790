import React, { Component, Fragment, lazy, Profiler } from 'react';
import { Grid, Box } from '@material-ui/core';
import { connect } from 'react-redux';

import { GATiming, GAEvent } from '../../../../Assets/GoogleAnalytics/googleAnalytics';

const DatenschutzBestimmungen = lazy(() => import('../../../Components/Menu/DatenschutzBestimmungen/DatenschutzBestimmungen'));

class datenschutzBestimmungen extends Component {

    setPerformance = (argProfilerId, argMode, argActualTime, argBaseTime, argStartTime, argCommitTime, argInteracions) => {
        try {
            if (argMode && argMode.toString() === "mount") {
                GATiming(argProfilerId, argMode, argActualTime, (argProfilerId + " " + argMode));
            }
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Datenschutz Bestimmungen - setPerformance", ("202107151416 - Error: " + error.message), null, true);
        }
    }

    render() {

        const comDatenschutzBestimmungen = (<DatenschutzBestimmungen activeColorMode={this.props.colorMode} />)

        return (
            <Fragment>
                <Profiler id="agb" onRender={this.setPerformance}>
                    <Grid container spacing={0} style={{ height: '100%' }}>
                        <Grid item xs={12}>
                            <Box p='10px' style={{ height: '90%' }}>
                                {comDatenschutzBestimmungen}
                            </Box>
                        </Grid>
                    </Grid>
                </Profiler>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        colorMode: state.settingsSubReducer.colorMode,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        /*handleGetPollenData: (token, locID) => dispatch(actionCreators.getPollen(token, locID)),
        onResetPollenError: () => dispatch(actionCreators.resetPollenError()),*/
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(datenschutzBestimmungen);
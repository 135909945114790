import * as actionTypes from './actionTypes';

/* making async*/
export const asyncIncrement = (argVal) => {
    /* state (sort, delete etc...) manipulation here */
    return {
        type: actionTypes.INCREMENT,
        val: argVal
    }
}

export const increment = (argVal) => {
    return (dispatch, getState) => {
        /* getState return the prior state*/
        /* send http request here instead of srtTimeout */
        setTimeout (()=> {
            /*const oldCounter = getState().counterSubReducer.counter;*/
            dispatch(asyncIncrement(argVal));
        },2000);
    }
}
/* making async*/
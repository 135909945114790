export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_STARTED = 'GET_TOKEN_STARTED';
export const GET_TOKEN_FAILURE = 'GET_TOKEN_FAILURE';
export const RESET_TOKEN_ERROR = 'RESET_TOKEN_ERROR';

export const GET_HOME_SUCCESS = 'GET_HOME_SUCCESS';
export const GET_HOME_STARTED = 'GET_HOME_STARTED';
export const GET_HOME_FAILURE = 'GET_HOME_FAILURE';
export const RESET_HOME_ERROR = 'RESET_HOME_ERROR';

export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_STARTED = 'GET_NEWS_STARTED';
export const GET_NEWS_FAILURE = 'GET_NEWS_FAILURE';
export const GET_NEWS_ADD = 'GET_NEWS_ADD';
export const RESET_NEWS_ERROR = 'RESET_NEWS_ERROR';

export const GET_VIDEO_SUCCESS = 'GET_VIDEO_SUCCESS';
export const GET_VIDEO_STARTED = 'GET_VIDEO_STARTED';
export const GET_VIDEO_FAILURE = 'GET_VIDEO_FAILURE';
export const GET_VIDEO_ADD = 'GET_VIDEO_ADD';
export const RESET_VIDEO_ERROR = 'RESET_NEWS_ERROR';

export const GET_REGEN_SUCCESS = 'GET_REGEN_SUCCESS';
export const GET_REGEN_STARTED = 'GET_REGEN_STARTED';
export const GET_REGEN_FAILURE = 'GET_REGEN_FAILURE';
export const RESET_REGEN_ERROR = 'RESET_NEWS_ERROR';

export const GET_POLLEN_SUCCESS = 'GET_POLLEN_SUCCESS';
export const GET_POLLEN_STARTED = 'GET_POLLEN_STARTED';
export const GET_POLLEN_FAILURE = 'GET_POLLEN_FAILURE';
export const RESET_POLLEN_ERROR = 'RESET_NEWS_ERROR';

export const GET_BIOWETTER_SUCCESS = 'GET_BIOWETTER_SUCCESS';
export const GET_BIOWETTER_STARTED = 'GET_BIOWETTER_STARTED';
export const GET_BIOWETTER_FAILURE = 'GET_BIOWETTER_FAILURE';
export const RESET_BIOWETTER_ERROR = 'RESET_NEWS_ERROR';

export const LOCATION_ADD = 'LOCATION_ADD';
export const LOCATION_STORE = 'LOCATION_STORE';
export const LOCATION_REMOVE = 'LOCATION_REMOVE';
export const LOCATION_CURRENT_GET_SUCCESS = 'LOCATION_CURRENT_GET_SUCCESS';
export const LOCATION_CURRENT_GET_STARTED = 'LOCATION_CURRENT_STARTED';
export const LOCATION_CURRENT_GET_FAILURE = 'LOCATION_CURRENT_FAILURE';
export const LOCATION_FROM_LOCAL = 'LOCATION_FROM_LOCAL';
export const RESET_LOCATION_ERROR = 'RESET_NEWS_ERROR';

export const INCREMENT = 'INCREMENT';
export const ADD = 'ADD';
export const GET = 'GET';
export const UPDATE = 'UPDATE';
export const NOWDATA_ADD = 'NOWDATA_ADD';
export const SETTINGS_ADD = 'SETTINGS_ADD';
export const HOME_ADD = 'HOME_ADD';
export const POLLEN_ADD = 'POLLEN_ADD';
export const BIOWETTER_ADD = 'BIOWETTER_ADD';
export const REGEN_ADD = 'REGEN_ADD';
export const NEWS_ADD = 'NEWS_ADD';
export const VIDEO_ADD = 'VIDEO_ADD';

export const K_GET_AKTUELLE_SUCCESS = 'K_GET_AKTUELLE_SUCCESS';
export const K_GET_AKTUELLE_STARTED = 'K_GET_AKTUELLE_STARTED';
export const K_GET_AKTUELLE_FAILURE = 'K_GET_AKTUELLE_FAILURE';
export const K_GET_AKTUELLE_CACHED = 'K_GET_AKTUELLE_CACHED';

export const K_GET_BIOWETTER_SUCCESS = 'K_GET_BIOWETTER_SUCCESS';
export const K_GET_BIOWETTER_STARTED = 'K_GET_BIOWETTER_STARTED';
export const K_GET_BIOWETTER_FAILURE = 'K_GET_BIOWETTER_FAILURE';
export const K_GET_BIOWETTER_CACHED = 'K_GET_BIOWETTER_CACHED';

export const KD_GET_TAGESVERLAUF_SUCCESS = 'KS_GET_TAGESVERLAUF_SUCCESS';
export const KD_GET_TAGESVERLAUF_STARTED = 'KD_GET_TAGESVERLAUF_STARTED';
export const KD_GET_TAGESVERLAUF_FAILURE = 'KD_GET_TAGESVERLAUF_FAILURE';

export const KD_GET_VIERZEHNTAGESPROGNOSE_SUCCESS = 'KD_GET_VIERZEHNTAGESPROGNOSE_SUCCESS';
export const KD_GET_VIERZEHNTAGESPROGNOSE_STARTED = 'KD_GET_VIERZEHNTAGESPROGNOSE_STARTED';
export const KD_GET_VIERZEHNTAGESPROGNOSE_FAILURE = 'KD_GET_VIERZEHNTAGESPROGNOSE_FAILURE';

export const K_GET_POLLENFLUG_SUCCESS = 'K_GET_POLLENFLUG_SUCCESS';
export const K_GET_POLLENFLUG_STARTED = 'K_GET_POLLENFLUG_STARTED';
export const K_GET_POLLENFLUG_FAILURE = 'K_GET_POLLENFLUG_FAILURE';
export const K_GET_POLLENFLUG_CACHED = 'K_GET_POLLENFLUG_CACHED';

export const K_GET_POLLENKALENDER_SUCCESS = 'K_GET_POLLENKALENDER_SUCCESS';
export const K_GET_POLLENKALENDER_STARTED = 'K_GET_POLLENKALENDER_STARTED';
export const K_GET_POLLENKALENDER_FAILURE = 'K_GET_POLLENKALENDER_FAILURE';
export const K_GET_POLLENKALENDER_CACHED = 'K_GET_POLLENKALENDER_CACHED';

export const KS_GET_TAGESVERLAUF_SUCCESS = 'KS_GET_TAGESVERLAUF_SUCCESS';
export const KS_GET_TAGESVERLAUF_STARTED = 'KS_GET_TAGESVERLAUF_STARTED';
export const KS_GET_TAGESVERLAUF_FAILURE = 'KS_GET_TAGESVERLAUF_FAILURE';
export const KS_GET_TAGESVERLAUF_CACHED = 'KS_GET_TAGESVERLAUF_CACHED';

export const KS_GET_VIERZEHNTAGESPROGNOSE_SUCCESS = 'KS_GET_VIERZEHNTAGESPROGNOSE_SUCCESS';
export const KS_GET_VIERZEHNTAGESPROGNOSE_STARTED = 'KS_GET_VIERZEHNTAGESPROGNOSE_STARTED';
export const KS_GET_VIERZEHNTAGESPROGNOSE_FAILURE = 'KS_GET_VIERZEHNTAGESPROGNOSE_FAILURE';

export const K_GET_WETTERNEWS_SUCCESS = 'K_GET_WETTERNEWS_SUCCESS';
export const K_ADD_WETTERNEWS_SUCCESS = 'K_ADD_WETTERNEWS_SUCCESS';
export const K_GET_WETTERNEWS_STARTED = 'K_GET_WETTERNEWS_STARTED';
export const K_GET_WETTERNEWS_FAILURE = 'K_GET_WETTERNEWS_FAILURE';
export const K_GET_WETTERNEWS_CACHED = 'K_GET_WETTERNEWS_CACHED';

export const K_GET_WETTERTV_SUCCESS = 'K_GET_WETTERTV_SUCCESS';
export const K_ADD_WETTERTV_SUCCESS = 'K_ADD_WETTERTV_SUCCESS';
export const K_GET_WETTERTV_STARTED = 'K_GET_WETTERTV_STARTED';
export const K_GET_WETTERTV_FAILURE = 'K_GET_WETTERTV_FAILURE';
export const K_GET_WETTERTV_CACHED = 'K_GET_WETTERTV_CACHED';

export const K_GET_GRAPH_DATA_SUCCESS = 'K_GET_GRAPH_DATA_SUCCESS';
export const K_GET_GRAPH_DATA_STARTED = 'K_GET_GRAPH_DATA_STARTED';
export const K_GET_GRAPH_DATA_FAILURE = 'K_GET_GRAPH_DATA_FAILURE';
export const K_GET_GRAPH_DATA_CACHED = 'K_GET_GRAPH_DATA_CACHED';

export const K_LOCATION_CURRENT_GET_SUCCESS = 'K_LOCATION_CURRENT_GET_SUCCESS';
export const K_LOCATION_CURRENT_GET_STARTED = 'K_LOCATION_CURRENT_GET_STARTED';
export const K_LOCATION_CURRENT_GET_FAILURE = 'K_LOCATION_CURRENT_GET_FAILURE';

export const K_GET_TOKEN_SUCCESS = 'K_GET_TOKEN_SUCCESS';
export const K_GET_TOKEN_STARTED = 'K_GET_TOKEN_STARTED';
export const K_GET_TOKEN_FAILURE = 'K_GET_TOKEN_FAILURE';
export const K_LOCATION_REPLACE = 'K_LOCATION_REPLACE';

export const K_GET_GROSSWETTERLAGEN_SUCCESS = 'K_GET_GROSSWETTERLAGEN_SUCCESS';
export const K_GET_GROSSWETTERLAGEN_STARTED = 'K_GET_GROSSWETTERLAGEN_STARTED';
export const K_GET_GROSSWETTERLAGEN_FAILURE = 'K_GET_GROSSWETTERLAGEN_FAILURE';
export const K_GET_GROSSWETTERLAGEN_CACHED = 'K_GET_GROSSWETTERLAGEN_CACHED';

export const K_GET_GARTENTIPP_SUCCESS = 'K_GET_GARTENTIPP_SUCCESS';
export const K_GET_GARTENTIPP_STARTED = 'K_GET_GARTENTIPP_STARTED';
export const K_GET_GARTENTIPP_FAILURE = 'K_GET_GARTENTIPP_FAILURE';
export const K_GET_GARTENTIPP_CACHED = 'K_GET_GARTENTIPP_CACHED';

export const K_GET_GARTENWETTER_SUCCESS = 'K_GET_GARTENWETTER_SUCCESS';
export const K_GET_GARTENWETTER_STARTED = 'K_GET_GARTENWETTER_STARTED';
export const K_GET_GARTENWETTER_FAILURE = 'K_GET_GARTENWETTER_FAILURE';
export const K_GET_GARTENWETTER_CACHED = 'K_GET_GARTENWETTER_CACHED';
export const K_GET_WETTERLEXIKON_SUCCESS = 'K_GET_WETTERLEXIKON_SUCCESS';
export const K_GET_WETTERLEXIKON_STARTED = 'K_GET_WETTERLEXIKON_STARTED';
export const K_GET_WETTERLEXIKON_FAILURE = 'K_GET_WETTERLEXIKON_FAILURE';
export const K_GET_WETTERLEXIKON_CACHED = 'K_GET_WETTERLEXIKON_CACHED';

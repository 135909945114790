import React, { memo, useEffect, useState } from 'react';
import { Typography, Breadcrumbs, Grid } from '@material-ui/core';
import Aos from 'aos';
import "aos/dist/aos.css";
import { makeStyles } from '@material-ui/core/styles';
import { Link as SiteLink } from 'react-router-dom';

import cssModule from './PollenflugInnerPage.module.css';
import PollenFlugDetailsPage from './PollenflugDetailsPage/PollenflugDetailsPage';

const useStyles = makeStyles((theme) => ({

    head: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '3rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    }
}));

const sortArrayByDate = (a, b) => {
    try {
        if (a.date && b.date) {
            if (new Date(a.date) === new Date(b.date)) {
                return 0;
            }
            else {
                return (new Date(a.date) < new Date(b.date)) ? -1 : 1;
            }
        }
    } catch (error) { console.log('202103041250', error) }
}

let tabOrganizer = null;
let componentOrganizer = null;
let sortArray = [];

const PollenflugInnerPage = memo((props) => {

    const classes = useStyles();
    const [hookPollenData, setPollenData] = useState(null);
    const [hookTabIndex1, setTabIndex1] = useState(1);
    const [hookTabIndex2, setTabIndex2] = useState(0);
    const [hookTabIndex3, setTabIndex3] = useState(0);

    function handleClick(event) {
        try {
            event.preventDefault();
        } catch (error) { console.log('202103101712', error) }
    }

    const setParam = (argIndex, argData) => {
        try{
        if (argIndex === 0) {
            setPollenData(argData[0]);
            setTabIndex1(1);
            setTabIndex2(0);
            setTabIndex3(0);
        } else if (argIndex === 1) {
            setPollenData(argData[1]);
            setTabIndex1(0);
            setTabIndex2(1);
            setTabIndex3(0);
        }
        else if (argIndex === 2) {
            setPollenData(argData[2]);
            setTabIndex1(0);
            setTabIndex2(0);
            setTabIndex3(1);
        }
        } catch (error) { console.log('202103101530', error) }
    }

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    try{
    if (props.componentData && props.componentData.kPollenflug && props.componentData.kPollenflug.length > 0) {
        sortArray = props.componentData.kPollenflug;
        sortArray = sortArray.sort(sortArrayByDate);
        if (sortArray.length === 0) {
            tabOrganizer = null;
        } else if (sortArray.length === 1) {
            componentOrganizer = (<PollenFlugDetailsPage componentData={sortArray[0]} />);
            tabOrganizer = (
                <React.Fragment>
                    <div className={hookTabIndex1 === 1 ? cssModule.ActiveTab : null} onClick={() => { setParam(0, sortArray) }}>Pollenflug heute</div>
                    { props.componentData.loading ? <span className={cssModule.Loader}></span> : null }
                </React.Fragment>
            );
        } else if (sortArray.length === 2) {
            componentOrganizer = (<PollenFlugDetailsPage componentData={sortArray[0]} />);
            tabOrganizer = (
                <React.Fragment>
                    <div className={hookTabIndex1 === 1 ? cssModule.ActiveTab : null} onClick={() => { setParam(0, sortArray) }}>Pollenflug heute</div>
                    <div>|</div>
                    <div className={hookTabIndex2 === 1 ? cssModule.ActiveTab : null} onClick={() => { setParam(1, sortArray) }}>morgen</div>
                    { props.componentData.loading ? <span className={cssModule.Loader}></span> : null}
                </React.Fragment>
            );
        }
        else if (sortArray.length === 3 || sortArray.length > 3) {
            componentOrganizer = (<PollenFlugDetailsPage componentData={sortArray[0]} />);
            tabOrganizer = (
                <React.Fragment>
                    <div className={hookTabIndex1 === 1 ? cssModule.ActiveTab : null} onClick={() => { setParam(0, sortArray) }}>Pollenflug heute</div>
                    <div>|</div>
                    <div className={hookTabIndex2 === 1 ? cssModule.ActiveTab : null} onClick={() => { setParam(1, sortArray) }}>morgen</div>
                    <div>|</div>
                    <div className={hookTabIndex3 === 1 ? cssModule.ActiveTab : null} onClick={() => { setParam(2, sortArray) }}>übermorgen</div>
                    { props.componentData.loading ? <span className={cssModule.Loader}></span> : null}
                </React.Fragment>
            );
        }
    }
    } catch (error) { console.log('202103101531', error) }

    return (
        <React.Fragment>
             <div className={cssModule.WetterSymbolLegendeParent}>
                <div className={cssModule.HeadSection}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <SiteLink className={cssModule.BreadcrubsLink} color="inherit" to="/" onClick={handleClick}>Home</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Gesundheit</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Pollenflug</SiteLink>
                    </Breadcrumbs>
                </div>
                <div className={cssModule.DataSection}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography className={classes.head} variant="h1" component="h1" gutterBottom>Der Pollenflug für { props.locationData ? props.locationData.kLocation? props.locationData.kLocation.name ? props.locationData.kLocation.name.toString() : "-" : "-" : "-" }</Typography>
                        <div className={cssModule.PollenflugItemsContainer}>
                            <div className={cssModule.ButtonTags}>
                                {tabOrganizer}
                            </div>
                            <Typography variant="subtitle1" component="div" className={cssModule.ContainerHeading}>Belastung durch</Typography>
                            {hookPollenData === null ? componentOrganizer : <PollenFlugDetailsPage componentData={hookPollenData} />}
                        </div>
                    </Grid>
                </Grid>
                </div>
             </div>
        </React.Fragment>
    );
});

export default PollenflugInnerPage;
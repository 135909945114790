import React from 'react';
import { Typography, Button } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";

import cssModule from './SomethingWentWrong.module.css';
import { ReactComponent as Wetter6 } from '../../../Assets/Desktop/Common/heavyrain.svg';

const useStyles = makeStyles({
    button: {
        textTransform: "none",
        transition: '.2s ease-in',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        padding: '1px',
        marginTop: '2rem',
    },
    textsHead:{
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginTop:'1rem',
        textShadow: '0px 3px 1px rgba(150, 150, 150, 0.35)'
    },
    textsDetails: {
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginTop:'1rem',
        fontSize:'1.3rem',
        textShadow: '0px 3px 1px rgba(150, 150, 150, 0.35)'
    }
});

const SomethingWentWrong = (props) => {
    const history = useHistory();
    const classes = useStyles();

    const handleButtonClick = () => {
        try{
            history.push("/");
        } catch (error) { console.log('202103101532', error) }

    }

    return (
        <div className = {cssModule.Parent}>
            <div className = {cssModule.ContainerBlock}>
                <Wetter6 height={200}/>
                <Typography className={classes.textsHead} variant="h3" component="h3">Hoppla! Etwas ist schief gelaufen!</Typography>
                <Typography className={classes.textsDetails} variant="body1" component="p">Die Anwendung hat einen unbekannten Fehler festgestellt. Unser technisches Personal wurde benachrichtigt und wird dies mit äußerster Dringlichkeit prüfen.</Typography>
                <Button className={classes.button} disableFocusRipple variant="contained" size="small" color="primary" onClick={handleButtonClick}>zurück</Button>
            </div>
        </div>
    );

}

export default SomethingWentWrong;
/* eslint-disable no-unreachable */
import * as actionTypes from './actionTypes';
import axios from 'axios';
import { GAEvent } from '../../Assets/GoogleAnalytics/googleAnalytics';

export const getNews = (argToken, argPageVal) => {

    try{
    return dispatch => {
        dispatch(getNewsStarted());
        const axiosInstance = axios.create({
            baseURL: 'https://apps.qmet.de/v2/',
            headers: { 'Authorization': `Bearer ${argToken}` }
        });

        axiosInstance.get(`news/${parseInt(argPageVal)}?locale=en`)
            .then(response => {
                if (response && response.data && response.data.data && response.data.data.length > 0) {
                    dispatch(getNewsSuccess(response.data.data));
                }
                else {
                    dispatch(getNewsFailure('Something went wrong'));
                    GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News - https://apps.qmet.de/v2/news/", "202107141730 - Page no : " + argPageVal + ", Locale: en, Error: API respond without data / wrong data structure", null, true);
                }
            })
            .catch(error => {
                dispatch(getNewsFailure(error.message));
                GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News - https://apps.qmet.de/v2/news/", "202107141731 - Page no : " + argPageVal + ", Locale: en, Error: " + error.message, null, true);
            })
        };
    } catch (error) { 
        GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News - https://apps.qmet.de/v2/news/", "202107141732 - Page no : " + argPageVal + ", Locale: en, Error: " + error.message, null, true);
     }
};

export const getMoreNews = (argToken, argPageVal) => {
try{
    return dispatch => {
        dispatch(getNewsStarted());
        const axiosInstance = axios.create({
            baseURL: 'https://apps.qmet.de/v2/',
            headers: { 'Authorization': `Bearer ${argToken}`}
        });

        axiosInstance.get(`news/${parseInt(argPageVal)}?locale=en`)
            .then(response => {
                if (response && response.data && response.data.data && response.data.data.length > 0) {
                    dispatch(getMoreNewsSuccess(response.data.data));
                }
                else {
                    dispatch(getNewsFailure('Something went wrong'));
                    GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News - https://apps.qmet.de/v2/news/", "202107141733 - Page no : " + argPageVal + ", Locale: en, Error: API respond without data / wrong data structure", null, true);
                }
            })
            .catch(error => {
                dispatch(getNewsFailure(error.message));
                GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News - https://apps.qmet.de/v2/news/", "202107141734 - Page no : " + argPageVal + ", Locale: en, Error: " + error.message, null, true);
            })
    };
} catch (error) {
    GAEvent("Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News - https://apps.qmet.de/v2/news/", "202107141735 - Page no : " + argPageVal + ", Locale: en, Error: " + error.message, null, true);
 }
};

const getNewsSuccess = data => ({
    type: actionTypes.GET_NEWS_SUCCESS,
    payload: data
});

const getMoreNewsSuccess = data => ({
    type: actionTypes.GET_NEWS_ADD,
    payload: data
});

const getNewsStarted = () => ({
    type: actionTypes.GET_NEWS_STARTED
});

const getNewsFailure = error => ({
    type: actionTypes.GET_NEWS_FAILURE,
    payload: {
        error
    }
});

export const resetNewsError = () => ({
    type: actionTypes.RESET_NEWS_ERROR,
});
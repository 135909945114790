import React, { Component, Fragment, lazy, Profiler } from 'react';
import { Grid, Box } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import * as actionCreators from '../../../../store/actions/index';
import { GATiming, GAEvent } from '../../../../Assets/GoogleAnalytics/googleAnalytics';

const Pollenflug = lazy(() => import('../../../Components/Menu/Pollenflug/Pollenflug'));

class pollenflug extends Component {

    state = {
        pollenDataError: false,
        pollenNoData: false,
        pollenData: undefined,
    }

    delay = (ms) => new Promise(res => setTimeout(res, ms));

    isTokenValid = async () => {

        try {

            let timeNow = new Date();
            let LSData = window.localStorage.getItem('wetternet');
            let LSDataObject = null;
            if (LSData && LSData !== null && LSData.length > 0) {
                LSDataObject = JSON.parse(LSData);
                if (LSDataObject && LSDataObject.token && LSDataObject.token.createTime) {
                    if (new Date(timeNow) > new Date(LSDataObject.token.createTime)) {
                        this.props.handleGetToken();
                        await this.delay(5000);
                        GAEvent("Token", "Token Expired", "Fetch new token", null, true);
                    }
                }
            }
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Token - isTokenValid", ("202107201704 - Error: " + error.message), null, true);
        }
    }

    componentDidMount = async () => {
        try {
            if (this.props.location && this.props.location !== null && this.props.location.ID_Stadt) {
                await this.isTokenValid();
                this.props.handleGetPollenData(this.props.userToken, this.props.location.ID_Stadt);
            } else {
                this.props.history.push('/meine-stadte');
            }

            const timer = setTimeout(() => {
                if (this.props.pollen.error !== null) {
                    this.setState({ pollenDataError: true });
                }
                else {
                    if (this.props.pollen.pollen) {
                        if (this.props.pollen.pollen === null || this.props.pollen.pollen === undefined || this.props.pollen.pollen.length <= 0) {
                            this.setState({ pollenNoData: true });
                        }
                    }
                    else {
                        this.setState({ pollenDataError: true });
                    }
                }
            }, 3000);
            return () => clearTimeout(timer);

        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Pollenflug - componentDidMount", ("202107151418 - Error: " + error.message), null, true);
        }
    }

    componentDidUpdate = async (prevProps) => {
        try {
            if (prevProps.location && prevProps.location.ID_Stadt && this.props.location && this.props.location.ID_Stadt) {
                if (Number(prevProps.location.ID_Stadt) !== Number(this.props.location.ID_Stadt)) {
                    await this.isTokenValid();
                    this.props.handleGetPollenData(this.props.userToken, this.props.location.ID_Stadt);
                }
            }
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Pollenflug - componentDidUpdate", ("202107151419 - Error: " + error.message), null, true);
        }
    }

    handleAlertClose = () => {
        try {
            if (this.props.pollen.pollen) {
                if (this.props.pollen.pollen === null || this.props.pollen.pollen === undefined || this.props.pollen.pollen.length <= 0) {
                    this.props.history.push('/');
                }
            }
            this.props.onResetPollenError();
            this.setState({ pollenDataError: false, pollenNoData: false });
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Pollenflug - handleAlertClose", ("202107151420 - Error: " + error.message), null, true);
         }
    }

    setPerformance = (argProfilerId, argMode, argActualTime, argBaseTime, argStartTime, argCommitTime, argInteracions) =>{
        try{
            if(argMode && argMode.toString() === "mount"){
                GATiming(argProfilerId, argMode, argActualTime, ( argProfilerId + " " + argMode ));
            }
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Pollenflug - setPerformance", ("202107151421 - Error: " + error.message), null, true);
        }
    }

    render() {

        let componentOrganizer = null;

        if (this.props && this.props.pollen && this.props.pollen.pollen){
            componentOrganizer = (<Pollenflug componentData={this.props.pollen.pollen ? this.props.pollen.pollen : null} activeColorMode={this.props.colorMode} />);
        }

        return (
            <Fragment>
                <Profiler id="Pollenflug" onRender={this.setPerformance}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Box p='0px'>
                                {componentOrganizer ? componentOrganizer : <div className="NoPage">{this.props.t('common.messages.unable_to_load_data')}</div>}
                            </Box>
                        </Grid>
                    </Grid>
                    <SweetAlert
                        show={this.state.pollenDataError}
                        warning
                        confirmBtnText="Okay"
                        confirmBtnCssClass={this.props.colorMode ? this.props.colorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                        style={this.props.colorMode ? this.props.colorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                        title={this.props.t('common.messages.attention')}
                        onConfirm={this.handleAlertClose}>
                            {
                                this.props.t('common.messages.unable_to_load_data')
                            }
                </SweetAlert>
                    <SweetAlert
                        show={this.state.pollenNoData}
                        warning
                        confirmBtnText={this.props.t('common.messages.back')}
                        confirmBtnCssClass={this.props.colorMode ? this.props.colorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                        style={this.props.colorMode ? this.props.colorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                        title={this.props.t('common.messages.attention')}
                        onConfirm={this.handleAlertClose}>
                            {
                                this.props.t('common.messages.no_data_now')
                            }
                </SweetAlert>
            </Profiler>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        location: state.locationSubReducer.location,
        pollen: state.pollenSubReducer,
        colorMode: state.settingsSubReducer.colorMode,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetPollenData: (token, locID) => dispatch(actionCreators.getPollen(token, locID)),
        onResetPollenError: () => dispatch(actionCreators.resetPollenError()),
        handleGetToken: () => { dispatch(actionCreators.getToken()) }
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(pollenflug));
import React, { memo } from 'react';
import {
    Typography,
    Breadcrumbs,
    Link,
    Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as SiteLink } from 'react-router-dom';

import ImgWetterNetMobil from '../../../../Assets/Desktop/Common/wetternet_mobil.jpg';
import cssModule from './WetterNetMobil.module.css';

const useStyles = makeStyles((theme) => ({

    head: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '3rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    head1: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '2.5rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    head2: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '1.9rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    head3: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '1.5rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    subHeadDark: {
        textTransform: "none",
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '600',
        fontSize: '1.2rem'
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '15px'
    },
    dataLast: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '0px'
    },
    dataLink: {
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '400',
    },
    MobilImage:{
        maxWidth: '100%',
        display: 'block',
        height: 'auto',
        marginTop: '0rem',
        marginBottom: '1rem',
        borderRadius:'5px'
    },
    DownloadContainer:{
        padding:'15px 0px',
        display:'flex',
        alignItems:'center',
        justifyContent: 'center',
        flexDirection: 'row'
    }
}));

const WetterNetMobil = memo((props) => {

    const classes = useStyles();

    function handleClick(event) {
        try {
            event.preventDefault();
        } catch (error) { console.log('202103101712', error) }
    }

    let componentOrganizer = null;

    try {
        componentOrganizer = (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography className={classes.head} variant="h1" component="h1" gutterBottom>Wetter.net mobil</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <img className={classes.MobilImage} src={ImgWetterNetMobil} alt="wetternet mobil" />
                        <Typography className={classes.head2} variant="h3" component="h3" >Wetter.net als Android App</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography className={classes.data} variant="body1" component="p">Die neue Wetter App von Wetter.net ist kostenlos und bietet 
                        dir von stündlichen Wettervorhersagen und verschiedenen Wetterkarten, über ein genaues Regenradar, Pollenflug und Biowetter 
                        für Allergiker bis hin zu Sonnenaufgang- und Sonnenuntergangangaben (in Deutschland, Österreich, Schweiz) alles was du brauchst. 
                        Rufe dein Wetter online ab, nutze unser Wetter-Widget oder bring dich täglich durch unsere Wetternews und Wettervideos auf den 
                        neusten Stand.</Typography>
                        <Typography className={classes.subHeadDark} variant="h3" component="h3" >Unsere Highlights:</Typography>
                        <ul>
                            <li>
                                <Typography className={classes.data} variant="body1" component="p">🌍 Wettervorhersage für jede Stadt weltweit</Typography>
                            </li>
                            <li>
                                <Typography className={classes.data} variant="body1" component="p">☔️ Regenradar topaktuell: Berechnungen alle 5 Minuten</Typography>
                            </li>
                            <li>
                                <Typography className={classes.data} variant="body1" component="p">🌾 Pollenflug Vorhersage für Allergiker</Typography>
                            </li>
                            <li>
                                <Typography className={classes.data} variant="body1" component="p">✔️ Alles Wichtige auf einen Blick mit unserem Wetter-Widget</Typography>
                            </li>
                            <li>
                                <Typography className={classes.data} variant="body1" component="p">🙇 Biowetter für Wetterfeinfühlige</Typography>
                            </li>
                            <li>
                                <Typography className={classes.data} variant="body1" component="p">📲 Täglich neue News und Videos aus unserer Redaktion und Studio</Typography>
                            </li>
                        </ul>

                        <div className={classes.DownloadContainer}>
                            <Link href="https://play.google.com/store/apps/details?id=com.qmet.wetternet" target="_blank" rel="noreferrer">
                                <span style={{textAlign: 'center'}}>
                                    <img src="https://www.wetter.net/images/googleplay.png" alt="googleplay button"/>
                                    <Typography className={classes.dataLast} variant="body1" component="p">Android App</Typography>
                                </span>
                            </Link>
                        </div>

                        <br/>
                        <Typography className={classes.head3} variant="h3" component="h3" >Die Highlights im Detail:</Typography>
                        <br/>
                        <Typography className={classes.subHeadDark} variant="h3" component="h3" >Wettervorhersage zum aktuellen Wetterzustand</Typography>
                        <ul>
                            <li>
                                <Typography className={classes.data} variant="body1" component="p">✅ Aktuelle Temperatur</Typography>
                            </li>
                            <li>
                                <Typography className={classes.data} variant="body1" component="p">✅ Gefühlte Temperatur</Typography>
                            </li>
                            <li>
                                <Typography className={classes.data} variant="body1" component="p">✅ Nacht-Temperatur</Typography>
                            </li>
                            <li>
                                <Typography className={classes.data} variant="body1" component="p">✅ Niederschlagsmenge in mm</Typography>
                            </li>
                            <li>
                                <Typography className={classes.data} variant="body1" component="p">✅ Temperaturverlauf mit Wettersymbolen und Niederschlagsmenge als Diagramm</Typography>
                            </li>
                            <li>
                                <Typography className={classes.data} variant="body1" component="p">✅ Windgeschwindigkeit in km/h</Typography>
                            </li>
                            <li>
                                <Typography className={classes.data} variant="body1" component="p">✅ Luftdruck in hPa</Typography>
                            </li>
                            <li>
                                <Typography className={classes.data} variant="body1" component="p">✅ Luftfeuchtigkeit in %</Typography>
                            </li>
                           <li>
                                <Typography className={classes.data} variant="body1" component="p">✅ Angabe zum Sonnenaufgang sowie Sonnenuntergang</Typography>
                            </li>
                        </ul>
                        <br />
                        <Typography className={classes.subHeadDark} variant="h3" component="h3" >Wetterkarten</Typography>
                        <Typography className={classes.data} variant="body1" component="p">
                            Unsere Wetterkarten sind genau und übersichtlich, damit du unkompliziert deinen gewünschten Wetterzustand abfragen kannst. Als Hintergrund bieten dir
                            die Wetterkarten Satellit und OpenStreetMap.
                        </Typography>
                        <br />
                        <Typography className={classes.data} variant="body1" component="p">
                            📡 Hochaufgelöstes Regenradar für Deutschland, Österreich und Schweiz: Visualisiert im 5-Minuten-Takt den tatsächlich gemessenen Regen der letzten 
                            15 Minuten, sowie eine Prognose für die kommenden 1,5 Stunden.
                        </Typography>
                        <Typography className={classes.data} variant="body1" component="p">
                            ☁️ Karte „Wolken“: Visualisiert die stündliche Wolkenprognose weltweit, für bis zu 18 Stunden in die Zukunft.
                        </Typography>
                        <Typography className={classes.data} variant="body1" component="p">
                            🌡 Karte „Temperatur“: Visualisiert farblich die Temperaturprognosen weltweit, für bis zu 18 Stunden in die Zukunft.
                        </Typography>
                        <Typography className={classes.data} variant="body1" component="p">
                            💧Karte „Regen“: Visualisiert farblich den stündlichen Flug der Regenwolken mit Angabe der Niederschlagsmenge in mm, 
                            für bis zu 18 Stunden in die Zukunft.
                        </Typography>
                        <br />
                        <Typography className={classes.subHeadDark} variant="h3" component="h3" >Pollenflug:</Typography>
                        <Typography className={classes.data} variant="body1" component="p">
                            Ob in Deutschland, Österreich oder der Schweiz: Hier kannst du eine detaillierte Pollenflug Vorhersage von bis zu 20 
                            Pollenarten für den heutigen und morgigen Tag abfragen.
                        </Typography>
                        <Typography className={classes.subHeadDark} variant="h3" component="h3" >Bio-Wetter:</Typography>
                        <Typography className={classes.data} variant="body1" component="p">
                            Für Wetterfeinfühlige bietet sich die Bio-Wetter Vorhersage mit bis zu 20 Eigenschaften für die nächsten 4 Tage an 
                            (Deutschland, Österreich, Schweiz).
                        </Typography>
                        <Typography className={classes.subHeadDark} variant="h3" component="h3" >Videos:</Typography>
                        <Typography className={classes.data} variant="body1" component="p">
                            Alle Videos vom größten YouTube-Wetterkanal Deutschlands findest du auf einen Blick in der App.
                        </Typography>
                        <Typography className={classes.subHeadDark} variant="h3" component="h3" >Wetter-Widget:</Typography>
                        <Typography className={classes.data} variant="body1" component="p">
                            Unser Wetter-Widget kannst du ganz einfach einrichten und nutzen, um immer topaktuell informiert zu sein.
                        </Typography>
                        <Typography className={classes.subHeadDark} variant="h3" component="h3" >News:</Typography>
                        <Typography className={classes.data} variant="body1" component="p">
                            Unsere Redaktion verfasst tägliche Wetternews zum Durchlesen, damit du immer auf dem aktuellen Stand bist – und das alles kostenlos.
                        </Typography>
                        <Typography className={classes.subHeadDark} variant="h3" component="h3" >Wetterfotos senden:</Typography>
                        <Typography className={classes.data} variant="body1" component="p">
                            Wenn du Teil unserer Wetter-Community werden möchtest, kannst du uns gerne deine Wetterfotos senden und mit etwas Glück zeigen 
                            wir dein Foto in unserem YouTube Video.
                        </Typography>
                        <Typography className={classes.data} variant="body1" component="p">
                            Jetzt unsere Wetter App kostenlos downloaden und immer topinformiert sein!
                        </Typography>
                        <Typography className={classes.data} variant="body1" component="p">
                            Für Verbesserungsvorschläge sowie Feedbackanregungen gerne ein Kommentar im Playstore hinterlassen. Wir gehen alle 
                            Kommentare durch und beantworten auch eure Fragen.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography className={classes.head2} variant="h3" component="h3" >Wetter.net iPhone/iPad App</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography className={classes.data} variant="body1" component="p">wetter.net ist auch für iPhones und iPads erhältlich. 
                        Sie können die wetter.net App direkt im iTunes Store herunterladen. Wir freuen uns auf Ihre Bewertung und sind auf Ihr 
                        Feedback gespannt.</Typography>
                    </Grid>
                </Grid>

                <div className={classes.DownloadContainer}>
                    <Link style={{ marginRight: '5px' }} href="https://apps.apple.com/de/app/wetter-net-weather-app/id438287289" target="_blank" rel="noreferrer">
                        <span style={{ textAlign: 'center' }}>
                            <img src="https://linkmaker.itunes.apple.com/assets/shared/badges/de-de/appstore-lrg.svg" alt="googleplay button" />
                            <Typography className={classes.dataLast} variant="body1" component="p">iPhone App</Typography>
                        </span>
                    </Link>
                    <Link style={{ marginLeft: '5px' }} href="https://apps.apple.com/de/app/wetter-net-for-ipad/id463420637" target="_blank" rel="noreferrer">
                        <span style={{ textAlign: 'center' }}>
                            <img src="https://linkmaker.itunes.apple.com/assets/shared/badges/de-de/appstore-lrg.svg" alt="googleplay button" />
                            <Typography className={classes.dataLast} variant="body1" component="p">iPad App</Typography>
                        </span>
                    </Link>
                </div>

            </React.Fragment>
        );
    } catch (error) {
        console.log('202107271435', error)
    }
    return (
        <React.Fragment>
            <div className={cssModule.MobilParent}>
                <div className={cssModule.HeadSection}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <SiteLink className={cssModule.BreadcrubsLink} color="inherit" to="/" onClick={handleClick}>Home</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Allgemein</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Wetter.net mobil</SiteLink>
                    </Breadcrumbs>
                </div>
                <div className={cssModule.DataSection}>
                    {componentOrganizer}
                </div>
            </div>
        </React.Fragment>
    );
});

export default WetterNetMobil;
import React, {Component} from 'react';
import { connect } from 'react-redux';

import * as actionCreators from '../../../../store/actions/index';
import WetterNewsSingleComponent from '../../../Components/WetterNewsKachelComponent/WetterNewsSingleComponent/WetterNewsSingleComponent';

class WetterNewsSingle extends Component {
    render(){
        return (<WetterNewsSingleComponent/>);
    }
}

const mapStateToProps = state => {
    return {
        kWetternews: state.kWetternewsSubReducer,
        token: state.tokenSubReducer,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetKWetternews: (token, page) => dispatch(actionCreators.getKWetternews(token, page)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WetterNewsSingle);
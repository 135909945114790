import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actionCreators from '../../../../store/actions/index';
import PollenflugInnerPageComponent from '../../../Components/InnerPages/PollenflugInnerPage/PollenflugInnerPage';

class PollenflugInnerPage extends Component {

    componentDidMount = () => {
        try {
            if (this.props.kToken && this.props.kToken.kToken && this.props.kToken.kToken.length > 0) {
                this.props.onGetPollenflugData(this.props.kToken.kToken, this.props.kLocation.kLocation.ID_Stadt);
            }
        } catch (error) { console.log('202103101405', error) }
    }

    render() {

        return (
            <PollenflugInnerPageComponent componentData={this.props.kPollenflug} locationData={this.props.kLocation}/>
        );
    }

}

const mapStateToProps = state => {
    return {
        kLocation: state.kLocationSubReducer,
        kToken: state.kTokenSubReducer,
        kPollenflug: state.kPollenflugSubReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onGetPollenflugData: (argToken, argLocationID) => { dispatch(actionCreators.getKPollenflug(argToken, argLocationID)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PollenflugInnerPage);
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import * as actionCreators from '../../../../store/actions/index';
import VideoInnerPageComponent from '../../../Components/InnerPages/VideoInnerPage/VideoInnerPage';

class VideoInnerPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            videoPageNumber: 1,
        }
    }

    componentDidMount = () => {
        try {
            if (this.props.kToken && this.props.kToken.kToken && this.props.kToken.kToken.length > 0) {
                this.props.handleGetKWettertv(this.props.kToken.kToken, 0);
            }
        } catch (error) { console.log('202103101405', error) }
    }

    componentDidUpdate = async (prevProps) => {

        try {
            if (prevProps.kToken !== this.props.kToken && this.props.kToken && this.props.kToken.kToken && this.props.kToken.kToken.length > 0) {
                /*if (await this.isTokenValid()) {*/
                this.props.handleGetKWettertv(this.props.kToken.kToken, 0);
                /*}*/
            }
        } catch (error) {
            console.log('202109221634', error);
        }
    }

    getVideo = () => {
        try {
            if (this.props.kToken && this.props.kToken.kToken && this.props.kToken.kToken.length > 0) {
                this.props.handleGetKWettertv(this.props.kToken.kToken, this.state.videoPageNumber)
                this.setState({ videoPageNumber: this.state.videoPageNumber + 1 });
            }
        } catch (error) { console.log('202103101406', error) }
    }

    render() {
        return (
            <VideoInnerPageComponent componentData={this.props.kWettertv} getMoreVideo={this.getVideo} />
        );
    }
}

const mapStateToProps = state => {
    return {
        kWettertv: state.kWettertvSubReducer,
        kToken: state.kTokenSubReducer,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetKWettertv: (token, page) => dispatch(actionCreators.getKWettertv(token, page)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoInnerPage);
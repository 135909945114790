import React, { Component, Fragment, lazy, Profiler } from 'react';
import { Grid } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import * as actionCreators from '../../../store/actions/index';
import { GATiming, GAEvent } from '../../../Assets/GoogleAnalytics/googleAnalytics';

const NewsList = lazy(() => import('../../Components/News/NewsList/NewsList'));

class news extends Component {

    constructor(props) {
        super(props);
        this.btnRef = React.createRef();
        this.state = {
            hasError: null,
            errorInfo: null,
            newsDataError: false,
            newsData: undefined,
            newsNoData: false,
            initialVal: 0
        }
    }

    delay = (ms) => new Promise(res => setTimeout(res, ms));

    isTokenValid = async () => {

        try {

            let timeNow = new Date();
            let LSData = window.localStorage.getItem('wetternet');
            let LSDataObject = null;
            if (LSData && LSData !== null && LSData.length > 0) {
                LSDataObject = JSON.parse(LSData);
                if (LSDataObject && LSDataObject.token && LSDataObject.token.createTime) {
                    if (new Date(timeNow) > new Date(LSDataObject.token.createTime)) {
                        this.props.handleGetToken();
                        await this.delay(5000);
                        GAEvent("Token", "Token Expired", "Fetch new token", null, true);
                    }
                }
            }
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Token - isTokenValid", ("202107201654 - Error: " + error.message), null, true);
        }
    }

    componentDidUpdate = async (prevProp) => {
        try {
            await this.isTokenValid();
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Meine Stadte - componentDidUpdate", ("202107201653 - Error: " + error.message), null, true);
        }
    }

    componentDidMount = async () => {
        try {
            await this.isTokenValid();
            this.props.handleGetNewsData(this.props.userToken, this.state.initialVal);

            const timer = setTimeout(() => {
                if (this.props.news.error !== null) {
                    this.setState({ newsDataError: true });
                }
                else {
                    if (this.props.news.news) {
                        if (this.props.news.news === null || this.props.news.news === undefined || this.props.news.news.length <= 0) {
                            this.setState({ newsNoData: true });
                        }
                    }
                    else {
                        this.setState({ newsDataError: true });
                    }
                }
            }, 3000);
            return () => clearTimeout(timer);
            //await this.getNewsData();
        } catch (error) {
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News - componentDidMount", ("202107151441 - Error: " + error.message), null, true);
         }
    }


    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        this.setState({ hasError: error, errorInfo: errorInfo })
    }

    handleAlertClose = () => {
        try{
        if (this.props.news.news) {
            if (this.props.news.news === null || this.props.news.news === undefined || this.props.news.news.length <= 0) {
                this.props.history.push('/');
            }
        }
        this.props.onResetNewsError()
        this.setState({ newsDataError: false, newsNoData: false });
    }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News - handleAlertClose", ("202107151442 - Error: " + error.message), null, true);
    }
    }

    loadMoreNews = () => {
        try {
            //this.getNewsData(this.state.initialVal + 1);
            this.props.handleGetMoreNewsData(this.props.userToken, this.state.initialVal + 1)
            this.setState({ initialVal: this.state.initialVal + 1 });
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News - loadMoreNews", ("202107151443 - Error: " + error.message), null, true);
         }
    }

    /*updateNews = (argNewNews) =>{
        let currentNews = this.state.newsData;
        this.setState({ newsData: currentNews.concat(argNewNews)})
    }*/

    setPerformance = (argProfilerId, argMode, argActualTime, argBaseTime, argStartTime, argCommitTime, argInteracions) =>{
        try{
            if(argMode && argMode.toString() === "mount"){
                GATiming(argProfilerId, argMode, argActualTime, ( argProfilerId + " " + argMode ));
            }
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News - setPerformance", ("202107151444 - Error: " + error.message), null, true);
        }
    }

    render() {

        let componentOrganizer = null;

        try{
            if (this.props && this.props.news && this.props.news.news && this.props.news.news.length > 0){
                componentOrganizer = (<NewsList fetchNewsData={this.loadMoreNews} componentData={this.props.news.news ? this.props.news.news : null} activeColorMode={this.props.colorMode} />);
            }
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "News - render", ("202107151445 - Error: " + error.message), null, true);
        }

        return (
            <Fragment>
                <Profiler id="News" onRender={this.setPerformance}>
                    <Grid container spacing={0} style={{ height: '100%' }}>
                        <Grid item xs={12}>
                            <div style={{ height: '100%' }}>
                                {componentOrganizer ? componentOrganizer : <div className="NoPage">{this.props.t('common.messages.unable_to_load_data')}</div>}
                            </div>
                        </Grid>
                    </Grid>
                    {this.state.errorInfo ?
                        (<div>
                            <h2>Something went wrong.</h2>
                            <details style={{ whiteSpace: 'pre-wrap' }}>
                                {this.state.error && this.state.error.toString()}
                                <br />
                                {this.state.errorInfo.componentStack}
                            </details>
                        </div>)
                        : null}
                    <SweetAlert
                        show={this.state.newsDataError}
                        warning
                        confirmBtnText="Okay"
                        confirmBtnCssClass={this.props.colorMode ? this.props.colorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                        style={this.props.colorMode ? this.props.colorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                        title={this.props.t('common.messages.attention')}
                        onConfirm={this.handleAlertClose}>
                            {
                                this.props.t('common.messages.unable_to_load_data')
                            }
                    </SweetAlert>
                    <SweetAlert
                        show={this.state.newsNoData}
                        warning
                        confirmBtnText={this.props.t('common.messages.back')}
                        confirmBtnCssClass={this.props.colorMode ? this.props.colorMode.toString() === "D" ? "dialog-confirm-btn-dark" : "dialog-confirm-btn" : "dialog-confirm-btn"}
                        style={this.props.colorMode ? this.props.colorMode.toString() === "D" ? { border: '1px solid #616161', backgroundColor: '#303030', color: '#d1d1d1' } : null : null}
                        title={this.props.t('common.messages.attention')}
                        onConfirm={this.handleAlertClose}>
                            {
                                this.props.t('common.messages.no_data_now')
                            }
                </SweetAlert>
            </Profiler>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        news: state.newsSubReducer,
        colorMode: state.settingsSubReducer.colorMode,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetNewsData: (token, page) => dispatch(actionCreators.getNews(token, page)),
        handleGetMoreNewsData: (token, page) => dispatch(actionCreators.getMoreNews(token, page)),
        onResetNewsError: () => dispatch(actionCreators.resetNewsError()),
        handleGetToken: () => { dispatch(actionCreators.getToken()) }
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(news));
import React, { memo } from 'react';
import {
    Typography,
    Breadcrumbs,
    Grid,
} from '@material-ui/core';
import { Link as SiteLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
//import Grosswetterlage from '../../../../Assets/Desktop/Common/grosswetterlage.jpg';
import Legende from '../../../../Assets/Desktop/Common/Legende.png';

import cssModule from './Grosswetterlagenkarte.module.css';

const useStyles = makeStyles((theme) => ({

    head: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '3rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '15px'
    },
    weatherVideo:{
        borderRadius: '5px',
        marginBottom: '1rem'
    },
    iFrameParent:{
        marginBottom:'1rem',
        borderRadius: '5px'
    },
    imageWrapper:{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center'
    },
    satImageL:{
        height:'100%',
        width:'100%',
        verticalAlign: 'middle',
        borderTopLeftRadius:'5px',
        borderBottomLeftRadius: '5px',
    },
    satImageR: {
        height: '100%',
        width: '100%',
        verticalAlign: 'middle',
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
    }
}));

const Grosswetterlagenkarte = memo((props) => {

    const classes = useStyles();

    function handleClick(event) {
        try {
            event.preventDefault();
        } catch (error) { console.log('202103101712', error) }
    }

    let componentOrganizer = null;
    let today = new Date();
    let heuteDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    let heuteDateFormatted = new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' });
    let heuteText = '';

    props.componentData.forEach(
        function(item){
            if (new Date(item.Datum).setHours(0, 0, 0) === heuteDate.setHours(0, 0, 0)){
                heuteText = item.Text;
                heuteDateFormatted = new Date(item.Datum).toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' });
            }
        }
    );

    try {

        componentOrganizer = (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography className={classes.head} variant="h1" component="h1" gutterBottom>Großwetterlage | {heuteDateFormatted}</Typography>
                        <div>
                                <div className={classes.imageWrapper}>
                                    <div>
                                    <img className={classes.satImageL} src={`http://167.172.173.171/images/grosswetterlage/grosswetterlage_europa.jpg`} alt="Großwetterlage"/>
                                    </div>
                                    <div>
                                        <img className={classes.satImageR} src={Legende} alt="Großwetterlage-legend"/>
                                    </div>
                                </div>
                        </div>
                        <Typography className={classes.data} variant="body1" component="p" style={{marginTop:'1rem'}}>
                            {heuteText}
                        </Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        );

    } catch (error) {
        console.log('202107281519', error);
    }

    return (
        <React.Fragment>
            <div className={cssModule.GrosswetterlagenkarteParent}>
                <div className={cssModule.HeadSection}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <SiteLink className={cssModule.BreadcrubsLink} color="inherit" to="/" onClick={handleClick}>Home</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Aktuell</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Großwetterlagenkarte</SiteLink>
                    </Breadcrumbs>
                </div>
                <div className={cssModule.DataSection}>
                    {componentOrganizer}
                </div>
            </div>
        </React.Fragment>
    );
});

export default Grosswetterlagenkarte;
import React, { memo } from 'react';
import {
    Typography,
    Breadcrumbs,
    Grid,
} from '@material-ui/core';
import { Link as SiteLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import cssModule from './Stromungsfilm.module.css';

const useStyles = makeStyles((theme) => ({

    head: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '3rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '15px'
    },
    weatherVideo:{
        borderRadius: '5px',
        marginBottom: '1rem'
    }
}));

const Agb = memo((props) => {

    const classes = useStyles();

    function handleClick(event) {
        try {
            event.preventDefault();
        } catch (error) { console.log('202103101712', error) }
    }

    let componentOrganizer = null;

    try {

        componentOrganizer = (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography className={classes.head} variant="h1" component="h1" gutterBottom>Der Strömungsfilm für Europa</Typography>
                        <video width="100%" height="62%" controls className={classes.weatherVideo}>
                            <source src='http://167.172.173.171/images/videos/Q-Met_EU_Luftmassen_2018_auto.mp4' type="video/mp4"/>
                        </video>
                        <Typography className={classes.data} variant="body1" component="p">
                            Der Strömungsfilm Europa zeigt den Verlauf der Luftmassen in den kommenden 72 Stunden. Die farbigen Flächen stehen für 
                            die unterschiedlichen Temperaturen der Luftmassen in ca. 1500 m Höhe. Damit kann man die großräumige Höhenströmung bestens 
                            verfolgen. Bläuliche Farbtöne stehen dabei für kalte Luftmassen. Rötliche Farben symbolisieren den Transport von Warmluft 
                            in der Höhe. Damit erkennt man recht schnell, ob wir eher mit wärmeren oder kälterem Wetter zu rechnen haben. Die geschlossenen 
                            Linien sind die Isobaren. Das sind Linien gleichen Luftdrucks. Die vielen kleinen Linien (pfeilähnliche Struktur) visualisieren 
                            die Strömungsrichtung der Luftmassen. Bei hohem Luftdruck strömt die Luft auf der Nordhalbkugel im Uhrzeigersinn, bei tiefem 
                            Luftdruck entgegen dem Uhrzeigersinn. Der Strömungsfilm wird einmal täglich am frühen Morgen aktualisiert. 
                        </Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        );

    } catch (error) {
        console.log('202107281519', error);
    }

    return (
        <React.Fragment>
            <div className={cssModule.StromungsfilmParent}>
                <div className={cssModule.HeadSection}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <SiteLink className={cssModule.BreadcrubsLink} color="inherit" to="/" onClick={handleClick}>Home</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Aktuell</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Strömungsfilm</SiteLink>
                    </Breadcrumbs>
                </div>
                <div className={cssModule.DataSection}>
                    {componentOrganizer}
                </div>
            </div>
        </React.Fragment>
    );
});

export default Agb;
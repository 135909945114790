import React, { memo } from 'react';
import {
    Typography,
    Breadcrumbs,
    Grid,
} from '@material-ui/core';
import { Link as SiteLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Iframe from 'react-iframe'

import cssModule from './Niederschlagsradar.module.css';

const useStyles = makeStyles((theme) => ({

    head: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '3rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '15px'
    },
    weatherVideo:{
        borderRadius: '5px',
        marginBottom: '1rem'
    },
    iFrameParent:{
        marginBottom:'1rem',
        borderRadius: '5px'
    }
}));

const Agb = memo((props) => {

    const classes = useStyles();

    function handleClick(event) {
        try {
            event.preventDefault();
        } catch (error) { console.log('202103101712', error) }
    }

    let componentOrganizer = null;

    try {

        componentOrganizer = (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography className={classes.head} variant="h1" component="h1" gutterBottom>Das Niederschlagsradar für Deutschland</Typography>
                        <div className={classes.iFrameParent}>
                            <Iframe url={`http://165.227.165.97/?lat=51.1657&lon=10.4515`}
                                width="100%"
                                height="600px"
                                id="myId"
                                className="myClassname"
                                display="initial"
                                position="relative"
                                overflow="hidden"
                                loading="Loading..."
                                frameBorder = "0"
                            />
                        </div>
                        <Typography className={classes.data} variant="body1" component="p">
                            Beim Wetter-Radar sehen Sie die aktuellen Niederschläge über Deutschland. Je nach Färbung fällt mehr oder weniger stark 
                            Niederschlag aus den Wolken. Das kann beispielsweise im Winter auch Schnee sein. Geht die Färbung in Richtung rosa/lila, 
                            dann ist das ein Zeichen dafür, dass es vor Ort wahrscheinlich ein Gewitter mit Hagel gibt. Anhand des Radarfilms kann man 
                            eine erste Abschätzung über die weitere Zugrichtung der Niederschläge vornehmen.
                        </Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        );

    } catch (error) {
        console.log('202107281519', error);
    }

    return (
        <React.Fragment>
            <div className={cssModule.StromungsfilmParent}>
                <div className={cssModule.HeadSection}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <SiteLink className={cssModule.BreadcrubsLink} color="inherit" to="/" onClick={handleClick}>Home</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Aktuell</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Niederschlagsradar</SiteLink>
                    </Breadcrumbs>
                </div>
                <div className={cssModule.DataSection}>
                    {componentOrganizer}
                </div>
            </div>
        </React.Fragment>
    );
});

export default Agb;
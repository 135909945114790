import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getKAktuelle = (argToken, argLat, argLon, argCity) => {
    try {
        return dispatch => {
            dispatch(getKAktuelleStarted());
            const axiosInstance = axios.create({
                baseURL: 'http://165.227.162.31:8000/v4/',
                headers: { 'Authorization': `Bearer ${argToken}` }
            });

            axiosInstance.post(`astro_data`, {lat: argLat, lon: argLon, city: argCity})
                .then(response => {
                    if (response && response.data && response.data.data) {
                        dispatch(getKAktuelleSuccess(response.data.data));
                    }
                    else {
                        dispatch(getKAktuelleFailure('Something went wrong'));
                    }
                })
                .catch(error => {
                    dispatch(getKAktuelleFailure(error.message));
                })
        };
        // eslint-disable-next-line no-unreachable
    } catch (error) { console.log('2021021036', error) }
};

const getKAktuelleSuccess = data => ({
    type: actionTypes.K_GET_AKTUELLE_SUCCESS,
    payload: data
});

const getKAktuelleStarted = () => ({
    type: actionTypes.K_GET_AKTUELLE_STARTED,
});

const getKAktuelleFailure = error => ({
    type: actionTypes.K_GET_AKTUELLE_FAILURE,
    payload: {
        error
    }
});

export const getCacheAktuellData = () => ({
    type: actionTypes.K_GET_AKTUELLE_CACHED,
});
import React, {memo} from 'react';

const WetterNewsSingleComponent = memo((props)=>{

    return(
        <div>wetter news singel component</div>
    );

});

export default WetterNewsSingleComponent;
import * as actionTypes from '../actions/actionTypes';
/*import { loadState } from '../../localStorage';*/

/*let localCache = loadState();*/
let initialState = null;

/*if (localCache && localCache.kToken) {
    initialState = localCache.kToken;
}
else {
    initialState = {
        kToken: null,
        loading: false,
        error: null
    }
}*/

initialState = {
    kToken: null,
    loading: false,
    error: null,
    createTime: null,
}

try {
    // eslint-disable-next-line no-extend-native
    Date.prototype.addHours = function (h) {
        this.setHours(this.getHours() + h);
        return this;
    }
} catch (error) { }

const reducer = (state = initialState, action) => {
    try {
        if (action.type === actionTypes.K_GET_TOKEN_SUCCESS) {
            return {
                ...state,
                loading: false,
                error: null,
                kToken: action.payload,
                createTime: new Date().addHours(3), //TOKEN EXPIRE TIME
            }
        } else if (action.type === actionTypes.K_GET_TOKEN_STARTED) {
            return {
                ...state,
                loading: true
            }
        }
        else if (action.type === actionTypes.K_GET_TOKEN_FAILURE) {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }
        }
        else {
            return state;
        }
    } catch (error) { console.log('202102191555', error) }
};

export default reducer;
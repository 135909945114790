import * as actionTypes from './actionTypes';
import axios from 'axios';

export const kStoreCurrentLoc = (argVal) => {
    return {
        type: actionTypes.K_LOCATION_REPLACE,
        payload: argVal
    }
}

export const getkLocation = (argToken, argLocID) => {

    try {
        return dispatch => {
            dispatch(getKLocationStarted());
            const axiosInstance = axios.create({
                baseURL: 'http://165.227.162.31:8000/v4/',
                headers: { 'Authorization': `Bearer ${argToken}` }
            });

            axiosInstance.get(`location/${argLocID}`)
                .then(response => {
                    if (response && response.data && response.data.data) {
                        dispatch(getKLocationSuccess(response.data.data));
                    }
                    else {
                        dispatch(getKLocationFailure('Something went wrong'));
                    }
                })
                .catch(error => {
                    dispatch(getKLocationFailure(error.message));
                })
        };
        // eslint-disable-next-line no-unreachable
    } catch (error) { console.log('202102231105', error) }
};

export const getkBrowserLocation =  (argToken, argLat, argLon) => {
    try {
        return dispatch => {
            dispatch(getKLocationStarted());
            const axiosInstance = axios.create({
            baseURL: 'http://165.227.162.31:8000/v4/',
                headers: { 'Authorization': `Bearer ${argToken}` }
            });
            axiosInstance.get(`location/geo/${argLon}/${argLat}`)
            .then(response => {
                if (response && response.data && response.data.data) {
                    dispatch(getKLocationSuccess(response.data.data));
                }
                else {
                    dispatch(getKLocationFailure('Something went wrong'))
                }
            })
            .catch(error => {
                dispatch(getKLocationFailure(error.message));
            })
        }

    }
    // eslint-disable-next-line no-unreachable
    catch (error) {console.log('202103051223', error);}
}

const getKLocationSuccess = data => ({
    type: actionTypes.K_LOCATION_CURRENT_GET_SUCCESS,
    payload: data
});

const getKLocationStarted = () => ({
    type: actionTypes.K_LOCATION_CURRENT_GET_STARTED,
});

const getKLocationFailure = error => ({
    type: actionTypes.K_LOCATION_CURRENT_GET_FAILURE,
    payload: {
        error
    }
});
/* eslint-disable no-dupe-keys */
import React, { Fragment, useState, Suspense, memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AppBar, Toolbar, IconButton, Typography, MenuItem, Menu, Drawer, Button, List, Divider, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Route, NavLink, Switch, Link, useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {GAEvent} from '../../../Assets/GoogleAnalytics/googleAnalytics';
import CloseIcon from '@material-ui/icons/Close';

import '../../../Assets/Languages/i18n';
import cssModule from './Navigation.module.css';

import Home from '../Home/Home';
import MeineStadte from '../MeineStadte/MeineStadte';
import BewerteApp from '../Menu/BewerteApp/BewerteApp';
import Biowetter from '../Menu/Biowetter/Biowetter';
import Kontakt from '../Menu/Kontakt/Kontakt';
import Pollenflug from '../Menu/Pollenflug/Pollenflug';
import WetterfotoSenden from '../Menu/WetterfotoSenden/WetterfotoSenden';
import DatenschutzBestimmungen from '../Menu/DatenschutzBestimmungen/DatenschutzBestimmungen';
import FaqPage from '../Menu/FaqPage/FaqPage';
import News from '../News/News';
import NewsDetails from '../../Components/News/NewsDetails/NewsDetails';
import Radar from '../Radar/Radar';
import Regen from '../Regen/Regen';
import Settings from '../Settings/Settings';
import Video from '../Video/Video';
import PageNotFound from '../PageNotFound/PageNotFound';
import DayData from '../../Components/Home/DateData/DateData';

import imageRagen from '../../../Assets/Common/footer_rain.png';
import imageRadar from '../../../Assets/Common/footer_radar.png';
import imageVideos from '../../../Assets/Common/footer_videos.png';
import imageNews from '../../../Assets/Common/footer_news.png';

import imageKontakt from '../../../Assets/Mobile/Common/side_menu_contact.png';
import imageBiowetter from '../../../Assets/Mobile/Common/side_menu_health.png';
import imagePollen from '../../../Assets/Mobile/Common/side_menu_pollen.png';
import imageBewerte from '../../../Assets/Mobile/Common/side_menu_rate.png';
import imageWetterfoto from '../../../Assets/Mobile/Common/side_menu_send_photo.png';
import imageAGB from '../../../Assets/Mobile/Common/info_logo.png';

import { ReactComponent as ImageHome } from '../../../Assets/Mobile/Common/home.svg';
import { ReactComponent as ImageHomeDark } from '../../../Assets/Mobile/Common/home_dark.svg';



const useStyles = makeStyles((theme) => ({
    drawerCustomPaper: {
        background: 'rgb(0,62,133)',
        background: 'linear-gradient(180deg, rgba(0,62,133,1) 0%, rgba(0,81,173,1) 50%, rgba(0,62,133,1) 100%)',
        color: '#fff',
        justifyContent: 'space-between'
    },
    drawerCustomPaperDark: {
        background: '#0c2140',
        color: '#d1d1d1',
        justifyContent: 'space-between'
    },
    locationButton: {
        color: '#fff',
        textTransform: 'capitalize',
        border: '1px solid #fff',
        marginTop: '10px',
    },
    locationButtonDark: {
        color: '#d1d1d1',
        textTransform: 'capitalize',
        border: '1px solid #d1d1d1',
        marginTop: '10px',
    },
    locationMenuButtonLabel: {
        color: '#fafafa',
        textTransform: 'none',
        fontSize: '1rem',
    },
    locationMenuButtonLabelDark: {
        color: '#d1d1d1',
        textTransform: 'none',
        fontSize: '1rem',
    },
    locationButtonLabel: {
        fontSize: '.7rem',
        fontFamily: 'Exo 2',
    },
    locationButtonIcon: {
        fontSize: '12px !important',
        color: '#fafafa',
    },
    locationButtonIconDark: {
        fontSize: '12px !important',
        color: '#d1d1d1',
    },
    menuRoot: {
        paddingTop: '0px',
        paddingBottom: '0px',
        minWidth: '60vw',
        backgroundColor: "#fafafa",
        color: 'rgba(84,84,84)',
    },
    menuRootDark: {
        paddingTop: '0px',
        paddingBottom: '0px',
        minWidth: '60vw',
        backgroundColor: "#252525",
        color: "#d1d1d1",
    },
    menuIcons: {
        color: '#fff',
    },
    menuDivider: {
        backgroundColor: 'rgba(0,142,223,.5)',
    },
    menuDividerDark: {
        backgroundColor: 'rgba(0, 94, 148,.5)',
    },
    menuSearchLocation: {
        backgroundColor: 'rgb(0,62,133)',
        textAlign: 'center',
        justifyContent: 'center',
    },
    menuSearchLocationDark: {
        backgroundColor: '#0c2140',
        textAlign: 'center',
        justifyContent: 'center',
    },
    MenuButtonLabel: {
        color: '#fff',
        textTransform: 'capitalize',
        fontSize: '.8rem',
    },
    appBar: {
        boxShadow: 'none',
    },
    appBarDark: {
        boxShadow: 'none',
        backgroundColor:'#0c2140',
    },
    tabHome: {
        backgroundColor: 'rgb(0,62,133)',
        borderRadius: '50%',
        display: 'absolute',
    },
    tabHomeWrapper: {
        color: '#fff',
        textTransform: 'capitalize',
    },
    grow: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        alignContent: 'stretch',
        backgroundColor: '#fafafa',
    },

    growDark: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        alignContent: 'stretch',
        backgroundColor: '#1e1e1e',
    },

    menuButton: {
        marginRight: theme.spacing(2),
        color: "#fafafa",
    },

    menuButtonDark: {
        marginRight: theme.spacing(2),
        color: "#d1d1d1",
    },

    menuIconButton: {
        color: "#fafafa",
    },

    menuIconButtonDark: {
        color: "#d1d1d1",
    },
    closeButton: {
        color: '#fff'
    }
}));


const Navigation = memo((tokenProps) => {

    const { t, i18n } = useTranslation();

    const currLocation = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const [hookDrawerOpen, sethookDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [hookIsScrolling, setIsScrolling] = useState(false);

    /*    useEffect(() => {
        }, [tokenProps.location]);*/

    /* ===== LEFT DRAWER ===== */

    const toggleDrawer = (open) => (event) => {
        try {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            sethookDrawerOpen(open);
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Navigation - toggleDrawer", ("202107151424 - Error: " + error.message), null, true);
         }
    };

    /* ===== LEFT DRAWER ===== */

    /* ===== LOCATION MENU ===== */

    const handleLocationClick = (event) => {
        try{
        setAnchorEl(event.currentTarget);
        GAEvent("App bar", "location list dropdown pressed", "location list dropdown open", null, false);
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Navigation - handleLocationClick", ("202107151425 - Error: " + error.message), null, true);
        }
    };

    const handleLocationClose = (argLocation) => {
        try{
            setAnchorEl(null);
            if (argLocation !== null) {
                tokenProps.changeLocation(argLocation);
            }
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Navigation - handleLocationClose", ("202107151426 - Error: " + error.message + " argLocation: " + argLocation), null, true);
        }
    };

    const calculateTemp = (argTemp, argUnit) => {
        try {
            if (argTemp === 99 || argTemp === -99) {
                return "-";
            }
            else {
                if (argUnit) {
                    if (argUnit.toString() === "F") {
                        return Math.round((argTemp * 9 / 5) + 32);
                    }
                    else {
                        return argTemp;
                    }
                }
            }
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Navigation - calculateTemp", ("202107151427 - Error: " + error.message + " Values: " + argTemp + ", " + argUnit), null, true);
         }
    }

    const handleBack = () => {
        try {
            history.push('/');
            handleBackIconClick("Hourly Data");
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Navigation - handleBack", ("202107151428 - Error: " + error.message), null, true);
         }
    }

    const handleNewsBack = () => {
        try {
            history.push('/news');
            handleBackIconClick("News");
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Navigation - handleNewsBack", ("202107151429 - Error: " + error.message), null, true);
         }
    }

    /* ===== LOCATION MENU ===== */

    const getWeatherName = (symbolID, argSunRise, argSunSet) => {
        try {
            let varWeather = null;
            let today = new Date();
            if (argSunRise && argSunSet) {

                const heuteSonnAuf = today.setHours(parseInt(argSunRise.substr(0, 2)), parseInt(argSunRise.substr(3, 2)), 0, 0);
                const heuteSonnUnter = today.setHours(parseInt(argSunSet.substr(0, 2)), parseInt(argSunSet.substr(3, 2)), 0, 0);

                switch (symbolID) {
                    case 1:
                        varWeather = '';
                        if ((Date.now() > heuteSonnAuf) && (Date.now() < heuteSonnUnter)) {
                             /*varWeather = 'Sonnig';*/
                             varWeather = t('common.weather_names.sunny');
                        } else {
                            /*varWeather = 'Klar';*/
                            varWeather = t('common.weather_names.clear');
                        }
                        return varWeather;
                    case 2:
                        varWeather = '';
                        if ((Date.now() > heuteSonnAuf) && (Date.now() < heuteSonnUnter)) {
                            /*varWeather = 'Leicht bewölkt';*/
                            varWeather = t('common.weather_names.slightly_cloudy_day');
                        } else {
                            /*varWeather = 'Gering bewölkt';*/
                            varWeather = t('common.weather_names.slightly_cloudy_night');
                        }
                        return varWeather;
                    case 3:
                         /*return ('Wolkig');*/
                         return (t('common.weather_names.cloudy'));
                    case 4:
                        /*return ('Stark bewölkt');*/
                        return (t('common.weather_names.heavily_cloudy'));
                    case 5:
                        /*return ('Bedeckt');*/
                        return (t('common.weather_names.covered'));
                    case 6:
                        /*return ('Regenschauer');*/
                        return (t('common.weather_names.rain_shower'));
                    case 7:
                        /*return ('Regen');*/
                        return (t('common.weather_names.rain'));
                    case 8:
                        /*return ('Gewitter');*/
                        return (t('common.weather_names.thunderstorm'));
                    case 9:
                        /*return ('Schneeschauer');*/
                        return (t('common.weather_names.snow_showers'));
                    case 10:
                        /*return ('Schnee');*/
                        return (t('common.weather_names.snow'));
                    case 11:
                        /*return ('Schneeregen');*/
                        return (t('common.weather_names.sleet'));
                    case 12:
                        /*return ('Nebel');*/
                        return (t('common.weather_names.fog'));
                    case 13:
                        /*return ('In Wolken');*/
                        return (t('common.weather_names.in_clouds'));
                    case 14:
                        /*return ('Sprühregen');*/
                        return (t('common.weather_names.drizzle'));
                    default:
                        /*return ('Keine Daten');*/
                        return (t('common.weather_names.no_data'));
                }

            } else {
                switch (symbolID) {
                    case 1:
                        /*return ('Sonnig');*/
                        return (t('common.weather_names.sunny'));
                    case 2:
                        /*return ('Leicht bewölkt');*/
                        return (t('common.weather_names.slightly_cloudy_day'));
                    case 3:
                        /*return ('Wolkig');*/
                        return (t('common.weather_names.cloudy'));
                    case 4:
                        /*return ('Stark bewölkt');*/
                        return (t('common.weather_names.heavily_cloudy'));
                    case 5:
                        /*return ('Bedeckt');*/
                        return (t('common.weather_names.covered'));
                    case 6:
                        /*return ('Regenschauer');*/
                        return (t('common.weather_names.rain_shower'));
                    case 7:
                        /*return ('Regen');*/
                        return (t('common.weather_names.rain'));
                    case 8:
                        /*return ('Gewitter');*/
                        return (t('common.weather_names.thunderstorm'));
                    case 9:
                        /*return ('Schneeschauer');*/
                        return (t('common.weather_names.snow_showers'));
                    case 10:
                        /*return ('Schnee');*/
                        return (t('common.weather_names.snow'));
                    case 11:
                        /*return ('Schneeregen');*/
                        return (t('common.weather_names.sleet'));
                    case 12:
                        /*return ('Nebel');*/
                        return (t('common.weather_names.fog'));
                    case 13:
                        /*return ('In Wolken');*/
                        return (t('common.weather_names.in_clouds'));
                    case 14:
                        /*return ('Sprühregen');*/
                        return (t('common.weather_names.drizzle'));
                    default:
                        /*return ('Keine Daten');*/
                        return (t('common.weather_names.no_data'));
                }
            }
        } catch (error) { 
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Navigation - getWeatherName", ("202107151430 - Error: " + error.message + " Values: " + symbolID + ", " + argSunRise + ", " + argSunSet), null, true);
         }
    }

    const handleMenuMyCityClick = () =>{
        try{
            GAEvent("Menu", "Menu item pressed", "Meine Städte from menu pressed", null, false);
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Navigation - handleMenuMyCityClick", ("202107151431 - Error: " + error.message), null, true);
        }
    }

    const handleMenuItemClick = (argMenuItem) => {
        try{
            GAEvent("Menu", "Menu item pressed", (argMenuItem + " menu pressed"), null, false);
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Navigation - handleMenuItemClick", ("202107151432 - Error: " + error.message + " Values: " + argMenuItem), null, true);
        }
    }

    const handleBackIconClick = (argMenuItem) => {
        try{
            GAEvent("App bar", "Menu item pressed", ("Back icon pressed from " + argMenuItem + " page"), null, false);
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Navigation - handleBackIconClick", ("202107151433 - Error: " + error.message + " Values: " + argMenuItem), null, true);
        }
    }

    const handleMenuIconClick = () =>{
        try{
            GAEvent("App bar", "Menu icon pressed", "Menu drawer open", null, false);
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Navigation - handleMenuIconClick", ("202107151434 - Error: " + error.message), null, true);
        }
    }

    const handleMenuLocationListClick = (argCity) =>{
        try{
            handleLocationClose(argCity);
            GAEvent("App bar", "City menu item pressed", ("City menu item " + argCity.name + " pressed"), null, false);
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Navigation - handleMenuLocationListClick", ("202107151435 - Error: " + error.message + " Values: " + argCity), null, true);
        }
    }

    const handleMenuMyCitiesButtonClick = (argStatus) =>{
        try{
            handleLocationClose(null);
            GAEvent("App bar", "Meine Städte button press", "My cities from location list pressed. My cities page open", null, false);
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Navigation - handleMenuMyCitiesButtonClick", ("202107151436 - Error: " + error.message), null, true);
        }
    }

    const handleUnitButtonClick = () =>{
        try{
            GAEvent("App bar", "Settings icon pressed", "Settings icon pressed. Settings page open", null, false);
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Navigation - handleUnitButtonClick", ("202107151437 - Error: " + error.message), null, true);
        }
    }

    const handleTabTapped = ( argTabName ) =>{
        try{
            GAEvent("Bottom Navigation", "Bottom navigation tapped", ( "Bottom navigation on item " + argTabName  +" tapped"), null, false);
        }catch(error){
            GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Navigation - handleTabTapped", ("202107151438 - Error: " + error.message + " Values: " + argTabName), null, true);
        }
    }

    const scrollMain = () => {
        let timer = null;
        if (timer !== null) {
            clearTimeout(timer);
        }
        setIsScrolling(true);
        timer = setTimeout(function () {
            setIsScrolling(false);
        }, 750);
    }

    let locationList = null;
    try {
        locationList = (
            //<MenuItem onClick={handleLocationClose}>Location 1</MenuItem>
            tokenProps.locationHistory.map((location, index) => {
                return (<MenuItem key={index} onClick={() => handleMenuLocationListClick(location)}>{location.name}</MenuItem>);
            })
        );
    } catch (error) {
        GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Navigation - location list", ("202107151439 - Error: " + error.message), null, true);
     }

     let componentOrganizer = null;

     try{
        componentOrganizer = (
            <Fragment>
                <div className={cssModule.DrawerContainer}>
                    <Drawer classes={{ root: classes.drawerCustom, paper: tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.drawerCustomPaperDark : classes.drawerCustomPaper : classes.drawerCustomPaper }} width={'65vw'} anchor='left' open={hookDrawerOpen} onClose={toggleDrawer(false)}>
                        <div>
                            <div className={cssModule.CloseParent}>
                                <Button
                                    onClick={toggleDrawer(false)}
                                    size="medium"
                                    className={classes.closeButton}
                                    endIcon={<CloseIcon>Close</CloseIcon>}
                                >Close</Button>
                            </div>
                            <div className={cssModule.MenuHeaderParent}>
                                <div className={tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? cssModule.MenuHeaderDark : cssModule.MenuHeader : cssModule.MenuHeader}>
                                    <Typography variant='subtitle1' gutterBottom={false}>{tokenProps.location ? tokenProps.location.name ? tokenProps.location.name : '-' : '-'}</Typography>
                                    <Typography variant='h1' gutterBottom={false}>{tokenProps.nowData ? isNaN(tokenProps.nowData.current_temp) ? '-' : calculateTemp(tokenProps.nowData.current_temp, tokenProps.settings.temperature) : '-'}°</Typography>
                                    <Typography variant='subtitle2' gutterBottom={true}>{tokenProps.nowData ? isNaN(tokenProps.nowData.ID_Wetter) ? '-' : getWeatherName(tokenProps.nowData.ID_Wetter, tokenProps.nowData.sunRiseDetails.SunRise, tokenProps.nowData.sunRiseDetails.SunSet) : '-'}</Typography>
                                    <span onClick={handleMenuMyCityClick}>
                                        <Button className={tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.locationButtonDark : classes.locationButton : classes.locationButton}
                                            classes={{ label: classes.locationButtonLabel }}
                                            startIcon={<SettingsOutlinedIcon className={tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.locationButtonIconDark : classes.locationButtonIcon : classes.locationButtonIcon} />}
                                            variant='outlined'
                                            size='small'
                                            component={Link}
                                            to="/meine-stadte"
                                            onClick={toggleDrawer(false)}>
                                            {/*Meine Städte*/}
                                            {t('app.navigation.menu.my_cities')}
                                        </Button>
                                    </span>
                                </div>
                            </div>
                            <div className={cssModule.MenuParent}>
                                <List>
                                    <NavLink to="/pollenflug" exact={true} activeClassName={cssModule.MenuActive} className={cssModule.NavLink} onClick={toggleDrawer(false)}>
                                        <ListItem button key='1' onClick={() => { handleMenuItemClick("Pollenflug") }}>
                                            <ListItemIcon className={classes.menuIcons}>{<img src={imagePollen} className={cssModule.MenuIcons} alt="Pollenflug" />}</ListItemIcon>
                                            <ListItemText primary={t('app.navigation.menu.pollen_count')} />
                                        </ListItem>
                                    </NavLink>
                                    <Divider className={tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.menuDividerDark : classes.menuDivider : classes.menuDivider} />
                                    <NavLink to="/biowetter" exact={true} activeClassName={cssModule.MenuActive} className={cssModule.NavLink} onClick={toggleDrawer(false)}>
                                        <ListItem button key='2' onClick={() => { handleMenuItemClick("Biowetter") }}>
                                            <ListItemIcon className={classes.menuIcons}>{<img src={imageBiowetter} className={cssModule.MenuIcons} alt="Biowetter" />}</ListItemIcon>
                                            <ListItemText primary={t('app.navigation.menu.bioWetter')} />
                                        </ListItem>
                                    </NavLink>
                                    <Divider className={tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.menuDividerDark : classes.menuDivider : classes.menuDivider} />
                                    <NavLink to="/wetterfoto-senden" exact={true} activeClassName={cssModule.MenuActive} className={cssModule.NavLink} onClick={toggleDrawer(false)}>
                                        <ListItem button key='3' onClick={() => { handleMenuItemClick("Wetterfoto senden") }}>
                                            <ListItemIcon className={classes.menuIcons}>{<img src={imageWetterfoto} className={cssModule.MenuIcons} alt="Wetterfoto senden" />}</ListItemIcon>
                                            <ListItemText primary={t('app.navigation.menu.send_weather_photo')} />
                                        </ListItem>
                                    </NavLink>
                                    <Divider className={tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.menuDividerDark : classes.menuDivider : classes.menuDivider} />
                                    <NavLink to="/bewerte-die-app" exact={true} activeClassName={cssModule.MenuActive} className={cssModule.NavLink} onClick={toggleDrawer(false)}>
                                        <ListItem button key='4' onClick={() => { handleMenuItemClick("Bewerte die App") }}>
                                            <ListItemIcon className={classes.menuIcons}>{<img src={imageBewerte} className={cssModule.MenuIcons} alt="Bewerte die App" />}</ListItemIcon>
                                            <ListItemText primary={t('app.navigation.menu.rate_the_app')} />
                                        </ListItem>
                                    </NavLink>
                                    <Divider className={tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.menuDividerDark : classes.menuDivider : classes.menuDivider} />
                                    <NavLink to="/kontakt" exact={true} activeClassName={cssModule.MenuActive} className={cssModule.NavLink} onClick={toggleDrawer(false)}>
                                        <ListItem button key='5' onClick={() => { handleMenuItemClick("Kontakt") }}>
                                            <ListItemIcon className={classes.menuIcons}>{<img src={imageKontakt} className={cssModule.MenuIcons} alt="Kontakt" />}</ListItemIcon>
                                            <ListItemText primary={t('app.navigation.menu.contact')} />
                                        </ListItem>
                                    </NavLink>
                                    <Divider className={tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.menuDividerDark : classes.menuDivider : classes.menuDivider} />
                                    <NavLink to="/agb" exact={true} activeClassName={cssModule.MenuActive} className={cssModule.NavLink} onClick={toggleDrawer(false)}>
                                        <ListItem button key='6' onClick={() => { handleMenuItemClick("AGB") }}>
                                            <ListItemIcon className={classes.menuIcons}>{<img src={imageAGB} className={cssModule.MenuIcons} alt="Kontakt" />}</ListItemIcon>
                                            <ListItemText primary={t('app.navigation.menu.agb')} />
                                        </ListItem>
                                    </NavLink>
                                    <Divider className={tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.menuDividerDark : classes.menuDivider : classes.menuDivider} />
                                <NavLink to="/faq" exact={true} activeClassName={cssModule.MenuActive} className={cssModule.NavLink} onClick={toggleDrawer(false)}>
                                    <ListItem button key='6' onClick={() => { handleMenuItemClick("FAQ") }}>
                                        <ListItemIcon className={classes.menuIcons}>{<img src={imageKontakt} className={cssModule.MenuIcons} alt="Kontakt" />}</ListItemIcon>
                                        <ListItemText primary={t('app.navigation.menu.faq')} />
                                    </ListItem>
                                </NavLink>
                                </List>
                            </div>
                        </div>
                        <div className={cssModule.MenuFooter}>
                            wetter.net
                        </div>
                    </Drawer>
                </div>
                <div className={tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.growDark : classes.grow : classes.grow}>
                    <div style={{ flexGrow: '0' }}>
                        <AppBar position="static" classes={{ root: tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.appBarDark : classes.appBar : classes.appBar }}>
                            <Toolbar style={{ justifyContent: 'space-between' }}>
                                <div>
                                    {currLocation.pathname.toString().substring(0, 12) === "/tagesdaten/" ?
                                        <IconButton
                                            edge="start"
                                            className={tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.menuButtonDark : classes.menuButton : classes.menuButton}
                                            color="inherit"
                                            aria-label="go back"
                                            onClick={handleBack}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                        : currLocation.pathname.toString().substring(0, 6) === "/news/" ?
                                            <IconButton
                                                edge="start"
                                                className={tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.menuButtonDark : classes.menuButton : classes.menuButton}
                                                color="inherit"
                                                aria-label="go back"
                                                onClick={handleNewsBack}>
                                                <ArrowBackIcon />
                                            </IconButton>
                                            : <span onClick={handleMenuIconClick}><IconButton
                                                edge="start"
                                                className={tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.menuButtonDark : classes.menuButton : classes.menuButton}
                                                color="inherit"
                                                aria-label="open drawer"
                                                onClick={toggleDrawer(true)}>
                                                <MenuIcon />
                                            </IconButton></span>}
                                </div>
                                <div>
                                    <Button aria-controls="location-menu"
                                        aria-haspopup="true"
                                        onClick={handleLocationClick}
                                        endIcon={<ExpandMoreIcon />}
                                        classes={{ label: tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.locationMenuButtonLabelDark : classes.locationMenuButtonLabel : classes.locationMenuButtonLabel, root: classes.locationMenuButtonRoot }}>
                                        {tokenProps.location ? tokenProps.location.name ? tokenProps.location.name : t('common.messages.no_location_selected') : t('common.messages.no_location_selected')}
                                    </Button>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={() => { handleLocationClose(null) }}
                                        classes={{ list: tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.menuRootDark : classes.menuRoot : classes.menuRoot }}>
                                        {locationList}
                                        <MenuItem onClick={() => { handleMenuMyCitiesButtonClick(null) }} className={tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.menuSearchLocationDark : classes.menuSearchLocation : classes.menuSearchLocation}>
                                            <Button
                                                component={Link}
                                                to="/meine-stadte"
                                                classes={{ label: tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.locationMenuButtonLabelDark : classes.locationMenuButtonLabel : classes.locationMenuButtonLabel }}
                                                startIcon={<SettingsOutlinedIcon />}>
                                                {/*Meine Städte*/}
                                                {t('app.navigation.menu.my_cities')}
                                            </Button>
                                        </MenuItem>
                                    </Menu>
                                </div>
                                <div>
                                    <NavLink to="/settings" exact={true} className={cssModule.NavLink}>
                                        <IconButton onClick={handleUnitButtonClick} aria-label="show settings" className={tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? classes.menuIconButtonDark : classes.menuIconButton : classes.menuIconButton}>
                                            <SettingsIcon />
                                        </IconButton>
                                    </NavLink>
                                </div>
                            </Toolbar>
                        </AppBar>
                    </div>

                    <main onScroll={scrollMain} style={{ flexGrow: '1', overflow: 'auto' }}>
                        <Suspense fallback={
                            <div className={cssModule.ErsteLadung}>
                                <div className={cssModule.LinearActivity}>
                                    <div className={cssModule.Indeterminate}></div>
                                </div>
                            </div>
                        }>
                            <Switch>
                                <Route path="/" exact render={(props) => { return (<Home pageScrolling={hookIsScrolling} userToken={tokenProps.userToken.token}{...props} />) }} />
                                <Route path="/regen" exact render={(props) => { return (<Regen userToken={tokenProps.userToken.token} {...props} />) }} />
                                <Route path="/radar" exact component={(props) => { return (<Radar {...props} />) }} />
                                <Route path="/video" exact render={(props) => { return (<Video userToken={tokenProps.userToken.token} {...props} />) }} />
                                <Route path="/news" render={(props) => { return (<News userToken={tokenProps.userToken.token} {...props} />) }} exact />
                                <Route path="/settings" exact component={(props) => { return (<Settings {...props} />)}} />
                                <Route path="/meine-stadte" exact render={(props) => { return (<MeineStadte userToken={tokenProps.userToken.token} {...props} />) }} />
                                <Route path="/pollenflug" exact render={(props) => { return (<Pollenflug userToken={tokenProps.userToken.token} {...props} />) }} />
                                <Route path="/biowetter" exact render={(props) => { return (<Biowetter userToken={tokenProps.userToken.token} {...props} />) }} />
                                <Route path="/wetterfoto-senden" exact render={(props) => { return (<WetterfotoSenden userToken={tokenProps.userToken.token} {...props} />) }} />
                                <Route path="/bewerte-die-app" exact component={(props) => { return (<BewerteApp {...props} />) }} />
                                <Route path="/kontakt" exact render={(props) => { return (<Kontakt userToken={tokenProps.userToken.token} {...props} />) }} />
                                <Route path="/agb" exact render={(props) => { return (<DatenschutzBestimmungen {...props} />) }} />
                                <Route path="/news/:id" exact render={(props) => { return (<NewsDetails userToken={tokenProps.userToken.token} activeColorMode={tokenProps.settings.colorMode} {...props} />) }} />
                                <Route path="/tagesdaten/:id/:type/:location" exact render={(props) => { return (<DayData activeColorMode={tokenProps.settings.colorMode} userToken={tokenProps.userToken.token} userLocation={tokenProps.location} {...props} />) }} />
                                <Route path="/faq" exact render={(props) => { return (<FaqPage {...props} />) }} />
                                <Route component={PageNotFound} />
                            </Switch>
                        </Suspense>
                    </main>
                    <div className={tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ? cssModule.CustomTabControlDark : cssModule.CustomTabControl : cssModule.CustomTabControl} style={{ flexGrow: '0' }}>
                        <NavLink to="/regen" exact={true} activeClassName={cssModule.CustomTabItemActive} className={cssModule.CustomTabItem} onClick={() => { handleTabTapped("Regen") }}>
                            <div>
                                <img className={cssModule.TabImage} src={imageRagen} alt="regen" />
                                <Typography variant='subtitle2'>
                                    {/*Regen*/}
                                    {t('app.navigation.tab.rain')}
                                </Typography>
                            </div>
                        </NavLink>
                        <NavLink to="/radar" exact={true} activeClassName={cssModule.CustomTabItemActive} className={cssModule.CustomTabItem} onClick={() => { handleTabTapped("Radar") }}>
                            <div>
                                <img className={cssModule.TabImage} src={imageRadar} alt="radar" />
                                <Typography variant='subtitle2'>
                                    {/*Radar*/}
                                    {t('app.navigation.tab.radar')}
                                </Typography>
                            </div>
                        </NavLink>
                        <NavLink to="/" exact={true} className={cssModule.CustomTabItemCenter} onClick={() => { handleTabTapped("Home") }}>
                            <div className={cssModule.MainCircle}>
                                {tokenProps.settings.colorMode ? tokenProps.settings.colorMode.toString() === "D" ?
                                    <ImageHomeDark className={cssModule.TabImageHome} /> :
                                    <ImageHome className={cssModule.TabImageHome} /> :
                                    <ImageHome className={cssModule.TabImageHome} />
                                }
                            </div>
                        </NavLink>
                        <NavLink to="/video" exact={true} activeClassName={cssModule.CustomTabItemActive} className={cssModule.CustomTabItem} onClick={() => { handleTabTapped("Video") }}>
                            <div>
                                <img className={cssModule.TabImage} src={imageVideos} alt="videos" />
                                <Typography variant='subtitle2'>
                                    {/*Video*/}
                                    {t('app.navigation.tab.video')}
                                </Typography>
                            </div>
                        </NavLink>
                        <NavLink to="/news" exact={true} activeClassName={cssModule.CustomTabItemActive} className={cssModule.CustomTabItem} onClick={() => { handleTabTapped("News") }}>
                            <div>
                                <img className={cssModule.TabImage} src={imageNews} alt="news" />
                                <Typography variant='subtitle2'>
                                    {/*News*/}
                                    {t('app.navigation.tab.news')}
                                </Typography>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </Fragment>
        );
     }catch(error){
         GAEvent("Render Error - " + new Date().toLocaleDateString('de-DE', { year: 'numeric', month: 'long', day: 'numeric' }), "Navigation - render", ("202107151440 - Error: " + error.message), null, true);
     }

    return (
        <Fragment>
            {componentOrganizer ? componentOrganizer : <div className="NoPage">{t('common.messages.unable_to_load_data')}</div>}
        </Fragment>
    );
});

export default Navigation
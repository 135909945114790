import * as actionTypes from '../actions/actionTypes';
import { loadState } from '../../localStorage';

let localCache = loadState();
let initialState = null;

if (localCache && localCache.homeData) {
    initialState = localCache.homeData;
}
else {
    initialState = {
        homeData: null,
        loading: false,
        error: null
    }
}

const reducer = (state = initialState, action) => {
    try{
    if (action.type === actionTypes.GET_HOME_SUCCESS) {
        return {
            ...state,
            loading: false,
            error: null,
            homeData: action.payload
        }
    } else if (action.type === actionTypes.GET_HOME_STARTED) {
        return {
            ...state,
            loading: true,
            error: null,
        }
    }
    else if (action.type === actionTypes.GET_HOME_FAILURE) {
        return {
            ...state,
            loading: false,
            error: action.payload.error,
        }
    } else if (action.type === actionTypes.RESET_HOME_ERROR) {
        return {
            ...state,
            error: null,
        }
    }
    else {
        return state;
    }
    } catch (error) { console.log('202102191548', error) }
};

export default reducer;
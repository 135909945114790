import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { loadState } from '../../localStorage';

let localCache = loadState();
let initialState = null;

if (localCache && localCache.settings) {
    initialState = localCache.settings;
}
else {
    initialState = {
        druck: "hpa",
        temperature: "C",
        windgeschwindigkeit: "kmh",
        colorMode: "L",
    }
}

const reducer = (state = initialState, action) => {
    try{

    if (action.type === actionTypes.SETTINGS_ADD) {
        return updateObject(state, { druck: action.val.druck, temperature: action.val.temperature, windgeschwindigkeit: action.val.windgeschwindigkeit, colorMode: action.val.colorMode });
    }
    return state;
    } catch (error) { console.log('202102191554', error) }
};

export default reducer;

































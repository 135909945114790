import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actionCreators from '../../../../store/actions/index';
import PollenKalenderComponent from '../../../Components/InnerPages/PollenKalender/PollenKalender';
class PollenKalender extends Component {

    componentDidMount = () => {
        try {
            if (this.props.kToken && this.props.kToken.kToken && this.props.kToken.kToken.length > 0) {
                this.props.handleGetKPollenKalender(this.props.kToken.kToken, null);
            }
        } catch (error) { console.log('202108250901', error) }
    }

    handlePollenChange = (argPollen) => {
        try{
            if (argPollen && argPollen.toString() === "ALLP"){
                this.props.handleGetKPollenKalender(this.props.kToken.kToken, null);
            }else{
                this.props.handleGetKPollenKalender(this.props.kToken.kToken, argPollen);
            }
        }catch(error){
            console.log('202109081410',error);
        }
    }

    render() {

        return (
            this.props.kPollenKalender ?
                this.props.kPollenKalender.kPollenKalender ?
                    this.props.kPollenKalender.kPollenKalender.length > 0 ?
                        <PollenKalenderComponent deliverError={this.props.deliverError} componentData={this.props.kPollenKalender} PollenChange={this.handlePollenChange}/>
            : null: null: null
        );
    }
};

const mapStateToProps = state => {
    return {
        kPollenKalender: state.kPollenKalenderSubReducer,
        kToken: state.kTokenSubReducer,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        handleGetKPollenKalender: (token, pollen) => dispatch(actionCreators.getKPollenKalender(token, pollen)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PollenKalender);
import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getKGartenTipp = (argToken, argMonth) => {

    try {
        return dispatch => {
            dispatch(getKGartenTippStarted());
            const axiosInstance = axios.create({
                baseURL: ' http://165.227.162.31:8000/v4/',
                headers: { 'Authorization': `Bearer ${argToken}` }
            });

            axiosInstance.get(`home/garden_tip?month=${argMonth}`)
                .then(response => {
                    if (response && response.data && response.data.data) {
                        dispatch(getKGartenTippSuccess(response.data.data));
                    }
                    else {
                        dispatch(getKGartenTippFailure('Something went wrong'));
                    }
                })
                .catch(error => {
                    dispatch(getKGartenTippFailure(error.message));
                })
        };
        // eslint-disable-next-line no-unreachable
    } catch (error) { console.log('202108251533', error) }
};

const getKGartenTippSuccess = data => ({
    type: actionTypes.K_GET_GARTENTIPP_SUCCESS,
    payload: data
});

const getKGartenTippStarted = () => ({
    type: actionTypes.K_GET_GARTENTIPP_STARTED,
});

const getKGartenTippFailure = error => ({
    type: actionTypes.K_GET_GARTENTIPP_FAILURE,
    payload: {
        error
    }
});

export const getCacheKGartenTippData = () => ({
    type: actionTypes.K_GET_GARTENTIPP_CACHED,
});
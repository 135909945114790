import React, { memo, useState } from 'react';
import {
    Typography,
    Breadcrumbs,
    Grid,
    Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as SiteLink } from 'react-router-dom';

import cssModule from './Gartenwetter.module.css';
import ImageLexi from '../../../../Assets/Desktop/Common/socken.jpg';

const useStyles = makeStyles((theme) => ({

    head: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '3rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    head1: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '2.5rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    head2: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '1.9rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    head3: {
        textTransform: "none",
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '1.5rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    subHeadDark: {
        textTransform: "none",
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '500',
        fontSize: '1.2rem'
    },
    data: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '15px'
    },
    dataLast: {
        color: 'rgba(34, 34, 34, 1)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
        marginBottom: '0px'
    },
    dataBlue: {
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '300',
    },
    dataLink: {
        color: 'rgba(0, 62, 133, .8)',
        fontSize: '1.1rem',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '400',
    },
    lexiData:{
        display:'flex',
    },
    lexiImage: {
        maxWidth: '100%',
        display: 'block',
        height: 'auto',
        borderRadius: '5px'
    },
    lexiImageParent: {
        margin: '0px',
        width: '300px'
    },
    lexiImageCaption: {
        fontSize: '.8rem',
        marginTop: '5px',
        fontFamily: 'Source Sans Pro, sans-serif !important',
        fontWeight: '400'
    },
    lexiDataText:{
        padding:'0px 10px 0px 20px',
        display:'flex',
        alignItems:'flex-start'
    },
    monthImagesParent:{
        textAlign: 'center',
        marginBottom:'1.5rem',
        marginTop:'1.5rem'
    },
    monthImage:{
        height: '27rem'
    },
}));

const WetterLegende = memo((props) => {

    const classes = useStyles();

    const [hookWetterTippIndex, setWetterTippIndex] = React.useState(1);
    const [hookWetterLexikonIndex, setWetterLexikonIndex] = React.useState(1);

    React.useEffect(() => {
        try {

            if (props.gartenWetterData && props.gartenWetterData.kGartenWetter && props.gartenWetterData.kGartenWetter.length > 0){
                setWetterLexikonIndex(randomIntFromInterval(1, props.gartenWetterData.kGartenWetter.length));
            }else{
                setWetterLexikonIndex(1);
            }

            if (props.gartenTippData && props.gartenTippData.kGartenTipp && props.gartenTippData.kGartenTipp.length > 0){
                setWetterTippIndex(randomIntFromInterval(1, props.gartenTippData.kGartenTipp.length));
            }else{
                setWetterTippIndex(1);
            }

        } catch (error) { console.log('202109071723', error) }
    }, [props.gartenWetterData, props.gartenTippData]);

    function randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }


    function handleClick(event) {
        try {
            event.preventDefault();
        } catch (error) { console.log('202103101712', error) }
    }

    const getMonthName = () => {
        try{
            return new Date().toLocaleDateString('de-DE',{month: 'long'})
        }catch(error){
            console.log('202108301617',error);
        }
    }

    let gartenLexikonOrganizer, gartenTippOrganizer = null;

    try {

        if (props.gartenWetterData && props.gartenWetterData.kGartenWetter && props.gartenWetterData.kGartenWetter.length > 0){
            gartenLexikonOrganizer = (
                <React.Fragment>
                    <Typography className={classes.head2} variant="h3" component="h3" gutterBottom>Gartenlexikon im {getMonthName()}:&nbsp;{
                        props.gartenWetterData.kGartenWetter[hookWetterLexikonIndex - 1] ? 
                        props.gartenWetterData.kGartenWetter[hookWetterLexikonIndex - 1].term ? 
                        props.gartenWetterData.kGartenWetter[hookWetterLexikonIndex - 1].term : null : null}</Typography>
                    <div className={classes.lexiData}>
                        <div className={classes.lexiDataImage}>
                            <figure className={classes.lexiImageParent}>
                                <img src={`http://167.172.173.171/images/gartenlexikon/${props.gartenWetterData.kGartenWetter[hookWetterLexikonIndex - 1] ? 
                                props.gartenWetterData.kGartenWetter[hookWetterLexikonIndex - 1].image ? 
                                props.gartenWetterData.kGartenWetter[hookWetterLexikonIndex - 1].image : null : null}`} alt={
                                    props.gartenWetterData.kGartenWetter[hookWetterLexikonIndex - 1] ?
                                        props.gartenWetterData.kGartenWetter[hookWetterLexikonIndex - 1].term ?
                                            props.gartenWetterData.kGartenWetter[hookWetterLexikonIndex - 1].term : "Lexikon Image" : "Lexikon Image"
                                } className={classes.lexiImage} />
                                <figcaption className={classes.lexiImageCaption}>{props.gartenWetterData.kGartenWetter[hookWetterLexikonIndex - 1] ? 
                                props.gartenWetterData.kGartenWetter[hookWetterLexikonIndex - 1].image_source ? 
                                props.gartenWetterData.kGartenWetter[hookWetterLexikonIndex - 1].image_source : null : null}</figcaption>
                            </figure>
                        </div>
                        <div className={classes.lexiDataText}>
                            <Typography className={classes.data} variant="body1" component="p">
                                {props.gartenWetterData.kGartenWetter[hookWetterLexikonIndex - 1] ?
                                    props.gartenWetterData.kGartenWetter[hookWetterLexikonIndex - 1].explaination ?
                                        props.gartenWetterData.kGartenWetter[hookWetterLexikonIndex - 1].explaination : null : null}
                            </Typography>
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        if (props.gartenTippData && props.gartenTippData.kGartenTipp && props.gartenTippData.kGartenTipp.length > 0){
            gartenTippOrganizer = (
                <React.Fragment>
                    <Typography className={classes.head2} variant="h3" component="h3" gutterBottom>Gartentipp:&nbsp;{props.gartenTippData.kGartenTipp[hookWetterTippIndex - 1] ? 
                    props.gartenTippData.kGartenTipp[hookWetterTippIndex - 1].term ? 
                            props.gartenTippData.kGartenTipp[hookWetterTippIndex - 1].term : null : null} </Typography>
                    <div className={classes.lexiData}>
                        {/*<div className={classes.lexiDataImage}>
                            <figure className={classes.lexiImageParent}>
                                <img src={ImageLexi} alt="Biowetter" className={classes.lexiImage} />
                                <figcaption className={classes.lexiImageCaption}>Ingo Bartussek / AdobeStock</figcaption>
                            </figure>
                        </div>*/}
                        <div className={classes.lexiDataTextSep}>
                            <Typography className={classes.data} variant="body1" component="p">
                                {props.gartenTippData.kGartenTipp[hookWetterTippIndex - 1] ?
                                props.gartenTippData.kGartenTipp[hookWetterTippIndex - 1].explaination ?
                                props.gartenTippData.kGartenTipp[hookWetterTippIndex - 1].explaination : null : null}
                            </Typography>
                        </div>
                    </div>
                </React.Fragment>
            );
        }

    } catch (error) { console.log('202103101519', error) }

    return (
        <React.Fragment>
            <div className={cssModule.WetterSymbolLegendeParent}>
                <div className={cssModule.HeadSection}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        <SiteLink className={cssModule.BreadcrubsLink} color="inherit" to="/" onClick={handleClick}>Home</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Freizeit</SiteLink>
                        <SiteLink className={cssModule.BreadcrubsNonLink} color="inherit" onClick={handleClick}>Gartenwetter</SiteLink>
                    </Breadcrumbs>
                </div>
                <div className={cssModule.DataSection}>
                    <React.Fragment>
                        <Grid container spacing={3}>
                                <Grid item xs={12} gutterBottom>
                                <Typography className={classes.head} variant="h1" component="h1" gutterBottom>Das Gartenwetter für Deutschland</Typography>

                                    {gartenLexikonOrganizer}

                                </Grid>
                                <Grid item xs={12}>
                                    <Divider/>
                                </Grid>
                                <Grid item xs={12}>
                                <Typography className={classes.head2} variant="h3" component="h3" gutterBottom>Phänologischer Kalender</Typography>
                                <div className={classes.monthImagesParent}>
                                    <img src={`http://167.172.173.171/images/gartenlexikon/phaenologischer-kalender-${new Date().getMonth() + 1}.png`} alt="Phänologischer Kalender" className={classes.monthImage} />
                                </div>
                                <div className={classes.lexiDataTextSep}>
                                        <Typography className={classes.data} variant="body1" component="p">
                                            Die Phänologie beschreibt die Entwicklungserscheinungen in der Natur, die im Jahresverlauf regelmäßig auftreten und 
                                            bezieht sich dabei vor allem auf die Ökologie und die Biogeografie. Mittels eines phänologischen Kalenders werden 
                                            die Eintrittszeiten bestimmter Erscheinungen erfasst. Insgesamt gibt es 10 phänologische Zeiten. Allerdings ist das 
                                            Eintreten dieser Zeiten regional unterschiedlich und deckt sich nicht mit den meteorologischen oder astronomischen 
                                            Jahreszeiten. Im Süden beginnen pflanzliche Entwicklungen in der Regel früher als im Norden, im Flachland ebenfalls 
                                            eher als in höheren Lagen. Der phänologische Kalender dient in der Landwirtschaft als Grundlage zur Beurteilung, 
                                            welche Arbeiten anstehen und durchzuführen sind. Das Pflanzenjahr beginnt mit dem Vorfrühling Ende Februar-Anfang 
                                            März. Die ersten Schneeglöckchen und der Winterjasmin blühen, Haselnuss und Salweide zeigen ebenfalls Blüten. 
                                            Während des Erstfrühlings blühen Forsythie, Kirsche und Birne. Die Natur ergrünt. Landwirtschaftliche Produkte 
                                            wie Kartoffeln und Rüben werden in die Erde gebracht. Der Vollfrühling im April und Mai bringt dann überall das 
                                            Grün zurück. Markant ist vor allem die Blüte des Apfels. Im Frühsommer, der größtenteils im Juni herrscht, blühen 
                                            vorrangig Gräser und der Schwarze Holunder. Die heißeste Zeit des Jahres wird durch den Hochsommer gekennzeichnet. 
                                            Dann blüht die Sommerlinde, Johannisbeeren reifen. Der Spätsommer lässt frühe Obstsorten reifen. Die zweite Heuernte 
                                            wird eingefahren, das Heidekraut beginnt zu blühen. Während des Frühherbstes reifen der Schwarze Holunder und die 
                                            Haselnuss. Im Vollherbst können Quitten, Walnüsse, Äpfel und Wein geerntet werden. Das Laub beginnt sich zu verfärben. 
                                            Mit dem Laubabwurf der Wildbäume setzt der Spätherbst ein. Ab Ende November herrscht dann Winter, die Vegetation ruht 
                                            bis zum Start ins neue Gartenjahr.
                                        </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12} gutterBottom>

                                {gartenTippOrganizer}

                            </Grid>
                        </Grid>
                    </React.Fragment>
                </div>
            </div>
        </React.Fragment>
    );
});

export default WetterLegende;
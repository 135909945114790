import * as actionTypes from './actionTypes';
import axios from 'axios';

export const getKWetternews = (argToken, argPageVal) => {
    try {
        return dispatch => {
            dispatch(getKWetternewsStarted());
            const axiosInstance = axios.create({
                baseURL: 'http://165.227.162.31:8000/v4/',
                headers: { 'Authorization': `Bearer ${argToken}` }
            });

            axiosInstance.get(`news/${parseInt(argPageVal)}?locale=en`)
                .then(response => {
                    if (response && response.data && response.data.data && response.data.data.length > 0) {
                        if(parseInt(argPageVal) === 0){
                            dispatch(getKWetternewsSuccess(response.data.data));
                        }else{
                            dispatch(addKWetternewsSuccess(response.data.data));
                        }
                    }
                    else {
                        dispatch(getKWetternewsFailure('Something went wrong'));
                    }
                })
                .catch(error => {
                    dispatch(getKWetternewsFailure(error.message));
                })
        };
        // eslint-disable-next-line no-unreachable
    } catch (error) { console.log('202102231125', error) }
};

const getKWetternewsSuccess = data => ({
    type: actionTypes.K_GET_WETTERNEWS_SUCCESS,
    payload: data
});

const addKWetternewsSuccess = data => ({
    type: actionTypes.K_ADD_WETTERNEWS_SUCCESS,
    payload: data
});

const getKWetternewsStarted = () => ({
    type: actionTypes.K_GET_WETTERNEWS_STARTED,
});

const getKWetternewsFailure = error => ({
    type: actionTypes.K_GET_WETTERNEWS_FAILURE,
    payload: {
        error
    }
});

export const getCacheKWetternewsData = () => ({
    type: actionTypes.K_GET_WETTERNEWS_CACHED,
})
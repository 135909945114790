import React, { Component, Fragment } from 'react';
import ScrollToTop from 'react-scroll-up';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import Navigation from '../Navigation/Navigation.js';
//import BGImage from '../../../Assets/Desktop/Common/wetter-net-background.jpg';
import BGImageFrühling from '../../../Assets/Desktop/Common/Frühling_AdobeStock_40352142_g-vision.jpg';
import BGImageHerbst from '../../../Assets/Desktop/Common/Herbst_AdobeStock_34988235_Visions-AD.jpg';
import BGImageSommer from '../../../Assets/Desktop/Common/Sommer_AdobeStock_667042_tomertu.jpg';
import BGImageWinter from '../../../Assets/Desktop/Common/Winter_AdobeStock_1107956_Eugeny_Shevchenko.jpg';
import cssModule from './Layout.module.css';

class Layout extends Component {

    getBGImage = () => {
        try {

            let nowDate = new Date();
            nowDate = new Date(nowDate.setHours(0, 0, 0));

            let nowYear = new Date().getFullYear();

            let dateFrühlingMin = new Date(nowYear, 3 - 1, 1, 0, 0, 0);
            let dateFrühlingMax = new Date(nowYear, 5 - 1, 31, 23, 59, 59);

            let dateSommerMin = new Date(nowYear, 6 - 1, 1, 0, 0, 0);
            let dateSommerMax = new Date(nowYear, 8 - 1, 31, 23, 59, 59);

            let dateHerbstMin = new Date(nowYear, 9 - 1, 1, 0, 0, 0);
            let dateHerbstMax = new Date(nowYear, 11 - 1, 30, 23, 59, 59);

            let dateWinterMaxBeg, dateWinterMinBeg, dateWinterMaxEnd, dateWinterMinEnd = null;

            dateWinterMinBeg = new Date(nowYear, 1 - 1, 1, 0, 0, 0);

            if (new Date(nowYear, 1, 29).getMonth() === 1) {
                dateWinterMaxBeg = new Date(nowYear, 2 - 1, 29, 23, 59, 59);
            } else {
                dateWinterMaxBeg = new Date(nowYear, 2 - 1, 28, 23, 59, 59);
            }

            dateWinterMinEnd = new Date(nowYear, 12 - 1, 1, 0, 0, 0);
            dateWinterMaxEnd = new Date(nowYear, 12 - 1, 31, 23, 59, 59);

            if (nowDate >= dateFrühlingMin && nowDate <= dateFrühlingMax) {
                return 'http://167.172.173.171/images/background_images/Frühling_AdobeStock_40352142_g-vision.jpg';
            } else if (nowDate >= dateSommerMin && nowDate <= dateSommerMax) {
                return 'http://167.172.173.171/images/background_images/Sommer_AdobeStock_667042_tomertu.jpg';
            } else if (nowDate >= dateHerbstMin && nowDate <= dateHerbstMax) {
                return 'http://167.172.173.171/images/background_images/Herbst_AdobeStock_34988235_Visions-AD.jpg';
            } else if (nowDate >= dateWinterMinBeg && nowDate <= dateWinterMaxBeg) {
                return 'http://167.172.173.171/images/background_images/Winter_AdobeStock_1107956_Eugeny Shevchenko.jpg';
            } else if (nowDate >= dateWinterMinEnd && nowDate <= dateWinterMaxEnd) {
                return 'http://167.172.173.171/images/background_images/Winter_AdobeStock_1107956_Eugeny Shevchenko.jpg';
            } else {
                return 'http://167.172.173.171/images/background_images/Winter_AdobeStock_1107956_Eugeny Shevchenko.jpg';
            }

        } catch (error) {
            console.log('202107200955', error);
        }
    }


    render() {
        return (
            <Fragment>
                <div style={{ background: "url(" + this.getBGImage() + ") no-repeat center center fixed", minHeight: '100vh'}} >
                    <Navigation />
                </div>
                <ScrollToTop showUnder={750} style={{ background: 'rgb(0,62,122)', borderRadius: '3px', bottom: '125px'}}>
                    <span className={cssModule.ToTopButton}>
                        <ExpandLessIcon style={{ fill: "white" }} fontSize="large"/>
                    </span>
                </ScrollToTop>
            </Fragment>
        );
    }
}

export default Layout;
import * as actionTypes from '../actions/actionTypes';
import { loadState } from '../../localStorage';

let localCache = loadState();
let initialState = null;

if (localCache && localCache.video) {
    initialState = localCache.video;
}
else {
    initialState = {
        video: null,
        loading: false,
        error: null
    }
}

const reducer = (state = initialState, action) => {
    try{

    if (action.type === actionTypes.GET_VIDEO_SUCCESS) {
        return {
            ...state,
            loading: false,
            error: null,
            video: action.payload
        }
    } else if (action.type === actionTypes.GET_VIDEO_STARTED) {
        return {
            ...state,
            loading: true
        }
    }
    else if (action.type === actionTypes.GET_VIDEO_FAILURE) {
        return {
            ...state,
            loading: false,
            error: action.payload.error,
        }
    }
    else if (action.type === actionTypes.GET_VIDEO_ADD) {
        return {
            ...state,
            loading: false,
            error: null,
            video: state.video.concat(action.payload)
        }
    } else if (action.type === actionTypes.RESET_VIDEO_ERROR) {
        return {
            ...state,
            error: null,
        }
    }
    else {
        return state;
    }
    } catch (error) { console.log('202102191556', error) }
};

export default reducer;